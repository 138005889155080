import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link, useHistory, useLocation, } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../_metronic/_helpers/AtHelper";

export default function ResetPassword(props) {
    const [accountVerified, setAccountVerified] = useState(false);
    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [tab, settab] = useState([0, 0, 0, 0]);
    const [showpassword, setShowpassword] = useState();
    const {t} = useTranslation();
    const [initValues, setInitValues] = useState({
      Email: "",
      Token: "",
      Password: "",
      ConfirmPassword: "",
    });
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
      try {
        const url2 = location?.search;
        let param = url2?.replace("?code=", "");
        let code = param?.split("%3D%3D,,,")[0] + "==";
        var emailSplit = param?.split(",,,")[1];
        emailSplit = emailSplit.split('&apn')[0];
        code = code.split(',,,')[0];
        console.log('emailSplit',emailSplit)
        console.log('code',code)
        if (code) {
          setAccountVerified(true);
          setToken(code);
          setEmail(emailSplit);
        }
      } catch (err) {
        console.error(err);
      }
    }, [location]);
    const [disableClick, setDisableClick] = useState(false);

    function sendCodeFunction() {
        setDisableClick(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let data =  {...initValues};
        data.Token = token;
        data.Email = email;
        var raw = JSON.stringify(data);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${window.$apiUrl}/api/Account/ResetPassword`, requestOptions)
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then((result) => {
            if (result[0] === 200) {
              setAccountVerified(true);
              setDisableClick(true);
              Swal.fire({
                position: "Center",
                icon: "success",
                title: t("Your_Password_has_been_updated_successfully"),
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push("/auth/login");
                }
              });
            } else {
              if (
                result[1].ModelState !== null &&
                result[1].ModelState !== undefined &&
                result[1].ModelState !== ""
              ) {
                let modelState = result[1].ModelState;
                if (modelState)
                  Object.keys(modelState).forEach(function(k) {
                    modelState[k].forEach((element) => {
                      Swal.fire(t("Error"), translateAuto(element), "error");
                    });
                  });
              } else if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Swal.fire(t("Error"), translateAuto(result[1].Message));
              } else if (
                result[1].error_description !== null &&
                result[1].error_description !== undefined &&
                result[1].error_description !== ""
              ) {
                Swal.fire(t("Error"), translateAuto(result[1].error_description));
              } else {
                Swal.fire(t("Error"), t("Bad_Request"));
              }
              setDisableClick(false);
            }
          })
          .catch((error) => {
            setDisableClick(false);
            console.log("error", error);
          });

    }

    return (
      <>
        {!accountVerified ? (
          <>
            <div
              className="login login-3 login-signin-on d-flex flex-row-fluid"
              id=""
            >
              <div
                className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
                style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
              >
                <div className="col-xl-6 col-lg-6 col-md-10 col-12  p-7 position-relative overflow-hidden">
                  <div className="card">
                    <div className="card-header card-header-tabs-line">
                      <div className="card-body">
                        <Row className="form-group">
                          <Col>
                            <div className="d-flex flex-center mb-15">
                              <a href={() => false}>
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/logos/TaapLogo.svg"
                                  )}
                                />
                              </a>
                            </div>
                          </Col>
                        </Row>
                        <div className="login-signin">
                          <div className="text-center">
                            <h2 className="font-size-h1 mb-5 font-weight-bolder">
                              Verify Your Email
                            </h2>
                            <div className="font-size-h3 text-muted font-weight-bold">
                              We have sent an email from
                              <a
                                href={() => false()}
                                className="text-primary text-decoration-none font-weight-bolder line-height-lg my-3"
                              >
                                {" "}
                                support@mail.empowerdl.com
                              </a>
                              <br />
                              please follow a link to verify your email
                            </div>
                            <div className="text-center my-10">
                              <Link
                                to="/auth/login"
                                className="btn btn-lg btnPrimaryTaap font-weight-bolder"
                              >
                                Log In
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end::Login Sign in form*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Login*/}
            </div>
          </>
        ) : (
          <>
            <div
              className="login login-3 login-signin-on d-flex flex-row-fluid"
              id=""
            >
              <div
                className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
                style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
              >
                <div className="col-xl-5 col-lg-5 col-md-10 col-12  p-7 position-relative overflow-hidden">
                  {/*begin::Login Header*/}
                  <div className="d-flex flex-center mb-15">
                    <a href={() => false}>
                      <SVG src={toAbsoluteUrl("/media/svg/logos/TaapLogo.svg")} />
                    </a>
                  </div>
                  {/*end::Login Header*/}
                  {/*begin::Login Sign in form*/}
                  <div className="card card-custom">
                    <div className="card-header card-header-tabs-line">
                      <div className="card-body">
                        <div className="login-signin">
                          <div className="mb-20 text-center">
                            <h3 className="font-size-h1">Setup New Password</h3>
                            <div className="text-muted font-weight-bold">
                              Already have reset your password ?{" "}
                              <span className="text-primary">
                                <Link to="/aut/login">Sign in here</Link>
                              </span>
                            </div>
                          </div>

                          <Form.Group controlId="formHorizontalEmail">
                            <Form.Label>Password</Form.Label>
                            <div className="mb-1">
                              {/*begin::Input wrapper*/}
                              <div className="position-relative mb-3">
                                <input
                                  onChange={(e) => {
                                    let objt = { ...initValues };
                                    objt.Password = e.target.value;
                                    setInitValues(objt);
                                    settab([0, 0, 0, 0]);
                                    let obj = [0, 0, 0, 0];

                                    if (e.target.value.length > 7) {
                                      obj[0] = 1;
                                    }
                                    if (/[A-Z]/.test(e.target.value)) {
                                      obj[1] = 1;
                                    }

                                    if (/[0-9]/.test(e.target.value)) {
                                      obj[2] = 1;
                                    }
                                    if (
                                      // eslint-disable-next-line no-useless-escape
                                      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
                                        e.target.value
                                      )
                                    ) {
                                      obj[3] = 1;
                                    }

                                    settab(obj);
                                  }}
                                  className="form-control form-control-lg form-control-solid"
                                  type={showpassword ? "text" : "password"}
                                  placeholder
                                  name="password"
                                  autoComplete="off"
                                />
                                <div className="position-absolute translate-middle-c top-50 end-0 mr-3">
                                  <span
                                    className="svg-icon svg-icon-2hx"
                                    onClick={() =>
                                      setShowpassword(
                                        showpassword ? false : true
                                      )
                                    }
                                  >
                                    <img
                                      src={toAbsoluteUrl(
                                        showpassword
                                          ? "/media/svg/icons/General/Visible.svg"
                                          : "/media/svg/icons/General/Hidden.svg"
                                      )}
                                      alt=""
                                      class="h-25px"
                                    />
                                  </span>
                                </div>
                              </div>
                              {/*end::Input wrapper*/}
                              {/*begin::Meter*/}
                              <div
                                className="d-flex align-items-center mb-3"
                                data-kt-password-meter-control="highlight"
                              >
                                <div
                                  className={`${
                                    tab.filter((x) => x === 1).length > 0
                                      ? "flex-grow-1 bg-secondary bg-success rounded h-5px mr-2"
                                      : "flex-grow-1 bg-secondary bg-active-success rounded h-5px mr-2"
                                  }`}
                                />
                                <div
                                  className={`${
                                    tab.filter((x) => x === 1).length > 1
                                      ? "flex-grow-1 bg-secondary bg-success rounded h-5px mr-2"
                                      : "flex-grow-1 bg-secondary bg-active-success rounded h-5px mr-2"
                                  }`}
                                />
                                <div
                                  className={`${
                                    tab.filter((x) => x === 1).length > 2
                                      ? "flex-grow-1 bg-secondary bg-success rounded h-5px mr-2"
                                      : "flex-grow-1 bg-secondary bg-active-success rounded h-5px mr-2"
                                  }`}
                                />
                                <div
                                  className={`${
                                    tab.filter((x) => x === 1).length > 3
                                      ? "flex-grow-1 bg-secondary bg-success rounded h-5px mr-2"
                                      : "flex-grow-1 bg-secondary bg-active-success rounded h-5px mr-2"
                                  }`}
                                />
                              </div>
                              {/*end::Meter*/}
                            </div>
                          </Form.Group>
                          <Form.Group controlId="formHorizontalEmail">
                            <Form.Label>Confirm Password</Form.Label>
                            <div className="mb-1">
                              {/*begin::Input wrapper*/}
                              <input
                                onChange={(e) => {
                                  let objt = { ...initValues };
                                  objt.ConfirmPassword = e.target.value;
                                  setInitValues(objt);
                                }}
                                className="form-control form-control-lg form-control-solid"
                                type="password"
                                placeholder
                                name="password"
                                autoComplete="off"
                              />
                            </div>
                          </Form.Group>
                          <div className="form-group d-flex flex-wrap flex-center">
                            <button
                            className={`${
                                disableClick
                                  ? "spinner spinner-white spinner-left px-md-12"
                                  : "px-9"
                              } btn btnPrimaryTaap font-weight-bold  py-4 my-3 mx-4`}
                              disabled={disableClick}
                                onClick={() => {
                                sendCodeFunction();
                              }}
                            >
                              Submit
                            </button>
                            <Link to="/auth">
                              <button
                                type="button"
                                id="kt_login_forgot_cancel"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                              >
                                Cancel
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Login Sign in form*/}
                  </div>
                </div>
              </div>
              {/*end::Login*/}
            </div>
          </>
        )}
      </>
    );
}
