import React, { useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { sortCaret, toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ConvertToDateCurrentTimeZone } from "../../../../../../_metronic/_helpers/AtHelper";
import { useTranslation } from "react-i18next";

export default function ActivityFunction({ Activitys = [] }) {
  const {t} = useTranslation();
  const columns = [
    {
      dataField: "Icon",
      text: "",
      classes: "text-center",
      formatter: (cell, row) => {
        if (row.Fields.length !== 0) {
          return (
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip id="customers-delete-tooltip">
                    {t("Activity_Details")}
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-warning btn-sm"
                  onClick={() => {}}
                >
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "User",
      text: t("User"),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Created",
      text: t("Date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <div className="align-items-center">
              {ConvertToDateCurrentTimeZone(row.Created)}
            </div>
          </>
        );
      },
    },
    {
      dataField: "Action",
      text: t("Event"),
      sort: true,
      sortCaret: sortCaret,
    },
  ];
  const [Activities, setActivities] = useState(
    NewActivites(
      Activitys.map((a, Index) => {
        a.Id = Index;
        return a;
      })
    )
  );

  function NewActivites(activitys = []) {
    try {
      var list = JSON.parse(JSON.stringify(activitys));
      (list ?? []).forEach((a, Index) => {
        a.Id = Index;
      });
      return list;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const expandRow = {
    renderer: (row) => {
      if (row.Fields.length !== 0) {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Field")}</TableCell>
                <TableCell>{t("Old_Value")}</TableCell>
                <TableCell>{t("New_Value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.Fields.map((x) => {
                return (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {x.Field}
                    </TableCell>
                    <TableCell>{x?.OldValue}</TableCell>
                    <TableCell>{x?.NewValue}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        );
      }
    },
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="form-group row">
          <div className="col-lg-5">
            <input
              type="text"
              id="SearchActivitiesInput"
              className="form-control"
              name="searchText"
              placeholder={t("Search")}
              onChange={(e) => {
                setActivities(
                  Activitys.filter(
                    (a) =>
                      String(a.Action)
                        .toLocaleLowerCase()
                        .trim()
                        .includes(
                          String(e.target.value)
                            .toLocaleLowerCase()
                            .trim()
                        ) ||
                      String(a.User)
                        .toLocaleLowerCase()
                        .trim()
                        .includes(
                          String(e.target.value)
                            .toLocaleLowerCase()
                            .trim()
                        )
                  )
                );
              }}
            />
            <small className="form-text text-muted">
              <b>{t("Search")}</b> {t("in_all_fields")}
            </small>
          </div>
          <div className="col-md-7">
            <div className="row">
              <OverlayTrigger
                overlay={
                  <Tooltip id="customers-delete-tooltip">
                    {t("All_Activities")}
                  </Tooltip>
                }
              >
                <Link
                  onClick={() => {
                    document.getElementById("SearchActivitiesInput").value = "";
                    setActivities(Activitys);
                  }}
                  to="#"
                  className="nav-link align-self-center btn btn-icon  btn-hover-primary mr-2"
                >
                  <i className="fas fa-list-ul icon-lg "></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id="customers-delete-tooltip">{t("Updates")}</Tooltip>
                }
              >
                <Link
                  onClick={() => {
                    document.getElementById("SearchActivitiesInput").value = "";
                    setActivities(
                      Activitys.filter(
                        (a) =>
                          String(a.Action)
                            .toLocaleLowerCase()
                            .trim()
                            .includes(
                              String("Update")
                                .toLocaleLowerCase()
                                .trim()
                            ) &&
                          !String(a.Action)
                            .toLocaleLowerCase()
                            .trim()
                            .includes(
                              String("status")
                                .toLocaleLowerCase()
                                .trim()
                            ) &&
                          !String(a.Action)
                            .toLocaleLowerCase()
                            .trim()
                            .includes(
                              String("receivables")
                                .toLocaleLowerCase()
                                .trim()
                            ) &&
                          !String(a.Action)
                            .toLocaleLowerCase()
                            .trim()
                            .includes(
                              String("payables")
                                .toLocaleLowerCase()
                                .trim()
                            )
                      )
                    );
                  }}
                  to="#"
                  className="nav-link align-self-center btn btn-icon btn-hover-primary mr-2"
                >
                  <i className="fas fa-edit icon-lg "></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id="customers-delete-tooltip">{t("Creation")}</Tooltip>
                }
              >
                <Link
                  onClick={() => {
                    document.getElementById("SearchActivitiesInput").value = "";

                    setActivities(
                      Activitys.filter((a) =>
                        String(a.Action)
                          .toLocaleLowerCase()
                          .trim()
                          .includes(
                            String("Create")
                              .toLocaleLowerCase()
                              .trim()
                          )
                      )
                    );
                  }}
                  to="#"
                  className="nav-link align-self-center btn btn-icon btn-hover-primary mr-2"
                >
                  <i className="far fa-plus-square icon-lg "></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id="customers-delete-tooltip">
                    {t("Status_changes")}
                  </Tooltip>
                }
              >
                <Link
                  onClick={() => {
                    let vv = [...Activitys];
                    let vv2 = [...Activitys];
                    let filteredArray = vv
                      .filter(
                        (element) =>
                          element.Fields.some(
                            (subElement) => subElement.Field === "Status Id"
                          ) && element.Action === "Updated from Trinium"
                      )
                      .map((element) => {
                        return Object.assign({}, element);
                      });

                    let filteredArray2 = vv2.filter((a) =>
                      String(a.Action)
                        .toLocaleLowerCase()
                        .trim()
                        .includes(
                          String("status")
                            .toLocaleLowerCase()
                            .trim()
                        )
                    );
                    document.getElementById("SearchActivitiesInput").value = "";
                    let uniunarr = filteredArray.concat(filteredArray2);
                    setActivities(uniunarr);
                  }}
                  to="#"
                  className="nav-link btn btn-icon btn-hover-primary mr-2"
                >
                  <i className="ki ki-reload icon-lg"></i>
                </Link>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12" >
        <BootstrapTable
          keyField="Created"
          defaultSorted={[{ dataField: "Created", order: "desc" }]}
          data={Activities || []}
          columns={columns}
          noDataIndication={
            <h4 style={{ textAlign: "center" }}>
              {t("no_data_available")}
            </h4>
          }
          pagination={paginationFactory()}
          expandRow={expandRow}
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          bordered={false}
        />
      </div>
    </>
  );
}
