import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
function FaqPage(props) {
    return (
        <div className="login login-3 login-signin-on d-flex flex-row-fluid" style={{ display: "block" }}>
            <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid" style={{ backgroundImage: 'url(/media/bg/bg-3.jpg)' }}>
                <div className="col-xl-8 col-lg-10 col-md-12 col-12  p-7 position-relative overflow-hidden">
                    <div className="d-flex flex-center mb-15">
                        <Link to="/auth/login">
                            <SVG src={toAbsoluteUrl("/media/svg/logos/TaapLogo.svg")} />
                        </Link>
                    </div>
                    <div className="col-12 text-center mb-6">
                        <h1 className="font-weight-bolder tet-dark-75">Frequently Asked Questions</h1>
                    </div>
                    <div className="card border-0 shadow-none">
                        <div className="card-body">
                            <div className="elementor-widget-container">
                                <p><strong>Can anyone post anything on TaaP Load Boards?</strong></p>
                                <p>In an environment where load hijacking and fraudulent double brokering occur daily, TaaP takes security very seriously. Only verified and reliable transportation professionals are permitted to use TaaP Load Boards. Before we provide service to a customer we make sure they have a valid Motor Carrier (MC) number, Federal Identification number or agent agreement.</p>
                                <p>We also provide you with the tools you need to qualify each company you find before you work with them. Get up-to-date broker credit scores, average days to pay, and bond information at the touch of a button. For brokers, the TaaP CarrierWatch service lets you <a href="/carrierwatch">view current carrier insurance certificates</a> and CSA safety information, as well as DOT authority.</p>
                                <p><strong>Is the TaaP Load Board any better than other boards?</strong></p><p>Yes! TaaP Load Boards don’t just give you the largest marketplace, they also give you the fastest service. Unlike other load boards that only update their information every fifteen seconds, TaaP Load Boards update information in real-time. Seconds count — just a few can be the difference between closing a deal and another search for loads or trucks.</p><p>You may have experience with other load boards that sell you information on loads or trucks that have been gone for days. TaaP Load Boards aren’t padded with old information to make them look busier. Searches on TaaP get you both the most recent matching results and updates when they’re no longer available, so you don’t waste valuable time calling.</p><p>TaaP Load Boards also integrate with powerful tools like <a href="/rateview" target="_blank" rel="noopener">TaaP RateView</a>, a service that provides contract and spot market rate information for tens of thousands of lanes in the US. These tools allow you to quickly judge market conditions and benchmark yourself against competitors, all through your TaaP Load Board.</p><p><strong>How much does a load board cost?</strong></p>
                                <p>TaaP offers pricing options and product packages to meet the specific needs of your company. Whether you’re a large company with many employees, or a smaller company with only a few, TaaP can provide a product that works for you.</p>
                                <p>For more information on pricing and products, contact a product specialist at  (480) 566-7100  or <a href="mailto:support@empowerdl.com" target="_blank" rel="noopener noreferrer">send us an&nbsp;email</a>.</p>
                                <p><strong>How can a TaaP Load Board help me?</strong></p>
                                <p>TaaP Load Boards give you access to the largest spot freight marketplace in the industry. When you use TaaP Load Boards, you are able to post with more visibility than with any other service, and search for more loads and trucks that meet your location and time requirements.</p>
                                <p>For <a href={window.$apiUrl}>carriers</a>, use TaaP Load Boards to fill your backhauls, and avoid running empty. Find loads to get you back home, or plan a triangular route—or trihaul—to increase profit in your lanes, and increase loaded miles by up to 50%.</p>
                                <p>For <a href={window.$apiUrl}>brokers and 3PLs</a>, use TaaP Load Boards to increase the number of loads you are able to manage. 75% of TaaP brokers surveyed were able to handle every load they were offered. Get your loads moving faster, with reliable carriers.</p>
                                <p><strong>Do I need an MC number to sign up for a TaaP load board?</strong></p>
                                <p>You don’t have to have it handy when you first sign up, but you’ll need to add it later. You will need to have your DOT number, though. If you’re looking to get your operating authority, <a href="/authority">we can help with that</a>.</p>
                                <p><strong>What is URS?</strong></p>
                                <p>It stands for Unified Registration System, which will eventually replace MC numbers. It’s an online registration system, and right now, anyone applying for new trucking authority will apply through the URS. The system will eventually be rolled out to include companies that are already registered with the FMCSA.</p>
                                <p><strong>How do I file IFTA taxes, and when are they due?</strong></p>
                                <p>To file your IFTA fuel tax reports, you have to keep track of where you drove, how many miles you drove, fuel receipts or fuel tickets, and odometer readings. You have to file for IFTA four times a year, and you have to keep the records on file for four years. They’re due on Jan. 31, April 30, July 31 and Oct. 31.&nbsp;</p>
                                <p><strong>Why am I not getting any calls on my truck posting?</strong></p>
                                <p>Make sure you are entering a destination when posting. When the&nbsp;destination is left blank, your posting will not appear as a match to a load posting. You’re more likely to get calls if you post your truck with a destination such as states and/or zones. Pro tip: If you really don’t have a preferred destination, list all the zones in your truck post, from Z0 through Z9.</p>
                                <p><strong>Why do I see results that are further away than what I specified in my search?</strong></p>
                                <p>When the search is run, it uses a radius around a city that is in air miles. All the results you see will be within that air mile radius, but the actual driving miles can be further. The rate per mile is based on practical road miles, though, so you can negotiate rate agreements with confidence.</p>
                                <p><strong>What is an ELD, and what is the ELD mandate?</strong></p>
                                <p>ELD stands for Electronic Logging Device. It’s a tool that lets drivers keep a Record of Duty Status (RODS) and track their Hours of Service (HOS) using electronic logbooks (e-logs). The ELD mandate is a rule that requires professional truck drivers and motor carriers to use electronic logbooks for HOS compliance. The requirement took effect on December 18, 2017.</p>
                                <p><strong>If I have an AOBRD, when do I need to&nbsp;switch&nbsp;to an ELD?</strong></p>
                                <p>Carriers and drives who used automatic on-board recording devices (AOBRDs) were permitted to continue using them for two more years after the ELD mandate took effect. All carriers and drivers must switch to ELDs as of December 16, 2019.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default injectIntl(connect(null, auth.actions)(FaqPage));
