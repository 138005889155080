import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Filter from "./Filter";
import BodyTable from "./table";
import { apiCall } from "./redux";
import { Helmet } from "react-helmet";
import { CardHeader } from "../../../../../_metronic/_partials/controls";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const initValues = {
  CustomerName: "",
  ShipperZipCode: "",
  ShipperCity: "",
  ShipperState: "",
  ConsigneeZipCode: "",
  ConsigneeCity: "",
  ConsigneeState: "",
  ModeId: "",
  ShipperReq: "",
  ConsigneeReq: "",
  ShipmentReq: "",
};
function LoadsIndex() {
  const {t} = useTranslation();
  const [filter, setFilter] = useState({
    destination: "",
    isQuickPay: null,
    onlyAllowed: false,
    pickup: "",
    Id: "",
    equipmentType: "",
    pageNumber: 1,
    pageSize: 10,
    sortField: "Id",
    sortOrder: "desc",
  });
  const [paramsGet, setParamsGet] = useState(
    `?destination=${filter.destination}&onlyAllowed=${filter.onlyAllowed}&pickup=${filter.pickup}&onlyAllowed=${filter.onlyAllowed}&isQuickPay=${filter.isQuickPay}&code=${filter.Id}&equipmentType[0]=${filter.equipmentType}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}&sortField=${filter.sortField}&sortOrder=${filter.sortOrder}`
  );
  useEffect(() => {
    fetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [paginationOptions, setpaginationOptions] = useState({
    custom: true,
    totalSize: 0,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
    ],
    sizePerPage: 10,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [modalPost, setModalPost] = useState(false);
  const [dataModal, setDataModal] = useState(initValues);
  // functions *********************
  async function fetchData(params) {
    try {
      const result = await apiCall({
        method: "Get",
        link: `/api/shipments${params === null ? paramsGet : params}`,
      });
      if (result[0] === 200) {
        setData(result[1] ?? []);
        let _paginationOptions = JSON.parse(JSON.stringify(paginationOptions));
        _paginationOptions.totalSize =
          result[1]?.Pagination?.TotalItemCount || 0;
        _paginationOptions.page = result[1]?.Pagination?.PageNumber || 0;
        _paginationOptions.sizePerPage = result[1]?.Pagination?.PageSize || 10;
        setpaginationOptions(_paginationOptions);
      } else {
        setData([]);
      }
    } catch (err) {}
  }
  function fetchFilter(column, value, value2, paginationprop) {
    try {
      let dataFilter = { ...filter };
      if (column === "startDate") {
        dataFilter.startDate = value;
        dataFilter.endDate = value2;
      } else if (column === "reload") {
        //fetch params
        const paramGet1 = `?destination=${dataFilter.destination}&onlyAllowed=${dataFilter.onlyAllowed}&isQuickPay=${dataFilter.isQuickPay}&pickup=${dataFilter.pickup}&code=${dataFilter.Id}&equipmentType[0]=${dataFilter.equipmentType}&pageNumber=${paginationprop.page}&pageSize=${paginationprop.sizePerPage}&sortField=${paginationprop.sortField}&sortOrder=${paginationprop.sortOrder}`;
        setParamsGet(paramGet1);
        setFilter(dataFilter);
        //call api
        fetchData(paramGet1);
        return false;
      } else {
        dataFilter[column] = value;
      }
      const paramGet1 = `?destination=${dataFilter.destination}&onlyAllowed=${dataFilter.onlyAllowed}&isQuickPay=${dataFilter.isQuickPay}&pickup=${dataFilter.pickup}&code=${dataFilter.Id}&equipmentType[0]=${dataFilter.equipmentType}&pageSize=${paginationprop.sizePerPage}&sortField=${paginationprop.sortField}&sortOrder=${paginationprop.sortOrder}`;

      setParamsGet(paramGet1);
      setFilter(dataFilter);

      //call api
      fetchData(paramGet1);
    } catch (err) {}
  }
  const history = useHistory();
  // *******************************

  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [value, setValue] = useState("");
  async function privateLoad(id) {
    try {
      setSearchLoading(true);
      const result = await apiCall({
        method: "Get",
        link: `/api/shipments/0?search=${id}`,
      });
      if (result?.length > 0 && result[0] === 200) {
        const data = result[1];
        if (data?.Id != null && data?.Id !== undefined) {
          history.push(`/load-detail/${data?.Code}?privateLoad=true`);
        }
        // console.log('data', data);
      } else {
          Swal.fire('error', t("Load_not_found"));
      }
      setSearchLoading(false);
    } catch (err) { 
      console.log("err",err);
      setSearchLoading(false);
    }
  }
  return (
    <>
      <Helmet>
        <title>TaaP | {t("Book")}</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Row>
        <Col xl="12" lg="12" md="12">
          <Row className="form-group">
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col lg="12" xl="12" md="12" className="d-flex">
                      <h3 className="card-label mr-4 mt-2">{t("Book")}</h3>
                      {toggleSearch ? (
                        <Fragment>
                          <div className="d-flex" style={{ width: '40%' }}>
                            <input type="text" className="form-control mr-2" placeholder="search for a private load" onChange={(e) => setValue(e.target.value)} />
                            <button type="submit" className={`${searchLoading ? "spinner spinner-white spinner-right" : ""} btn-sm btn btnPrimaryTaap mr-2`} onClick={async (e) => {
                              await privateLoad(value);
                              e.preventDefault();
                            }}>
                              {!searchLoading ? <i className="flaticon2-search text-white"></i> : <span>Loading</span>}
                              
                            </button>
                            {!searchLoading && (
                              <button className="btn btn-danger btn-sm" onClick={(e) => {
                                e.preventDefault();
                                setToggleSearch(false);
                              }}>
                                <i className="flaticon2-delete text-white"></i>
                              </button>
                            )}
                            
                          </div>
                        </Fragment>
                      ) : (
                          <Fragment></Fragment>
                      )}
                      {!toggleSearch && (
                        <button className="btn btnPrimaryTaap btn-sm" onClick={(e) => {
                          setToggleSearch(true);
                          e.preventDefault();
                        }}>
                          <i className="flaticon2-search text-white pr-2"></i>
                          {t("Private_loads")}
                        </button>
                      )}
                      
                    </Col>
                  </Row>
                </CardHeader>
                <Card.Body className="shipmentQuotes">
                  <Row className="form-group">
                    <Col>
                      <Filter
                        fetchFilter={fetchFilter}
                        setDataModal={setDataModal}
                        dataFilter={filter}
                        setFilter={filter}
                        dataModal={dataModal}
                        data={data}
                        initValues={initValues}
                        modalPost={modalPost}
                        setModalPost={setModalPost}
                        paginationOptions={paginationOptions}
                        setpaginationOptions={setpaginationOptions}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BodyTable
                        data={data ?? []}
                        dataFilter={filter}
                        setDataFilter={setFilter}
                        setDataModal={setDataModal}
                        setData={setData}
                        dataModal={dataModal}
                        modalPost={modalPost}
                        setModalPost={setModalPost}
                        fetchFilter={fetchFilter}
                        paginationOptions={paginationOptions}
                        setpaginationOptions={setpaginationOptions}
                        statusId={filter.statusId}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default LoadsIndex;
