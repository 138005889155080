/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import Swal from "sweetalert2";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../../../_metronic/_partials/dropdowns";
import { useTranslation } from "react-i18next";
import { ConvertToDateCurrentTimeZone, translateAuto } from "../../../../../../_metronic/_helpers/AtHelper";

const adapter = new LocalStorage("db");
const db = low(adapter);
export default function NotesComp({ id, IsDetailsMode }) {
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const [NotesList, setNotesList] = useState([]);
  const [showModalNote, setshowModalNote] = useState(false);
  const [initNoteObj, setinitNoteObj] = useState({
    Body: "",
    ShipmentId: id,
    IsPublic: true,
  });
  const { t } = useTranslation();
  function GetNotes() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Notes/${id}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setNotesList(data.Notes || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function PostNote() {
    setisLoadingAuth(true);
    var row = JSON.stringify(initNoteObj);
    return fetch(`${window.$apiurl}/api/Notes`, {
      method: "POST",
      body: row,
      redirect: "follow",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setshowModalNote(false);
          GetNotes();
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire(t("Error"), translateAuto(element), "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_request"));
          }
        }
        setisLoadingAuth(false);
      })
      .catch((error) => {
        Swal.fire(t("Error"), error);
        setisLoadingAuth(false);
      });
  }
  useEffect(() => {
    GetNotes();
  }, [id]);

  return (
    <>
      <Card>
        <CardHeader title={t("Notes")}>
          <CardHeaderToolbar>
            {!IsDetailsMode && (
              <button
                type="button"
                className="btn btn-success mr-4"
                onClick={() => {
                  setinitNoteObj({
                    Body: "",
                    ShipmentId: id,
                    IsPublic: true,
                  });
                  setshowModalNote(true);
                }}
              >
                <i className="fas fa-plus mr-3" />
                {t("Add_Note")}
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            {NotesList.map((item, index) => {
              return (
                <div className="col-xl-4">
                  {/*begin::Card*/}
                  <div className="card card-custom gutter-b card-stretch shadow-sm bg-gray-100">
                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                      {/*begin::Toolbar*/}
                      {item?.IsOwner && !IsDetailsMode && (
                        <div className="d-flex justify-content-end">
                          <Dropdown
                            className="dropdown-inline position-unset"
                            alignRight
                          >
                            <Dropdown.Toggle
                              as={DropdownCustomToggler}
                              id="dropdown-toggle-top"
                            >
                              <i className="ki ki-bold-more-hor" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                              <ul className="navi navi-hover py-5">
                                <li className="navi-item">
                                  <p
                                    className="navi-link"
                                    onClick={() => {
                                      Swal.fire({
                                        title: t("Are_you_sure"),
                                        text:
                                        t("You_wont_be_able_to_revert_this"),
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: t("Yes_delete_it"),
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          var myHeaders = new Headers();
                                          myHeaders.append(
                                            "Authorization",
                                            `Bearer ${
                                              db.read().getState()?.Token
                                            }`
                                          );
                                          myHeaders.append(
                                            "Content-Type",
                                            "application/json"
                                          );

                                          var requestOptions = {
                                            method: "delete",
                                            headers: myHeaders,
                                            redirect: "follow",
                                          };

                                          fetch(
                                            `${window.$apiurl}/api/Notes/${item.Id}`,
                                            requestOptions
                                          )
                                            .then(async (response) => {
                                              const statusCode = await response.status;
                                              const contentType = response.headers.get(
                                                "content-type"
                                              );
                                              if (
                                                contentType &&
                                                contentType.indexOf(
                                                  "application/json"
                                                ) !== -1
                                              ) {
                                                return response
                                                  .json()
                                                  .then((data) => [
                                                    statusCode,
                                                    data,
                                                  ]);
                                              } else {
                                                return response
                                                  .text()
                                                  .then((text) => [
                                                    statusCode,
                                                    { Message: "" },
                                                  ]);
                                              }
                                            })
                                            .then(async (result) => {
                                              if (result[0] === 200) {
                                                Swal.fire(
                                                  t("Deleted"),
                                                  t("Your_note_has_been_deleted"),
                                                  "success"
                                                );
                                                GetNotes();
                                              } else {
                                                if (
                                                  result[1].ModelState !==
                                                    null &&
                                                  result[1].ModelState !==
                                                    undefined &&
                                                  result[1].ModelState !== ""
                                                ) {
                                                  let modelState =
                                                    result[1].ModelState;
                                                  if (modelState)
                                                    Object.keys(
                                                      modelState
                                                    ).forEach(function(k) {
                                                      modelState[k].forEach(
                                                        (element) => {
                                                          Swal.fire(
                                                            "Oops!",
                                                            element,
                                                            "error"
                                                          );
                                                        }
                                                      );
                                                    });
                                                } else if (
                                                  result[1].Message !== null &&
                                                  result[1].Message !==
                                                    undefined &&
                                                  result[1].Message !== ""
                                                ) {
                                                  Swal.fire(
                                                    "Oops!",
                                                    translateAuto(result[1].Message),
                                                    "error"
                                                  );
                                                } else if (
                                                  result[1]
                                                    .error_description !==
                                                    null &&
                                                  result[1]
                                                    .error_description !==
                                                    undefined &&
                                                  result[1]
                                                    .error_description !== ""
                                                ) {
                                                  Swal.fire(
                                                    "Oops!",
                                                    translateAuto(result[1].error_description),
                                                    "error"
                                                  );
                                                } else {
                                                  Swal.fire(
                                                    "Oops!",
                                                    t("Bad_Request"),
                                                    "error"
                                                  );
                                                }
                                              }
                                            })
                                            .catch((error) => {
                                              console.log("error", error);
                                            });
                                        }
                                      });
                                    }}
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger mr-2">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/General/Trash.svg"
                                        )}
                                      />
                                    </span>
                                    <span className="navi-text">Delete</span>
                                  </p>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}

                      {/*end::Toolbar*/}
                      {/*begin::User*/}
                      <div className="d-flex align-items-center mb-7">
                        <div className="d-flex flex-column">
                          <a
                            href={() => false}
                            className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
                          >
                            {item?.AddedBy}
                          </a>
                          <span className="text-muted font-weight-bold">
                            {ConvertToDateCurrentTimeZone(item?.Created)}
                          </span>
                        </div>
                      </div>
                      {/*end::User*/}
                      {/*begin::Desc*/}
                      <p className="">{item?.Body}</p>
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Card*/}
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>
      <Modal
        size="xl"
        show={showModalNote}
        onHide={() => {
          setshowModalNote(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Add_Note")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                <p className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">
                  {t("Note")}
                  <span className="text-danger">*</span>
                </p>
                <span className="font-weight-bold">
                  <textarea
                    className="form-control"
                    maxlength="400"
                    rows="3"
                    value={initNoteObj.Body}
                    onChange={(e) => {
                      let obj = { ...initNoteObj };
                      obj.Body = e.target.value;
                      setinitNoteObj(obj);
                    }}
                  ></textarea>
                </span>
              </div>
            </div>
            <div className="col-md-12 mt-5">
              <label className="checkbox checkbox-success">
                <input
                  type="checkbox"
                  name="Checkboxes5"
                  checked={initNoteObj?.IsPublic}
                  onChange={(e) => {
                    let obj = { ...initNoteObj };
                    obj.IsPublic = e.target.checked;
                    setinitNoteObj(obj);
                  }}
                />
                <span className="mr-2" />
                {t("Public")}
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setshowModalNote(false);
            }}
          >
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            disabled={isLoadingAuth}
            onClick={() => {
              // if (AttachmentInitObj.Id !== undefined) {
              //   PutNote();
              // } else {
              //   PostNote();
              // }
              PostNote();
            }}
          >
            {t("Confirm")}
            {isLoadingAuth && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
