import React from "react";
import {
  Modal
} from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

export function RequiredHtml() {
  return <label className="text-danger pl-2">*</label>;
}

export async function FetchApiExample(method, link, setResult, isResultReturned = false, data = null,token = "") {
  var myHeaders = new Headers();
  if(token !== ""){
    myHeaders.append("Authorization", `Bearer ${token}`);
  }
  var requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
    body : data
  };
  if(ValidateField(data)) requestOptions.body = data;
  return fetch(`${window.$apiUrl}/${link}`, requestOptions)
    .then(async (response) => {
      const statusCode = await response.status;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => [statusCode, data]);
      } else {
        return response.text().then((text) => [statusCode, { Message: "" }]);
      }
    })
    .then(async (result) => {
      if (result[0] === 200) {
        var data = result[1];
        if (setResult) setResult(data);
      }
      if(isResultReturned) return result;
    })
    .catch((error) => {
      console.log("error", error);
    });
}

export function creditCardTypeFromNumber(number) {
  try {
    // visa
    if (!!number) {
      var re = new RegExp("^4");
      if (number.match(re) != null)
        return "Visa";

      // Mastercard 
      // Updated for Mastercard 2017 BINs expansion
      if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
        return "MasterCard";

      // AMEX
      re = new RegExp("^3[47]");
      if (number.match(re) != null)
        return "Amex";

      // Discover
      re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
      if (number.match(re) != null)
        return "Discover";

      // Diners
      re = new RegExp("^36");
      if (number.match(re) != null)
        return "Diners";

      // Diners - Carte Blanche
      re = new RegExp("^30[0-5]");
      if (number.match(re) != null)
        return "Diners - Carte Blanche";

      // JCB
      re = new RegExp("^35(2[89]|[3-8][0-9])");
      if (number.match(re) != null)
        return "JCB";

      // Visa Electron
      re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
      if (number.match(re) != null)
        return "Visa Electron";

      // Union Pay
      if (number.match(/^(62[0-9]{14,17})$/))
        return "Union Pay";
    }
    return "";
  } catch (err) {
    console.log("~err", err);
  }
}

export function ValidateField(value) {
  try {
    if (value === null || value === undefined || value === "") return false;
    return true;
  } catch (err) {
    console.log("~ err", err);
  }
}

export function FetchHeaderModal(props){
  return (
    <Modal.Header>
    <Modal.Title>
      {props?.title}
    </Modal.Title>
    <IconButton
      aria-label="Close"
      onClick={(e) => {
        props.onHide();
      }}
    >
      <CloseIcon />
    </IconButton>
  </Modal.Header>
  )
}