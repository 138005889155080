import objectPath from "object-path";
import React, { useMemo } from "react";
import { useHtmlClassService } from "../../layout";
import { Demo2Dashboard } from "./Demo2Dashboard";

export function Dashboard() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            demo: objectPath.get(
                uiService.config,
                "demo"
            )};
    }, [uiService]);
    return <>
        
        {layoutProps.demo === 'demo2' && <Demo2Dashboard />}
       
    </>;
}
