/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import Swal from "sweetalert2";
import { Accordion, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../../../_metronic/_helpers/AtHelper";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function LoadItems({
  values,
  setFieldValue,
  id,
  product,
  ViewModelOptions,
  getShipmentData,
  LoadStopsList,
  IsDetailsMode,
}) {

  const [TableView, setTableView] = useState(false);
  const history = useHistory();
  const {t}= useTranslation();
  const [LoadItemsList, setLoadItemsList] = useState([]);
  const [isCollapsedShipping, setIsCollapsedShipping] = useState(false);
  const columns = [
    {
      dataField: "Id",
      text: t("Item"),
    },
    {
      dataField: "PurchaseOrder",
      text: "PO#",
    },
    {
      dataField: "Packaging",
      text: t("Packaging"),
    },
    {
      dataField: "PackagingType",
      text: t("Pallet_Types"),
      style: {
        minWidth: "115px",
      },
    },
    {
      dataField: "Pieces",
      text: t("Pieces"),
    },
    {
      dataField: "WeightType",
      text: t("Weight_Type"),
      style: {
        minWidth: "118px",
      },
    },
    {
      dataField: "WeightMode",
      text: t("Weight_Mode"),
      style: {
        minWidth: "118px",
      },
    },
    {
      dataField: "Weight",
      text: t("Weight"),
    },
    {
      dataField: "Commodity",
      text: t("Commodity"),
    },
    {
      dataField: "SpaceReq",
      text: t("Space_Req"),
      style: {
        minWidth: "118px",
      },
    },
    {
      dataField: "Sku",
      text: "Sku",
    },
    {
      dataField: "PickupStop",
      text: t("PickUp"),
    },
    {
      dataField: "DropStop",
      text: t("Drop"),
    },
  ];
  useEffect(() => {
    if (id) {
      getLoadItemsData();
    }
  }, [id]);
  function getLoadItemsData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/api/Shipments/Items/${id}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setLoadItemsList(result[1].Items || []);
        } else if (result[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function SaveItems(ItemObj) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(ItemObj);

    return fetch(
      `${window.$apiurl}/api/Shipments/Items`,
      {
        method: "post",
        headers: myHeaders,
        body: raw,
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setFieldValue("TotalWeight", result[1].TotalWeight);
          setFieldValue("WeightType", result[1].WeightType);
          getLoadItemsData();
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire(t("Error"), translateAuto(element), "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_request"));
          }
        }
      })
      .catch((error) => {
        Swal.fire(t("Error"), error);
      });
  }
  function PutItems(ItemObj) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(ItemObj);

    return fetch(
      `${window.$apiurl}/api/Shipments/Items/${ItemObj.Id}`,
      {
        method: "Put",
        headers: myHeaders,
        body: raw,
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          //getViewModel();
          getLoadItemsData();
          setFieldValue("TotalWeight", result[1].TotalWeight);
          setFieldValue("WeightType", result[1].WeightType);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire("Error", translateAuto(element), "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_request"));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function DeleteCommodity(ItemObj) {
    Swal.fire({
      title: t("Are_you_sure"),
      text: t("You_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes_delete_it"),
    }).then((result) => {
      if (result.isConfirmed) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${db.read().getState()?.Token}`
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: "delete",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          `${window.$apiurl}/api/Shipments/Items/${ItemObj.Id}`,
          requestOptions
        )
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then(async (result) => {
            if (result[0] === 200) {
              Swal.fire(
                t("Deleted"),
                t("Your_Commodity_has_been_deleted"),
                "success"
              );
              setFieldValue("TotalWeight", result[1].TotalWeight);
              setFieldValue("WeightType", result[1].WeightType);
              getLoadItemsData();
            } else {
              if (
                result[1].ModelState !== null &&
                result[1].ModelState !== undefined &&
                result[1].ModelState !== ""
              ) {
                let modelState = result[1].ModelState;
                if (modelState)
                  Object.keys(modelState).forEach(function(k) {
                    modelState[k].forEach((element) => {
                      Swal.fire("Oops!", translateAuto(element), "error");
                    });
                  });
              } else if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Swal.fire("Oops!", translateAuto(result[1].Message), "error");
              } else if (
                result[1].error_description !== null &&
                result[1].error_description !== undefined &&
                result[1].error_description !== ""
              ) {
                Swal.fire("Oops!", translateAuto(result[1].error_description), "error");
              } else {
                Swal.fire("Oops!", t("Bad_request"), "error");
              }
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  }
  return (
    <Accordion defaultActiveKey="0" className="stop-bg mb-10">
      <Card>
        <CardHeader className="px-10 border-bottom-1">
          <div className="card-title pl-12">
            {IsDetailsMode !== true ? (
              <h3 className="card-label">{t("Commodities")}</h3>
            ) : (
              <>
                <h3 className="card-label">
                  {t("Commodity_Details")}
                  {!TableView ? (
                    <span className="label pulse pulse-primary p-5 ml-5">
                      <span className="position-relative font-size-lg font-weight-bolder">
                        {LoadItemsList.length}
                      </span>
                      <span className="pulse-ring"></span>
                    </span>
                  ) : (
                    ""
                  )}
                </h3>
              </>
            )}
          </div>
          {IsDetailsMode !== true ? (
            <CardHeaderToolbar>
              {LoadItemsList.find(
                (o) => o.isChanged === true || o.Id === undefined
              ) ? (
                <button
                  type="button"
                  className="btn btn-light-warning pl-5 mr-5 mr-5"
                  onClick={() => {
                    LoadItemsList.forEach(async (element, index) => {
                      if (element.Id !== undefined) {
                        if (element.isChanged) {
                          await PutItems(element);
                        }
                      } else {
                        await SaveItems(element);
                      }
                    });
                  }}
                >
                  <i class="fas fa-save"></i>
                </button>
              ) : (
                ""
              )}

              <button
                type="button"
                className="btn btn-light-success"
                onClick={() => {
                  setLoadItemsList([
                    {
                      ShipmentId: id,
                      PurchaseOrder: "",
                      Sku: "",
                      PackagingId: null,
                      PackagingTypeId: null,
                      Commodity: "",
                      Cube: 0.0,
                      CubeMode: "",
                      CubeType: "",
                      FreightClass: "",
                      FreightNMFCClass: "",
                      HandlingAmount: 0,
                      Pieces: 0,
                      SpaceReq: "",
                      Weight: 0,
                      WeightMode: "",
                      WeightType: "",
                    },
                    ...LoadItemsList,
                  ]);
                }}
              >
                <i
                  className={`fas fa-plus fa-sm`}
                  style={{
                    paddingRight: "initial",
                  }}
                ></i>{" "}
              </button>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <button
                  className="btn btn-llink"
                  onClick={() => {
                    try {
                      setIsCollapsedShipping(
                        isCollapsedShipping ? false : true
                      );
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  {isCollapsedShipping ? (
                    <span className="svg-icon svg-icon-primary no-rotate svg-icon-2x">
                      {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Angle-double-right.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                          />
                          <path
                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                            opacity="0.3"
                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"
                          />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  ) : (
                    <span className="svg-icon svg-icon-primary rotate svg-icon-2x">
                      {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Angle-double-right.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                          />
                          <path
                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                            opacity="0.3"
                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"
                          />
                        </g>
                      </svg>
                    </span>
                  )}
                </button>
              </Accordion.Toggle>
            </CardHeaderToolbar>
          ) : (
            <CardHeaderToolbar>
              {!TableView ? (
                <>
                  <button
                    type="button"
                    className="btn btn-light-primary"
                    onClick={() => {
                      setTableView(true);
                    }}
                  >
                    {t("Table_View")}
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-light-primary"
                  onClick={() => {
                    setTableView(false);
                  }}
                >
                  {t("Card_View")}
                </button>
              )}
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <button
                  className="btn btn-llink"
                  onClick={() => {
                    try {
                      setIsCollapsedShipping(
                        isCollapsedShipping ? false : true
                      );
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  {isCollapsedShipping ? (
                    <span className="svg-icon svg-icon-primary no-rotate svg-icon-2x">
                      {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Angle-double-right.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                          />
                          <path
                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                            opacity="0.3"
                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"
                          />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  ) : (
                    <span className="svg-icon svg-icon-primary rotate svg-icon-2x">
                      {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Angle-double-right.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                          />
                          <path
                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                            fill="currentcolor"
                            fillRule="nonzero"
                            opacity="0.3"
                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"
                          />
                        </g>
                      </svg>
                    </span>
                  )}
                </button>
              </Accordion.Toggle>
            </CardHeaderToolbar>
          )}
        </CardHeader>
        <Accordion.Collapse eventKey="0">
          <CardBody className="p-10 ">
            {!TableView ? (
              <>
                {LoadItemsList?.map((items, index) => {
                  return (
                    <Card className="bg-commodity commodity-step">
                      <CardHeader
                        title={
                          items.Id !== undefined
                            ? `Item #: ${items.Id}`
                            : "New Item"
                        }
                        className="rounded p-3 text-info h-25"
                      >
                        {IsDetailsMode !== true ? (
                          <CardHeaderToolbar>
                            <button
                              className="btn btn-light-danger pl-5 mr-5"
                              onClick={() => {
                                if (items.Id !== undefined) {
                                  DeleteCommodity(items);
                                } else {
                                  var _arrayobj = [...LoadItemsList];
                                  _arrayobj.splice(index, 1);
                                  setLoadItemsList(_arrayobj);
                                }
                              }}
                            >
                              <i
                                className="fas fa-times"
                                style={{ fontSize: "18px" }}
                              />
                            </button>
                          </CardHeaderToolbar>
                        ) : (
                          ""
                        )}
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col">
                            <div className="p-2">
                              {/* First Row */}
                              <div className="form-group row">
                                <div className="col-lg-3">
                                  <label>PO#</label>
                                  <input
                                    placeholder="PO#"
                                    className="form-control"
                                    type="text"
                                    name="PurchaseOrder"
                                    disabled={IsDetailsMode}
                                    value={items?.PurchaseOrder}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].PurchaseOrder = e.target.value;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>

                                <div className="col-lg-3">
                                  <label>{t("Packaging")}</label>
                                  <ReactSelect
                                    name="PackagingGroupId"
                                    options={ViewModelOptions?.Packaging?.map(
                                      (a) => {
                                        return { label: a.Name, value: a.Id };
                                      }
                                    )}
                                    isDisabled={IsDetailsMode}
                                    value={ViewModelOptions?.Packaging?.filter(
                                      (a) => a.Id === items.PackagingId
                                    )?.map((a) => {
                                      return { label: a.Name, value: a.Id };
                                    })}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].PackagingId = e?.value;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3">
                                  <label>{t("Pallet_Types")}</label>{" "}
                                  <ReactSelect
                                    name="PalletTypeId"
                                    isDisabled={IsDetailsMode}
                                    options={ViewModelOptions?.PalletTypes?.map(
                                      (a) => {
                                        return { label: a.Name, value: a.Id };
                                      }
                                    )}
                                    value={ViewModelOptions?.PalletTypes?.filter(
                                      (a) => a.Id === items.PackagingTypeId
                                    )?.map((a) => {
                                      return { label: a.Name, value: a.Id };
                                    })}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].PackagingTypeId = e?.value;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>

                                <div className="col-lg-3">
                                  <label>{t("Pieces")}</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="Pieces"
                                    disabled={IsDetailsMode}
                                    placeholder="Unit Count"
                                    required={true}
                                    min="0"
                                    value={items?.Pieces}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];

                                      if (e.target.value === "") {
                                        obj[index].Pieces = e.target.value;
                                      } else {
                                        const value = Math.max(
                                          0,
                                          Math.min(
                                            10000000,
                                            Number(e.target.value)
                                          )
                                        );
                                        obj[index].Pieces = value;
                                      }

                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);


                                    
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>{t("Weight_Type")}</label>

                                  <ReactSelect
                                    name="WeightType"
                                    isDisabled={IsDetailsMode}
                                    isOptionDisabled={(option) =>
                                      option.disabled === "yes"
                                    }
                                    options={ViewModelOptions?.WeightTypes?.map(
                                      (a) => {
                                        if (
                                          LoadItemsList.filter(
                                            (o) => o.WeightType !== ""
                                          ).length > 0
                                        ) {
                                          if (
                                            LoadItemsList.filter(
                                              (o) => o.WeightType === a.Name
                                            ).length > 0
                                          ) {
                                            return {
                                              label: a.Name,
                                              value: a.Id,
                                              disabled: "no",
                                            };
                                          } else {
                                            return {
                                              label: a.Name,
                                              value: a.Id,
                                              disabled:
                                                LoadItemsList.length === 1
                                                  ? "no"
                                                  : "yes",
                                            };
                                          }
                                        } else {
                                          return {
                                            label: a.Name,
                                            value: a.Id,
                                            disabled: "no",
                                          };
                                        }
                                      }
                                    )}
                                    value={ViewModelOptions?.WeightTypes?.filter(
                                      (a) => a.Name === items.WeightType
                                    )?.map((a) => {
                                      return { label: a.Name, value: a.Id };
                                    })}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].WeightType = e?.label;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>{t("Weight_Mode")}</label>

                                  <ReactSelect
                                    name="WeightMode"
                                    isDisabled={IsDetailsMode}
                                    options={ViewModelOptions?.MeasureModes?.map(
                                      (a) => {
                                        return { label: a.Name, value: a.Id };
                                      }
                                    )}
                                    value={ViewModelOptions?.MeasureModes?.filter(
                                      (a) => a.Name === items.WeightMode
                                    )?.map((a) => {
                                      return { label: a.Name, value: a.Id };
                                    })}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].WeightMode = e?.label;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>{t("Weight")}</label>
                                  <input
                                    type="number"
                                    name="Weight"
                                    id="WeightId"
                                    disabled={IsDetailsMode}
                                    className="form-control"
                                    placeholder="Weight"
                                    min="0"
                                    value={items?.Weight}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];

                                      if (e.target.value === "") {
                                        obj[index].Weight = e.target.value;
                                      } else {
                                        const value = Math.max(
                                          0,
                                          Math.min(
                                            10000000,
                                            Number(e.target.value)
                                          )
                                        );
                                        obj[index].Weight = value;
                                      }

                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>

                                <div className="col-lg-3 mt-5">
                                  <label>{t("Commodity")}</label>
                                  <input
                                    placeholder="Commodity"
                                    className="form-control"
                                    disabled={IsDetailsMode}
                                    type="text"
                                    name="Commodity"
                                    value={items?.Commodity}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].Commodity = e.target.value;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>Cube</label>
                                  <input
                                    placeholder="Cube"
                                    className="form-control"
                                    disabled={IsDetailsMode}
                                    type="number"
                                    name="Cube"
                                    value={items?.Cube}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];

                                      if (e.target.value === "") {
                                        obj[index].Cube = e.target.value;
                                      } else {
                                        const value = Math.max(
                                          0,
                                          Math.min(
                                            10000000,
                                            Number(e.target.value)
                                          )
                                        );
                                        obj[index].Cube = value;
                                      }

                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>{t("Space_Req")}</label>
                                  <input
                                    type="text"
                                    placeholder="Space Req"
                                    className="form-control"
                                    disabled={IsDetailsMode}
                                    name="SpaceReq"
                                    value={items?.SpaceReq}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].SpaceReq = e.target.value;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>SKU</label>
                                  <input
                                    placeholder="SKU"
                                    className="form-control"
                                    type="text"
                                    disabled={IsDetailsMode}
                                    name="Sku"
                                    value={items?.Sku}
                                    onChange={(e) => {
                                      let obj = [...LoadItemsList];
                                      obj[index].Sku = e.target.value;
                                      if (items.Id !== undefined) {
                                        obj[index].isChanged = true;
                                      }
                                      setLoadItemsList(obj);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>{t("PickUp")}</label>
                                  <ReactSelect
                                    name="PickUpStop"
                                    isDisabled={IsDetailsMode}
                                    value={[
                                      {
                                        Id: null,
                                        Name: values.PickupName,
                                        Type: "Pick-up",
                                      },
                                      ...LoadStopsList,
                                    ]
                                      .filter(
                                        (a) => a.Id === items?.PickupStopId
                                      )
                                      .map((b) => {
                                        return { label: b.Name, value: b.Id };
                                      })}
                                    onChange={(e) => {
                                      try {
                                        let obj = [...LoadItemsList];
                                        obj[index].PickupStopId = e?.value;
                                        if (items.Id !== undefined) {
                                          obj[index].isChanged = true;
                                        }
                                        setLoadItemsList(obj);
                                      } catch (err) {}
                                    }}
                                    options={[
                                      {
                                        Id: null,
                                        Name: values.PickupName,
                                        Type: "Pick-up",
                                      },
                                      ...LoadStopsList,
                                    ]
                                      .filter((a) => a.Type === "Pick-up")
                                      .map((b) => {
                                        return { label: b.Name, value: b.Id };
                                      })}
                                  />
                                </div>
                                <div className="col-lg-3 mt-5">
                                  <label>{t("Drop")}</label>

                                  <ReactSelect
                                    name="DropStop"
                                    isDisabled={IsDetailsMode}
                                    value={[
                                      {
                                        Id: null,
                                        Name: values.DestinationName,
                                        Type: "Drop",
                                      },
                                      ...LoadStopsList,
                                    ]
                                      .filter((a) => a.Id === items?.DropStopId)
                                      .map((b) => {
                                        return { label: b.Name, value: b.Id };
                                      })}
                                    onChange={(e) => {
                                      try {
                                        let obj = [...LoadItemsList];
                                        obj[index].DropStopId = e?.value;
                                        if (items.Id !== undefined) {
                                          obj[index].isChanged = true;
                                        }
                                        setLoadItemsList(obj);
                                      } catch (err) {}
                                    }}
                                    options={[
                                      {
                                        Id: null,
                                        Name: values.DestinationName,
                                        Type: "Drop",
                                      },
                                      ...LoadStopsList,
                                    ]
                                      .filter((a) => a.Type === "Drop")
                                      .map((b) => {
                                        return { label: b.Name, value: b.Id };
                                      })}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
              </>
            ) : (
              <BootstrapTable
                wrapperClasses="react-bootstrap-table table-responsive"
                classes="table table table-head-custom table-vertical-center smTable"
                bootstrap4
                bordered={false}
                remote
                keyField="Id"
                data={LoadItemsList === null ? [] : LoadItemsList}
                columns={columns}
              ></BootstrapTable>
            )}
          </CardBody>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
