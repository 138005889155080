import { LanguageSelectorDropdown } from ".../../../src/_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { useFormik } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";
import { translateAuto } from "../../../../_metronic/_helpers/AtHelper";
import { login } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
const initialValues = {
  email: "",
  password: "",
};
const adapter = new LocalStorage("db");
const db = low(adapter);

function Login(props) {
  const today = new Date().getFullYear();
  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Wrong_email_format"))
      .min(3, t("Minimum_3_symbols"))
      .max(50, t("Maximum_50_symbols"))
      .required(t("Email_is_required")),
    password: Yup.string()
      .min(8, t("Minimum_8_symbols"))
      .max(50, t("Maximum_50_symbols"))
      .required(t("Password_is_required")),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { accessToken } }) => {
            console.log("accessToken", accessToken)
            disableLoading();
            props.login(accessToken);
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });
  const [typePassword, setTypePassword] = useState("password");

  //fetch auto login
  const [role, setRole] = useState("");
  async function GetProfileInfo(token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(`${window.$apiUrl}/api/Account/Profile`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          db.set("FirstName", data?.Profile?.FirstName).write();
          db.set("LastName", data?.Profile?.LastName).write();
          db.set("CompanyName", data?.Profile?.CompanyName).write();
          db.set("IsSubscribed", data?.IsSubscribed).write();
          db.set("IsManager", data?.IsManager).write();
          db.set(
            "ProfilePicture",
            data?.Profile?.ProfilePicture === ""
              ? "/assets/media/svg/avatars/blank.svg"
              : data?.Profile?.ProfilePicture
          ).write();
          db.set("ProfilePicture", data?.Profile?.ProfilePicture).write();
          const userDefault = {
            FullName: `${data?.Profile?.FirstName} ${data?.Profile?.LastName}`,
            Email: data?.Profile?.Email,
          };
          db.set("Token", token).write();
          db.set("User", userDefault).write();
          const user = {
            id: 1,
            username: `${data?.Profile?.FirstName} ${data?.Profile?.LastName}`,
            password: undefined,
            email: data?.Profile?.Email,
            accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
            refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
          };
          db.set("user", user).write();
          if (data?.Profile?.Role === "Driver") {
            Swal.fire({
              icon: "error",
              title: "Access denied...",
              text: "You don't have right to access to the TaaP Web",
            });
          } else {
            props.login("access-token-8f3ae836da744329a6f93bf20594b5cc");
          }
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire(t("Error"), translateAuto(element), "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_Request"));
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //fetch auto login
  useEffect(() => {
    if (window.location.search.includes("?token=")) {
      let token = window.location.search;
      token = token.replace("?token=", "")?.replace("&reLogin=true","");
      // try{
      //   document.getElementById('kt_login').className = "d-none";
      // }catch(err) {}
      window.$isProfile = true;
      Swal.fire({
        title: 'Authenticating',
        html: 'Please wait...',
        // closeButtonAriaLabel : false,
        didOpen: () => {
          Swal.showLoading()
        },
        timer: 2500
      }).then(async () => {
        if (!!token) {
          await GetProfileInfo(token ?? "");
        }
      });
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TaaP | {t("Login")}</title>
      </Helmet>
      <div
        className="login login-3 login-signin-on d-flex flex-row-fluid"
        id="kt_login"
      >
        <div
          className="flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
          style={{ backgroundImage: "url(/media/bg/bg-taap.jpg)",backgroundPosition:"bottom" }}
        >
          <div className="position-fixed top-0 end-0 m-5 Zindex-1">
            <LanguageSelectorDropdown />
          </div>
          <div className="col-xl-4 col-lg-5 col-md-10 col-12  p-7 position-relative overflow-hidden mx-auto">
            {/*begin::Login Header*/}
            <div className="d-flex flex-center mb-15">
              <span>
                <SVG src={toAbsoluteUrl("/media/svg/logos/TaapLogo-White.svg")} />
              </span>
            </div>
            {/*end::Login Header*/}
            {/*begin::Login Sign in form*/}
            <div className="card card-custom bg-transparent rounded">
              <div className="card-header card-header-tabs-line border-0">
                <div className="card-body">
                  <div className="login-signin">
                    <div className="mb-20 text-center text-white">
                      <h3>{t("Sign_In_to_Taap")}</h3>
                    </div>
                    <form
                      onSubmit={formik.handleSubmit}
                      className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                      <div className="form-group mb-5 pull-left">
                        <label className="control-label font-weight-bold text-white">
                          {t("Email")}
                        </label>
                        <input
                          placeholder= {t("Email")}
                          type="email"
                          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                          )}`}
                          name="email"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block invalid-feedback">
                             * {formik.errors.email}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group mb-5">
                        <div className=" d-flex flex-wrap justify-content-between ">
                          <label className="control-label font-weight-bold text-white">
                            {t("Password")}
                            <span
                              style={{ cursor: "pointer" }}
                              className="ml-3"
                              onClick={() => {
                                if (typePassword === "text") {
                                  setTypePassword("password");
                                } else {
                                  setTypePassword("text");
                                }
                              }}
                            >
                              {typePassword === "text" && (
                                <i className="fas fa-eye"></i>
                              )}
                              {typePassword === "password" && (
                                <i className="fas fa-eye-slash"></i>
                              )}
                            </span>
                          </label>

                          <Link
                            to="/auth/forgot-password"
                            className="text-white text-hover-primary font-weight-bolder  mr-2"
                            id="kt_login_forgot"
                          >
                            {t("Forgotten_Password")}?
                          </Link>
                        </div>
                        <input
                          placeholder={t("Password")}
                          type={typePassword}
                          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                          )}`}
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block invalid-feedback">
                              * {formik.errors.password}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="text-center">
                        <button
                          id="kt_login_signin_submit"
                          className="btn btnPrimaryTaap font-weight-bold px-9 py-4 my-3 mx-4"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          <span>{t("Sign_In")}</span>
                          {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*end::Login Sign in form*/}
            </div>
          </div>
          <div
            className={`footer bg-transparente py-4 d-flex flex-lg-column  bottom-0 w-100`}
            id="kt_footer"
          >
            {/* begin::Container */}
            <div
              className={`container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between `}
            >
              {/* begin::Copyright */}
              <div className="text-dark order-2 order-md-1">
                <span className="text-white font-weight-bold mr-2">
                  {today} &copy;
                </span>
                {` `}
                <a
                  href="/auth/login"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-white text-hover-primary"
                >
                  TaaP
                </a>
              </div>
              {/* end::Copyright */}
              {` `}
              {/* begin::Nav */}
              <div className="nav nav-dark order-1 order-md-2">
                <Link
                  to="/privacy-policy"
                  rel="noopener noreferrer"
                  className="nav-link pr-3 pl-0 text-white"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/terms"
                  rel="noopener noreferrer"
                  className="nav-link px-3 text-white"
                >
                  Terms
                </Link>
                <Link
                  to="/faq"
                  rel="noopener noreferrer"
                  className="nav-link pl-3 pr-0 text-white"
                >
                  FAQ
                </Link>
              </div>
              {/* end::Nav */}
            </div>
            {/* end::Container */}
          </div>
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
