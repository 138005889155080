/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactSelect from "react-select";
import moment from "moment";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  ConvertToDateCurrentTimeZone,
  ConvertToDateGMT,
  formatPhoneNumber,
  translateAuto,
} from "../../../../../../_metronic/_helpers/AtHelper";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function LoadStops({
  values,
  setFieldValue,
  id,
  product,
  ViewModelOptions,
  getShipmentData,
  setLoadStopsList,
  LoadStopsList,
  ContriesViewModel,
  getViewModel,
  IsDetailsMode,
}) {
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const [showModalAddresses, setshowModalAddresses] = useState(false);
  const [initAddressObject, setinitAddressObject] = useState(false);
  useEffect(() => {
    if (id) {
      getLoadStopsData();
    }
  }, [id]);
  const {t} = useTranslation();
  function getLoadStopsData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/api/Shipments/Stops/${id}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setLoadStopsList(result[1].Stops || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getAddressDetail(AddressId, Position) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Address/${AddressId}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          let obj = data.Address;

          let StopObj = [...LoadStopsList];
          StopObj[Position].Address1 = obj.Address1;
          StopObj[Position].Address2 = obj.Address2;
          StopObj[Position].City = obj.City;
          StopObj[Position].State = obj.State;
          StopObj[Position].Country = obj.Country;
          StopObj[Position].Zipcode = obj.Zipcode;
          StopObj[Position].ContactName = obj.ContactName;
          StopObj[Position].ContactPhone = obj.ContactPhone;
          StopObj[Position].ContactPhone2 = obj.ContactPhone2;
          StopObj[Position].ContactFax = obj.ContactFax;
          StopObj[Position].ContactEmail = obj.ContactEmail;
          setLoadStopsList(StopObj);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function PutStopPosition(array) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ShipmentId: id,
      Stops: array.map((a) => {
        return {
          StopId: a.Id,
          Position: a.Position,
        };
      }),
    });
    return fetch(
      `${window.$apiurl}/api/Shipments/MoveStops`,
      {
        method: "Put",
        headers: myHeaders,
        body: raw,
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
        }
      })
      .catch((error) => {
        Swal.fire(t("Error"), error);
      });
  }
  function PutStop(params, index) {
    setisLoadingAuth(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(params);
    return fetch(
      `${window.$apiurl}/api/Shipments/Stops/${params.Id}`,
      {
        method: "Put",
        headers: myHeaders,
        body: raw,
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          let StopObj = [...LoadStopsList];
          StopObj[index].isChanged = false;
          setLoadStopsList(StopObj);
          Swal.fire(t("Great"), t("Your_stop_has_been_updated"), "success");
          getLoadStopsData();
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire(t("Error"), translateAuto(element), "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_request"));
          }
        }
        setisLoadingAuth(false);
      })
      .catch((error) => {
        Swal.fire(t("Error"), error);
        setisLoadingAuth(false);
      });
  }
  function PostStop(params, index) {
    if (params.AddressId == null) {
      Swal.fire("Oops!", t("Please_select_a_company_name"), "warning");
      return;
    }
    setisLoadingAuth(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(params);
    return fetch(
      `${window.$apiurl}/api/Shipments/Stops`,
      {
        method: "post",
        headers: myHeaders,
        body: raw,
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          let StopObj = [...LoadStopsList];
          StopObj[index].isChanged = false;
          StopObj[index].Id = result[1].Id;
          setLoadStopsList(StopObj);
          Swal.fire(t("Great"), t("Your_stop_has_been_added"), "success");
          getLoadStopsData();
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire(t("Error"), translateAuto(element), "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_request"));
          }
        }
        setisLoadingAuth(false);
      })
      .catch((error) => {
        Swal.fire(t("Error"), error);
        setisLoadingAuth(false);
      });
  }

  function DeleteStop(ItemObj) {
    Swal.fire({
      title: t("Are_you_sure"),
      text: t("You_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes_delete_it"),
    }).then((result) => {
      if (result.isConfirmed) {
        setisLoadingAuth(true);

        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${db.read().getState()?.Token}`
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: "delete",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          `${window.$apiurl}/api/Shipments/Stops/${ItemObj.Id}`,
          requestOptions
        )
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then(async (result) => {
            if (result[0] === 200) {
              Swal.fire(t("Deleted"), t("Your_stop_has_been_updated"), "success");
              getLoadStopsData();
            } else {
              if (
                result[1].ModelState !== null &&
                result[1].ModelState !== undefined &&
                result[1].ModelState !== ""
              ) {
                let modelState = result[1].ModelState;
                if (modelState)
                  Object.keys(modelState).forEach(function(k) {
                    modelState[k].forEach((element) => {
                      Swal.fire("Oops!", translateAuto(element), "error");
                    });
                  });
              } else if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Swal.fire("Oops!", translateAuto(result[1].Message), "error");
              } else if (
                result[1].error_description !== null &&
                result[1].error_description !== undefined &&
                result[1].error_description !== ""
              ) {
                Swal.fire("Oops!", translateAuto(result[1].error_description), "error");
              } else {
                Swal.fire("Oops!", t("Bad_Request"), "error");
              }
            }
            setisLoadingAuth(false);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  }
  return LoadStopsList.sort(
    (a, b) => parseInt(a.Position) - parseInt(b.Position)
  ).map((items, index) => {
    return (
      <>
        <Card key={index}>
          <CardHeader
            title={`${items.Type} : ${
              items.Id !== undefined
                ? ViewModelOptions?.Addresses?.filter(
                    (a) => a.Id === items?.AddressId
                  )[0]?.Name
                : t("New_Stop")
            }`}
          >
            {IsDetailsMode !== true ? (
              <CardHeaderToolbar>
                {items.isChanged === true || items.Id === undefined ? (
                  <button
                    type="button"
                    disabled={isLoadingAuth}
                    className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5 mr-5"
                    onClick={() => {
                      if (items.Id !== undefined) {
                        PutStop(items, index);
                      } else {
                        PostStop(items, index);
                      }
                    }}
                  >
                    <i className="fas fa-save mr-3" />
                    {t("Save")}
                    {isLoadingAuth && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                ) : (
                  ""
                )}
                {index !== 0 && (
                  <button
                    onClick={(e) => {
                      setLoadStopsList([]);
                      let arrayobj = [...LoadStopsList];
                      arrayobj[index].Position =
                        LoadStopsList[index].Position - 1;
                      arrayobj[index - 1].Position =
                        LoadStopsList[index].Position + 1;
                      PutStopPosition(LoadStopsList);

                      setLoadStopsList(arrayobj);
                    }}
                    className="btn btn-light-primary py-3 pl-5 mr-5"
                  >
                    <i
                      className="fas fa-arrow-up"
                      style={{ fontSize: "18px" }}
                    />
                  </button>
                )}
                {LoadStopsList.length - 1 !== index && (
                  <button
                    className="btn btn-light-primary py-3 pl-5 mr-5"
                    onClick={(e) => {
                      let arrayobj = [...LoadStopsList];
                      arrayobj[index].Position =
                        LoadStopsList[index].Position + 1;
                      arrayobj[index + 1].Position =
                        LoadStopsList[index].Position - 1;
                      PutStopPosition(LoadStopsList);
                      setLoadStopsList(arrayobj);
                    }}
                  >
                    <i
                      className="fas fa-arrow-down"
                      style={{ fontSize: "18px" }}
                    />
                  </button>
                )}
                <button
                  className="btn btn-light-danger py-3 pl-5 mr-5"
                  onClick={() => {
                    if (items.Id !== undefined) {
                      DeleteStop(items);
                    } else {
                      var _arrayobj = [...LoadStopsList];
                      _arrayobj.splice(index, 1);
                      setLoadStopsList(_arrayobj);
                    }
                  }}
                >
                  <i className="fas fa-times" style={{ fontSize: "18px" }} />
                </button>
              </CardHeaderToolbar>
            ) : (
              ""
            )}
          </CardHeader>
          <CardBody>
            <div className="row mb-5">
              <Col xl="12" lg="12" md="12" className="mb-5">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      {t("Company_Name")}
                    </a>
                    <span className="font-weight-bold">
                      <CreatableSelect
                        className="mt-4"
                        isDisabled={IsDetailsMode}
                        isClearable
                        value={ViewModelOptions?.Addresses?.filter(
                          (a) => a.Id === items?.AddressId
                        ).map((a) => {
                          return {
                            value: a.Id,
                            label: a.Name,
                            ValueId: a.ValueId,
                          };
                        })}
                        options={ViewModelOptions?.Addresses?.map((a) => {
                          return {
                            value: a.Id,
                            label: a.Name,
                          };
                        })}
                        onChange={async (newValue) => {
                          if (newValue !== null) {
                            if (newValue?.__isNew__) {
                              setshowModalAddresses(true);
                              setinitAddressObject({
                                Name: newValue.label,
                                Code: "",
                                Address1: "",
                                Address2: "",
                                Zipcode: "",
                                City: "",
                                State: "",
                                Country: "United States",
                                Coupon: "",
                                ContactFirstName: "",
                                ContactLastName: "",
                                ContactEmail: "",
                                ContactPhone: "",
                                ContactPhone2: "",
                                ContactFax: "",
                                Position: index,
                                IsShipper: items.Type === "Drop" ? false : true,
                              });
                            } else {
                              let StopObj = [...LoadStopsList];
                              StopObj[index].AddressId = newValue?.value;
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              setLoadStopsList(StopObj);
                              getAddressDetail(newValue?.value, index);
                            }
                          }
                        }}
                        name="ShipperId"
                        id="ShipperId"
                        label="Company"
                      />
                    </span>
                  </div>
                </div>
              </Col>
              <div className="col-xl-8 col-lg-8 col-md-12 border-right">
                <Row>
                  <div className="col-xl col-lg col-md-5 border-right">
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Street_Address")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="text"
                            className="form-control mt-4"
                            name="ShipperAddress"
                            id="ShipperAddress"
                            disabled={true}
                            value={items?.Address1}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Address")}2
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="text"
                            className="form-control mt-4"
                            name="ShipperAddress2"
                            id="ShipperAddress2"
                            disabled={true}
                            value={items?.Address2}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              {t("Country")}
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperCountry"
                                id="ShipperCountry"
                                disabled={true}
                                value={items?.Country}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              {t("State")}
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperState"
                                id="ShipperState"
                                disabled={true}
                                value={items?.State}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              {t("City")}
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperCity"
                                id="ShipperCity"
                                disabled={true}
                                value={items?.City}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              {t("Zip")}
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperZip"
                                id="ShipperZip"
                                disabled={true}
                                value={items?.Zipcode}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl col-lg col-md-5 border-right">
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Contact_Name")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="text"
                            className="form-control mt-4"
                            name="ShipperContactName"
                            id="ShipperContactName"
                            disabled={true}
                            label="Contact"
                            value={items?.ContactName}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Contact_Email")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="email"
                            className="form-control mt-4"
                            name="ShipperEmail"
                            id="ShipperEmail"
                            label="Email"
                            disabled={true}
                            value={items?.ContactEmail}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              {t("Contact_Phone")} 1
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ShipperPhone"
                                id="ShipperPhone"
                                disabled={true}
                                value={formatPhoneNumber(items?.ContactPhone)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              {t("Contact_Phone")} 2
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ShipperPhone"
                                id="ShipperPhone"
                                disabled={true}
                                value={formatPhoneNumber(items?.ContactPhone2)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Contact_Fax")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="tel"
                            className="form-control mt-4"
                            name="ShipperFax"
                            id="ShipperFax"
                            label="Fax"
                            disabled={true}
                            value={items?.ContactFax}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      {t("Note")}
                    </a>
                    <span className="text-muted font-weight-bold">
                      <textarea
                        className="form-control"
                        maxlength="400"
                        disabled={IsDetailsMode}
                        rows="3"
                        value={items.Note}
                        onChange={(e) => {
                          let StopObj = [...LoadStopsList];
                          if (items.Id !== undefined) {
                            StopObj[index].isChanged = true;
                          }
                          StopObj[index].Note = e.target.value;
                          setLoadStopsList(StopObj);
                        }}
                      ></textarea>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-5 border-right">
                {items.Type === "Drop" ? (
                  <>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("MABD")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="date"
                            className="form-control mt-4"
                            name="RequiredShipDate7"
                            disabled={IsDetailsMode}
                            id="RequiredShipDate7"
                            label="Ship"
                            value={String(
                              moment(items.Mabd).format("YYYY-MM-DD")
                            )}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[index].Mabd = e.target.value;
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("DEL")} #
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="text"
                            className="form-control mt-4"
                            name="ShipperNumber14"
                            disabled={IsDetailsMode}
                            id="ShipperNumber14"
                            value={items.DestinationNumber}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[index].DestinationNumber = e.target.value;
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Del_appointment_date")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="datetime-local"
                            className="form-control mt-4"
                            name="DelAppointmentDate"
                            disabled={IsDetailsMode}
                            id="DelAppointmentDate"
                            label="Ship"
                            value={moment(
                              ConvertToDateCurrentTimeZone(
                                items.DelAppointmentDate
                              )
                            ).format("YYYY-MM-DD[T]HH:mm")}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[
                                index
                              ].DelAppointmentDate = ConvertToDateGMT(
                                e.target.value
                              );
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Del_appointment_to_date")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="datetime-local"
                            className="form-control mt-4"
                            name="DelAppointmentToDate"
                            id="DelAppointmentToDate"
                            disabled={IsDetailsMode}
                            label="Ship"
                            value={moment(
                              ConvertToDateCurrentTimeZone(
                                items.DelAppointmentToDate
                              )
                            ).format("YYYY-MM-DD[T]HH:mm")}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[
                                index
                              ].DelAppointmentToDate = ConvertToDateGMT(
                                e.target.value
                              );
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Req_Ship_Date")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="date"
                            className="form-control mt-4"
                            name="RequiredShipDate2"
                            id="RequiredShipDate2"
                            label="Ship"
                            disabled={IsDetailsMode}
                            value={String(
                              moment(items.ReqShipDate).format("YYYY-MM-DD")
                            )}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[index].ReqShipDate = e.target.value;
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          PU #
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="text"
                            className="form-control mt-4"
                            name="ShipperNumber3"
                            id="ShipperNumber3"
                            disabled={IsDetailsMode}
                            value={items.PickupNumber}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[index].PickupNumber = e.target.value;
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Seal")} #
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="text"
                            className="form-control mt-4"
                            name="SealNumber"
                            id="SealNumber"
                            disabled={IsDetailsMode}
                            value={items.SealNumber}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[index].SealNumber = e.target.value;
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>

                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Pu_Appointment_Date")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="datetime-local"
                            className="form-control mt-4"
                            name="PuAppointmentDate"
                            disabled={IsDetailsMode}
                            id="PuAppointmentDate"
                            label="Ship"
                            value={moment(
                              ConvertToDateCurrentTimeZone(
                                items.PuAppointmentDate
                              )
                            ).format("YYYY-MM-DD[T]HH:mm")}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[
                                index
                              ].PuAppointmentDate = ConvertToDateGMT(
                                e.target.value
                              );
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className=" flex-wrap align-items-center pb-5">
                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                        <a
                          href={() => false}
                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                        >
                          {t("Pu_Appointment_To_Date")}
                        </a>
                        <span className="text-muted font-weight-bold">
                          <input
                            type="datetime-local"
                            className="form-control mt-4"
                            name="PuAppointmentToDate"
                            id="PuAppointmentToDate"
                            disabled={IsDetailsMode}
                            label="Ship"
                            value={moment(
                              ConvertToDateCurrentTimeZone(
                                items.PuAppointmentToDate
                              )
                            ).format("YYYY-MM-DD[T]HH:mm")}
                            onChange={(e) => {
                              let StopObj = [...LoadStopsList];
                              if (items.Id !== undefined) {
                                StopObj[index].isChanged = true;
                              }
                              StopObj[
                                index
                              ].PuAppointmentToDate = ConvertToDateGMT(
                                e.target.value
                              );
                              setLoadStopsList(StopObj);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      {t("Special_Req")}
                    </a>
                    <span className="font-weight-bold">
                      <ReactSelect
                        isMulti
                        className="mt-4"
                        name="ShipperSpecialReq"
                        id="ShipperSpecialReq"
                        isDisabled={IsDetailsMode}
                        value={ViewModelOptions?.SpecialReq?.filter(function(
                          el
                        ) {
                          return items?.SpecialReq?.indexOf(el.Name) >= 0;
                        })?.map((a) => {
                          return {
                            value: a.Name,
                            label: a.Name,
                          };
                        })}
                        options={ViewModelOptions?.SpecialReq?.map((a) => {
                          return {
                            value: a.Name,
                            label: a.Name,
                          };
                        })}
                        onChange={(newValue) => {
                          let StopObj = [...LoadStopsList];
                          if (newValue !== null) {
                            StopObj[index].SpecialReq = newValue.map(
                              (a) => a.value
                            );
                          } else {
                            StopObj[index].SpecialReq = [];
                          }
                          if (items.Id !== undefined) {
                            StopObj[index].isChanged = true;
                          }
                          setLoadStopsList(StopObj);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Modal
          size="xl"
          show={showModalAddresses}
          onHide={() => {
            setshowModalAddresses(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("Add")}{" "}
              {initAddressObject?.IsShipper === true ? "Shipping" : "Receiving"}{" "}
              {t("Address")}:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pb-5 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <label>
                    {t("Name")}<span className="text-danger">*</span>
                  </label>
                  <input
                    readOnly
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.Name}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.Name = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="Name"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>
                    {t("Address")}<span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.Address1}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.Address1 = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="Address1"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>{t("Address")} 2</label>
                  <input
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.Address2}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.Address2 = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="Address2"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>
                    {t("Country")}<span className="text-danger">*</span>
                  </label>

                  <ReactSelect
                    required
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.Country = e?.value;
                      setinitAddressObject(obj);
                    }}
                    name="Country"
                    isSearchable={true}
                    isClearable
                    placeholder={t("Select_Country")}
                    options={ContriesViewModel.map((a) => {
                      return { label: a.Name, value: a.Name };
                    })}
                    value={ContriesViewModel.filter(
                      (a) => a.Name === initAddressObject?.Country
                    ).map((a) => {
                      return { label: a.Name, value: a.Name };
                    })}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>
                    {t("State")}<span className="text-danger">*</span>
                  </label>
                  <ReactSelect
                    isSearchable={true}
                    isClearable
                    placeholder={t("Select_State")}
                    options={ContriesViewModel?.find(
                      (a) => a.Name === initAddressObject?.Country
                    )?.States?.map((a) => {
                      return { label: a.Name, value: a.Name };
                    })}
                    value={ContriesViewModel?.find(
                      (a) => a.Name === initAddressObject?.Country
                    )
                      ?.States?.filter(
                        (a) => a.Name === initAddressObject?.State
                      )
                      ?.map((a) => {
                        return { label: a.Name, value: a.Name };
                      })}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.State = e?.value;
                      setinitAddressObject(obj);
                    }}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>
                    {t("City")}<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.City}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.City = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="City"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>
                    {t("Zip_code")}<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.Zipcode}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.Zipcode = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="Zipcode"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>{t("Contact_First_Name")}</label>
                  <input
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.ContactFirstName}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.ContactFirstName = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="ContactFirstName"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>{t("Contact_Last_Name")}</label>
                  <input
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.ContactLastName}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.ContactLastName = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="ContactLastName"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>{t("Contact_Email")}</label>
                  <input
                    type="text"
                    className="form-control  form-control-lg"
                    value={initAddressObject.ContactEmail}
                    onChange={(e) => {
                      let obj = { ...initAddressObject };
                      obj.ContactEmail = e.target.value;
                      setinitAddressObject(obj);
                    }}
                    name="ContactEmail"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>{t("Contact_Phone")}</label>
                  <PhoneInput
                    country={"us"}
                    value={initAddressObject?.ContactPhone}
                    onChange={(phone) => {
                      let obj = { ...initAddressObject };
                      obj.ContactPhone = phone;
                      setinitAddressObject(obj);
                    }}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>{t("Contact_Phone")} 2</label>
                  <PhoneInput
                    country={"us"}
                    value={initAddressObject?.ContactPhone2}
                    onChange={(phone) => {
                      let obj = { ...initAddressObject };
                      obj.ContactPhone2 = phone;
                      setinitAddressObject(obj);
                    }}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>{t("Contact_Fax")}</label>
                  <PhoneInput
                    country={"us"}
                    value={initAddressObject?.ContactFax}
                    onChange={(phone) => {
                      let obj = { ...initAddressObject };
                      obj.ContactFax = phone;
                      setinitAddressObject(obj);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setshowModalAddresses(false);
              }}
            >
              {t("Close")}
            </Button>
            <Button
              variant="primary"
              disabled={isLoadingAuth}
              onClick={() => {
                var myHeaders = new Headers();
                myHeaders.append(
                  "Authorization",
                  `Bearer ${db.read().getState()?.Token}`
                );

                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify(initAddressObject);

                setisLoadingAuth(true);

                return fetch(
                  `${window.$apiurl}/api/Address`,
                  {
                    method: "post",
                    headers: myHeaders,
                    body: raw,
                  }
                )
                  .then(async (response) => {
                    const statusCode = await response.status;
                    const contentType = response.headers.get("content-type");
                    if (
                      contentType &&
                      contentType.indexOf("application/json") !== -1
                    ) {
                      return response.json().then((data) => [statusCode, data]);
                    } else {
                      return response
                        .text()
                        .then((text) => [statusCode, { Message: "" }]);
                    }
                  })
                  .then((result) => {
                    if (result[0] === 200) {
                      getViewModel();
                      let StopObj = [...LoadStopsList];
                      StopObj[initAddressObject.Position].AddressId =
                        result[1]?.Address?.Id;
                      StopObj[initAddressObject.Position].Address1 =
                        initAddressObject.Address1;
                      StopObj[initAddressObject.Position].Address2 =
                        initAddressObject.Address2;
                      StopObj[initAddressObject.Position].City =
                        initAddressObject.City;
                      StopObj[initAddressObject.Position].State =
                        initAddressObject.State;
                      StopObj[initAddressObject.Position].Country =
                        initAddressObject.Country;
                      StopObj[initAddressObject.Position].Zipcode =
                        initAddressObject.Zipcode;
                      StopObj[initAddressObject.Position].ContactName =
                        initAddressObject.ContactFirstName +
                        " " +
                        initAddressObject.ContactLastName;
                      StopObj[initAddressObject.Position].ContactPhone =
                        initAddressObject.ContactPhone;
                      StopObj[initAddressObject.Position].ContactPhone2 =
                        initAddressObject.ContactPhone2;
                      StopObj[initAddressObject.Position].ContactFax =
                        initAddressObject.ContactFax;
                      StopObj[initAddressObject.Position].ContactEmail =
                        initAddressObject.ContactEmail;
                      setshowModalAddresses(false);
                    } else {
                      if (
                        result[1].ModelState !== null &&
                        result[1].ModelState !== undefined &&
                        result[1].ModelState !== ""
                      ) {
                        let modelState = result[1].ModelState;
                        if (modelState)
                          Object.keys(modelState).forEach(function(k) {
                            modelState[k].forEach((element) => {
                              Swal.fire("Error", translateAuto(element), "error");
                            });
                          });
                      } else if (
                        result[1].Message !== null &&
                        result[1].Message !== undefined &&
                        result[1].Message !== ""
                      ) {
                        Swal.fire(t("Error"), translateAuto(result[1].Message));
                      } else if (
                        result[1].error_description !== null &&
                        result[1].error_description !== undefined &&
                        result[1].error_description !== ""
                      ) {
                        Swal.fire(t("Error"), translateAuto(result[1].error_description));
                      } else {
                        Swal.fire(t("Error"), t("Bad_Request"));
                      }
                    }
                    setisLoadingAuth(false);
                  })
                  .catch((error) => {
                    Swal.fire(t("Error"), error);
                    setisLoadingAuth(false);
                  });
              }}
            >
              {t("Confirm")}
              {isLoadingAuth && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  });
}
