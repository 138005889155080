/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import withTracker from "../../../withTracker";
import ActivateAccount from "./ActivateAccount";
import FaqPage from "./Faq/faq";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import PrivacyPolicyPage from "./PrivacyPolicy/PrivacyPolicy";
import Registration from "./Registration";
import ResetPassword from "./resetPassword";
import TermsConditionsPage from "./Terms/Terms-Conditions";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}

        <Switch>
          <ContentRoute path="/register/membership" component={withTracker(Registration)} />
          <ContentRoute path="/auth/login" component={Login} />
          ResetPassword
          <ContentRoute path="/ResetPassword" component={withTracker(ResetPassword)} />
          <ContentRoute
            path="/useractivationemail"
            component={ActivateAccount}
          />
          <ContentRoute
            path="/terms"
            component={withTracker(TermsConditionsPage)}
          />
          <ContentRoute
            path="/faq"
            component={withTracker(FaqPage)}
          />
          <ContentRoute
            path="/privacy-policy"
            component={withTracker(PrivacyPolicyPage)}
          />
          <ContentRoute
            path="/auth/forgot-password"
            component={withTracker(ForgotPassword)}
          />
          <Redirect from="/auth" exact={true} to="/auth/login" />
          <Redirect to="/auth/login" />
        </Switch>
      </div>
      {/*end::Content body*/}

      {/* begin::Mobile footer */}
      {!String(window.location.href).includes("/auth/login") === true ?
        <>
          <div className="d-none d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
              &copy; 2020 TaaP
            </div>
            <div className="d-flex order-1 order-sm-2 my-2">
              <Link to="/privacy-policy" className="text-dark-75 text-hover-primary">
                Privacy
              </Link>
              <Link to="/terms" className="text-dark-75 text-hover-primary ml-4">
                Terms and Conditions
              </Link>
              <Link to="/faq" className="text-dark-75 text-hover-primary ml-4">
                Faq
              </Link>
            </div>
          </div>
          {/* end::Mobile footer */}


        </>
        : ""
      }
    </>
  );
}
