/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { headerSortingClasses, sortCaret } from "../../../_helpers";
import { useTranslation } from "react-i18next";
import { getDate } from "../../../_helpers/AtHelper";
export const StatusColumnFormatter2 = (row) => {
  let colotCode;
  switch (row.StatusCode) {
    case "DF":
      colotCode = "#C0C0C0";
      break;
    case "BK":
      colotCode = "#8950FC";
      break;
    case "OFP":
      colotCode = "#32C5D2";
      break;
    case "AT":
      colotCode = "#ff7c43";
      break;
    case "IT":
      colotCode = "#6993ff";
      break;
    case "AR":
      colotCode = "#FFA800";
      break;
    case "DL":
      colotCode = "#009688";
      break;
    case "CL":
      colotCode = "#991f17";
      break;

    default:
      colotCode = "";
      break;
  }

  return (
    <span
      className={`label label-lg text-white label-inline font-weight-bold py-4`}
      style={{ backgroundColor: colotCode, whiteSpace: "nowrap" }}
    >
      {row.Status}
    </span>
  );
};
export function TableLoadWidget({
  className,
  entities,
  loadingData,
  setstatusSelected,
  statusSelected,
  getDachboardLoads,
  getchartDate,
  setSortOption,
  SortOption,
}) {
  const { t } = useTranslation();
  const columns = [
    {
      text: "ID#",
      dataField: "Code",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => {
        return (
          <Link
            to={"/track-load/" + row?.Id}
            className="btn btn-link font-weight-bolder text-decoration-none pl-0"
          >
            {row?.Code}
          </Link>
        );
      },
    },
    {
      dataField: "CreationDate",
      text: t("Created"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return getDate(row.CreationDate);
      },
    },
    {
      dataField: "IsDriverAssigned",
      text: "Driver assigned",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            style={{ cursor: "default" }}
            className={
              "btn btn-sm btn-light-" +
              (row.IsDriverAssigned ? "success" : "danger")
            }
          >
            {row.IsDriverAssigned ? "true" : "false"}
          </span>
        );
      },
    },
    {
      dataField: "Driver",
      text: t("Driver"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row?.DriverFisrtName === "" && row?.DriverFisrtName === ""
          ? "-"
          : row?.DriverFisrtName + " " + row?.DriverLastName;
      },
    },
    {
      dataField: "Shipper",
      text: t("PU_City_ST"),
      formatter: (cell, row) => {
        return `${row?.PickupCity} ${row?.PickupState}`;
      },
    },
    {
      dataField: "Consignee",
      text: t("Del_City_ST"),
      formatter: (cell, row) => {
        return `${row?.DestinationCity} ${row?.DestinationState}`;
      },
    },
    {
      dataField: "EquipmentType",
      text: t("EquipmentType"),
      sort: true,
      sortCaret: sortCaret,
      style: {
        textAlign: "center",
      },
      formatter: (cell, row) => {
        return row?.EquipmentType ?? "-";
      },
    },
    // {
    //   dataField: "Distance",
    //   text: "Distance",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style : {
    //     textAlign : 'center'
    //   },
    //   formatter: (cell, row) => {
    //     return row?.Distance ?? "-";
    //   },
    // },
    {
      dataField: "Miles",
      text: t("Miles"),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Price",
      text: t("Price"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            style={{ cursor: "default" }}
            className="btn btn-sm btn-light-info"
          >
            {`$${row?.Price}`}
          </span>
        );
      },
    },
    {
      dataField: "PuAppointmentDate",
      text: t("PuAppointment_From"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return getDate(row.PuAppointmentDate);
      },
    },
    {
      dataField: "PuAppointmentToDate",
      text: t("PuAppointment_To"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return getDate(row.PuAppointmentToDate);
      },
    },
    // {
    //   dataField: "MustArriveByDate",
    //   text: "MA By Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (cell, row) => {
    //     return <Link
    //     style={{cursor : 'default'}}
    //     className="btn btn-sm btn-light-primary"
    //   >
    //     {getDate(row.MustArriveByDate)}
    //   </Link>
    //   },
    // },
    // {
    //   dataField: "ReqShipDate",
    //   text: "ReqShip Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (cell, row) => {
    //     return <Link
    //     style={{cursor : 'default'}}

    //     className="btn btn-sm btn-light-primary"
    //   >
    //     {getDate(row.ReqShipDate)}
    //   </Link>
    //   },
    // },
    {
      dataField: "IsQuickPay",
      text: "Quick Pay",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            style={{ cursor: "default" }}
            className={
              "btn btn-sm btn-light-" + (row.IsQuickPay ? "success" : "danger")
            }
          >
            {row.IsQuickPay ? "true" : "false"}
          </span>
        );
      },
    },
    {
      dataField: "Status",
      text: t("Status"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return StatusColumnFormatter2(row);
      },
    },
  ];
  const GetHandlerTableChange = (type, newState) => {
    if (type === "sort") {
      let values = { ...SortOption };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      getDachboardLoads(statusSelected?.StatusId, values);
    }
  };
  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start align-self-center flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {statusSelected?.Name !== undefined &&
            statusSelected?.Name !== null &&
            statusSelected?.Name !== ""
              ? `${statusSelected?.Name} `
              : "Latest "}
            {t("Latest_Loads")}
          </span>
        </h3>
        <div className="card-toolbar">
          {statusSelected !== "" ? (
            <button
              className="btn btn-info font-weight-bolder font-size-sm mr-3"
              onClick={() => {
                getchartDate();

                setstatusSelected("");
                getDachboardLoads("", SortOption);
              }}
            >
              {t("Clear_Filter")}
            </button>
          ) : (
            ""
          )}
          <Link
            to={"/myLoads"}
            className="btn btn-primary font-weight-bolder font-size-sm"
          >
            {t("Show_all_loads")}
          </Link>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        <div className="table-responsive">
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            noDataIndication={
              loadingData === true ? (
                <h4 style={{ textAlign: "center" }}>Loading ...</h4>
              ) : (
                <h4 style={{ textAlign: "center" }}>
                  {t("There_is_no_data_available_at_the_moment")}.
                </h4>
              )
            }
            bordered={false}
            remote
            onTableChange={GetHandlerTableChange}
            keyField="Id"
            data={entities === null ? [] : entities}
            columns={columns}
          ></BootstrapTable>
        </div>
        {/* end::Table */}
      </div>
      {/* end::Body */}
    </div>
  );
}
