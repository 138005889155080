import React from "react";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
export const AlertsColumnFormatter = (cellContent, row) => {
  return (
    <>
      {row.FfAlert && (
        <OverlayTrigger
          overlay={
            <Tooltip
              style={{ pointerEvents: "none" }}
              id="products-delete-tooltip"
            >
              <div dangerouslySetInnerHTML={{ __html: row.FfAlert }} />
            </Tooltip>
          }
        >
          <a href={()=>false} className="btn btn-icon btn-light btn-hover-warning btn-sm ml-1">
            <span className="svg-icon svg-icon-md svg-icon-warning">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {row.PickupAllert && (
        <OverlayTrigger
          overlay={
            <Tooltip id="customers-edit-tooltip">
              The pickup date passed and no vendor has been selected
            </Tooltip>
          }
        >
          <a href={()=>false} className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-warning">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Code/Warning-1-circle.svg"
                )}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {row.IsDispatchReportSent && (
        <OverlayTrigger
          className="mr-2"
          overlay={
            <Tooltip id="products-edit-tooltip">
              The outbound dispatch report have been sent to the carrier
            </Tooltip>
          }
        >
          <a href={()=>false} className="btn btn-icon btn-light btn-hover-light btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-success">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Sending mail.svg"
                )}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {row.AlertHeavy && (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-edit-tooltip">This Shipment is heavy</Tooltip>
          }
        >
          <a href={()=>false} className="btn btn-icon btn-light btn-hover-light btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/weight-icon.svg"
                )}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {row.AlertHot && (
        <OverlayTrigger
          overlay={<Tooltip id="products-edit-tooltip">Hot</Tooltip>}
        >
          <a href={()=>false} className="btn btn-icon btn-light btn-hover-light btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/fire.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {row.AlertDate && (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-edit-tooltip">
              Shipment has Pickup or Delivery Appointment Date
            </Tooltip>
          }
        >
          <a href={()=>false} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-action">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {row.AlertHazardousMaterial && (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-edit-tooltip">
              Shipment contain Hazardous materials
            </Tooltip>
          }
        >
          <a href={()=>false}
            className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
            //onClick={() => openEditProductPage(row.id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/296395.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
    </>
  );
};

export const StatusColumnFormatter2 = (row) => {
  let colotCode;
  switch (row.StatusCode) {
    case "DF":
      colotCode = "#C0C0C0";
      break;
    case "OP":
      colotCode = "#4caf50";
      break;
    case "BK":
      colotCode = "#8950FC";
      break;
    case "OFP":
      colotCode = "#32C5D2";
      break;
    case "AT":
      colotCode = "#ff7c43";
      break;
    case "IT":
      colotCode = "#6993ff";
      break;
    case "AR":
      colotCode = "#FFA800";
      break;
    case "DL":
      colotCode = "#009688";
      break;
    case "CL":
      colotCode = "#991f17";
      break;

    default:
      colotCode = "";
      break;
  }

  return (
    <span
      className={`label label-lg text-white label-inline font-weight-bold py-4`}
      style={{backgroundColor:colotCode, whiteSpace:"nowrap" }}
    >
      {row.Status}
    </span>
  );
};
