import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { LanguageSelectorDropdown } from ".../../../src/_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../_metronic/_helpers/AtHelper";

const initialValues = {
  Email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();
  const ForgotPasswordSchema = Yup.object().shape({
    Email: Yup.string()
      .email(t("Wrong_email_format"))
      .min(3, t("Minimum_3_symbols"))
      .max(50, t("Maximum_50_symbols"))
      .required(t("Email_is_required")),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setDisableClick(true);
      requestPassword(values.Email)
        .then(() => setIsRequested(true))
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.Email }
            )
          );
        });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      return fetch(
        `${window.$apiUrl}/api/Account/ForgetPassword`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Allow: "POST",
          },
          body: JSON.stringify(values),
        }
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            let timerInterval;
            Swal.fire({
              icon : 'success',
              title: 'Success',
              text: t("Please_check_your_email_to_setup_a_new_password"),
              timer: 2500,
              didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft()
                }, 100)
              },
              willClose: () => {
                history.push("/auth/login");
                clearInterval(timerInterval)
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
              }
            });
           
            setDisableClick(false);
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Swal.fire(t("Error"), translateAuto(element), "error");
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Swal.fire(t("Error"), translateAuto(result[1].Message));
              setDisableClick(false);
            } else if (
              result[1].error_description !== null &&
              result[1].error_description !== undefined &&
              result[1].error_description !== ""
            ) {
              Swal.fire(t("Error"), translateAuto(result[1].error_description));
            } else {
              Swal.fire(t("Error"), t("Bad_Request"));
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div
          className="login login-3 login-signin-on d-flex flex-row-fluid"
          id=""
        >
          <div
            className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
            style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
          >
          <div className="position-fixed top-0 end-0 m-5 Zindex-1">
            <LanguageSelectorDropdown />
          </div>
            <div className="col-xl-4 col-lg-5 col-md-10 col-12  p-7 position-relative overflow-hidden">
              {/*begin::Login Header*/}
              <div className="d-flex flex-center mb-15">
                <a href={() => false}>
                  <SVG src={toAbsoluteUrl("/media/svg/logos/TaapLogo.svg")} />
                </a>
              </div>
              {/*end::Login Header*/}
              {/*begin::Login Sign in form*/}
              <div className="card card-custom">
                <div className="card-header card-header-tabs-line">
                  <div className="card-body">
                    <div className="login-signin">
                      <div className="mb-20 text-center">
                        <h3 className="font-size-h1">{t("Forgotten_Password")} ?</h3>
                        <div className="text-muted font-weight-bold">
                          {t("Enter_your_email_to_reset_your_password")}
                        </div>
                      </div>
                      <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                      >
                        <div className="form-group mb-5 pull-left">
                          <label className="control-label font-weight-bold">
                            {t("Email")}
                          </label>
                          <input
                            value={formik.values.Email}
                            onChange={(e) => {
                              formik.setFieldValue("CardCvc", e.target.value);
                            }}
                            placeholder={t("Email")}
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                              "Email"
                            )}`}
                            name="Emi"
                            {...formik.getFieldProps("Email")}
                          />
                          {formik.touched.Email && formik.errors.Email ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.Email}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group d-flex flex-wrap flex-center">
                          <button
                            id="kt_login_forgot_submit"
                            type="submit"
                            className={`${
                              disableClick
                                ? "spinner spinner-white spinner-left px-md-12"
                                : "px-9"
                            } btn btnPrimaryTaap font-weight-bold  py-4 my-3 mx-4`}
                            disabled={disableClick}
                          >
                            {t("Submit")}
                          </button>
                          <Link to="/auth">
                            <button
                              type="button"
                              id="kt_login_forgot_cancel"
                              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                              {t("Cancel")}
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/*end::Login Sign in form*/}
              </div>
            </div>
          </div>
          {/*end::Login*/}
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
