/* eslint-disable react-hooks/exhaustive-deps */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { Formik } from "formik";
import React, { useEffect, useState, useCallback } from "react";
import { Button, Col, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import LoadItems from "../LoadComponent/LoadItems";
import LoadStops from "../LoadComponent/LoadStops";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import ActivityFunction from "../LoadComponent/Activities";
import { useHistory } from "react-router-dom";
import { DropdownCustomToggler } from "../../../../../../_metronic/_partials/dropdowns";
import BolForm from "../../../../../pages/BOL";
import DynamicReport from "../../../../../pages/DynamicReport";
import AttachementComp from "../LoadComponent/Attachement";
import NotesComp from "../LoadComponent/Notes";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";
import {
  ConvertToDateCurrentTimeZone,
  ConvertToDateGMT,
  convertValueSecondsToDuration,
  currencyFormat,
  formatPhoneNumber,
  translateAuto,
} from "../../../../../../_metronic/_helpers/AtHelper";
import ReactSelect from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { sortCaret } from "../../../../../../_metronic/_helpers";

const adapter = new LocalStorage("db");
const db = low(adapter);
var typingTimer;

export function ProductEditForm({
  actionsLoading,
  product,
  btnRef,
  saveProduct,
  backToProductsList,
  saveProductClick,
  isLoadingAuth,
  setisLoadingAuth,
  getShipmentData,
  ActivitiesList,
  setActivitiesList,
}) {
  const history = useHistory();

  const [ViewModelOptions, setViewModelOptions] = useState([]);
  const [showModalAddresses, setshowModalAddresses] = useState(false);
  const [showModalActivities, setshowModalActivities] = useState(false);
  const [LoadingBids, setLoadingBids] = useState(false);
  const [BolMulti, setBolMulti] = useState(false);
  const [showAssignToTrucker, setshowAssignToTrucker] = useState(false);
  const [showInvitToTrucker, setshowInvitToTrucker] = useState(false);
  const [showBidsList, setshowBidsList] = useState(false);
  const [TruckerSelected, setTruckerSelected] = useState(null);
  const [TruckerList, setTruckerList] = useState([]);
  const [ContriesViewModel, setContriesViewModel] = useState([]);
  const [LoadStopsList, setLoadStopsList] = useState([]);
  const [BidsListOptions, setBidsListOptions] = useState([]);
  const [shippingselect, setShippingselect] = useState();
  const [receivingselect, setReceivingselect] = useState();
  const { t } = useTranslation();
  function GetContriesViewModel() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Countries`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setContriesViewModel(data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function GetBidsList(id) {
    setLoadingBids(true);
    setBidsListOptions([]);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Shipments/Bids/${id}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setBidsListOptions(data?.Bids || []);
        }
        setLoadingBids(false);
      })
      .catch((error) => {
        setLoadingBids(false);

        console.log("error", error);
      });
  }
  function GetTruckers() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Import/GetTruckers`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setTruckerList(data?.Truckers || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Shipments/GetViewModel`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setViewModelOptions(result[1]);
        } else if (result[0] === 402) {
          Swal.fire(t("Error"), translateAuto(result[1].Message));
          history.push("/user-profile#billing");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function validateZipCode(
    country,
    zipCode,
    city,
    state,
    adrsse,
    name,
    creatLoad
  ) {
    if (adrsse === "" || adrsse === null || adrsse === undefined) {
      Swal.fire(t("Error"), t("Please_enter_address"));
      return;
    }
    if (city === "" || city === null || city === undefined) {
      Swal.fire(t("Error"), t("The_city_field_is_required"));
      return;
    }
    if (state === "" || state === null || state === undefined) {
      Swal.fire(t("Error"), t("The_state_field_is_required"));
      return;
    }
    if (zipCode === "" || zipCode === null || zipCode === undefined) {
      Swal.fire(t("Error"), t("The_zipcode_field_is_required"));
      return;
    }
    if (name === "" || name === null || name === undefined) {
      Swal.fire(t("Error"), t("Please_enter_name"));
      return;
    }
    const apiKey = "AIzaSyA1A9EPSRfIpJkN2v8CweA_W2_BUGCIdU8";
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?components=country:${country}|postal_code:${zipCode}&sensor=false&key=${apiKey}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          const ct = result[1]?.results[0]?.address_components?.find(
            (x) => x?.types.length > 0 && x.types[0] === "locality"
          );
          const zip = result[1]?.results[0]?.address_components?.find(
            (x) => x?.types.length > 0 && x.types[0] === "postal_code"
          );
          const st = result[1]?.results[0]?.address_components?.find(
            (x) =>
              x?.types.length > 0 &&
              x.types[0] === "administrative_area_level_1"
          );
          if (result[1]?.results.length === 0) {
            Swal.fire({
              title: t("Something_is_wrong"),
              text: t("The_zip_code_is_not_valid_please_check_it"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: t("Yes_continue_with_this_zip_code"),
              cancelButtonText: t("No_check_it"),
            }).then((result) => {
              if (result.isConfirmed) {
                creatLoad();
              }
            });
          } else if (state.toLowerCase() !== st?.long_name.toLowerCase()) {
            Swal.fire({
              // title: 'Something is wrong?',
              text: `${city} ${t("doesnt_belong_to")} ${state}, ${("please_check_it")}`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: t("Yes_continue_with_this_zip_code"),
              cancelButtonText: t("No_check_it"),
            }).then((result) => {
              if (result.isConfirmed) {
                creatLoad();
              }
            });
          } else if (city.toLowerCase() !== ct?.long_name.toLowerCase()) {
            Swal.fire({
              // title: 'Something is wrong?',
              text: `${t("The_zip_code_doesnt_belong_to")} ${city}, ${t("please_check_it")}`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: t("Yes_continue_with_this_zip_code"),
              cancelButtonText: t("No_check_it"),
            }).then((result) => {
              if (result.isConfirmed) {
                creatLoad();
              }
            });
          } else if (
            zipCode?.trim().toLowerCase() !==
            zip?.long_name?.trim().toLowerCase()
          ) {
            Swal.fire({
              title: t("Something_is_wrong"),
              text: t("The_zip_code_is_not_valid_please_check_it"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: t("Yes_continue_with_this_zip_code"),
              cancelButtonText: t("No_check_it"),
            }).then((result) => {
              if (result.isConfirmed) {
                creatLoad();
              }
            });
          } else {
            creatLoad();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getViewModel();
    GetContriesViewModel();
    GetTruckers();
  }, []);
  useEffect(() => {
    if (product.id) {
      GetBidsList(product.id);
    }
  }, [product]);
  const [initAddressObject, setinitAddressObject] = useState({
    Name: "",
    Code: "",
    Address1: "",
    Address2: "",
    Zipcode: "",
    City: "",
    State: "",
    Country: "United States",
    Coupon: "",
    ContactFirstName: "",
    ContactLastName: "",
    ContactEmail: "",
    ContactPhone: "",
    ContactPhone2: "",
    ContactFax: "",
    IsShipper: false,
  });
  const columns = [
    {
      dataField: "Trucker",
      text: t("Trucker"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.Trucker;
      },
      style: (cell, row) => {
        if (row.Selected) {
          return {
            background: "#cfffe5",
          };
        }
      },
    },
    {
      dataField: "Price",
      text: t("Price"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.Price);
      },
      style: (cell, row) => {
        if (row.Selected) {
          return {
            background: "#cfffe5",
          };
        }
      },
    },
    {
      dataField: "Created",
      text: t("Post_Date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return ConvertToDateCurrentTimeZone(row.Created);
      },
      style: (cell, row) => {
        if (row.Selected) {
          return {
            background: "#cfffe5",
          };
        }
      },
    },
    {
      dataField: "AcceptBid",
      text: t("Accept_Bid"),
      sort: true,
      sortCaret: sortCaret,
      style: (cell, row) => {
        if (row.Selected) {
          return {
            background: "#cfffe5",
          };
        }
      },
      formatter: (cell, row) => {
        return (
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="layout-tooltip">{t("Accept_Bid")}</Tooltip>}
          >
            <button
              onClick={() => {
                if (
                  BidsListOptions.filter((a) => a.Selected === true).length > 0
                ) {
                  return;
                }

                Swal.fire({
                  title: t("Are_you_sure"),
                  text: t("You_wont_be_select_this_bid"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Confirm!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setLoadingBids(true);
                    setBidsListOptions([]);

                    var myHeaders = new Headers();
                    myHeaders.append(
                      "Authorization",
                      `Bearer ${db.read().getState()?.Token}`
                    );

                    var requestOptions = {
                      method: "GET",
                      headers: myHeaders,
                      redirect: "follow",
                    };

                    fetch(
                      `${window.$apiurl}/api/Shipments/SelectBid/${row?.Id}`,
                      requestOptions
                    )
                      .then(async (response) => {
                        const statusCode = await response.status;
                        const contentType = response.headers.get(
                          "content-type"
                        );
                        if (
                          contentType &&
                          contentType.indexOf("application/json") !== -1
                        ) {
                          return response
                            .json()
                            .then((data) => [statusCode, data]);
                        } else {
                          return response
                            .text()
                            .then((text) => [statusCode, { Message: "" }]);
                        }
                      })
                      .then((result) => {
                        if (result[0] === 200) {
                          getShipmentData(product.id);
                          GetBidsList(product.id);
                          Swal.fire(
                            t("Great"),
                            t("Trucker_has_been_selected_successfully"),
                            "success"
                          );
                        } else {
                          if (
                            result[1].ModelState !== null &&
                            result[1].ModelState !== undefined &&
                            result[1].ModelState !== ""
                          ) {
                            let modelState = result[1].ModelState;
                            if (modelState)
                              Object.keys(modelState).forEach(function (k) {
                                modelState[k].forEach((element) => {
                                  Swal.fire(t("Error"), translateAuto(element), "error");
                                });
                              });
                          } else if (
                            result[1].Message !== null &&
                            result[1].Message !== undefined &&
                            result[1].Message !== ""
                          ) {
                            Swal.fire(t("Error"), translateAuto(result[1].Message));
                          } else if (
                            result[1].error_description !== null &&
                            result[1].error_description !== undefined &&
                            result[1].error_description !== ""
                          ) {
                            Swal.fire(t("Error"),  translateAuto(result[1].error_description));
                          } else {
                            Swal.fire(t("Error"), t("Bad_Request"));
                          }
                          GetBidsList(product.id);
                        }
                        setisLoadingAuth(false);
                      })
                      .catch((error) => {
                        GetBidsList(product.id);

                        Swal.fire(t("Error"), error);
                        setisLoadingAuth(false);
                      });
                  }
                });
              }}
              type="button"
              className="btn btn-sm btn-icon btn-bg-light"
            >
              <i
                className={`fas fa-check ${row.Selected ? "text-success" : ""
                  } `}
              ></i>
            </button>
          </OverlayTrigger>
        );
      },
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("Shipper")} | {t("Loads")}
        </title>
      </Helmet>
      <Formik
        enableReinitialize={true}
        initialValues={product}
        //validationSchema={ProductEditSchema}
        onSubmit={(values) => {
          saveProduct(values);
        }}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => {
          function getAddressDetail(AddressId, isShipping) {
            var myHeaders = new Headers();
            myHeaders.append(
              "Authorization",
              `Bearer ${db.read().getState()?.Token}`
            );

            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };

            fetch(`${window.$apiurl}/api/Address/${AddressId}`, requestOptions)
              .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json().then((data) => [statusCode, data]);
                } else {
                  return response
                    .text()
                    .then((text) => [statusCode, { Message: "" }]);
                }
              })
              .then(async (result) => {
                if (result[0] === 200) {
                  var data = result[1];
                  let obj = data.Address;
                  if (isShipping) {
                    setFieldValue("ShippingAddress1", obj.Address1);
                    setFieldValue("ShippingAddress2", obj.Address2);
                    setFieldValue("ShippingCity", obj.City);
                    setFieldValue("ShippingState", obj.State);
                    setFieldValue("ShippingCountry", obj.Country);
                    setFieldValue("ShippingZipCode", obj.Zipcode);
                    setFieldValue("ShippingContactName", obj.ContactName);
                    setFieldValue("ShippingContactPhone", obj.ContactPhone);
                    setFieldValue("ShippingContactPhone2", obj.ContactPhone2);
                    setFieldValue("ShippingContactFax", obj.ContactFax);
                    setFieldValue("ShippingContactEmail", obj.ContactEmail);
                  } else {
                    setFieldValue("ReceivingAddress1", obj.Address1);
                    setFieldValue("ReceivingAddress2", obj.Address2);
                    setFieldValue("ReceivingCity", obj.City);
                    setFieldValue("ReceivingCountry", obj.Country);
                    setFieldValue("ReceivingState", obj.State);
                    setFieldValue("ReceivingZipCode", obj.Zipcode);
                    setFieldValue("ReceivingContactName", obj.ContactName);
                    setFieldValue("ReceivingContactPhone", obj.ContactPhone);
                    setFieldValue("ReceivingContactPhone2", obj.ContactPhone2);
                    setFieldValue("ReceivingContactFax", obj.ContactFax);
                    setFieldValue("ReceivingContactEmail", obj.ContactEmail);
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          }

          return (
            <>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <Card className="bg-transparent shadow-none header-detail mb-1">
                        {actionsLoading && <ModalProgressBar />}
                        <CardHeader className="border-0 px-0">
                          <CardHeaderToolbar className="pl-0 align-content-end">
                            <button
                              type="button"
                              onClick={backToProductsList}
                              className="btn btn-light"
                            >
                              <i className="fa fa-arrow-left"></i>
                              {t("Back")}
                            </button>
                            {`  `}

                            <button
                              className="btn btn-primary mx-2"
                              disabled={isLoadingAuth}
                              onClick={() => {
                                if (product?.IsDraft === true) {
                                  setFieldValue("AsDraft", true);
                                } else {
                                  setFieldValue("AsDraft", false);
                                }

                                handleSubmit();
                              }}
                            >
                              {t("Save")}
                            </button>
                            {product?.IsDraft === true && (
                              <button
                                className="btn btn-success ml-xl-2 ml-lg-0 ml-md-0 ml-0 mt-xl-0 mt-lg-1 mt-md-2 mt-3"
                                disabled={isLoadingAuth}
                                onClick={() => {
                                  setFieldValue("AsDraft", false);
                                  handleSubmit();
                                }}
                              >
                                {t("Save_Post")}
                              </button>
                            )}
                            {product.id !== undefined && (
                              <>
                                <Dropdown
                                  className="dropdown-inline position-unset pl-3"
                                  alignLeft
                                >
                                  <Dropdown.Toggle
                                    className="btn btn-sm btn-icon btn-bg-light "
                                    variant="transparent"
                                    id="dropdown-toggle-top"
                                    as={DropdownCustomToggler}
                                  >
                                    <i className="fas fa-cogs text-warning" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu dropdown-menu-sm  dropdown-menu-right topFixedMenu">
                                    <ul className="navi navi-hover py-5">
                                      <li className="navi-item">
                                        <p
                                          className="navi-link btn"
                                          onClick={() => {
                                            setBolMulti(true);
                                            //setRateConfirmationMulti(true);
                                          }}
                                        >
                                          <span className="navi-icon">
                                            <i className="fas fa-file-invoice text-info"></i>
                                          </span>
                                          <span className="navi-text text-info">
                                            BOL
                                          </span>
                                        </p>
                                      </li>
                                      {(product.StatusCode === "OP" ||
                                        product.StatusCode === "DF") &&
                                        db.read().getState()?.IsDash2Dash ===
                                        "True" ? (
                                        <li className="navi-item">
                                          <p
                                            className="navi-link btn"
                                            onClick={() => {
                                              setshowAssignToTrucker(true);
                                              setTruckerSelected(null);
                                            }}
                                          >
                                            <span className="navi-icon">
                                              <i className="fas fa-file-invoice text-primary"></i>
                                            </span>
                                            <span className="navi-text text-primary text-truncate">
                                              {t("Assigne_Shipment")}
                                            </span>
                                          </p>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {product.StatusCode === "OP" ? (
                                        <li className="navi-item">
                                          <p
                                            className="navi-link btn"
                                            onClick={() => {
                                              setshowInvitToTrucker(true);
                                              setTruckerSelected(null);
                                            }}
                                          >
                                            <span className="navi-icon">
                                              <i className="fas fa-user-plus text-success"></i>
                                            </span>
                                            <span className="navi-text text-success text-truncate">
                                              {t("Invite_trucker")}
                                            </span>
                                          </p>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {product.StatusCode === "BK" &&
                                        db.read().getState()?.IsDash2Dash ===
                                        "True" ? (
                                        <li className="navi-item">
                                          <p
                                            className="navi-link btn"
                                            onClick={() => {
                                              document
                                                .getElementsByClassName(
                                                  "dropdown-menu-right"
                                                )[0]
                                                .classList.remove("show");
                                              setisLoadingAuth(true);
                                              var myHeaders = new Headers();
                                              myHeaders.append(
                                                "Authorization",
                                                `Bearer ${db.read().getState()?.Token
                                                }`
                                              );

                                              myHeaders.append(
                                                "Content-Type",
                                                "application/json"
                                              );

                                              var raw = JSON.stringify({
                                                ShipmentId: product.id,
                                                Assign: false,
                                                TruckerId: null,
                                              });

                                              return fetch(
                                                `${window.$apiurl}/api/Shipments/Assignment`,
                                                {
                                                  method: "post",
                                                  headers: myHeaders,
                                                  body: raw,
                                                }
                                              )
                                                .then(async (response) => {
                                                  const statusCode = await response.status;
                                                  const contentType = response.headers.get(
                                                    "content-type"
                                                  );
                                                  if (
                                                    contentType &&
                                                    contentType.indexOf(
                                                      "application/json"
                                                    ) !== -1
                                                  ) {
                                                    return response
                                                      .json()
                                                      .then((data) => [
                                                        statusCode,
                                                        data,
                                                      ]);
                                                  } else {
                                                    return response
                                                      .text()
                                                      .then((text) => [
                                                        statusCode,
                                                        { Message: "" },
                                                      ]);
                                                  }
                                                })
                                                .then((result) => {
                                                  if (result[0] === 200) {
                                                    getShipmentData(product.id);
                                                    GetBidsList(product.id);

                                                    Swal.fire(
                                                      t("Great"),
                                                      t(
                                                        "Unassigned_successfully"
                                                      ),
                                                      "success"
                                                    );
                                                  } else {
                                                    if (
                                                      result[1].ModelState !==
                                                      null &&
                                                      result[1].ModelState !==
                                                      undefined &&
                                                      result[1].ModelState !==
                                                      ""
                                                    ) {
                                                      let modelState =
                                                        result[1].ModelState;
                                                      if (modelState)
                                                        Object.keys(
                                                          modelState
                                                        ).forEach(function (k) {
                                                          modelState[k].forEach(
                                                            (element) => {
                                                              Swal.fire(
                                                                t("Error"),
                                                                element
                                                              );
                                                            }
                                                          );
                                                        });
                                                    } else if (
                                                      result[1].Message !==
                                                      null &&
                                                      result[1].Message !==
                                                      undefined &&
                                                      result[1].Message !== ""
                                                    ) {
                                                      Swal.fire(
                                                        t("Error"),
                                                        translateAuto(result[1].Message)
                                                      );
                                                    } else if (
                                                      result[1]
                                                        .error_description !==
                                                      null &&
                                                      result[1]
                                                        .error_description !==
                                                      undefined &&
                                                      result[1]
                                                        .error_description !==
                                                      ""
                                                    ) {
                                                      Swal.fire(
                                                        t("Error"),
                                                        translateAuto(result[1].error_description)
                                                      );
                                                    } else {
                                                      Swal.fire(
                                                        t("Error"),
                                                        t("Bad_Request")
                                                      );
                                                    }
                                                  }
                                                  setisLoadingAuth(false);
                                                })
                                                .catch((error) => {
                                                  Swal.fire(t("Error"), error);
                                                  setisLoadingAuth(false);
                                                });
                                            }}
                                          >
                                            <span className="navi-icon">
                                              <i className="fas fa-file-invoice text-primary"></i>
                                            </span>
                                            <span className="navi-text text-primary text-truncate">
                                              {t("Unassigne_Shipment")}
                                            </span>
                                          </p>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {db.read().getState()?.IsDash2Dash ===
                                        "False" ? (
                                        <li className="navi-item">
                                          <p
                                            className="navi-link btn"
                                            onClick={() => {
                                              GetBidsList(product.id);
                                              setshowBidsList(true);
                                            }}
                                          >
                                            <span className="navi-icon">
                                              <i className="fas fa-gavel text-warning"></i>
                                            </span>
                                            <span className="navi-text text-warning text-truncate">
                                              {t("Bids")}
                                            </span>
                                          </p>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                    </ul>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </>
                            )}
                            {isLoadingAuth && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </CardHeaderToolbar>
                        </CardHeader>
                      </Card>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <Card>
                        <CardHeader
                          className="border-bottom-0"
                          title={t("Basic_Info")}
                        >
                          <div className="separator separator-dashed my-3"></div>
                        </CardHeader>
                        <CardBody>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                SO #
                              </a>
                              <a
                                href={() => false}
                                className="text-parimary font-weight-bold"
                              >
                                <input
                                  type="text"
                                  className="form-control "
                                  name="MOText"
                                  id="SOText"
                                  label="SO"
                                  disabled={true}
                                  value={values.Id}
                                  onChange={(e) => {
                                    setFieldValue("Id", e.target.value);
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Load")} #
                              </a>
                              <a
                                href={() => false}
                                className="text-parimary font-weight-bold"
                              >
                                <input
                                  type="text"
                                  className="form-control "
                                  name="Code"
                                  id="Code"
                                  label="Code"
                                  disabled={true}
                                  value={values.Code}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Shipment_Date")}
                                <span className="text-danger">*</span>
                              </a>
                              <span className="text-muted font-weight-bold">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="ShipmentDate"
                                  id="ShipmentDate"
                                  label="Shipment Date"
                                  value={String(
                                    moment(values.ShipmentDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  )}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "ShipmentDate",
                                      e.target.value
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center mb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Mode")}
                                <span className="text-danger">*</span>
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  name="ModeId"
                                  id="ModeId"
                                  label="ModeId"
                                  value={ViewModelOptions?.Modes?.filter(
                                    (a) => a.Id === values.ModeId
                                  )?.map((a) => {
                                    return { label: a.Name, value: a.Id };
                                  })}
                                  options={ViewModelOptions?.Modes?.map((a) => {
                                    return { label: a.Name, value: a.Id };
                                  })}
                                  onChange={(e) => {
                                    setFieldValue("ModeId", e?.value);
                                  }}
                                  inputId="react-select-single"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center mb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Equipment_Type")}
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  name="EquipmentTypeId"
                                  id="EquipmentTypeId"
                                  label="EquipmentTypeId"
                                  inputId="react-select-single"
                                  value={ViewModelOptions?.EquipmentTypes?.filter(
                                    (a) => a.Id === values.EquipmentTypeId
                                  )?.map((a) => {
                                    return { label: a.Name, value: a.Id };
                                  })}
                                  options={ViewModelOptions?.EquipmentTypes?.map(
                                    (a) => {
                                      return { label: a.Name, value: a.Id };
                                    }
                                  )}
                                  onChange={(e) => {
                                    setFieldValue("EquipmentTypeId", e?.value);
                                  }}
                                />
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Driving_Time")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="DrivingTime"
                                    id="DrivingTime"
                                    label="DrivingTime"
                                    disabled={true}
                                    value={convertValueSecondsToDuration(
                                      values?.DrivingTime
                                    )}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_Miles")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Miles"
                                    id="Miles"
                                    label="Miles"
                                    disabled={true}
                                    value={values.TotalMiles}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                              <div
                                href="#Accessorials"
                                className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2"
                              >
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Price")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="BasicPrice"
                                    id="BasicPrice"
                                    label="BasicPrice"
                                    value={values.BasicPrice}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "BasicPrice",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                              <div
                                href="#Accessorials"
                                className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2"
                                onClick={() => {
                                  var ele = document.getElementById(
                                    "Accessorials"
                                  );
                                  window.scrollTo(
                                    ele.offsetLeft,
                                    ele.offsetTop
                                  );
                                }}
                              >
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_accessorials")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="TotalAccessorials"
                                    id="TotalAccessorials"
                                    label="TotalAccessorials"
                                    value={currencyFormat(
                                      values.TotalAccessorials
                                    )}
                                    disabled={true}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-5">
                              <div
                                href="#Accessorials"
                                className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2"
                              >
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_Price")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="TotalPrice"
                                    disabled={true}
                                    id="TotalPrice"
                                    label="TotalPrice"
                                    value={currencyFormat(values.TotalPrice)}
                                  />
                                </span>
                              </div>
                            </div>

                            <div className="d-flex flex-wrap align-items-center pb-5 col-xl-6 col-lg-12 col-md-12">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Weight_Type")}
                                </a>
                                <span className="font-weight-bold">
                                  <ReactSelect
                                    name="WeightTypeIds"
                                    id="WeightTypeIds"
                                    options={ViewModelOptions?.WeightTypes?.map(
                                      (a) => {
                                        return {
                                          label: a.Name,
                                          value: a.Name,
                                        };
                                      }
                                    )}
                                    value={ViewModelOptions?.WeightTypes?.filter(
                                      (a) => a.Name === values.WeightType
                                    )?.map((a) => {
                                      return { label: a.Name, value: a.Name };
                                    })}
                                    onChange={(e) => {
                                      setFieldValue("WeightType", e.value);
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-xl-6 col-lg-12 col-md-12">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_Weight")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="TotalWeight"
                                    id="TotalWeight"
                                    label="TotalWeight"
                                    value={values?.TotalWeight}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "TotalWeight",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-12">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Value")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="Value"
                                    id="Value"
                                    label="Value"
                                    min="0"
                                    value={values?.Value}
                                    onChange={(e) => {
                                      if (e.target.value === "") {
                                        setFieldValue("Value", e.target.value);
                                      } else {
                                        const value = Math.max(
                                          0,
                                          Math.min(
                                            10000000,
                                            Number(e.target.value)
                                          )
                                        );
                                        setFieldValue("Value", value);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                              <div className="checkbox-list d-flex pl-0">
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    checked={values.BiddingEnabled}
                                    id="BiddingEnabled"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "BiddingEnabled",
                                        e.target.checked
                                      );
                                    }}
                                    name="BiddingEnabled"
                                    className="mr-2"
                                  />
                                  <span className="mb-auto"></span>
                                  <div className="d-flex flex-column flex-grow-1">
                                    <label
                                      className="form-check-label"
                                      for="BiddingEnabled"
                                    >
                                      {t("Enable_Bidding")}
                                    </label>
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                              <div className="checkbox-list d-flex pl-0">
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    checked={values.IsPrivate}
                                    id="IsPrivate"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "IsPrivate",
                                        e.target.checked
                                      );
                                    }}
                                    name="IsPrivate"
                                    className="mr-2"
                                  />
                                  <span className="mb-auto"></span>
                                  <div className="d-flex flex-column flex-grow-1">
                                    <label
                                      className="form-check-label"
                                      for="IsPrivate"
                                    >
                                      {t("IsPrivate")}
                                    </label>
                                  </div>
                                </label>
                              </div>
                            </div>
                            {values.QuickPaySuspended !== true ? (
                              <div className="col-xl-12 col-lg-12 col-md-12 mb-5">
                                <div className="checkbox-list d-flex pl-0">
                                  <label className="checkbox">
                                    <input
                                      type="checkbox"
                                      checked={values.IsQuickPay}
                                      id="IsQuickPay"
                                      disabled={
                                        values.StatusCode !== "OP" &&
                                        values.StatusCode !== "" &&
                                        values.StatusCode !== "DF"
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "IsQuickPay",
                                          e.target.checked
                                        );
                                      }}
                                      name="IsQuickPay"
                                      className="mr-2"
                                    />
                                    <span className="mb-auto"></span>
                                    <div className="d-flex flex-column flex-grow-1">
                                      <label
                                        className="form-check-label"
                                        for="IsQuickPay"
                                      >
                                        {t("Available_for_QP")}
                                      </label>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-8">
                  <Card className="bg-transparent shadow-none Service-order">
                    {actionsLoading && <ModalProgressBar />}
                    <CardBody className="p-0">
                      <div className="mt-15">
                        <Card>
                          <CardHeader title={t("Shipping_Details")}>
                            <CardHeaderToolbar>
                              {product.id && (
                                <button
                                  type="button"
                                  className="btn btn-success mr-4"
                                  onClick={() => {
                                    setLoadStopsList([
                                      ...LoadStopsList,
                                      {
                                        ShipmentId: product.id,
                                        AddressId: null,
                                        SealNumber: "",
                                        PickupNumber: "",
                                        SpecialReq: [],
                                        ReqShipDate: null,
                                        PuAppointmentDate: null,
                                        PuAppointmentToDate: null,
                                        DestinationNumber: "",
                                        Mabd: null,
                                        DelAppointmentDate: null,
                                        DelAppointmentToDate: null,

                                        Note: "",
                                        Type: "Pick-up",
                                      },
                                    ]);
                                  }}
                                >
                                  <i className="fas fa-plus mr-3" />
                                  {t("Add_Stops")}
                                </button>
                              )}
                            </CardHeaderToolbar>
                          </CardHeader>
                          <CardBody>
                            <div className="row mb-5">
                              <Col xl="12" lg="12" md="12" className="mb-5">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Company_Name")}
                                      <span className="text-danger">*</span>
                                    </a>
                                    <span className="font-weight-bold">
                                      <CreatableSelect
                                        className="mt-4"
                                        isClearable
                                        name="PickupId"
                                        id="PickupId"
                                        label="Company"
                                        value={ViewModelOptions?.Addresses?.filter(
                                          (a) => a.Id === values?.PickupId
                                        ).map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                            ValueId: a.ValueId,
                                          };
                                        })}
                                        options={ViewModelOptions?.Addresses?.filter(
                                          (el) => el.Name !== receivingselect
                                        )?.map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                            ValueId: a.ValueId,
                                          };
                                        })}
                                        onChange={async (newValue) => {
                                          if (newValue !== null) {
                                            setShippingselect(newValue.label);
                                            if (newValue?.__isNew__) {
                                              setshowModalAddresses(true);
                                              setinitAddressObject({
                                                Name: newValue.label,
                                                Code: "",
                                                Address1: "",
                                                Address2: "",
                                                Zipcode: "",
                                                City: "",
                                                State: "",
                                                Country: "United States",
                                                Coupon: "",
                                                ContactFirstName: "",
                                                ContactLastName: "",
                                                ContactEmail: "",
                                                ContactPhone: "",
                                                ContactPhone2: "",
                                                ContactFax: "",
                                                IsShipper: true,
                                              });
                                            } else {
                                              setFieldValue(
                                                "PickupId",
                                                newValue?.value
                                              );
                                              setFieldValue(
                                                "PickupName",
                                                newValue?.label
                                              );
                                              getAddressDetail(
                                                newValue?.value,
                                                true
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              <div className="col-xl-8 col-lg-8 col-md-5 border-right">
                                <Row>
                                  <div className="col-xl col-lg col-md  border-right">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 1
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            disabled={true}
                                            value={values?.ShippingAddress1}
                                            name="ShipperAddress1"
                                            id="ShipperAddress1"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 2
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            disabled={true}
                                            value={values?.ShippingAddress2}
                                            name="ShipperAddress2"
                                            id="ShipperAddress2"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Country")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingCountry"
                                              id="ShippingCountry"
                                              value={values?.ShippingCountry}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("State")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingState"
                                              id="ShippingState"
                                              value={values?.ShippingState}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("City")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingCity"
                                              id="ShippingCity"
                                              value={values?.ShippingCity}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Zip")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingZipCode"
                                              id="ShippingZipCode"
                                              value={values?.ShippingZipCode}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl col-lg col-md border-right">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Name")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ShippingContactName"
                                            id="ShippingContactName"
                                            label="Contact"
                                            value={values?.ShippingContactName}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Email")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="email"
                                            className="form-control mt-4"
                                            name="ShippingContactEmail"
                                            id="ShippingContactEmail"
                                            label="Email"
                                            value={values?.ShippingContactEmail}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 1
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ShippingContactPhone1"
                                              id="ShippingContactPhone1"
                                              value={formatPhoneNumber(
                                                values?.ShippingContactPhone
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 2
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ShippingContactPhone2"
                                              id="ShippingContactPhone2"
                                              value={formatPhoneNumber(
                                                values?.ShippingContactPhone2
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Fax")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="tel"
                                            className="form-control mt-4"
                                            name="ShippingContactFax"
                                            id="ShippingContactFax"
                                            label="Fax"
                                            value={formatPhoneNumber(
                                              values?.ShippingContactFax
                                            )}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Note")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <textarea
                                        className="form-control"
                                        maxlength="400"
                                        rows="3"
                                        value={values.ShippingNote}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ShippingNote",
                                            e.target.value
                                          );
                                        }}
                                      ></textarea>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-12 col-12 border-right">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      Req. {t("Ship_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="RequiredShipDate"
                                        id="RequiredShipDate"
                                        label="Ship"
                                        value={String(
                                          moment(values.ReqShipDate).format(
                                            "YYYY-MM-DD"
                                          )
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ReqShipDate",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      PU #
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control mt-4"
                                        name="ShipperNumber"
                                        id="ShipperNumber"
                                        value={values.PickupNumber}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "PickupNumber",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Seal")} #
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control mt-4"
                                        name="SealNumber"
                                        id="SealNumber"
                                        value={values.SealNumber}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "SealNumber",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Special_Req")}
                                    </a>
                                    <span className="font-weight-bold">
                                      <Select
                                        isMulti
                                        className="mt-4"
                                        name="ShipperSpecialReq"
                                        id="ShipperSpecialReq"
                                        value={ViewModelOptions?.SpecialReq?.filter(
                                          function (el) {
                                            return (
                                              values?.ShippingSpecialReq?.indexOf(
                                                el.Name
                                              ) >= 0
                                            );
                                          }
                                        )?.map((a) => {
                                          return {
                                            value: a.Name,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.SpecialReq?.map(
                                          (a) => {
                                            return {
                                              value: a.Name,
                                              label: a.Name,
                                            };
                                          }
                                        )}
                                        onChange={(newValue) => {
                                          if (newValue !== null) {
                                            setFieldValue(
                                              "ShippingSpecialReq",
                                              newValue.map((a) => a.value)
                                            );
                                          } else {
                                            setFieldValue(
                                              "ShippingSpecialReq",
                                              []
                                            );
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Pu_Appointment_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="datetime-local"
                                        className="form-control mt-4"
                                        name="PuAppointmentDate"
                                        id="PuAppointmentDate"
                                        label="Ship"
                                        value={moment(
                                          ConvertToDateCurrentTimeZone(
                                            values.PuAppointmentDate
                                          )
                                        ).format("YYYY-MM-DD[T]HH:mm")}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "PuAppointmentDate",
                                            ConvertToDateGMT(e.target.value)
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Pu_Appointment_To_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="datetime-local"
                                        className="form-control mt-4"
                                        name="PuAppointmentToDate"
                                        id="PuAppointmentToDate"
                                        label="Ship"
                                        value={moment(
                                          ConvertToDateCurrentTimeZone(
                                            values.PuAppointmentToDate
                                          )
                                        ).format("YYYY-MM-DD[T]HH:mm")}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "PuAppointmentToDate",
                                            ConvertToDateGMT(e.target.value)
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <LoadStops
                          values={values}
                          setFieldValue={setFieldValue}
                          id={product.id}
                          product={product}
                          ViewModelOptions={ViewModelOptions}
                          getShipmentData={getShipmentData}
                          setLoadStopsList={setLoadStopsList}
                          LoadStopsList={LoadStopsList}
                          getViewModel={getViewModel}
                          ContriesViewModel={ContriesViewModel}
                          IsDetailsMode={false}
                        />

                        <Card>
                          <CardHeader title={t("Receiving_Details")}>
                            <CardHeaderToolbar>
                              {product.id && (
                                <button
                                  type="button"
                                  className="btn btn-success mr-4"
                                  onClick={() => {
                                    setLoadStopsList([
                                      ...LoadStopsList,
                                      {
                                        ShipmentId: product.id,
                                        AddressId: null,
                                        SealNumber: "",
                                        PickupNumber: "",
                                        SpecialReq: [],
                                        ReqShipDate: null,
                                        PuAppointmentDate: null,
                                        PuAppointmentToDate: null,
                                        DestinationNumber: "",
                                        Mabd: null,
                                        DelAppointmentDate: null,
                                        DelAppointmentToDate: null,
                                        Note: "",
                                        Type: "Drop",
                                      },
                                    ]);
                                  }}
                                >
                                  <i className="fas fa-plus mr-3" />
                                  {t("Add_Stops")}
                                </button>
                              )}
                            </CardHeaderToolbar>
                          </CardHeader>
                          <CardBody>
                            <div className="row mb-5">
                              <Col xl="12" lg="12" md="12" className="mb-5">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Company_Name")}
                                      <span className="text-danger">*</span>
                                    </a>
                                    <span className="font-weight-bold">
                                      <CreatableSelect
                                        className="mt-4"
                                        isClearable
                                        name="DestinationId"
                                        id="DestinationId"
                                        label="Company"
                                        value={ViewModelOptions?.Addresses?.filter(
                                          (a) => a.Id === values?.DestinationId
                                        ).map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.Addresses?.filter(
                                          function (el) {
                                            return el.Name !== shippingselect;
                                          }
                                        )?.map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                          };
                                        })}
                                        onChange={async (newValue) => {
                                          if (newValue !== null) {
                                            setReceivingselect(newValue.label);
                                            if (newValue?.__isNew__) {
                                              setshowModalAddresses(true);
                                              setinitAddressObject({
                                                Name: newValue.label,
                                                Code: "",
                                                Address1: "",
                                                Address2: "",
                                                Zipcode: "",
                                                City: "",
                                                State: "",
                                                Country: "United States",
                                                Coupon: "",
                                                ContactFirstName: "",
                                                ContactLastName: "",
                                                ContactEmail: "",
                                                ContactPhone: "",
                                                ContactPhone2: "",
                                                ContactFax: "",
                                                IsShipper: false,
                                              });
                                            } else {
                                              setFieldValue(
                                                "DestinationId",
                                                newValue?.value
                                              );
                                              setFieldValue(
                                                "DestinationName",
                                                newValue?.label
                                              );
                                              getAddressDetail(
                                                newValue?.value,
                                                false
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              <div className="col-xl-8 col-lg-8 col-md-5 border-right">
                                <Row>
                                  <div className="col-xl col-lg col-md border-right">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 1
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ReceivingAddress1"
                                            id="ReceivingAddress1"
                                            value={values?.ReceivingAddress1}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 2
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ReceivingAddress2"
                                            id="ReceivingAddress2"
                                            value={values?.ReceivingAddress2}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Country")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              disabled={true}
                                              className="form-control mt-4"
                                              name="ReceivingCountry"
                                              id="ReceivingCountry"
                                              value={values?.ReceivingCountry}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("State")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              disabled={true}
                                              className="form-control mt-4"
                                              name="ReceivingState"
                                              id="ReceivingState"
                                              value={values?.ReceivingState}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("City")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ReceivingCity"
                                              id="ReceivingCity"
                                              value={values?.ReceivingCity}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Zip")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ReceivingZipCode"
                                              id="ReceivingZipCode"
                                              value={values?.ReceivingZipCode}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl col-lg col-md border-right">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Name")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ReceivingContactName"
                                            id="ReceivingContactName"
                                            label="Contact"
                                            value={values?.ReceivingContactName}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Email")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="email"
                                            className="form-control mt-4"
                                            name="ReceivingContactEmail"
                                            id="ReceivingContactEmail"
                                            disabled={true}
                                            label="Email"
                                            value={
                                              values?.ReceivingContactEmail
                                            }
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 1
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ReceivingContactPhone1"
                                              id="ReceivingContactPhone1"
                                              value={formatPhoneNumber(
                                                values?.ReceivingContactPhone
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5  col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 2
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ReceivingContactPhone2"
                                              id="ReceivingContactPhone2"
                                              value={formatPhoneNumber(
                                                values?.ReceivingContactPhone2
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Fax")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="tel"
                                            className="form-control mt-4"
                                            name="ReceivingContactFax"
                                            id="ReceivingContactFax"
                                            label="Fax"
                                            disabled={true}
                                            value={formatPhoneNumber(
                                              values?.ReceivingContactFax
                                            )}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                </Row>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Note")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <textarea
                                        className="form-control"
                                        maxlength="400"
                                        rows="3"
                                        value={values.ReceivingNote}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ReceivingNote",
                                            e.target.value
                                          );
                                        }}
                                      ></textarea>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-12 border-right">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("MABD")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="Mabd"
                                        id="Mabd"
                                        label="Mabd"
                                        disabled={!values.ReqShipDate}
                                        value={String(
                                          moment(values.Mabd).format(
                                            "YYYY-MM-DD"
                                          )
                                        )}
                                        min={String(
                                          moment(values.ReqShipDate).format(
                                            "YYYY-MM-DD"
                                          )
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "Mabd",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("DEL")} #
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control mt-4"
                                        name="DestinationNumber"
                                        id="DestinationNumber"
                                        value={values.DestinationNumber}
                                        disabled={!values.PickupNumber}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "DestinationNumber",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Special_Req")}
                                    </a>
                                    <span className="font-weight-bold">
                                      <Select
                                        isMulti
                                        className="mt-4"
                                        name="ReceivingSpecialReq"
                                        id="ReceivingSpecialReq"
                                        value={ViewModelOptions?.SpecialReq?.filter(
                                          function (el) {
                                            return (
                                              values?.ReceivingSpecialReq?.indexOf(
                                                el.Name
                                              ) >= 0
                                            );
                                          }
                                        )?.map((a) => {
                                          return {
                                            value: a.Name,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.SpecialReq?.map(
                                          (a) => {
                                            return {
                                              value: a.Name,
                                              label: a.Name,
                                            };
                                          }
                                        )}
                                        onChange={(newValue) => {
                                          if (newValue !== null) {
                                            setFieldValue(
                                              "ReceivingSpecialReq",
                                              newValue.map((a) => a.value)
                                            );
                                          } else {
                                            setFieldValue(
                                              "ReceivingSpecialReq",
                                              []
                                            );
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Del_Appointment_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="datetime-local"
                                        className="form-control mt-4"
                                        name="DelAppointmentDate"
                                        id="DelAppointmentDate"
                                        label="Ship"
                                        disabled={!values.PuAppointmentDate}
                                        min={String(
                                          moment(
                                            ConvertToDateCurrentTimeZone(
                                              values.PuAppointmentDate
                                            )
                                          ).format("YYYY-MM-DD[T]HH:mm")
                                        )}
                                        value={moment(
                                          ConvertToDateCurrentTimeZone(
                                            values.DelAppointmentDate
                                          )
                                        ).format("YYYY-MM-DD[T]HH:mm")}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "DelAppointmentDate",
                                            ConvertToDateGMT(e.target.value)
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Del_Appointment_To_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="datetime-local"
                                        className="form-control mt-4"
                                        name="DelAppointmentToDate"
                                        id="DelAppointmentToDate"
                                        label="Ship"
                                        disabled={
                                          !values.PuAppointmentToDate
                                        }
                                        min={String(
                                          moment(
                                            ConvertToDateCurrentTimeZone(
                                              values.PuAppointmentToDate
                                            )
                                          ).format("YYYY-MM-DD")
                                        )}
                                        value={moment(
                                          ConvertToDateCurrentTimeZone(
                                            values.DelAppointmentToDate
                                          )
                                        ).format("YYYY-MM-DD[T]HH:mm")}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "DelAppointmentToDate",
                                            ConvertToDateGMT(e.target.value)
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card id="Accessorials">
                          <CardHeader title="Accessorials">
                            <CardHeaderToolbar>
                              <button
                                type="button"
                                className="btn btn-success mr-4"
                                onClick={() => {
                                  var array = [
                                    ...values.Accessorials,
                                    {
                                      Name: "",
                                      IsPercentage: false,
                                      Price: 0,
                                      Quantity: 0,
                                      Total: 0,
                                    },
                                  ];
                                  setFieldValue(`Accessorials`, array);
                                }}
                              >
                                <i className="fas fa-plus" />
                              </button>
                            </CardHeaderToolbar>
                          </CardHeader>
                          <CardBody>
                            {values?.Accessorials?.map((items, indexs) => {
                              return (
                                <div className="row mb-5" key={indexs}>
                                  <div className="col-md-3">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <p className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3">
                                        {t("Name")}
                                      </p>
                                      <span className="font-weight-bold">
                                        <CreatableSelect
                                          className="mt-4"
                                          isClearable
                                          isOptionDisabled={(option) =>
                                            option.disabled === "yes"
                                          }
                                          name={`Accessorials.Name[${indexs}]`}
                                          value={ViewModelOptions?.Accessorials?.filter(
                                            (a) =>
                                              a.Name ===
                                              values?.Accessorials[indexs].Name
                                          ).map((a) => {
                                            return {
                                              value: a.Name,
                                              label: a.Name,
                                            };
                                          })}
                                          options={ViewModelOptions?.Accessorials?.map(
                                            (a) => {
                                              if (
                                                values.Accessorials.filter(
                                                  (x) => x.Name === a.Name
                                                ).length > 0
                                              ) {
                                                return {
                                                  value: a.Name,
                                                  label: a.Name,
                                                  disabled: "yes",
                                                };
                                              } else {
                                                return {
                                                  value: a.Name,
                                                  label: a.Name,
                                                  disabled: "no",
                                                };
                                              }
                                            }
                                          )}
                                          id={`Accessorials.Name[${indexs}]`}
                                          onChange={(e) => {
                                            if (e !== null) {
                                              if (e?.__isNew__) {
                                                let obj = {
                                                  ...ViewModelOptions,
                                                };
                                                obj.Accessorials.push({
                                                  Id: e.label,
                                                  Name: e.label,
                                                });
                                                setViewModelOptions(obj);
                                              }
                                              setFieldValue(
                                                `Accessorials[${indexs}].Name`,
                                                e.value
                                              );
                                            } else {
                                              setFieldValue(
                                                `Accessorials[${indexs}].Name`,
                                                null
                                              );
                                            }
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-1 mt-10 pt-3">
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={items.IsPercentage}
                                        id={"IsPercentage" + indexs}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `Accessorials[${indexs}].IsPercentage`,
                                            e.target.checked
                                          );
                                          setFieldValue(
                                            `Accessorials[${indexs}].Total`,
                                            e.target.checked
                                              ? (items.Price *
                                                values.BasicPrice) /
                                              100
                                              : items.Price * items.Quantity
                                          );
                                        }}
                                        name={"IsPercentage" + indexs}
                                        className="mr-2"
                                      />
                                      <span className="mb-auto"></span>
                                      <div className="d-flex flex-column flex-grow-1">
                                        <label
                                          className="form-check-label"
                                          for={"IsPercentage" + indexs}
                                        >
                                          %
                                        </label>
                                      </div>
                                    </label>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <p className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3">
                                        {items.IsPercentage
                                          ? "Percentage"
                                          : t("Price")}
                                      </p>
                                      <span className="font-weight-bold">
                                        <input
                                          type="number"
                                          className="form-control mt-4"
                                          name={`Accessorials.Price[${indexs}]`}
                                          id={`Accessorials.Price[${indexs}]`}
                                          value={items.Price}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `Accessorials[${indexs}].Price`,
                                              e.target.value
                                            );
                                            setFieldValue(
                                              `Accessorials[${indexs}].Total`,
                                              items.IsPercentage
                                                ? (items.Price *
                                                  values.BasicPrice) /
                                                100
                                                : items.Price * items.Quantity
                                            );
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  {!items.IsPercentage && (
                                    <div className="col-md-3">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <p className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3">
                                          {t("Quantity")}
                                        </p>
                                        <span className="font-weight-bold">
                                          <input
                                            type="number"
                                            className="form-control mt-4"
                                            name={`Accessorials.Quantity[${indexs}]`}
                                            id={`Accessorials.Quantity[${indexs}]`}
                                            value={items.Quantity}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `Accessorials[${indexs}].Quantity`,
                                                e.target.value
                                              );
                                              setFieldValue(
                                                `Accessorials[${indexs}].Total`,
                                                items.IsPercentage
                                                  ? (items.Price *
                                                    values.BasicPrice) /
                                                  100
                                                  : items.Price * items.Quantity
                                              );
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-md-1 mt-10 pt-3">
                                    <strong>
                                      $
                                      {items.IsPercentage
                                        ? currencyFormat(
                                          (items.Price * values.BasicPrice) /
                                          100
                                        )
                                        : currencyFormat(
                                          items.Price * items.Quantity
                                        )}
                                    </strong>
                                  </div>
                                  <div className="col-md-1">
                                    <button
                                      type="button"
                                      className="btn btn-danger mt-10"
                                      onClick={() => {
                                        var _arrayobj = JSON.parse(
                                          JSON.stringify(values.Accessorials)
                                        );
                                        _arrayobj.splice(indexs, 1);
                                        setFieldValue(
                                          `Accessorials`,
                                          _arrayobj
                                        );
                                      }}
                                    >
                                      <i className="fas fa-trash" />
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </CardBody>
                          <CardFooter>
                            <h4 className="mr-2" style={{ float: "right" }}>
                              {t("Total")}: $
                              {currencyFormat(
                                values?.Accessorials?.reduce(
                                  (a, b) => a + (b["Total"] || 0),
                                  0
                                )
                              )}
                            </h4>
                          </CardFooter>
                        </Card>
                        {product.id !== undefined && (
                          <>
                            <LoadItems
                              values={values}
                              setFieldValue={setFieldValue}
                              id={product.id}
                              product={product}
                              ViewModelOptions={ViewModelOptions}
                              getShipmentData={getShipmentData}
                              LoadStopsList={LoadStopsList}
                              IsDetailsMode={false}
                            />
                            <AttachementComp
                              id={product.id}
                              IsDetailsMode={false}
                            />
                            <NotesComp id={product.id} IsDetailsMode={false} />
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>

              <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="layout-tooltip"> {t("Back")}</Tooltip>}
                >
                  <li className="nav-item mb-2" data-placement="left">
                    <button
                      onClick={backToProductsList}
                      type="button"
                      className="btn btn-sm btn-icon btn-bg-light"
                    >
                      <i className="fa fa-arrow-left"></i>
                    </button>
                  </li>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="documentations-tooltip">{t("Save")}</Tooltip>
                  }
                >
                  <li className="nav-item mb-2" data-placement="left">
                    <button
                      type="submit"
                      className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
                      onClick={() => {
                        if (product?.IsDraft === true) {
                          setFieldValue("AsDraft", true);
                        } else {
                          setFieldValue("AsDraft", false);
                        }
                        handleSubmit();
                      }}
                    >
                      {isLoadingAuth ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <i className="fas fa-save text-primary"></i>
                      )}
                    </button>
                  </li>
                </OverlayTrigger>
                {product.id !== undefined && (
                  <>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="documentations-tooltip">
                          {t("Activities")}
                        </Tooltip>
                      }
                    >
                      <li className="nav-item mb-2" data-placement="left">
                        <button
                          className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
                          onClick={() => {
                            setshowModalActivities(true);
                          }}
                        >
                          <i className="fas fa-history text-warning"></i>
                        </button>
                      </li>
                    </OverlayTrigger>
                  </>
                )}
              </ul>
              <Modal
                size="xl"
                show={showModalAddresses}
                onHide={() => {
                  setshowModalAddresses(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t("Add")}{" "}
                    {initAddressObject?.IsShipper === true
                      ? t("Shipping")
                      : t("Receiving")}{" "}
                    {t("Address")}:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="pb-5 col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          {t("Name")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Name}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Name = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Name"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("Address")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Address1}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Address1 = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Address1"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Address")} 2</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Address2}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Address2 = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Address2"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("Country")}
                          <span className="text-danger">*</span>
                        </label>

                        <Select
                          required
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Country = e?.value;
                            setinitAddressObject(obj);
                          }}
                          name="Country"
                          isSearchable={true}
                          isClearable
                          placeholder={"Select Country"}
                          options={ContriesViewModel.map((a) => {
                            return { label: a.Name, value: a.Name };
                          })}
                          value={ContriesViewModel.filter(
                            (a) => a.Name === initAddressObject?.Country
                          ).map((a) => {
                            return { label: a.Name, value: a.Name };
                          })}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("State")}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          required
                          isSearchable={true}
                          isClearable
                          placeholder={"Select State"}
                          options={ContriesViewModel?.find(
                            (a) => a.Name === initAddressObject?.Country
                          )?.States?.map((a) => {
                            return { label: a.Name, value: a.Name };
                          })}
                          value={ContriesViewModel?.find(
                            (a) => a.Name === initAddressObject?.Country
                          )
                            ?.States?.filter(
                              (a) => a.Name === initAddressObject?.State
                            )
                            ?.map((a) => {
                              return { label: a.Name, value: a.Name };
                            })}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.State = e?.value;
                            setinitAddressObject(obj);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("City")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.City}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.City = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="City"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("Zip_code")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Zipcode}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Zipcode = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Zipcode"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_First_Name")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactFirstName}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactFirstName = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactFirstName"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Last_Name")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactLastName}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactLastName = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactLastName"
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Email")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactEmail}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactEmail = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactEmail"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Phone")}</label>
                        <PhoneInput
                          country={"us"}
                          value={initAddressObject.ContactPhone}
                          onChange={(phone) => {
                            let obj = { ...initAddressObject };
                            obj.ContactPhone = phone;
                            setinitAddressObject(obj);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Phone")} 2</label>
                        <PhoneInput
                          country={"us"}
                          value={initAddressObject.ContactPhone2}
                          onChange={(phone) => {
                            let obj = { ...initAddressObject };
                            obj.ContactPhone2 = phone;
                            setinitAddressObject(obj);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Fax")}</label>
                        <PhoneInput
                          country={"us"}
                          value={initAddressObject.ContactFax}
                          onChange={(phone) => {
                            let obj = { ...initAddressObject };
                            obj.ContactFax = phone;
                            setinitAddressObject(obj);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setshowModalAddresses(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={isLoadingAuth}
                    onClick={async () => {
                      var country = initAddressObject.Country;
                      switch (initAddressObject.Country) {
                        case "United States":
                          country = "US";
                          break;
                        case "Canada":
                          country = "CA";
                          break;
                        case "Mexico":
                          country = "MX";
                          break;
                        default:
                          break;
                      }
                      var creatLoad = () => {
                        var myHeaders = new Headers();
                        myHeaders.append(
                          "Authorization",
                          `Bearer ${db.read().getState()?.Token}`
                        );

                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify(initAddressObject);

                        setisLoadingAuth(true);

                        return fetch(`${window.$apiurl}/api/Address`, {
                          method: "post",
                          headers: myHeaders,
                          body: raw,
                        })
                          .then(async (response) => {
                            const statusCode = await response.status;
                            const contentType = response.headers.get(
                              "content-type"
                            );
                            if (
                              contentType &&
                              contentType.indexOf("application/json") !== -1
                            ) {
                              return response
                                .json()
                                .then((data) => [statusCode, data]);
                            } else {
                              return response
                                .text()
                                .then((text) => [statusCode, { Message: "" }]);
                            }
                          })
                          .then((result) => {
                            if (result[0] === 200) {
                              getViewModel();
                              if (initAddressObject.IsShipper) {
                                setFieldValue(
                                  "PickupId",
                                  result[1]?.Address?.Id
                                );
                                setFieldValue(
                                  "PickupName",
                                  result[1]?.Address?.Name
                                );
                                setFieldValue(
                                  "ShippingAddress1",
                                  initAddressObject.Address1
                                );
                                setFieldValue(
                                  "ShippingAddress2",
                                  initAddressObject.Address2
                                );
                                setFieldValue(
                                  "ShippingCity",
                                  initAddressObject.City
                                );
                                setFieldValue(
                                  "ShippingCountry",
                                  initAddressObject.Country
                                );
                                setFieldValue(
                                  "ShippingState",
                                  initAddressObject.State
                                );
                                setFieldValue(
                                  "ShippingZipCode",
                                  initAddressObject.Zipcode
                                );
                                setFieldValue(
                                  "ShippingContactName",
                                  initAddressObject.ContactFirstName +
                                  " " +
                                  initAddressObject.ContactLastName
                                );
                                setFieldValue(
                                  "ShippingContactPhone",
                                  initAddressObject.ContactPhone
                                );
                                setFieldValue(
                                  "ShippingContactPhone2",
                                  initAddressObject.ContactPhone2
                                );
                                setFieldValue(
                                  "ShippingContactFax",
                                  initAddressObject.ContactFax
                                );
                                setFieldValue(
                                  "ShippingContactEmail",
                                  initAddressObject.ContactEmail
                                );
                              } else {
                                setFieldValue(
                                  "DestinationId",
                                  result[1]?.Address?.Id
                                );
                                setFieldValue(
                                  "DestinationName",
                                  result[1]?.Address?.Name
                                );
                                setFieldValue(
                                  "ReceivingAddress1",
                                  initAddressObject.Address1
                                );
                                setFieldValue(
                                  "ReceivingAddress2",
                                  initAddressObject.Address2
                                );
                                setFieldValue(
                                  "ReceivingCountry",
                                  initAddressObject.Country
                                );
                                setFieldValue(
                                  "ReceivingCity",
                                  initAddressObject.City
                                );
                                setFieldValue(
                                  "ReceivingState",
                                  initAddressObject.State
                                );
                                setFieldValue(
                                  "ReceivingZipCode",
                                  initAddressObject.Zipcode
                                );
                                setFieldValue(
                                  "ReceivingContactName",
                                  initAddressObject.ContactFirstName +
                                  " " +
                                  initAddressObject.ContactLastName
                                );
                                setFieldValue(
                                  "ReceivingContactPhone",
                                  initAddressObject.ContactPhone
                                );
                                setFieldValue(
                                  "ReceivingContactPhone2",
                                  initAddressObject.ContactPhone2
                                );
                                setFieldValue(
                                  "ReceivingContactFax",
                                  initAddressObject.ContactFax
                                );
                                setFieldValue(
                                  "ReceivingContactEmail",
                                  initAddressObject.ContactEmail
                                );
                              }
                              setshowModalAddresses(false);
                            } else {
                              if (
                                result[1].ModelState !== null &&
                                result[1].ModelState !== undefined &&
                                result[1].ModelState !== ""
                              ) {
                                let modelState = result[1].ModelState;
                                if (modelState)
                                  Object.keys(modelState).forEach(function (k) {
                                    modelState[k].forEach((element) => {
                                      Swal.fire(t("Error"), translateAuto(element), "error");
                                    });
                                  });
                              } else if (
                                result[1].Message !== null &&
                                result[1].Message !== undefined &&
                                result[1].Message !== ""
                              ) {
                                Swal.fire(t("Error"), translateAuto(result[1].Message));
                              } else if (
                                result[1].error_description !== null &&
                                result[1].error_description !== undefined &&
                                result[1].error_description !== ""
                              ) {
                                Swal.fire(
                                  t("Error"),
                                  translateAuto(result[1].error_description)
                                );
                              } else {
                                Swal.fire(t("Error"), t("Bad_Request"));
                              }
                            }
                            setisLoadingAuth(false);
                          })
                          .catch((error) => {
                            Swal.fire(t("Error"), error);
                            setisLoadingAuth(false);
                          });
                      };
                      validateZipCode(
                        country,
                        initAddressObject.Zipcode,
                        initAddressObject.City,
                        initAddressObject.State,
                        initAddressObject.Address1,
                        initAddressObject.Name,
                        creatLoad
                      );
                    }}
                  >
                    {t("Confirm")}
                    {isLoadingAuth && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="xl"
                show={showModalActivities}
                onHide={() => {
                  setshowModalActivities(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t("Activities")} : {product?.Code}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ActivityFunction Activitys={ActivitiesList} />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setshowModalActivities(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="xl"
                show={showBidsList}
                onHide={() => {
                  setshowBidsList(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t("Bids_for")} : {product?.Code}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center"
                    bootstrap4
                    bordered={false}
                    keyField="Id"
                    pagination={paginationFactory({
                      showTotal: true,
                      sizePerPage: 10,
                      sizePerPageList: [
                        { text: "5", value: 5 },
                        { text: "10", value: 10 },
                        { text: "50", value: 50 },
                      ],
                    })}
                    data={BidsListOptions || []}
                    noDataIndication={
                      LoadingBids === true ? (
                        <h4 style={{ textAlign: "center" }}>{"Loading..."}.</h4>
                      ) : (
                        <h4 style={{ textAlign: "center" }}>
                          {t("no_data_available")}.
                        </h4>
                      )
                    }
                    columns={columns}
                  ></BootstrapTable>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setshowBidsList(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="md"
                show={showAssignToTrucker}
                onHide={() => {
                  setshowAssignToTrucker(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t("Trucker_Assignement")} : {product?.Code}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label>{t("Truckers")}</label>
                  <Select
                    name="Truckers"
                    id="Truckers"
                    label="Truckers"
                    options={TruckerList?.map((a) => {
                      return {
                        label:
                          (a.Name === null ? "" : a.Name + " - ") + a.Owner,
                        value: a.Id,
                      };
                    })}
                    onChange={(e) => {
                      setTruckerSelected(e?.value);
                    }}
                    inputId="react-select-single"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setshowAssignToTrucker(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={isLoadingAuth}
                    onClick={() => {
                      if (
                        TruckerSelected === null ||
                        TruckerSelected === "" ||
                        TruckerSelected === undefined
                      ) {
                        Swal.fire(t("Error"), t("Please_select_trucker"));
                        return;
                      }
                      setisLoadingAuth(true);

                      var myHeaders = new Headers();
                      myHeaders.append(
                        "Authorization",
                        `Bearer ${db.read().getState()?.Token}`
                      );

                      myHeaders.append("Content-Type", "application/json");

                      var raw = JSON.stringify({
                        ShipmentId: product.id,
                        Assign: true,
                        TruckerId: TruckerSelected,
                      });

                      return fetch(
                        `${window.$apiurl}/api/Shipments/Assignment`,
                        {
                          method: "post",
                          headers: myHeaders,
                          body: raw,
                        }
                      )
                        .then(async (response) => {
                          const statusCode = await response.status;
                          const contentType = response.headers.get(
                            "content-type"
                          );
                          if (
                            contentType &&
                            contentType.indexOf("application/json") !== -1
                          ) {
                            return response
                              .json()
                              .then((data) => [statusCode, data]);
                          } else {
                            return response
                              .text()
                              .then((text) => [statusCode, { Message: "" }]);
                          }
                        })
                        .then((result) => {
                          if (result[0] === 200) {
                            setshowAssignToTrucker(false);
                            Swal.fire(
                              t("Great"),
                              t("Assigned_successfully"),
                              "success"
                            );
                            getShipmentData(product.id);
                          } else {
                            if (
                              result[1].ModelState !== null &&
                              result[1].ModelState !== undefined &&
                              result[1].ModelState !== ""
                            ) {
                              let modelState = result[1].ModelState;
                              if (modelState)
                                Object.keys(modelState).forEach(function (k) {
                                  modelState[k].forEach((element) => {
                                    Swal.fire("Error", translateAuto(element), "error");
                                  });
                                });
                            } else if (
                              result[1].Message !== null &&
                              result[1].Message !== undefined &&
                              result[1].Message !== ""
                            ) {
                              Swal.fire("Error", translateAuto(result[1].Message));
                            } else if (
                              result[1].error_description !== null &&
                              result[1].error_description !== undefined &&
                              result[1].error_description !== ""
                            ) {
                              Swal.fire("Error", translateAuto(result[1].error_description));
                            } else {
                              Swal.fire("Error", t("Bad_Request"));
                            }
                          }
                          setisLoadingAuth(false);
                        })
                        .catch((error) => {
                          Swal.fire("Error", error);
                          setisLoadingAuth(false);
                        });
                    }}
                  >
                    {t("Confirm")}
                    {isLoadingAuth && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="md"
                show={showInvitToTrucker}
                onHide={() => {
                  setshowInvitToTrucker(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t("Trucker_Invite")} : {product?.Code}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label>{t("Trucker_Email")}</label>
                  <input
                    name="Truckers"
                    type="email"
                    className="form-control  form-control-lg"
                    onChange={(e) => {
                      let val = e.target.value;
                      setTruckerSelected(val);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setshowInvitToTrucker(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={isLoadingAuth}
                    onClick={() => {
                      let val = TruckerSelected;

                      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                      if (!val.match(validRegex)) {
                        Swal.fire(t("Error"), t("Please_fill_valid_email"));
                        return false;
                      }
                      setisLoadingAuth(true);

                      var myHeaders = new Headers();
                      myHeaders.append(
                        "Authorization",
                        `Bearer ${db.read().getState()?.Token}`
                      );

                      myHeaders.append("Content-Type", "application/json");

                      var raw = JSON.stringify({
                        ShipmentId: product.id,
                        Email: TruckerSelected,
                      });

                      return fetch(`${window.$apiurl}/api/Shipments/Invite`, {
                        method: "post",
                        headers: myHeaders,
                        body: raw,
                      })
                        .then(async (response) => {
                          const statusCode = await response.status;
                          const contentType = response.headers.get(
                            "content-type"
                          );
                          if (
                            contentType &&
                            contentType.indexOf("application/json") !== -1
                          ) {
                            return response
                              .json()
                              .then((data) => [statusCode, data]);
                          } else {
                            return response
                              .text()
                              .then((text) => [statusCode, { Message: "" }]);
                          }
                        })
                        .then((result) => {
                          if (result[0] === 200) {
                            setshowInvitToTrucker(false);
                            Swal.fire(
                              t("Great"),
                              t("Trucker_Invitation_success"),
                              "success"
                            );
                            getShipmentData(product.id);
                          } else {
                            if (
                              result[1].ModelState !== null &&
                              result[1].ModelState !== undefined &&
                              result[1].ModelState !== ""
                            ) {
                              let modelState = result[1].ModelState;
                              if (modelState)
                                Object.keys(modelState).forEach(function (k) {
                                  modelState[k].forEach((element) => {
                                    Swal.fire(t("Error"), translateAuto(element), "error");
                                  });
                                });
                            } else if (
                              result[1].Message !== null &&
                              result[1].Message !== undefined &&
                              result[1].Message !== ""
                            ) {
                              Swal.fire(t("Error"), translateAuto(result[1].Message));
                            } else if (
                              result[1].error_description !== null &&
                              result[1].error_description !== undefined &&
                              result[1].error_description !== ""
                            ) {
                              Swal.fire(
                                t("Error"),
                                translateAuto(result[1].error_description)
                              );
                            } else {
                              Swal.fire(t("Error"), t("Bad_Request"));
                            }
                          }
                          setisLoadingAuth(false);
                        })
                        .catch((error) => {
                          Swal.fire(t("Error"), error);
                          setisLoadingAuth(false);
                        });
                    }}
                  >
                    {t("Confirm")}
                    {isLoadingAuth && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          );
        }}
      </Formik>
      <DynamicReport
        setShowModal={setBolMulti}
        showModal={BolMulti}
        id={product.id}
        title={`BOL Multi Shipment ID#: ${product.Code}`}
        reportContent={<BolForm id={product.id} />}
      />
    </>
  );
}

export function TimeDurationInput({
  value,
  scale,
  onChange,
  className,
  values,
  setFieldValue,
}) {
  const [duration, setDuration] = useState(convertFromValue(value, scale));

  useEffect(() => {
    const newDuration = convertFromValue(value, scale);
    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      if (newDuration !== duration) {
        setDuration(newDuration);
      }
    }, 500);
  }, [value, scale]);

  const onInputChange = useCallback(
    ({ target }) => {
      setDuration(target.value);
      const newValue = convertToValue(target.value, scale);
      if (!isNaN(newValue)) {
        onChange(newValue);
        setFieldValue("RestTime", newValue * (60 * 60));
      }
    },
    [onChange, scale]
  );

  return (
    <input
      type="text"
      className={className}
      placeholder="0h"
      value={duration}
      onChange={onInputChange}
      data-testid="duration-input"
    />
  );
}

export function convertValueToDuration(value) {
  const minutes = Math.floor((value * 60) % 60);
  const hours = Math.floor(value % 24);
  const days = Math.floor(value / 24);
  return [days && `${days}d`, hours && `${hours}h`, minutes && `${minutes}m`]
    .filter((x) => !!x)
    .join(" ");
}

export const SCALE_CONVERSIONS = {
  m: 60000,
  h: 3600000,
  d: 86400000,
};

export function convertDurationToValue(duration) {
  const matches = duration.trim().match(/^(\d+d)?\s*(\d+h)?\s*(\d+m)?$/i);
  if (!matches) return parseFloat(duration);
  const [days, hours, minutes, seconds, milliseconds] = matches
    .slice(1)
    .map((x) => parseInt(x) || 0);

  return (
    (((days * 24 + hours) * 60 + minutes) * 60 + seconds) * 1000 + milliseconds
  );
}
export function convertValueFromScale(value, scale) {
  return value * (SCALE_CONVERSIONS[scale] || 1);
}

export function convertValueToScale(value, scale) {
  return value / (SCALE_CONVERSIONS[scale] || 1);
}
export const convertFromValue = (value, scale) =>
  convertValueToDuration(convertValueFromScale(value, scale));

export const convertToValue = (duration, scale) => {
  return convertValueToScale(convertDurationToValue(duration), scale);
};
