import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
function PrivacyPolicyPage(props) {
    return (
        <div className="login login-3 login-signin-on d-flex flex-row-fluid" style={{ display: "block" }}>
            <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid" style={{ backgroundImage: 'url(/media/bg/bg-3.jpg)' }}>
                <div className="col-xl-8 col-lg-10 col-md-12 col-12  p-7 position-relative overflow-hidden">
                    <div className="d-flex flex-center mb-15">
                        <Link to="/auth/login">
                            <SVG src={toAbsoluteUrl("/media/svg/logos/TaapLogo.svg")} />
                        </Link>
                    </div>
                    <div className="col-12 text-center mb-6">
                        <h1 className="font-weight-bolder tet-dark-75">Privacy Policy</h1>
                    </div>
                    <div className="card border-0 shadow-none">
                        <div className="card-body">
                            <div className="elementor-widget-container">
                                <p><strong>Last Updated: 2021-07-12</strong></p>
                                <p>If you are a California resident, please <a href="https://oag.ca.gov/privacy/ccpa" target="_blank" rel="noopener noreferrer">click here</a> for more information about your specific privacy rights.</p>
                                <p>Your privacy is important to us. Please read this Privacy Notice carefully to learn how we collect, use, share, and otherwise process information relating to individuals (“Personal Data”), and to learn about your rights and choices regarding your Personal Data.</p>
                                <p>TaaP Solutions is the controller of your Personal Data as described in this Privacy Notice, unless specified otherwise. This Privacy Notice applies to the collection and processing of Personal Data collected by us when you visit our branded websites that link to this Privacy Notice; use our online products and services as an authorized user where we act as a controller of your Personal Data; visit our branded social media pages; visit our facilities; communicate with us (including emails, phone calls, texts or fax); or register for, attend or take part in our events, webinars, trade shows or contests.</p>
                                <p>This Privacy Notice does not apply when we process Personal Data on behalf of our customers in the role of processor or other service provider, such as when we allow customers to create their own websites/applications to offer their own products and services, to send electronic communications to others; or otherwise use, collect, share or process Personal Data via our online products and services.&nbsp; Our customers’ privacy policies may be different from ours, and we are not responsible for those practices. For information about the privacy practices of our customers who use our products and services as a controller, please contact that customer directly.</p>
                                <p><strong>1. Collection of Personal Data.</strong> We collect three types of Personal Data from you:</p>
                                <ul>
                                    <li><em>Information You Provide:</em> We collect and record any information that you provide to us directly, including any personal identifiers, professional or employment-related information, financial account information, commercial information, visual information, or internet activity information that you provide to us through this website or other medium.</li>
                                    <li><em>Information We Collect Automatically:</em> We automatically collect and store information about your use of this website and our services. To do so, we may use cookie technology and other online identifiers to track your IP address, web browser, geolocation, or your activity on this site.</li>
                                    <li><em>Other Information We Collect:</em> We may combine data from other source with Personal Data we collect from you. These other sources may be from third parties or from publicly available sources.&nbsp; This may include information related to your employment, education, commercial interactions, and internet activity.</li>
                                </ul>
                                <p>In some cases, the collection and processing (see Section 2 below) of Personal Data is required for you to receive certain products or services. Personal Data does not include information that is anonymized or aggregated such that you cannot be identified from it.</p>
                                <p>If you provide us or our service providers with any Personal Data relating to other individuals, you represent that you have the authority to do so and have obtained any necessary consent for the information to be used in accordance with this Privacy Notice. If you believe that your Personal Data has been provided to us improperly or want to exercise your rights relating to your Personal Data, please contact us by using the information in the Contact Us section (Section 16) below.</p>
                                <p><strong>2. Processing and Use of Personal Data.</strong> We process your Personal Data for the purposes listed below. Where required by law, we obtain your consent to use and process your Personal Data for these purposes. Otherwise, we rely on another authorized legal basis (including but not limited to the (a) performance of a contract or (b) legitimate interest to collect and process your Personal Data.</p>
                                <ul>
                                    <li><em>Providing our services and website access:</em> We process your Personal Data to ensure both you and we meet our obligations under the applicable contract, terms of use, or service agreement, to project demand, and to improve our offerings; absent a contract, we process your Personal Data to further our legitimate interest in operating and improving our websites and services.</li>
                                    <li><em>Securing our websites and services:</em> We process your Personal Data as part of our efforts to maintain, monitor and secure our website and services. This may include aggregating data, verifying accounts, investigating suspicious activity, and enforcing our terms and policies to the extent necessary to further our legitimate interest in maintaining a safe and secure website, products and services and in protecting our rights and the rights of others.</li>
                                    <li><em>User Account Management:</em> If you have registered for an account with us, we process your Personal Data to manage your user account for the purpose of meeting our obligations to you according to applicable contract or terms of service.</li>
                                    <li><em>Responding to contact requests:</em> If you contact us electronically or by phone, we process your Personal Data to perform our contract with you or to the extent it is necessary for our legitimate interest in responding to your inquiry and communicating with you. We may record and process communications for training, quality assurance, and administration purposes. If required under applicable law, we will obtain your prior consent or give you the option to object to a call being recorded.</li>
                                    <li><em>Managing payments:</em> We may process your financial information and other Personal Data to verify that information and to collect payments to the extent that doing so is required to perform our contract with you.</li>
                                    <li><em>Tracking office visitors:</em> If you visit our facilities, we may process your Personal Data for security, health, or safety reasons (including any NDAs our visitors may be required to sign) to the extent such processing is necessary to further our legitimate interest in protecting our offices and our confidential information against unauthorized access.</li>
                                    <li><em>Marketing and Advertising:</em> We may process your Personal Data to advertise to you, conduct market research, and to provide other personalized content based upon your Personal Data to the extent it is necessary for our legitimate interest in advertising our websites, services or products. Where legally required, we will obtain your consent before engaging in any marketing or advertising.</li>
                                    <li><em>Complying with legal and safety obligations:</em> We process your Personal Data when cooperating with public and government authorities, protecting our legal rights, conducting audits, and protecting against abuse of our services and products.</li>
                                </ul>
                                <p><strong>3. Protecting Personal Data.</strong> We take commercially reasonable precautions to protect the Personal Data in our possession from loss, misuse, unauthorized access, disclosure, alteration, or destruction. While we follow generally accepted standards to protect Personal Data, no method of storage or transmission is 100% secure or error-free. Personal Data you send to or from the website or via e-mail may not be secure, and we encourage you to contact us about more secure ways to share sensitive information when necessary. Where you use passwords, ID numbers, or other special access features on this site, it is your responsibility to safeguard them and to log out of any accounts you access after your sessions.</p>
                                <p><strong>4. Your Individual Rights.</strong> You may have certain rights relating to your Personal Data based on applicable local data protection laws. Depending on the applicable laws these rights may include the right to:</p>
                                <ul>
                                    <li>Request and receive copies of your Personal Data that we hold;</li>
                                    <li>Request additional information about how we process your Personal Data;</li>
                                    <li>Correct inaccurate or incomplete Personal Data (taking into account our use of it);</li>
                                    <li>Request deletion of your Personal Data;</li>
                                    <li>Restrict or object to our processing of your Personal Data. Where we process Personal Data for direct marketing purposes (either by us or third parties), you may not have to provide a specific reason for such objection;</li>
                                    <li>Require us (if possible) to transfer your Personal Data to another controller (i.e., data portability);</li>
                                    <li>Restrict certain disclosures of your Personal Data to third parties;</li>
                                    <li>Not be subject to a decision based solely on automated processing, including profiling, which produces legal effects; and</li>
                                    <li>Withdraw your consent to the processing of your Personal Data (to the extent we base processing on consent and not on another lawful basis).</li>
                                </ul>
                                <p>We will not discriminate against you, in any manner prohibited by applicable law, for exercising these rights. You may exercise these rights, to the extent applicable, by sending us an email at support@empowerdl.com, writing to us at 10565 N 114th ST suite #116 Scottsdale, AZ 85259, making the request via our website at https://dev-taap-app.empowerdatalogistics.com/privacy-policy.&nbsp; We will respond to any such requests within 30 days of receipt.</p>
                                <p><strong>5. Personal Data Retention.</strong> Personal Data will be stored for as long as the information is required to fulfill our legitimate business needs or the purposes for which the Personal Data was collected, or for such period as is required by law.</p>
                                <p><strong>6. Data Transfers to Countries Outside the EU/EEA.</strong> Data entered on our website are transferred to a server in the U.S. and stored there. Your data may be used and disclosed by the Company and its divisions, holding companies, subsidiaries and affiliates, or other entities outside the European Union (EU) and the European Economic Area (EEA) including in the U.S. Please note that in countries outside the country in which you live a different standard of data protection might apply than the standard that you are used to in your own country.</p>
                                <p><strong>7. Disclosing Personal Data to Third Parties.</strong> We share Personal Data with third parties in a manner consistent with our Terms of Service with you.&nbsp; We may share your Personal Information with the categories of recipients described below:</p>
                                <ul>
                                    <li>TaaP Solutions’ divisions, holding companies, subsidiaries, and affiliates. Third party service providers or other entities that perform services on our behalf, help us provide you with our products and services, and that otherwise support our relationship with you (such as shipping or direct mailing organizations). These third-party service providers have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with this Privacy Notice and as permitted by applicable data protection laws.</li>
                                    <li>Law enforcement, government agencies, or other regulators to comply with law or legal requirements, to enforce our agreements, and to protect our rights and the property or safety of TaaP Solutions, our users, or third parties.</li>
                                    <li>Transactional parties if we, or some or all of our assets, acquire or are acquired by another entity, including through a sale or in connection with a bankruptcy.</li>
                                    <li>Your employer or coworkers if you receive our products or services in connection with your employment.</li>
                                </ul>
                                <p>We may also share Personal Information that has been deidentified or aggregated with third parties for any purpose.</p>
                                <p><strong>8. Promotional and Marketing Policy.</strong> We may ask you to consent to being contacted by us for promotional and marketing purposes. However, you may opt-out of receiving promotional or marketing emails at any time by notifying us as a reply to any unwanted e-mail, by using the unsubscribe function in our newsletter, contacting us at support@empowerdl.com, or by writing to us at TaaP Solutions, 10565 N 114th ST suite #116 Scottsdale, AZ 85259. Requests to unsubscribe from TaaP Solutions e-mails may take 5 business days to process.</p>
                                <p><strong>9. Cookies and Tracking Technologies</strong>. Please see our Cookies and Tracking Technologies Notification below.</p>
                                <p><strong>10. Third-party Websites.</strong> The website may contain links to other third-party sites. When you click on one of these links you are visiting a website operated by someone other than us and the operator of that website may have a different privacy policy. We are not responsible for their individual privacy practices, so we encourage you to investigate the privacy policies of such third-party operators.</p>
                                <p><strong>11. Children</strong>. Our website is not intended for children under 16 years of age. No one under age 16 may provide any information to us through this website. We do not knowingly collect Personal Data from children under 16. If you are under 16, do not access, use, or provide any information on the website or on or through any of its features. If we learn we have collected or received Personal Data from a child under 16 without parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us by sending us an email at support@empowerdl.com or writing to us TaaP Solutions, 10565 N 114th ST suite #116 Scottsdale, AZ 85259.</p>
                                <p><strong>12. Data Breaches.</strong> While every effort and measure is taken to reduce the risk of data breaches, we have dedicated controls and procedures in place for such situations, along with the procedures that are required to make notifications to the relevant Supervisory Authority and data subjects (where applicable).</p>
                                <p><strong>13. Changes to this Privacy Policy.</strong> We may update this Privacy Policy at our discretion to reflect changes we deem necessary or to satisfy legal requirements. We will post a prominent notice of material changes on our websites.</p>
                                <p><strong>14. Contact Us.</strong> We welcome comments and questions regarding this Privacy Policy. Any such questions should be directed via e-mail to support@empowerdl.com. Additionally, you may make your request in writing to TaaP Solutions, 10565 N 114th ST suite #116 Scottsdale, AZ 85259.</p>
                                <h3><strong>California Consumer Privacy Act Disclosures</strong></h3>
                                <p>This California Consumer Privacy Act disclosure page (“Disclosure”) supplements the Privacy Notice above and is effective as of 2021-07-27. The Privacy Notice describes the personal information that we collect, the sources from which we collect it, the purposes for which we use it, the limited circumstances under which we share personal information, and with whom we share it. These additional disclosures are required by the California Consumer Privacy Act:</p>
                                <p><strong>Categories of personal information collected.</strong> The personal information that TaaP Solutions collects, or has collected from consumers in the twelve months prior to the effective date of this Disclosure, fall into the following categories established by the California Consumer Privacy Act:</p>
                                <ul>
                                    <li>identifiers (e.g., name, address, phone number, IP address);</li>
                                    <li>protected classifications (e.g., age, gender);</li>
                                    <li>financial and commercial information (e.g., credit card numbers, purchase history);</li>
                                    <li>internet or other online activity information;</li>
                                    <li>geolocation data (e.g., computer/device location);</li>
                                    <li>audio or visual information;</li>
                                    <li>professional information (e.g., job title); and</li>
                                    <li>inferences drawn from any of the above.</li>
                                </ul>
                                <p><strong>Categories of personal information disclosed for a business purpose.</strong> In the 12 months prior to the effective date of this Disclosure, TaaP Solutions has disclosed to the third parties identified in the “Disclosing Personal Information to Third Parties” section of the Privacy Notice above personal information that falls into the following categories established by the California Consumer Privacy Act:</p>
                                <ul>
                                    <li>identifiers;</li>
                                    <li>protected classifications;</li>
                                    <li>financial and commercial information;</li>
                                    <li>internet or other online activity information;</li>
                                    <li>geolocation data;<br />audio or visual information; and</li>
                                    <li>professional/educational information.</li>
                                </ul>
                                <p><strong>Right to Access or Delete Personal Information:</strong> The California Consumer Protect Act may provide you with the right to request information about the personal information TaaP Solutions collects about you, to receive a copy of your personal information, or delete the personal information we hold. If you wish to do any of these things, please visit https://dev-taap-app.empowerdatalogistics.com/privacy-policy or contact Customer Service. Depending on your data choices, certain services may be limited or unavailable. You may exercise these rights by sending us an email at support@empowerdl.com, writing to us at TaaP Solutions, 10565 N 114th ST suite #116 Scottsdale, AZ 85259, making the request via our website at https://dev-taap-app.empowerdatalogistics.com/privacy-policy.&nbsp; We will respond to any such requests within 30 days of receipt.</p>
                                <p><strong>Sharing your Personal Information.</strong> We share Personal Data with third parties in a manner consistent with our Terms of Service with you.&nbsp; You may have the right to opt-out of the sale of this information. You may exercise these rights by sending us an email at support@empowerdl.com, writing to us at TaaP Solutions, 10565 N 114th ST suite #116 Scottsdale, AZ 85259, making the request via our website at https://dev-taap-app.empowerdatalogistics.com/privacy-policy.&nbsp; We will respond to any such requests within 30 days of receipt.</p>
                                <p><strong>No Discrimination.</strong> TaaP Solutions will not discriminate against any consumer for exercising their rights under the California Consumer Privacy Act.</p>
                                <p><strong>COOKIES AND TRACKING TECHNOLOGIES</strong></p>
                                <p>Cookies and other Tracking Technologies. A cookie is a small data file that a website places in your web browser to remember information about you. We use some cookies and tracking technologies for purposes which are necessary for your use of our website, products, or services. These may include technologies which remember your preferences and settings; information that you may enter online; or to keep you logged in to our offerings. With your consent, we may also use cookies and tracking technologies for other purposes.</p>
                                <p><em>Strictly-necessary cookies and technology.</em> These are cookies and other technologies that are required for the operation of our website, our products, and our services, and therefore cannot be turned off. You can set your browser or device to block or alert you about these cookies, but functionality may be impaired. These cookies do not store any personal data. These cookies are used solely for the purpose of carrying out or facilitating the transmission of a communication and are strictly necessary to provide you with the information service you have expressly requested. The use of these cookies for the purposes described above is based on our legitimate interest to ensure your access to and the proper technical functioning of our website, products, and services that you have expressly requested.</p>
                                <p><em>Analytical/Performance cookies.</em> Where you have permitted the use of performance cookies, we use tools such as Google Analytics or Segment to collect statistical information about how our website and services are used. Such analytics services involve the use of cookies that collect information such as your IP address or other identifiers, browser information, and information about the content you view and interact with to record how you use the website, our products, and services. These analytics services help us count users, identify popular offerings, and track the demographics and interests of our users.</p><p><em>Targeting cookies.</em> These cookies record your activity on our site or using our services including the pages you have visited and the links you have followed. We may use this information to make our content more relevant to you to measure the effectiveness of our marketing. We may also share this information with third parties for this purpose. These are also optional cookies, although if you do not allow these cookies, you will experience less targeted advertising.</p>
                                <p><strong>Your Choices: Managing Your Preferences</strong></p>
                                <p>Your Preferences</p>
                                <p>When you first visit our website you will be presented with a banner which offers you a choice about whether to accept or reject cookies or tracking technologies of different types, with the exception of those cookies which are strictly necessary for a particular service.</p>
                                <p>If you wish, you can also choose how web browser cookies are handled by your device via your browser settings. Some devices allow you to control this through your device settings. If you choose not to receive cookies at any time, websites may not function properly and certain services will not be provided. Each browser and device is different, so check the settings menu of the browser or device to learn how to change your settings and cookie preferences.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default injectIntl(connect(null, auth.actions)(PrivacyPolicyPage));
