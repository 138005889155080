import moment from "moment";
import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  headerSortingClasses,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { StatusColumnFormatter2 } from "./column-formatters/AlertsColumnFormatter";
import "./Style.css";


var getDate = function(d) {
  if (d === null || d === "" || d === undefined) return "Empty";
  var format = "MM/DD/YYYY hh:mm A";
  if (
    moment(d)
      .startOf("day")
      .valueOf() === moment(d).valueOf()
  ) {
    format = "MM/DD/YYYY";
  }
  return moment(d).format(format);
};
function BodyTable(props) {
  const {t} = useTranslation();

  const columnsOrder = [
    {
      text: "ID#",
      dataField: "Code",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => {
        return (
          <Link
            to={"/track-load/" + row?.Id}
            className="btn btn-link font-weight-bolder text-decoration-none pl-0"
          >
            {row?.Code}
          </Link>
        );
      },
    },
    {
      dataField: "CreationDate",
      text: t("Created"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return getDate(row.CreationDate);
      },
    },
    {
      dataField: "IsDriverAssigned",
      text: "Driver assigned",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            style={{ cursor: "default" }}
            className={
              "btn btn-sm btn-light-" +
              (row.IsDriverAssigned ? "success" : "danger")
            }
          >
            {row.IsDriverAssigned ? "true" : "false"}
          </span>
        );
      },
    },
    {
      dataField: "Driver",
      text: t("Driver"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row?.DriverFisrtName === "" && row?.DriverFisrtName === ""
          ? "-"
          : row?.DriverFisrtName + " " + row?.DriverLastName;
      },
    },
    {
      dataField: "Shipper",
      text: t("PU_City_ST"),
      formatter: (cell, row) => {
        return `${row?.PickupCity} ${row?.PickupState}`;
      },
    },
    {
      dataField: "Consignee",
      text: t("Del_City_ST"),
      formatter: (cell, row) => {
        return `${row?.DestinationCity} ${row?.DestinationState}`;
      },
    },
    {
      dataField: "EquipmentType",
      text: t("EquipmentType"),
      sort: true,
      sortCaret: sortCaret,
      style: {
        textAlign: "center",
      },
      formatter: (cell, row) => {
        return row?.EquipmentType ?? "-";
      },
    },
    // {
    //   dataField: "Distance",
    //   text: "Distance",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style : {
    //     textAlign : 'center'
    //   },
    //   formatter: (cell, row) => {
    //     return row?.Distance ?? "-";
    //   },
    // },
    {
      dataField: "Miles",
      text: t("Miles"),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Price",
      text: t("Price"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            style={{ cursor: "default" }}
            className="btn btn-sm btn-light-info"
          >
            {`$${row?.Price}`}
          </span>
        );
      },
    },
    {
      dataField: "PuAppointmentDate",
      text: t("PuAppointment_From"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return getDate(row.PuAppointmentDate);
      },
    },
    {
      dataField: "PuAppointmentToDate",
      text: t("PuAppointment_To"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return getDate(row.PuAppointmentToDate);
      },
    },
    // {
    //   dataField: "MustArriveByDate",
    //   text: "MA By Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (cell, row) => {
    //     return <Link
    //     style={{cursor : 'default'}}
    //     className="btn btn-sm btn-light-primary"
    //   >
    //     {getDate(row.MustArriveByDate)}
    //   </Link>
    //   },
    // },
    // {
    //   dataField: "ReqShipDate",
    //   text: "ReqShip Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (cell, row) => {
    //     return <Link
    //     style={{cursor : 'default'}}

    //     className="btn btn-sm btn-light-primary"
    //   >
    //     {getDate(row.ReqShipDate)}
    //   </Link>
    //   },
    // },
    {
      dataField: "IsQuickPay",
      text: "Quick Pay",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            style={{ cursor: "default" }}
            className={
              "btn btn-sm btn-light-" + (row.IsQuickPay ? "success" : "danger")
            }
          >
            {row.IsQuickPay ? "true" : "false"}
          </span>
        );
      },
    },
    {
      dataField: "Status",
      text: t("Status"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return StatusColumnFormatter2(row);
      },
    },
  ];
  const GetHandlerTableChange = (type, newState) => {
    props.setData([]);
    let pagination = { ...props.paginationOptions };

    if (type === "sort") {
      pagination.sortField = newState.sortField;
      pagination.sortOrder = newState.sortOrder;
      let values = { ...props?.dataFilter };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      props.setDataFilter(values);
    }
    if (type === "pagination") {
      pagination.sizePerPage = newState.sizePerPage;
      pagination.pageSize = newState.sizePerPage;
      pagination.page = newState.page;
      let values = { ...props?.dataFilter };
      values.pageSize = newState.sizePerPage;
      values.page = newState.page;
      props.setDataFilter(values);
    }
    props.fetchFilter("reload", true, "", pagination);
  };
  return (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(props.paginationOptions)}
      >
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                id={"Id"}
                noDataIndication={
                  <h4
                    className="text-muted my-5"
                    style={{ textAlign: "center" }}
                  >
                    {t("There_is_no_data_available_at_the_moment")}
                  </h4>
                }
                keyField={"Id"}
                data={props?.data?.Result ?? []}
                columns={columnsOrder}
                onTableChange={GetHandlerTableChange}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={props?.data?.Result ?? []} />
                <NoRecordsFoundMessage entities={props?.data?.Result ?? []} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </Fragment>
  );
}

export default BodyTable;
