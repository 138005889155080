import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { currencyFormat } from "../../../../../../_metronic/_helpers/AtHelper";
const adapter = new LocalStorage("db");
const db = low(adapter);

class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
    };
    this.state = { data: obj };
  }

  render() {
    const formData = this?.props?.DataInvoice;
    return (
      <>
        <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
          <div className="col-md-12">
              <div colSpan="2" className="mb-5 pb-5">
                  {formData?.Logo !== null &&
                    formData?.Logo !== "" &&
                    formData?.Logo !== undefined ? (
                    <img
                      style={{ objectFit: "cover" }}
                      src={formData?.Logo}
                      alt="Logo"
                      width="100"
                      height="100"
                          />
                        ) : (
                          ""
                    )}
                </div>
            <div className="row pb-10" style={{marginRight:"0px", marginLeft: '0px'}}>
              <div className="col-md-8 px-0 mr-auto">
                <table className="w-100">
                  <tbody className="border-0">
                    <tr>
                      <td className="border-0 text-left">
                        <h3 className="font-weight-bolder text-dark-65 pb-8">
                          Bill #{formData?.Code}
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-0 text-left w-50">
                        <p className="font-weight-bolder text-dark-65 mb-1">
                          Issue Date:
                        </p>
                      </td>
                      <td className="border-0 text-left">
                        <p className="font-weight-bolder text-dark-65 mb-1">
                          Due Date:
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-0 text-left w-50">
                        <h6 className="font-weight-bolder text-dark-75 pb-8">
                          {moment(formData?.BillDate).format("MMM DD, YYYY")}
                        </h6>
                      </td>
                      <td className="border-0 text-left w-50">
                        <h6 className="font-weight-bolder text-dark-75 pb-8">
                          {formData?.BillDueDate !== null &&
                          formData?.BillDueDate !== "" &&
                          formData?.BillDueDate !== undefined
                            ? moment(formData?.BillDueDate).format(
                                "MMM DD, YYYY"
                              )
                            : "-"}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-0 text-left">
                        <p className="font-weight-bolder text-dark-65">
                          Issued For:
                        </p>
                      </td>
                      <td className="border-0 text-left w-50">
                        <p className="font-weight-bolder text-dark-65">
                          Issued By:
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-0 text-left d-flex flex-column">
                        <h6 className="font-weight-bolder text-dark-75">
                          {formData?.IssuedFor?.Name}
                        </h6>
                        <p className="font-weight-bold text-dark-65 mb-0">
                          {formData?.IssuedFor?.Address1}
                        </p>
                        <p className="font-weight-bold text-dark-65">
                          {formData?.IssuedFor?.State !== "" &&
                          formData?.IssuedFor?.State !== null &&
                          formData?.IssuedFor?.State !== undefined
                            ? formData?.IssuedFor?.City + ", "
                            : formData?.IssuedFor?.City}
                          {formData?.IssuedFor?.Zipcode !== "" &&
                          formData?.IssuedFor?.Zipcode !== null &&
                          formData?.IssuedFor?.Zipcode !== undefined
                            ? formData?.IssuedFor?.State + ", "
                            : formData?.IssuedFor?.State}
                          {formData?.IssuedFor?.Zipcode}
                        </p>
                      </td>
                      <td className="border-0 text-left w-50">
                        <h6 className="font-weight-bolder text-dark-75">
                          {formData?.IssuedBy?.Name}
                        </h6>
                        <p className="font-weight-bold text-dark-65 mb-0">
                          {formData?.IssuedBy?.Address1}
                        </p>
                        <p className="font-weight-bold text-dark-65">
                          {formData?.IssuedBy?.State !== "" &&
                          formData?.IssuedBy?.State !== null &&
                          formData?.IssuedBy?.State !== undefined
                            ? formData?.IssuedBy?.City + ", "
                            : formData?.IssuedBy?.City}{" "}
                          {formData?.IssuedBy?.Zipcode !== "" &&
                          formData?.IssuedBy?.Zipcode !== null &&
                          formData?.IssuedBy?.Zipcode !== undefined
                            ? formData?.IssuedBy?.State + ", "
                            : formData?.IssuedBy?.State}
                          {formData?.IssuedBy?.Zipcode}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td className="border-0 text-left w-50">
                        <p className="font-weight-bolder text-dark-65 mb-1">
                          Origin:
                        </p>
                      </td>
                      <td className="border-0 text-left">
                        <p className="font-weight-bolder text-dark-65 mb-1">
                          Destination:
                        </p>
                      </td>
                      <td className="border-0 text-left">
                        <p className="font-weight-bolder text-dark-65 mb-1">
                          Mode:
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-0 text-left w-50">
                        <h6 className="font-weight-bolder text-dark-75 pb-8">
                          {formData?.PickupName}
                        </h6>
                      </td>
                      <td className="border-0 text-left w-50">
                        <h6 className="font-weight-bolder text-dark-75 pb-8">
                          {formData?.DestinationName}
                        </h6>
                      </td>
                      <td className="border-0 text-left w-50">
                        <h6 className="font-weight-bolder text-dark-75 pb-8">
                          {formData?.Mode}
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-md-12 px-0 ">
                <div className="react-bootstrap-table table-responsive">
                  <table className="w-25 justify-content-end ml-auto">
                    <tbody className="border-0">
                      <tr>
                        <td
                          className="font-weight-boldest align-text-top text-right border-0 text-dark-50 h-6 pt-6"
                          style={{ fontSize: "15px" }}
                        >
                          Total
                        </td>
                        <td className="font-weight-boldest align-text-top text-right border-0 text-dark-75 pt-6">
                          ${currencyFormat(formData?.TotalPrice)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function BillForm({ row }) {
  const [DataInvoice, setDataInvoice] = useState(null);
  useEffect(() => {
    getShipperTruckerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);
  function getShipperTruckerInfo() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${window.$apiUrl}/api/MyLoads/Bill/${row.Id}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setDataInvoice(result[1]?.Invoice);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="card-body">
      <div className="container mb-md-5 mt-4">
        <div className="col-md-12 mb-4">
          <div className="text-right">
            <button
              type="button"
              className="btn btn-primary font-weight-bolder mr-3"
              onClick={handlePrint}
            >
              Print bill
            </button>
          </div>
        </div>
      </div>
      <ComponentToPrint ref={componentRef} DataInvoice={DataInvoice} />
    </div>
  );
}
