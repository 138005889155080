/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { useTranslation } from "react-i18next";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const {t} = useTranslation();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
  const [userProfilePicture, setuserProfilePicture] = useState("");

useEffect(() => {
  setuserProfilePicture(db.read().getState().ProfilePicture);
}, []);

useEffect(() => {
  console.log("🚀 ~ file: UserProfileDropdown.js ~ line 36 ~ useEffect ~ userProfilePicture", userProfilePicture)
}, [userProfilePicture]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          onClick={() => {
            setuserProfilePicture(db.read().getState().ProfilePicture);
          }}
          className={
            "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {db.read().getState()?.FirstName} {db.read().getState()?.LastName}
          </span>
          {!!userProfilePicture ? (
            <span className="symbol symbol-35 logoImgMain">
              <span
                id="spanLogo"
                style={{
                  backgroundImage: `url("${userProfilePicture.toString()}")`,
                }}
                className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"
              >
                {/* {String(db.read().getState()?.FirstName)[0]} */}
              </span>
            </span>
          ) : (
            <span className="symbol symbol-35">
              <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
                {String(db.read().getState()?.FirstName)[0]}
              </span>
            </span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="bg-light-primary mr-3 flex-shrink-0">
                  <img
                    id="ImageProfileDropDownTogel"
                    className="imgLogo"
                    src={
                      userProfilePicture === ""
                        ? "/media/svg/avatars/blank.svg"
                        : userProfilePicture
                    }
                    alt=""
                  />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {db.read().getState()?.FirstName}{" "}
                  {db.read().getState()?.LastName}
                </div>
                {/* <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messages
                </span> */}
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage:
                  "url('" +
                  (db.read().getState().ProfilePicture === ""
                    ? "/media/svg/avatars/blank.svg"
                    : db.read().getState().ProfilePicture) +
                  "')",
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.firstname[0]}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstname} {user.lastname}
              </div>
              <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  {t("My_Profile")}
                </div>
              </div>
            </div>
          </Link>
          {/* <Link to="/chat" className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">{t("My_Messages")}</div>
                <div className="text-muted">{t("Inbox_and_tasks")}</div>
              </div>
            </div>
          </Link>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">{t("My_Activities")}</div>
                <div className="text-muted">{t("Logs_and_notifications")}</div>
              </div>
            </div>
          </a> */}
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              {t("Sign_Out")}
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
