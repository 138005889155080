import React from "react";
import GoogleMapReact from "google-map-react";
import { toAbsoluteUrl } from "../../_helpers";
const exampleMapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ABE8C7"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#CFE2EB"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#CFE2EB"
      }
    ]
  }
];
export function GoogleMapnewversion({ DetailTrakingGeometryInfo }) {
  const renderMarkers = async (map, maps) => {
    var bounds = new maps.LatLngBounds();
    const infoWindow = new maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
    //actual
    var data1 = DetailTrakingGeometryInfo?.map((item) => {
      return {
        lat: Number(item?.Latitude),
        lng: Number(item?.Longitude),
        Location: item?.City + ", " + item?.State,
        EventName: item?.EventName,
      };
    });
    const directionsService = new maps.DirectionsService();
    calculateAndDisplayRoute(directionsService);

    async function calculateAndDisplayRoute(directionsService) {
      window.gMap = map;
      data1.map((items, index) => {
        let _marker2 = new maps.Marker({
          position: items,
          map: map,
          icon: {
            url: items.EventName==="Pick-up"? toAbsoluteUrl("/media/icons/Sender.png") : items.EventName==="Drop-off"? toAbsoluteUrl("/media/icons/Receiver.png") : toAbsoluteUrl("/media/icons/Stop 1.png"),
          },
        });

        _marker2.addListener("click", () => {
          infoWindow.setContent(
            `<div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Location:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${items?.Location}</div>
          
        </div>
        <div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Event:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${items?.EventName}</div>
          
        </div>`
          );
          infoWindow.open(map, _marker2);
        });
        bounds.extend(_marker2.position);
        return false;
      });
      for (var j = 0, parts = [], max = 22 - 1; j < data1.length; j = j + max) {
        parts.push(data1.slice(j, j + max + 1));
      }
      for (var k = 0; k < parts.length; k++) {
        
    const directionsRenderer = new maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: "#6993ff",
        strokeOpacity: 3.0,
        strokeWeight: 4,
      },
    });
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var l = 1; l < parts[k].length - 1; l++) {
          waypoints.push({ location: parts[k][l], stopover: false });
        }
        // Service options
        var service_options = {
          origin: parts[k][0],
          //destination: data1[data1.length - 1],
          destination: parts[k][parts[k].length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
        };
        // Send request
        await directionsService.route(service_options, (response, status) => {
          if (status !== "OK") {
            console.log("Directions request failed due to " + status);
            return;
          }
          if (!window.gRenderers) window.gRenderers = [];
          window.gRenderers.push(directionsRenderer);
          directionsRenderer.setMap(map);
          directionsRenderer.setOptions({
            suppressMarkers: true,
            preserveViewport: false,
          });
          directionsRenderer.setDirections(response);
          directionsRenderer.setMap(map);
        });
      }
    }
  };
  return (
    <>
      {DetailTrakingGeometryInfo?.length > 0 ? (
        <GoogleMapReact
          key={1}
          bootstrapURLKeys={{
            key: "AIzaSyA1A9EPSRfIpJkN2v8CweA_W2_BUGCIdU8",
          }}
          defaultCenter={{
            lat: 37.923984,
            lng: -97.539071,
          }}
          options={{ styles: exampleMapStyles }}
          defaultZoom={4}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        ></GoogleMapReact>
      ) : (
        <GoogleMapReact
          key={2}
          bootstrapURLKeys={{
            key: "AIzaSyA1A9EPSRfIpJkN2v8CweA_W2_BUGCIdU8",
          }}
          defaultCenter={{
            lat: 37.923984,
            lng: -97.539071,
          }}
          defaultZoom={4}
          options={{ styles: exampleMapStyles }}
        ></GoogleMapReact>
      )}
    </>
  );
}
