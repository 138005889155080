import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { translateAuto } from "../../../../_metronic/_helpers/AtHelper";

export default function ActivateAccount(props) {
    const [status, setStatus] = useState(false);
    const location = useLocation();
    const {t} = useTranslation();
    useEffect(() => {
      try{
          ActivateAccountEmail(location?.search?.replace("?code=",""));
      }catch(err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);
    async function ActivateAccountEmail(code){
        try{
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");      
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({Code : code}),
            redirect: "follow",
          };
      
          fetch(`${window.$apiUrl}/api/Account/ConfirmEmail`, requestOptions)
            .then(async (response) => {
              const statusCode = await response.status;
              const contentType = response.headers.get("content-type");
              if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json().then((data) => [statusCode, data]);
              } else {
                return response.text().then((text) => [statusCode, { Message: "" }]);
              }
            })
            .then((result) => {
              if ((result?.length > 0 && result[0] === 200) || result === undefined) {
                setStatus(true);
              } else {
                if (
                  result[1].ModelState !== null &&
                  result[1].ModelState !== undefined &&
                  result[1].ModelState !== ""
                ) {
                  let modelState = result[1].ModelState;
                  if (modelState)
                    Object.keys(modelState).forEach(function (k) {
                      modelState[k].forEach((element) => {
                        Swal.fire(t("Error"), translateAuto(element), "error");
                      });
                    });
                } else if (
                  result[1].Message !== null &&
                  result[1].Message !== undefined &&
                  result[1].Message !== ""
                ) {
                  Swal.fire(t("Error"), translateAuto(result[1].Message), "error");
                } else if (
                  result[1].error_description !== null &&
                  result[1].error_description !== undefined &&
                  result[1].error_description !== ""
                ) {
                  Swal.fire(t("Error"), translateAuto(result[1].error_description), "error");
                } else {
                  Swal.fire(t("Error"), t("Bad_Request"), "error");
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        }catch(err) {}
    }
    return (
      <Fragment>
        <div
          className="login login-3 login-signin-on d-flex flex-row-fluid"
          id=""
        >
          <div
            className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
            style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
          >
            <div className="col-xl-6 col-lg-6 col-md-10 col-12  p-7 position-relative overflow-hidden">
              <div className="card">
                <div className="card-header card-header-tabs-line">
                  <div className="card-body">
                    <Row className="form-group">
                      <Col>
                        <div className="d-flex flex-center mb-15">
                          <a href={() => false}>
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/logos/TaapLogo.svg"
                              )}
                            />
                          </a>
                        </div>
                      </Col>
                    </Row>
                    {status ? (
                      <div className="login-signin">
                        <div className="text-center">
                          <h2 className="font-size-h1 mb-5 font-weight-bolder">
                            Your Account has been activated successfully
                            <span>
                              <i
                                className="flaticon2-check-mark text-success ml-3"
                                style={{ fontSize: "28px" }}
                              ></i>
                            </span>
                          </h2>
                          <h6 className="text-muted">
                            Thanks for signing up and welcome to Taap App!
                          </h6>
                          <div className="text-center my-10">
                            <Link
                              to="/logout"
                              className="btn btn-lg btnPrimaryTaap font-weight-bolder"
                            >
                              Log In
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="login-signin">
                        <div className="text-center">
                          <h2 className="font-size-h1 mb-5 font-weight-bolder">
                            Account already activated
                            <span>
                              <i
                                className="flaticon2-cross text-danger ml-3"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </span>
                          </h2>
                          <div className="text-center my-10">
                            <Link
                              to="/logout"
                              className="btn btn-lg btnPrimaryTaap font-weight-bolder"
                            >
                              Log In
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/*end::Login Sign in form*/}
                </div>
              </div>
            </div>
          </div>
          {/*end::Login*/}
        </div>
      </Fragment>
    );
}
