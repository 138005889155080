/* eslint-disable react-hooks/exhaustive-deps */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import LoadStops from "../LoadComponent/LoadStops";
import LoadItems from "../LoadComponent/LoadItems";
import ActivityFunction from "../LoadComponent/Activities";
import AttachementComp from "../LoadComponent/Attachement";
import NotesComp from "../LoadComponent/Notes";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";
import {
  convertValueSecondsToDuration,
  formatPhoneNumber,
  translateAuto,
} from "../../../../../../_metronic/_helpers/AtHelper";
import { TimeDurationInput } from "react-time-duration-input";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function ProductDetailForm({
  actionsLoading,
  product,
  btnRef,
  saveProduct,
  backToProductsList,
  saveProductClick,
  isLoadingAuth,
  setisLoadingAuth,
  getShipmentData,
  ActivitiesList,
  setActivitiesList,
}) {
  const history = useHistory();

  const [ViewModelOptions, setViewModelOptions] = useState([]);
  const [showModalAddresses, setshowModalAddresses] = useState(false);
  const [showModalActivities, setshowModalActivities] = useState(false);
  const [ContriesViewModel, setContriesViewModel] = useState([]);
  const [LoadStopsList, setLoadStopsList] = useState([]);
  const [TimeEstimationState, setTimeEstimationState] = useState(0);
  useEffect(() => {
    if (product.RestTime) {
      setTimeEstimationState(product.RestTime / 60 / 60);
    }
  }, [product]);
  const { t } = useTranslation();
  function GetContriesViewModel() {
    var myHeaders = new Headers();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Countries`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setContriesViewModel(data);
        } else if (result[0] === 401) {
          history.push("/logout");
        } else if (result[0] === 402) {
          Swal.fire(t("Error"), translateAuto(result[1].Message));
          history.push("/user-profile#billing");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/api/Shipments/GetViewModel`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setViewModelOptions(result[1]);
        } else if (result[0] === 402) {
          Swal.fire(t("Error"), translateAuto(result[1].Message));
          history.push("/user-profile#billing");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getViewModel();
    GetContriesViewModel();
  }, []);
  const [initAddressObject, setinitAddressObject] = useState({
    Name: "",
    Code: "",
    Address1: "",
    Address2: "",
    Zipcode: "",
    City: "",
    State: "",
    Country: "United States",
    Coupon: "",
    ContactFirstName: "",
    ContactLastName: "",
    ContactEmail: "",
    ContactPhone: "",
    ContactPhone2: "",
    ContactFax: "",
    IsShipper: false,
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shipper | Loads</title>
      </Helmet>
      <Formik
        enableReinitialize={true}
        initialValues={product}
        //validationSchema={ProductEditSchema}
        onSubmit={(values) => {
          saveProduct(values);
        }}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => {
          function getAddressDetail(AddressId, isShipping) {
            var myHeaders = new Headers();
            myHeaders.append(
              "Authorization",
              `Bearer ${db.read().getState()?.Token}`
            );

            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };

            fetch(`${window.$apiurl}/api/Address/${AddressId}`, requestOptions)
              .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json().then((data) => [statusCode, data]);
                } else {
                  return response
                    .text()
                    .then((text) => [statusCode, { Message: "" }]);
                }
              })
              .then(async (result) => {
                if (result[0] === 200) {
                  var data = result[1];
                  let obj = data.Address;
                  if (isShipping) {
                    setFieldValue("ShippingAddress1", obj.Address1);
                    setFieldValue("ShippingAddress2", obj.Address2);
                    setFieldValue("ShippingCity", obj.City);
                    setFieldValue("ShippingState", obj.State);
                    setFieldValue("ShippingCountry", obj.Country);
                    setFieldValue("ShippingZipCode", obj.Zipcode);
                    setFieldValue("ShippingContactName", obj.ContactName);
                    setFieldValue("ShippingContactPhone", obj.ContactPhone);
                    setFieldValue("ShippingContactPhone2", obj.ContactPhone2);
                    setFieldValue("ShippingContactFax", obj.ContactFax);
                    setFieldValue("ShippingContactEmail", obj.ContactEmail);
                  } else {
                    setFieldValue("ReceivingAddress1", obj.Address1);
                    setFieldValue("ReceivingAddress2", obj.Address2);
                    setFieldValue("ReceivingCity", obj.City);
                    setFieldValue("ReceivingCountry", obj.Country);
                    setFieldValue("ReceivingState", obj.State);
                    setFieldValue("ReceivingZipCode", obj.Zipcode);
                    setFieldValue("ReceivingContactName", obj.ContactName);
                    setFieldValue("ReceivingContactPhone", obj.ContactPhone);
                    setFieldValue("ReceivingContactPhone2", obj.ContactPhone2);
                    setFieldValue("ReceivingContactFax", obj.ContactFax);
                    setFieldValue("ReceivingContactEmail", obj.ContactEmail);
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          }
          return (
            <>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-4">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <Card className="bg-transparent shadow-none header-detail mb-1">
                        {actionsLoading && <ModalProgressBar />}
                        <CardHeader className="border-0 pl-0">
                          <CardHeaderToolbar className="pl-0 align-content-end">
                            <button
                              type="button"
                              onClick={backToProductsList}
                              className="btn btn-light"
                            >
                              <i className="fa fa-arrow-left"></i>
                              {t("Back")}
                            </button>
                            {`  `}
                            {isLoadingAuth && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </CardHeaderToolbar>
                        </CardHeader>
                      </Card>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <Card>
                        <CardHeader
                          className="border-bottom-0"
                          title={t("Basic_Info")}
                        >
                          <div className="separator separator-dashed my-3"></div>
                        </CardHeader>
                        <CardBody>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                SO #
                              </a>
                              <a
                                href={() => false}
                                className="text-parimary font-weight-bold"
                              >
                                <input
                                  type="text"
                                  className="form-control "
                                  name="MOText"
                                  id="SOText"
                                  label="SO"
                                  disabled={true}
                                  value={values.Id}
                                  onChange={(e) => {
                                    setFieldValue("Id", e.target.value);
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Load")} #
                              </a>
                              <a
                                href={() => false}
                                className="text-parimary font-weight-bold"
                              >
                                <input
                                  type="text"
                                  className="form-control "
                                  name="Code"
                                  id="Code"
                                  label="Code"
                                  disabled={true}
                                  value={values.Code}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Shipment_Date")}
                              </a>
                              <span className="text-muted font-weight-bold">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="ShipmentDate"
                                  disabled={true}
                                  id="ShipmentDate"
                                  label="Shipment Date"
                                  value={String(
                                    moment(values.ShipmentDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  )}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "ShipmentDate",
                                      e.target.value
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Mode")}
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  name="ModeId"
                                  isDisabled={true}
                                  id="ModeId"
                                  label="ModeId"
                                  value={ViewModelOptions?.Modes?.filter(
                                    (a) => a.Id === values.ModeId
                                  )?.map((a) => {
                                    return { label: a.Name, value: a.Id };
                                  })}
                                  options={ViewModelOptions?.Modes?.map((a) => {
                                    return { label: a.Name, value: a.Id };
                                  })}
                                  onChange={(e) => {
                                    setFieldValue("ModeId", e?.value);
                                  }}
                                  inputId="react-select-single"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                {t("Equipment_Type")}
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  name="EquipmentTypeId"
                                  id="EquipmentTypeId"
                                  label="EquipmentTypeId"
                                  isDisabled={true}
                                  inputId="react-select-single"
                                  value={ViewModelOptions?.EquipmentTypes?.filter(
                                    (a) => a.Id === values.EquipmentTypeId
                                  )?.map((a) => {
                                    return { label: a.Name, value: a.Id };
                                  })}
                                  options={ViewModelOptions?.EquipmentTypes?.map(
                                    (a) => {
                                      return { label: a.Name, value: a.Id };
                                    }
                                  )}
                                  onChange={(e) => {
                                    setFieldValue("EquipmentTypeId", e?.value);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-md-6 mt-4">
                              <div className="checkbox-list d-flex pl-0">
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    checked={values.SignatureRequired}
                                    disabled={true}
                                    id="SignatureRequired"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "SignatureRequired",
                                        e.target.checked
                                      );
                                    }}
                                    name="Checkboxes1"
                                    className="mr-2"
                                  />
                                  <span className="mb-auto"></span>
                                  <div className="d-flex flex-column flex-grow-1">
                                    <label
                                      className="form-check-label"
                                      for="SignatureRequired"
                                    >
                                      {t("Signature_Required")}
                                    </label>
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="checkbox-list d-flex pl-0">
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    checked={values.IsQuickPay}
                                    id="IsQuickPay"
                                    disabled={true}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "IsQuickPay",
                                        e.target.checked
                                      );
                                    }}
                                    name="Checkboxes1"
                                    className="mr-2"
                                  />
                                  <span className="mb-auto"></span>
                                  <div className="d-flex flex-column flex-grow-1">
                                    <label
                                      className="form-check-label"
                                      for="IsQuickPay"
                                    >
                                      {t("Quick_Pay")}
                                    </label>
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-6 mt-3">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_Miles")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Miles"
                                    id="Miles"
                                    label="Miles"
                                    disabled={true}
                                    value={values.TotalMiles}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-6 mt-3">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_Price")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="TotalPrice"
                                    id="TotalPrice"
                                    disabled={true}
                                    label="TotalPrice"
                                    value={values.TotalPrice}
                                    onChange={(e) => {
                                      if (e.target.value === "") {
                                        setFieldValue(
                                          "TotalPrice",
                                          e.target.value
                                        );
                                      } else {
                                        const value = Math.max(
                                          0,
                                          Math.min(
                                            10000000,
                                            Number(e.target.value)
                                          )
                                        );
                                        setFieldValue("TotalPrice", value);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-6">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Driving_Time")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="DrivingTime"
                                    id="DrivingTime"
                                    label="DrivingTime"
                                    disabled={true}
                                    value={convertValueSecondsToDuration(
                                      values?.DrivingTime
                                    )}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-6">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Rest_Time")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  {/* <input type="text" className="form-control" value={TimeEstimationState} onChange={onInputChange} data-testid="duration-input" /> */}
                                  <TimeDurationInput
                                    value={TimeEstimationState}
                                    scale={["d", "h", "m"]}
                                    className="form-control"
                                    onChange={setTimeEstimationState}
                                    values={values}
                                    disabled={true}
                                    setFieldValue={setFieldValue}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-6">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_Time")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Total"
                                    id="Total"
                                    disabled={true}
                                    label="Total"
                                    value={convertValueSecondsToDuration(
                                      values?.RestTime + values?.DrivingTime
                                    )}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-6">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Value")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="Value"
                                    id="Value"
                                    label="Value"
                                    disabled={true}
                                    min="0"
                                    value={values?.Value}
                                    onChange={(e) => {
                                      if (e.target.value === "") {
                                        setFieldValue("Value", e.target.value);
                                      } else {
                                        const value = Math.max(
                                          0,
                                          Math.min(
                                            10000000,
                                            Number(e.target.value)
                                          )
                                        );
                                        setFieldValue("Value", value);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-12">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Total_Weight")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="TotalWeight"
                                    id="TotalWeight"
                                    disabled={true}
                                    label="TotalWeight"
                                    value={`${
                                      values?.TotalWeight !== undefined &&
                                      values?.TotalWeight !== null
                                        ? values?.TotalWeight
                                        : ""
                                    } ${
                                      values?.WeightType !== null &&
                                      values?.WeightType !== undefined &&
                                      values?.WeightType !== ""
                                        ? "(" + values?.WeightType + ")"
                                        : ""
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-12">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Trailer_Number")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="TrailerNumber"
                                    id="TrailerNumber"
                                    disabled={true}
                                    label="TrailerNumber"
                                    value={values?.TrailerNumber}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center pb-5 col-md-12">
                              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                >
                                  {t("Truck_Number")}
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="TruckNumber"
                                    id="TruckNumber"
                                    disabled={true}
                                    label="TruckNumber"
                                    value={values?.TruckNumber}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-8">
                  <Card className="bg-transparent shadow-none Service-order">
                    {actionsLoading && <ModalProgressBar />}
                    <CardBody className="p-0">
                      <div className="mt-15">
                        <Card>
                          <CardHeader title={t("Shipping_Details")}></CardHeader>
                          <CardBody>
                            <div className="row mb-5">
                              <Col xl="12" lg="12" md="12" className="mb-5">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Company_Name")}
                                    </a>
                                    <span className="font-weight-bold">
                                      <CreatableSelect
                                        className="mt-4"
                                        isClearable
                                        name="PickupId"
                                        isDisabled={true}
                                        id="PickupId"
                                        label="Company"
                                        value={ViewModelOptions?.Addresses?.filter(
                                          (a) => a.Id === values?.PickupId
                                        ).map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                            ValueId: a.ValueId,
                                          };
                                        })}
                                        options={ViewModelOptions?.Addresses?.map(
                                          (a) => {
                                            return {
                                              value: a.Id,
                                              label: a.Name,
                                              ValueId: a.ValueId,
                                            };
                                          }
                                        )}
                                        onChange={async (newValue) => {
                                          if (newValue !== null) {
                                            if (newValue?.__isNew__) {
                                              setshowModalAddresses(true);
                                              setinitAddressObject({
                                                Name: newValue.label,
                                                Code: "",
                                                Address1: "",
                                                Address2: "",
                                                Zipcode: "",
                                                City: "",
                                                State: "",
                                                Country: "United States",
                                                Coupon: "",
                                                ContactFirstName: "",
                                                ContactLastName: "",
                                                ContactEmail: "",
                                                ContactPhone: "",
                                                ContactPhone2: "",
                                                ContactFax: "",
                                                IsShipper: true,
                                              });
                                            } else {
                                              setFieldValue(
                                                "PickupId",
                                                newValue?.value
                                              );
                                              setFieldValue(
                                                "PickupName",
                                                newValue?.label
                                              );
                                              getAddressDetail(
                                                newValue?.value,
                                                true
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              <div className="col-xl-8 col-lg-8 col-md-5 border-right">
                                <Row>
                                  <div className="col-xl col-lg col-md-5 border-right">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 1
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            disabled={true}
                                            value={values?.ShippingAddress1}
                                            name="ShipperAddress1"
                                            id="ShipperAddress1"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 2
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            disabled={true}
                                            value={values?.ShippingAddress2}
                                            name="ShipperAddress2"
                                            id="ShipperAddress2"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Country")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingCountry"
                                              id="ShippingCountry"
                                              value={values?.ShippingCountry}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("State")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingState"
                                              id="ShippingState"
                                              value={values?.ShippingState}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("City")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingCity"
                                              id="ShippingCity"
                                              value={values?.ShippingCity}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Zip")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ShippingZipCode"
                                              id="ShippingZipCode"
                                              value={values?.ShippingZipCode}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl col-lg col-md-5 border-right">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Name")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ShippingContactName"
                                            id="ShippingContactName"
                                            label="Contact"
                                            value={values?.ShippingContactName}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Email")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="email"
                                            className="form-control mt-4"
                                            name="ShippingContactEmail"
                                            id="ShippingContactEmail"
                                            label="Email"
                                            value={values?.ShippingContactEmail}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 1
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ShippingContactPhone1"
                                              id="ShippingContactPhone1"
                                              value={formatPhoneNumber(
                                                values?.ShippingContactPhone
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 2
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ShippingContactPhone2"
                                              id="ShippingContactPhone2"
                                              value={formatPhoneNumber(
                                                values?.ShippingContactPhone2
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Fax")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="tel"
                                            className="form-control mt-4"
                                            name="ShippingContactFax"
                                            id="ShippingContactFax"
                                            label="Fax"
                                            value={values?.ShippingContactFax}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Note")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <textarea
                                        className="form-control"
                                        maxlength="400"
                                        rows="3"
                                        disabled={true}
                                        value={values.ShippingNote}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ShippingNote",
                                            e.target.value
                                          );
                                        }}
                                      ></textarea>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-5 border-right">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      Req. {t("Ship_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="RequiredShipDate"
                                        id="RequiredShipDate"
                                        disabled={true}
                                        label="Ship"
                                        value={String(
                                          moment(values.ReqShipDate).format(
                                            "YYYY-MM-DD"
                                          )
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ReqShipDate",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      PU #
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control mt-4"
                                        name="ShipperNumber"
                                        id="ShipperNumber"
                                        disabled={true}
                                        value={values.PickupNumber}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "PickupNumber",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Seal")} #
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control mt-4"
                                        name="SealNumber"
                                        id="SealNumber"
                                        disabled={true}
                                        value={values.SealNumber}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "SealNumber",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Special_Req")}
                                    </a>
                                    <span className="font-weight-bold">
                                      <Select
                                        isMulti
                                        className="mt-4"
                                        name="ShipperSpecialReq"
                                        id="ShipperSpecialReq"
                                        isDisabled={true}
                                        value={ViewModelOptions?.SpecialReq?.filter(
                                          function(el) {
                                            return (
                                              values?.ShippingSpecialReq?.indexOf(
                                                el.Name
                                              ) >= 0
                                            );
                                          }
                                        )?.map((a) => {
                                          return {
                                            value: a.Name,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.SpecialReq?.map(
                                          (a) => {
                                            return {
                                              value: a.Name,
                                              label: a.Name,
                                            };
                                          }
                                        )}
                                        onChange={(newValue) => {
                                          if (newValue !== null) {
                                            setFieldValue(
                                              "ShippingSpecialReq",
                                              newValue.map((a) => a.value)
                                            );
                                          } else {
                                            setFieldValue(
                                              "ShippingSpecialReq",
                                              []
                                            );
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Pu_Appointment_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="PuAppointmentDate"
                                        id="PuAppointmentDate"
                                        disabled={true}
                                        label="Ship"
                                        value={String(
                                          moment(
                                            values.PuAppointmentDate
                                          ).format("YYYY-MM-DD")
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "PuAppointmentDate",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Pu_Appointment_To_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="PuAppointmentDateTo"
                                        id="PuAppointmentDateTo"
                                        disabled={true}
                                        label="Ship"
                                        value={String(
                                          moment(
                                            values.PuAppointmentDateTo
                                          ).format("YYYY-MM-DD")
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "PuAppointmentDateTo",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <LoadStops
                          values={values}
                          setFieldValue={setFieldValue}
                          id={product.id}
                          product={product}
                          ViewModelOptions={ViewModelOptions}
                          getShipmentData={getShipmentData}
                          setLoadStopsList={setLoadStopsList}
                          LoadStopsList={LoadStopsList}
                          getViewModel={getViewModel}
                          ContriesViewModel={ContriesViewModel}
                          IsDetailsMode={true}
                        />

                        <Card>
                          <CardHeader title="Receiving Details"></CardHeader>
                          <CardBody>
                            <div className="row mb-5">
                              <Col xl="12" lg="12" md="12" className="mb-5">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Company_Name")}
                                    </a>
                                    <span className="font-weight-bold">
                                      <CreatableSelect
                                        className="mt-4"
                                        isClearable
                                        name="DestinationId"
                                        id="DestinationId"
                                        isDisabled={true}
                                        label="Company"
                                        value={ViewModelOptions?.Addresses?.filter(
                                          (a) => a.Id === values?.DestinationId
                                        ).map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.Addresses?.map(
                                          (a) => {
                                            return {
                                              value: a.Id,
                                              label: a.Name,
                                            };
                                          }
                                        )}
                                        onChange={async (newValue) => {
                                          if (newValue !== null) {
                                            if (newValue?.__isNew__) {
                                              setshowModalAddresses(true);
                                              setinitAddressObject({
                                                Name: newValue.label,
                                                Code: "",
                                                Address1: "",
                                                Address2: "",
                                                Zipcode: "",
                                                City: "",
                                                State: "",
                                                Country: "United States",
                                                Coupon: "",
                                                ContactFirstName: "",
                                                ContactLastName: "",
                                                ContactEmail: "",
                                                ContactPhone: "",
                                                ContactPhone2: "",
                                                ContactFax: "",
                                                IsShipper: false,
                                              });
                                            } else {
                                              setFieldValue(
                                                "DestinationId",
                                                newValue?.value
                                              );
                                              setFieldValue(
                                                "DestinationName",
                                                newValue?.label
                                              );
                                              getAddressDetail(
                                                newValue?.value,
                                                false
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              <div className="col-xl-8 col-lg-8 col-md-12 border-right">
                                <Row>
                                  <div className="col-xl col-lg col-md-5 border-right">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 1
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ReceivingAddress1"
                                            id="ReceivingAddress1"
                                            value={values?.ReceivingAddress1}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Street_Address")} 2
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ReceivingAddress2"
                                            id="ReceivingAddress2"
                                            value={values?.ReceivingAddress2}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Country")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              disabled={true}
                                              className="form-control mt-4"
                                              name="ReceivingCountry"
                                              id="ReceivingCountry"
                                              value={values?.ReceivingCountry}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("State")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              disabled={true}
                                              className="form-control mt-4"
                                              name="ReceivingState"
                                              id="ReceivingState"
                                              value={values?.ReceivingState}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("City")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ReceivingCity"
                                              id="ReceivingCity"
                                              value={values?.ReceivingCity}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Zip")}
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control mt-4"
                                              name="ReceivingZipCode"
                                              id="ReceivingZipCode"
                                              value={values?.ReceivingZipCode}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl col-lg col-md-5">
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Name")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control mt-4"
                                            name="ReceivingContactName"
                                            id="ReceivingContactName"
                                            label="Contact"
                                            value={values?.ReceivingContactName}
                                            disabled={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Email")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="email"
                                            className="form-control mt-4"
                                            name="ReceivingContactEmail"
                                            id="ReceivingContactEmail"
                                            disabled={true}
                                            label="Email"
                                            value={
                                              values?.ReceivingContactEmail
                                            }
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" flex-wrap align-items-center pb-5 col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 1
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ReceivingContactPhone1"
                                              id="ReceivingContactPhone1"
                                              value={formatPhoneNumber(
                                                values?.ReceivingContactPhone
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className=" flex-wrap align-items-center pb-5  col-xl-6 col-lg-12">
                                        <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                          >
                                            {t("Contact_Phone")} 2
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="tel"
                                              className="form-control mt-4"
                                              name="ReceivingContactPhone2"
                                              id="ReceivingContactPhone2"
                                              value={formatPhoneNumber(
                                                values?.ReceivingContactPhone2
                                              )}
                                              disabled={true}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className=" flex-wrap align-items-center pb-5">
                                      <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                        >
                                          {t("Contact_Fax")}
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="tel"
                                            className="form-control mt-4"
                                            name="ReceivingContactFax"
                                            id="ReceivingContactFax"
                                            label="Fax"
                                            disabled={true}
                                            value={values?.ReceivingContactFax}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Note")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <textarea
                                        className="form-control"
                                        maxlength="400"
                                        rows="3"
                                        disabled={true}
                                        value={values.ReceivingNote}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ReceivingNote",
                                            e.target.value
                                          );
                                        }}
                                      ></textarea>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl col-lg col-md-5 border-right">
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      MABD
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="Mabd"
                                        id="Mabd"
                                        label="Mabd"
                                        disabled={true}
                                        value={String(
                                          moment(values.Mabd).format(
                                            "YYYY-MM-DD"
                                          )
                                        )}
                                        onChange={(e) => {
                                          setFieldValue("Mabd", e.target.value);
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      DEL #
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control mt-4"
                                        name="DestinationNumber"
                                        id="DestinationNumber"
                                        disabled={true}
                                        value={values.DestinationNumber}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "DestinationNumber",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Special_Req")}
                                    </a>
                                    <span className="font-weight-bold">
                                      <Select
                                        isMulti
                                        className="mt-4"
                                        name="ReceivingSpecialReq"
                                        isDisabled={true}
                                        id="ReceivingSpecialReq"
                                        value={ViewModelOptions?.SpecialReq?.filter(
                                          function(el) {
                                            return (
                                              values?.ReceivingSpecialReq?.indexOf(
                                                el.Name
                                              ) >= 0
                                            );
                                          }
                                        )?.map((a) => {
                                          return {
                                            value: a.Name,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.SpecialReq?.map(
                                          (a) => {
                                            return {
                                              value: a.Name,
                                              label: a.Name,
                                            };
                                          }
                                        )}
                                        onChange={(newValue) => {
                                          if (newValue !== null) {
                                            setFieldValue(
                                              "ReceivingSpecialReq",
                                              newValue.map((a) => a.value)
                                            );
                                          } else {
                                            setFieldValue(
                                              "ReceivingSpecialReq",
                                              []
                                            );
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Del_Appointment_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="DelAppointmentDate"
                                        id="DelAppointmentDate"
                                        disabled={true}
                                        label="Ship"
                                        value={String(
                                          moment(
                                            values.DelAppointmentDate
                                          ).format("YYYY-MM-DD")
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "DelAppointmentDate",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className=" flex-wrap align-items-center pb-5">
                                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                    >
                                      {t("Del_Appointment_To_Date")}
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="date"
                                        className="form-control mt-4"
                                        name="PuAppointmentDateTo"
                                        id="PuAppointmentDateTo"
                                        disabled={true}
                                        label="Ship"
                                        value={String(
                                          moment(
                                            values.PuAppointmentDateTo
                                          ).format("YYYY-MM-DD")
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "PuAppointmentDateTo",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        {product.id !== undefined && (
                          <>
                            <LoadItems
                              values={values}
                              setFieldValue={setFieldValue}
                              id={product.id}
                              product={product}
                              ViewModelOptions={ViewModelOptions}
                              getShipmentData={getShipmentData}
                              LoadStopsList={LoadStopsList}
                              IsDetailsMode={true}
                            />
                            <AttachementComp
                              id={product.id}
                              IsDetailsMode={true}
                            />
                            <NotesComp id={product.id} IsDetailsMode={true} />
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>

              <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="layout-tooltip">{t("Back")}</Tooltip>}
                >
                  <li className="nav-item mb-2" data-placement="left">
                    <button
                      onClick={backToProductsList}
                      type="button"
                      className="btn btn-sm btn-icon btn-bg-light"
                    >
                      <i className="fa fa-arrow-left"></i>
                    </button>
                  </li>
                </OverlayTrigger>

                {product.id !== undefined && (
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="documentations-tooltip">{t("Activities")}</Tooltip>
                    }
                  >
                    <li className="nav-item mb-2" data-placement="left">
                      <button
                        className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
                        onClick={() => {
                          setshowModalActivities(true);
                        }}
                      >
                        <i className="fas fa-history text-warning"></i>
                      </button>
                    </li>
                  </OverlayTrigger>
                )}
              </ul>
              <Modal
                size="xl"
                show={showModalAddresses}
                onHide={() => {
                  setshowModalAddresses(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{t("Add_Address")}:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="pb-5 col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          {t("Name")}<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Name}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Name = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Name"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("Address")}<span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Address1}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Address1 = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Address1"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Address")} 2</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Address2}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Address2 = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Address2"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("Country")}<span className="text-danger">*</span>
                        </label>

                        <Select
                          required
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Country = e?.value;
                            setinitAddressObject(obj);
                          }}
                          name="Country"
                          isSearchable={true}
                          isClearable
                          placeholder={"Select Country"}
                          options={ContriesViewModel.map((a) => {
                            return { label: a.Name, value: a.Name };
                          })}
                          value={ContriesViewModel.filter(
                            (a) => a.Name === initAddressObject?.Country
                          ).map((a) => {
                            return { label: a.Name, value: a.Name };
                          })}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("State")}<span className="text-danger">*</span>
                        </label>
                        <Select
                          required
                          isSearchable={true}
                          isClearable
                          placeholder={"Select State"}
                          options={ContriesViewModel?.find(
                            (a) => a.Name === initAddressObject?.Country
                          )?.States?.map((a) => {
                            return { label: a.Name, value: a.Name };
                          })}
                          value={ContriesViewModel?.find(
                            (a) => a.Name === initAddressObject?.Country
                          )
                            ?.States?.filter(
                              (a) => a.Name === initAddressObject?.State
                            )
                            ?.map((a) => {
                              return { label: a.Name, value: a.Name };
                            })}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.State = e?.value;
                            setinitAddressObject(obj);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("City")}<span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.City}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.City = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="City"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>
                          {t("Zip_code")}<span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.Zipcode}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.Zipcode = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="Zipcode"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_First_Name")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactFirstName}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactFirstName = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactFirstName"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Last_Name")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactLastName}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactLastName = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactLastName"
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Email")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactEmail}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactEmail = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactEmail"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Phone")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactPhone}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactPhone = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactPhone"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Phone")} 2</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactPhone2}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactPhone2 = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactPhone2"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>{t("Contact_Fax")}</label>
                        <input
                          type="text"
                          className="form-control  form-control-lg"
                          value={initAddressObject.ContactFax}
                          onChange={(e) => {
                            let obj = { ...initAddressObject };
                            obj.ContactFax = e.target.value;
                            setinitAddressObject(obj);
                          }}
                          name="ContactFax"
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setshowModalAddresses(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={isLoadingAuth}
                    onClick={() => {
                      var myHeaders = new Headers();
                      myHeaders.append(
                        "Authorization",
                        `Bearer ${db.read().getState()?.Token}`
                      );

                      myHeaders.append("Content-Type", "application/json");

                      var raw = JSON.stringify(initAddressObject);

                      setisLoadingAuth(true);

                      return fetch(
                        `${window.$apiurl}/api/Address`,
                        {
                          method: "post",
                          headers: myHeaders,
                          body: raw,
                        }
                      )
                        .then(async (response) => {
                          const statusCode = await response.status;
                          const contentType = response.headers.get(
                            "content-type"
                          );
                          if (
                            contentType &&
                            contentType.indexOf("application/json") !== -1
                          ) {
                            return response
                              .json()
                              .then((data) => [statusCode, data]);
                          } else {
                            return response
                              .text()
                              .then((text) => [statusCode, { Message: "" }]);
                          }
                        })
                        .then((result) => {
                          if (result[0] === 200) {
                            getViewModel();
                            if (initAddressObject.IsShipper) {
                              setFieldValue("PickupId", result[1]?.Address?.Id);
                              setFieldValue(
                                "PickupName",
                                result[1]?.Address?.Name
                              );
                              setFieldValue(
                                "ShippingAddress1",
                                initAddressObject.Address1
                              );
                              setFieldValue(
                                "ShippingAddress2",
                                initAddressObject.Address2
                              );
                              setFieldValue(
                                "ShippingCity",
                                initAddressObject.City
                              );
                              setFieldValue(
                                "ShippingCountry",
                                initAddressObject.Country
                              );
                              setFieldValue(
                                "ShippingState",
                                initAddressObject.State
                              );
                              setFieldValue(
                                "ShippingZipCode",
                                initAddressObject.Zipcode
                              );
                              setFieldValue(
                                "ShippingContactName",
                                initAddressObject.ContactFirstName +
                                  " " +
                                  initAddressObject.ContactLastName
                              );
                              setFieldValue(
                                "ShippingContactPhone",
                                initAddressObject.ContactPhone
                              );
                              setFieldValue(
                                "ShippingContactPhone2",
                                initAddressObject.ContactPhone2
                              );
                              setFieldValue(
                                "ShippingContactFax",
                                initAddressObject.ContactFax
                              );
                              setFieldValue(
                                "ShippingContactEmail",
                                initAddressObject.ContactEmail
                              );
                            } else {
                              setFieldValue(
                                "DestinationId",
                                result[1]?.Address?.Id
                              );
                              setFieldValue(
                                "DestinationName",
                                result[1]?.Address?.Name
                              );
                              setFieldValue(
                                "ReceivingAddress1",
                                initAddressObject.Address1
                              );
                              setFieldValue(
                                "ReceivingAddress2",
                                initAddressObject.Address2
                              );
                              setFieldValue(
                                "ReceivingCountry",
                                initAddressObject.Country
                              );
                              setFieldValue(
                                "ReceivingCity",
                                initAddressObject.City
                              );
                              setFieldValue(
                                "ReceivingState",
                                initAddressObject.State
                              );
                              setFieldValue(
                                "ReceivingZipCode",
                                initAddressObject.Zipcode
                              );
                              setFieldValue(
                                "ReceivingContactName",
                                initAddressObject.ContactFirstName +
                                  " " +
                                  initAddressObject.ContactLastName
                              );
                              setFieldValue(
                                "ReceivingContactPhone",
                                initAddressObject.ContactPhone
                              );
                              setFieldValue(
                                "ReceivingContactPhone2",
                                initAddressObject.ContactPhone2
                              );
                              setFieldValue(
                                "ReceivingContactFax",
                                initAddressObject.ContactFax
                              );
                              setFieldValue(
                                "ReceivingContactEmail",
                                initAddressObject.ContactEmail
                              );
                            }
                            setshowModalAddresses(false);
                          } else {
                            if (
                              result[1].ModelState !== null &&
                              result[1].ModelState !== undefined &&
                              result[1].ModelState !== ""
                            ) {
                              let modelState = result[1].ModelState;
                              if (modelState)
                                Object.keys(modelState).forEach(function(k) {
                                  modelState[k].forEach((element) => {
                                    Swal.fire(t("Error"), translateAuto(element), "error");
                                  });
                                });
                            } else if (
                              result[1].Message !== null &&
                              result[1].Message !== undefined &&
                              result[1].Message !== ""
                            ) {
                              Swal.fire(t("Error"), translateAuto(result[1].Message));
                            } else if (
                              result[1].error_description !== null &&
                              result[1].error_description !== undefined &&
                              result[1].error_description !== ""
                            ) {
                              Swal.fire(t("Error"), translateAuto(result[1].error_description));
                            } else {
                              Swal.fire(t("Error"), t("Bad_Request"));
                            }
                          }
                          setisLoadingAuth(false);
                        })
                        .catch((error) => {
                          Swal.fire(t("Error"), error);
                          setisLoadingAuth(false);
                        });
                    }}
                  >
                    {t("Confirm")}
                    {isLoadingAuth && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="xl"
                show={showModalActivities}
                onHide={() => {
                  setshowModalActivities(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{t("Activities")}: {product?.Code}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ActivityFunction Activitys={ActivitiesList} />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setshowModalActivities(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
}
