import React from "react";
import preval from 'preval.macro'
import moment from "moment";
//const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`


export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("MM-DD-YYYY");
  return buildDate;
};

export function FooterCompact({
  today,
  footerClasses,
  footerContainerClasses,
}) {
  return (
    <>
      {/* begin::Footer */}
      <div
        className={`footer bg-white py-4 d-flex flex-lg-column  ${footerClasses}`}
        id="kt_footer"
      >
        {/* begin::Container */}
        <div
          className={`${footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          {/* begin::Copyright */}
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">
              {today} &copy;
            </span>
            {` `}
            <a
              href="https://empowerdatalogistics.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="text-dark-75 text-hover-primary"
            >
              eMPower
            </a>{" "}
            <span className="text-muted mr-2">
              Build Version{" "}
              <a
                href={() => false}
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-bolder text-muted"
              >
                {window.$appVersion}
              </a>{" "}
              / Build Date: <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>.
            </span>
          </div>
          {/* end::Copyright */}
          {` `}
          {/* begin::Nav */}
        
          {/* end::Nav */}
        </div>
        {/* end::Container */}
      </div>
      {/* end::Footer */}
    </>
  );
}
