import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
function TermsConditionsPage(props) {
  return (
    <div
      className="login login-3 login-signin-on d-flex flex-row-fluid"
      style={{ display: "block" }}
    >
      <div
        className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
        style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
      >
        <div className="col-xl-8 col-lg-10 col-md-12 col-12  p-7 position-relative overflow-hidden">
          <div className="d-flex flex-center mb-15">
            <Link to="/auth/login">
              <SVG src={toAbsoluteUrl("/media/svg/logos/TaapLogo.svg")} />
            </Link>
          </div>
          <div className="col-12 text-center mb-6">
            <h1 className="font-weight-bolder tet-dark-75">
              Terms and Conditions
            </h1>
          </div>
          <div className="card border-0 shadow-none">
            <div className="card-body">
              <p>
                <h3 className="mt-3">
                  TaaP® SOLUTIONS, LLC TERMS &amp; CONDITIONS
                </h3>
                The following terms and conditions (“Terms &amp; Conditions”)
                govern your use of the TaaP Solutions, LLC (“TaaP”) service
                which may consist of web sites, mobile applications, installed
                applications, and service offerings (“Service”). By accessing,
                viewing, or using the content, material, or services available
                on or through this Service, you (“You”) indicate that you have
                read and understand the Terms &amp; Conditions herein and as
                they may change from time to time, and that you agree to them
                and intend to be legally bound by them. “You” refers to the
                individual using the TaaP Service and if You use the Service
                on behalf of a corporation, LLC, partnership, or other business
                entity, then You shall include that business entity and any
                individuals associated therewith using our Service. Once You
                accept these Terms &amp; Conditions, You hereby acknowledge and
                agree that at anytime, and at its sole discretion, TaaP may
                modify the Terms &amp; Conditions by posting the modified Terms
                &amp; Conditions on the Service, accessible via a link entitled
                TaaP Terms &amp; Conditions associated with this agreement
                and/or Site or any successor site explicitly designated. If You
                do not agree to these Terms &amp; Conditions, You are not
                granted permission to use this Service.
              </p>
              <ol>
                <ol>
                  <li>
                    <h3 className="mt-3">APPROPRIATE USE.</h3>
                    You acknowledge that our service is a neutral venue where
                    customers may meet; as such we have no control over the
                    quality, safety, or legal aspects of the transactions that
                    may take place. You certify:
                    <ol>
                      <li>
                        For TaaP freight match services:
                        <ol>
                          <li>
                            You are a bona fide TaaP, freight broker, 3PL,
                            freight forwarder, intermodal or rail company or
                            motor carrier of a legal age to operate and to enter
                            into an agreement of this nature.
                          </li>
                          <li>
                            You shall maintain appropriate authority and will
                            cease immediately to use the TaaP service if for
                            any reason You no longer maintain such authority.
                            <ol>
                              <li>
                                You will not represent yourselves as operating
                                under the authority of any company without
                                express permission from such company.
                              </li>
                              <li>
                                You will not attempt to broker freight without
                                proper legal authority.
                              </li>
                              <li>
                                You will not enter into any transaction to
                                transport freight without the appropriate
                                carrier authority. You must be authorized as an
                                interstate carrier to use TaaP Service(s) in
                                any way that involves interstate transport. If
                                you are an intrastate carrier only, you are
                                strictly prohibited from the use of TaaP
                                Service(s) other than for intrastate purposes.
                              </li>
                              <li>
                                You will not enter into any transaction to
                                transport freight without the appropriate level
                                of insurance coverage or bond.
                              </li>
                              <li>
                                You will not enter into a transaction to
                                transport freight outside the geographic bounds
                                of your carrier authority.
                              </li>
                              <li>
                                You will not enter into a transaction to
                                transport commodities You are not authorized to
                                transport.
                              </li>
                              <li>
                                You will not enter into any transaction to
                                transport freight on equipment that fails to
                                meet any applicable Federal or State
                                regulations.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li>
                        That your use of the TaaP Service is solely for your
                        commercial purposes related to your movement of freight
                        or other services offered by TaaP on the Service and
                        that You shall not reproduce, republish, resell, or
                        distribute such information in any format, in whole or
                        in part, for sale or commercial use by third parties.
                      </li>
                      <li>
                        Your access or use of the Service is not for the purpose
                        of competing with TaaP with respect to the services
                        offered on the Service. You agree any violation shall
                        create irreparable harm.
                      </li>
                      <li>
                        That without prior written permission of TaaP, You
                        will not allow non-registered users access to the
                        TaaP Service and will never provide your password to
                        any non-registered user, nor will You share any
                        information from the Service with any non-authorized
                        users. It is a violation of these Terms &amp; Conditions
                        to share your login.
                      </li>
                      <li>
                        You shall not resell or assign your rights or
                        obligations under these Terms &amp; Conditions.
                      </li>
                      <li>
                        You agree that any violation of the above warranties may
                        result in (1) immediate termination of your registration
                        and access to the Service, (2) enforcement by TaaP
                        availing itself of any other legal remedy under state
                        and/or federal law. If a legal remedy is sought by
                        TaaP, You shall be responsible for legal costs,
                        including without limitation, reasonable attorney fees.
                      </li>
                      <li>
                        You shall conduct your business in an ethical manner and
                        shall not engage in any illegal, deceptive, misleading
                        or fraudulent practice.
                      </li>
                      <li>
                        Use of any import/export capability to transfer TaaP
                        information from your computer system shall be
                        restricted to one or more identified computers located
                        at the address(es) noted on your service agreement, and
                        shall not be distributed to any other location(s).
                        Unless otherwise stated, all information downloaded or
                        exported from TaaP services is intended for use by
                        You or the employee performing the download and shall
                        not be distributed to any other users or locations.
                      </li>
                      <li>
                        All seats provided under your office subscription are
                        for use by your employees or agents located at the
                        physical address listed on your subscription agreement.
                        If desired, your employees may download a second copy of
                        the application on a home computer for temporary or
                        occasional use for company business. Your subscription
                        does not cover your employees or agents that are
                        employed at a different location from the one listed on
                        your subscription agreement. A separate subscription is
                        required for each business location used by your agents
                        and employees.
                      </li>
                      <li>
                        Job aggregators are not allowed to post jobs on the
                        TaaP Service and You agree such access may be
                        terminated at any time.
                      </li>
                    </ol>
                  </li>
                </ol>
              </ol>
              <ol>
                <li>
                  <h3 className="mt-3">TaaP DISCLAIMER.</h3>
                  TaaP presents information in many ways; most often on our
                  websites; always as a service to You. Our goal is to provide
                  the most accurate information available in our complex and
                  constantly changing transportation marketplace. While we
                  endeavor to be as accurate and timely as possible, we make no
                  warranty or guarantee concerning accuracy, reliability,
                  completeness, or suitability, and provide all information AS
                  IS. Use of our Service is at your own risk. TaaP does not
                  make safety determinations; we report safety data using
                  government data including, but not limited to FMCSA records.
                  The TaaP CarrierWatch® product is aggregate content TaaP
                  receives from government and commercial sources customarily
                  determined to be reliable. TaaP endeavors to keep this
                  information as updated as possible, however, You must make
                  your own determination as to safety, authority and/or business
                  practices. TaaP provides a venue for brokers, carriers and
                  TaaPs to meet in order to offer sell and buy services.
                  TaaP is not involved in the actual transaction between
                  buyer and seller. While we may help facilitate, we have no
                  control over and do not guarantee safety or legality. It is
                  your sole responsibility, as a user of the Service, to check
                  the credentials, including but not limited to the
                  safety/authority record, of any party introduced to You by our
                  service and ascertain that such party will meet your
                  transportation needs.
                </li>
                <li>
                  <h3 className="mt-3">
                    CHANGE TO TERMS &amp; CONDITIONS/SERVICE &amp;
                    DISCONTINUANCE.
                  </h3>
                  TaaP may, from time to time, at our sole discretion, make
                  changes to certain of the Terms &amp; Conditions. Notification
                  of any changes will be highlighted on the TaaP Service
                  associated with this agreement or Site, accessible via a link
                  entitled TaaP Terms &amp; Conditions, in advance of any
                  such change. Your continued use of the Service after any
                  changes shall constitute your agreement. TaaP reserves the
                  right (1) to modify, discontinue or suspend any aspect of our
                  Service or site at anytime, and (2) to impose
                  limitations/restriction or restrict access to our Service
                  without notice or liability.
                </li>
                <li>
                  <h3 className="mt-3">CONFIDENTIALITY/NON-DISCLOSURE.</h3>
                  TaaP may disclose to You, or You may otherwise learn of or
                  discover, our documents, business practices, object code,
                  source code, management styles, day-to-day business
                  operations, capabilities, systems, current and future
                  strategies, marketing information, financial information,
                  software technologies, processes, procedures, methods and
                  applications, or other aspects of our business (“Our
                  Information”). You hereby agree and acknowledge that any and
                  all of Our Information is confidential and shall be our sole
                  and exclusive intellectual property and proprietary
                  information. You agree to use Our Information only for the
                  specific purposes as allowed by these Terms &amp; Conditions.
                  Any disclosure of Our Information to a third party,
                  specifically including a direct competitor, is strictly
                  prohibited and will be vigorously challenged in a court of
                  law. All obligations contained herein shall survive the
                  termination of this agreement. Furthermore,You acknowledge
                  that Our Information is proprietary, confidential and
                  extremely valuable to us, and that we would be materially
                  damaged by your disclosure of Our Information. You acknowledge
                  and agree that monetary damages provide an insufficient remedy
                  for the breach of this confidentiality obligation, and that we
                  shall be entitled to injunctive relief.
                </li>
                <li>
                  <h3 className="mt-3">PROPRIETARY RIGHTS.</h3>
                  Information provided by TaaP or its third party information
                  providers is protected by federal copyright law, and is
                  proprietary to TaaP and/or its third party information
                  providers.
                  <h3 className="mt-3">Copyright.</h3>&nbsp;The protected
                  information found on the TaaP Service includes, but is not
                  limited to, the design, layout, look, appearance and graphics.
                  Reproduction is prohibited unless permission is granted in
                  writing by TaaP. TaaP owns, solely and exclusively, all
                  rights, title and interest in and to the TaaP Service, all
                  the content (including, for example, audio, photographs,
                  illustrations, graphics, pictures, drawings, sketches, other
                  visuals, video, copy, recordings, software, artwork, images,
                  text forms, etc.), code, data and materials thereon, the look
                  and feel, design and organization of the TaaP Service, and
                  the compilation of the content, code, data and materials on
                  such Service, including but not limited to any copyrights,
                  trademark rights, patent rights, database rights, moral
                  rights, and other intellectual property and proprietary rights
                  therein. Your use of the TaaP Service does not grant to You
                  ownership of any content, code, data or materials You may
                  access on these Services.
                  <h3 className="mt-3">Trademarks.</h3>&nbsp;All trademarks,
                  logos, service marks and trade names displayed on TaaP’s
                  Service are registered to TaaP Solutions, LLC and may not
                  be used unless authorized by TaaP. Those that are not the
                  property of, or licensed to TaaP Solutions, LLC, are
                  acknowledged on the Service. Nothing contained on any TaaP
                  Service should be construed as granting, by implication, any
                  license or right to use any trademark without our written
                  permission or that of the third party rights holder. Your
                  misuse of any trademark is strictly prohibited. If You would
                  like to license the use of any of our trademarks or have
                  questions regarding trademarks, please&nbsp;
                  <a href="/auth/login">contact us</a>.
                </li>
                <li>
                  <h3 className="mt-3">YOUR ACCOUNT.</h3>
                  To access our Service You must register. You agree to provide
                  true, accurate and complete information as prompted by the
                  registration form and all forms You access in our site or
                  receive directly from a TaaP representative, and You agree
                  to update this information to maintain its truthfulness,
                  accuracy, and completeness.&nbsp; By subscribing to any
                  TaaP Service, You authorize TaaP to include data about
                  You in the TaaP Directory. This includes publicly available
                  data about your company, such as your DOT profile information,
                  as well as data You have provided TaaP about your company
                  and operations.&nbsp;All TaaP Directory information may be
                  viewed by active TaaP subscribers.
                </li>
                <li>
                  <h3 className="mt-3">
                    EXPORT RESTRICTIONS &amp; COMPLIANCE WITH LAWS
                  </h3>
                  . This Service may refer to some services or programs that are
                  not available worldwide without specifically identifying that
                  the offers are geographically limited. Our reference to such
                  services or programs does not imply that TaaP intends to
                  offer such services or programs in all countries or locations.
                  You may not access, download, or use the Service or any
                  material provided on our site in violation of U.S. export laws
                  or regulations. You and TaaP agree to comply fully with all
                  applicable laws, rules, or regulations, domestic or foreign,
                  including but not limited to the laws and regulations
                  concerning import and export of goods, the Foreign Corrupt
                  Practices Act and other laws prohibiting bribery,
                  nondiscrimination, forced or involuntary labor, and equal
                  opportunity in employment.
                </li>
                <li>
                  <h3 className="mt-3">OTHER SITES.</h3>
                  This Service may, from time to time, contain links to other
                  Internet websites for the convenience of users in locating
                  information, products, or services that may be of interest.
                  These sites and any other sites operated or maintained by
                  third parties are operated or maintained by organizations over
                  which TaaP exercises no control, and TaaP expressly
                  disclaims any and all responsibility for the content,
                  information, links, and other items, the accuracy and
                  completeness of the information, and the quality of products
                  or services made available or advertised on these third-party
                  sites. TaaP does not control, endorse, promote, or have any
                  affiliation with any other website unless expressly stated
                  herein.
                </li>
                <li>
                  <h3 className="mt-3">MOBILE SERVICES.</h3>
                  This Service may provide certain services that are available
                  to You via your mobile phone or other mobile device if You
                  have downloaded an application or subscribed to them,
                  including the ability to use your mobile device to receive and
                  reply to messages from TaaP and access certain other
                  features (collectively, the “Mobile Services”). Your mobile
                  carrier’s normal messaging, data, and other rates and fees may
                  apply to your use of the Mobile Services. You acknowledge that
                  use of Mobile Services, including location information, is
                  subject to network capabilities, environmental conditions such
                  as structures, buildings, weather, geography, landscape, and
                  topography, available data, atmospheric conditions and other
                  factors. Use may be limited to mobile devices located in the
                  United States. In addition, downloading, installing, or using
                  certain Mobile Services may be prohibited or restricted by
                  your mobile carrier, and not all Mobile Services may work with
                  all carriers or devices. Therefore, You are responsible for
                  checking with your mobile carrier to determine if the Mobile
                  Services are available for your mobile devices, what
                  restrictions, if any, may be applicable to your use of the
                  Mobile Services and how much they will cost You. By using the
                  Mobile Services, You agree that TaaP may communicate with
                  You by SMS, MMS or other electronic means to your mobile
                  device and that certain information about your usage of the
                  Mobile Services may be communicated to TaaP. In the event
                  You change or deactivate your mobile telephone number, You
                  agree to promptly update your mobile subscription account
                  information with us to ensure that the messages TaaP
                  intends to send to You are not sent to another entity who
                  acquires such mobile telephone number. Due to coverage
                  limitations, TaaP location information or data received
                  through Mobile Services should not be used for or relied on
                  for emergency location or safety purposes. NOTHING CONTAINED
                  IN THESE TERMS AND CONDITIONS WILL CONSTITUTE OR BE CONSTRUED
                  AS ANY REPRESENTATION OR WARRANTY BY TaaP THAT THE MOBILE
                  SERVICES, INCLUDING WITHOUT LIMITATION, LOCATION INFORMATION
                  (A) WILL BE AVAILABLE, UNINTERRUPTED, TIMELY OR ERROR-FREE;
                  (B) WILL MEET YOUR REQUIREMENTS; OR (C) WILL INCLUDE DATA THAT
                  IS ACCURATE, COMPLETE OR RELIABLE.
                </li>
                <li>
                  <h3 className="mt-3">INTERACTIONS WITH THE SERVICE.</h3>
                  By interacting with the Service, transmitting any&nbsp;
                  information, material, suggestions, or other content
                  (collectively, “User Content”) to TaaP, You automatically
                  grant TaaP the royalty-free, perpetual, irrevocable,
                  non-exclusive right and license to use, reproduce, modify,
                  adapt, publish, translate, create derivative works from,
                  distribute, redistribute, transmit, perform and display such
                  User Content (in whole or part) throughout the universe and/or
                  to incorporate it in other works in any form, media, or
                  technology now known or later developed for the full term of
                  any rights that may exist in such User Content. By interacting
                  with the Service or transferring information You agree that
                  TaaP, its affiliates, and assigns are licensed to use the
                  information in a manner we deem reasonable in our sole and
                  independent judgment. &nbsp;Further, TaaP is free to use
                  any ideas, concepts, know-how, techniques, and suggestions
                  contained in any communications You send to this Service for
                  any purpose whatever, including, but not limited to, creating
                  and marketing products or services using such information.
                  TaaP welcomes your feedback and suggestions about how to
                  improve our Service.
                </li>
                <li>
                  <h3 className="mt-3">SYSTEM REQUIREMENTS.</h3>
                  You agree to abide by recommended system requirements,
                  including amendments and upgrades thereto as published by
                  TaaP from time to time, and agree that TaaP's Service
                  shall not be installed or used on any computer system that
                  does not meet minimum requirements.
                </li>
                <li>
                  <h3 className="mt-3">DRIVER SAFETY</h3>
                  You agree to assume full responsibility to drive safely,
                  observe all traffic rules/laws and use your own personal best
                  judgment while driving. You agree that You will not enter or
                  change information or otherwise interact with the Service
                  while driving and You waive any claims against TaaP that
                  may arise out of any accidents or damages resulting from use
                  of the Service in violation of the foregoing.
                </li>
                <li>
                  <h3 className="mt-3">
                    MIS-USE OF MEDIA POSTINGS, MONITORING, INDEMNIFICATION.
                  </h3>
                  Although TaaP may, from time to time, monitor or review
                  discussions, chats, blogs, forums, social media postings,
                  transmissions, bulletin boards, and the like on the Service,
                  TaaP is under no obligation to do so and assumes no
                  responsibility or liability arising from the content of any
                  such locations nor for any error, defamation, libel, slander,
                  omission, falsehood, obscenity, pornography, profanity,
                  danger, or inaccuracy contained in any information contained
                  within such locations on the Service. You are prohibited from
                  posting or transmitting any unlawful, threatening, libelous,
                  defamatory, obscene, scandalous, inflammatory, pornographic,
                  or profane material or any material that could constitute or
                  encourage conduct that could be considered a criminal offense,
                  give rise to civil liability, or otherwise violate any law.
                  TaaP will fully cooperate with any law enforcement
                  authorities or court order requesting or directing TaaP to
                  disclose the identity of anyone posting any such information
                  or materials. TaaP reserves the right to remove messages or
                  material posted by You, as a user of the Service, to message
                  boards or other areas, at its sole discretion. By submitting
                  messages and/or materials to the Service, You agree to
                  indemnify, defend and hold harmless TaaP from all damages,
                  costs and expenses, including reasonable attorneys’ fees and
                  costs arising out of all claims, challenges or actions,
                  including claims for infringement, libel and slander, related
                  to your submission.
                </li>
                <li>
                  <h3 className="mt-3">PUBLIC FORUMS.</h3>
                  TaaP may, from time to time, make messaging services, chat
                  services, bulletin boards, message boards, blogs, other forums
                  and other such services available on or through our websites.
                  In addition to any other rules or regulations that we may post
                  in connection with a particular service, You agree that You
                  shall not upload, post, transmit, distribute or otherwise
                  publish through any website or any service or feature made
                  available on or through our websites, any materials which (A)
                  restrict or inhibit any other user from using and enjoying the
                  websites or the websites’ services, (B) are fraudulent,
                  unlawful, threatening, abusive, harassing, libelous,
                  defamatory, obscene, vulgar, offensive, pornographic, profane,
                  sexually explicit or indecent, (C) constitute or encourage
                  conduct that would constitute a criminal offense, give rise to
                  civil liability or otherwise violate any local, state,
                  national or international law, (D) violate, plagiarize or
                  infringe the rights of third parties including, without
                  limitation, copyright, trademark, trade secret,
                  confidentiality, contract, patent, rights of privacy or
                  publicity or any other proprietary right, (E) contain a virus,
                  spyware, or other harmful component, (F) contain embedded
                  links, advertising, chain letters or pyramid schemes of any
                  kind, or (G) constitute or contain false or misleading
                  indications of origin, endorsement or statements of fact. You
                  further agree not to impersonate any other person or entity,
                  whether actual or fictitious, including anyone from TaaP.
                  You also may not offer to buy or sell any product or service
                  on or through your comments submitted to our forums. You alone
                  are responsible for the content and consequences of any of
                  your activities.
                </li>
                <li>
                  <h3 className="mt-3">PRIVACY STATEMENT.</h3>
                  TaaP respects your privacy and security. TaaP’s goal is
                  to provide You with a personalized Internet experience that
                  delivers the information, resources, and services that are
                  most relevant and helpful to You. In order to achieve this
                  goal, TaaP may collect information during your visits to
                  understand what differentiates You from other users. This
                  Privacy Statement incorporated and made a part hereof,
                  discloses the information gathering and dissemination
                  practices of TaaP and can be viewed at&nbsp;
                  <h3 className="mt-3">
                    <h3 className="mt-3">
                      <a href="/privacy-policy/" rel="noopener" target="_blank">
                        www.TaaP.com
                      </a>
                      .
                    </h3>
                  </h3>
                </li>
                <li>
                  <h3 className="mt-3">
                    DISCLAIMER OF WARRANTY AND LIABILITY.
                  </h3>
                  THIS SERVICE AND THE CONTENT ON AND MADE AVAILABLE HEREIN, AND
                  THE SERVICES AND PRODUCTS OFFERED IN CONNECTION THEREWITH ARE
                  MADE AVAILABLE ON AN “AS IS” BASIS ONLY. USE OF THIS SERVICE
                  IS ENTIRELY AT YOUR OWN RISK. YOU ACKNOWLEDGE THAT SUCH
                  INFORMATION AND MATERIALS MAY CONTAIN INACCURACIES OR ERRORS
                  AND WE EXPRESSLY EXCLUDE LIABILITY FOR ANY SUCH INACCURACIES
                  OR ERRORS TO THE FULLEST EXTENT PERMITTED BY LAW. TaaP
                  MAKES NO REPRESENTATIONS OR WARRANTIES, AND HEREBY DISCLAIMS
                  ALL REPRESENTATIONS AND WARRANTIES, WITH RESPECT TO THIS
                  SERVICE AND THE CONTENT ON AND MADE AVAILABLE THROUGH THIS
                  SERVICE, AND THE SERVICES AND PRODUCTS OFFERED IN CONNECTION
                  THEREWITH, EXPRESS AND IMPLIED, WRITTEN AND ORAL, ARISING FROM
                  COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, AND
                  OTHERWISE, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, ACCURACY, TITLE, QUALITY, SYSTEMS INTEGRATION, AND
                  NON-INFRINGEMENT. TaaP shall not be liable for any direct,
                  special, indirect, incidental, consequential, exemplary,
                  extra-contractual, or punitive damages of any kind whatsoever,
                  including, without limitation, lost revenues or lost profits,
                  which may or does result from the use of, access to, or
                  inability to use this Service, the content, or the products or
                  services connected therewith, regardless of legal theory,
                  whether or not any party had been advised of the possibility
                  or probability of such damages, and even if the remedies
                  otherwise available fail their essential purpose. You
                  acknowledge that TaaP's sole obligation and exclusive
                  responsibility in the event of material and continuing
                  non-conformity, defect or error in the Service shall be to
                  take reasonable corrective actions upon discovery of the
                  problem, and in no event shall TaaP and/or its third party
                  information provider’s cumulative liability under this
                  agreement exceed the total fees paid by You to TaaP during
                  the preceding 6 months. TaaP's liability is limited to the
                  fullest extent permitted by law.
                </li>
                <li>
                  <h3 className="mt-3">INDEMNIFICATION.</h3>
                  You agree to defend, indemnify, and hold harmless TaaP, its
                  contractors/subsidiaries/affiliated companies, and all of
                  their respective directors, officers, employees,
                  representatives, proprietors, partners, shareholders,
                  servants, principals, agents, predecessors, successors,
                  assigns, and attorneys from and against any and all suits,
                  actions, claims, proceedings, damages, settlements, judgments,
                  injuries, liabilities, losses, risks, costs, and expenses
                  (including without limitation attorneys’ fees and litigation
                  expenses) relating to or arising from this Service, your use
                  of this Service (or any derivatives of this Service offered to
                  You) or any use under your password whether or not authorized
                  by You, your fraud, violation of law, or willful misconduct,
                  and any breach by You of these Terms &amp; Conditions,
                  including, but not limited to, your use of our site,
                  uploading, emailing, posting, publishing, transmitting or
                  submitting any content related to our site, or any
                  misrepresentation, breach of warranty or certification made by
                  You.
                </li>
                <li>
                  <h3 className="mt-3">
                    FEES &amp; PAYMENTS/PURCHASING SERVICES.
                  </h3>
                  For Monthly Statement and ACH customers, TaaP will invoice
                  You on a monthly basis for all Services, and all amounts due
                  under such invoice shall be payable within twenty (20) days
                  after the date of invoice.&nbsp;You shall pay all fees at the
                  current rates in accordance with the current TaaP payment
                  policies, which may be modified from time to time at the sole
                  discretion of TaaP; customarily by written notice specified
                  on your invoice. You are responsible for all taxes. Certain
                  Services may require pre-paid fees and TaaP payment policy
                  may not apply to certain other Services (see TaaP ONBOARD
                  below). For credit card customers, You understand that your
                  credit card may be charged for the services selected
                  immediately upon receipt of your access passwords. Thereafter,
                  your credit card may be charged in advance for services
                  provided. Subscription price may be prorated for partial month
                  at start up. If any amount due TaaP is not paid as
                  specified, such amount will be subject to a finance charge,
                  equal to 1.5% of the unpaid balance per month (18% per annum)
                  or the highest amount allowable by law whichever is greater.
                  TaaP shall apply all payments on accounts first to finance
                  charges, and the remainder, if any, to the principal. TaaP
                  reserves the right to hold You responsible for reasonable
                  costs associated with collection, including but not limited to
                  collection agency fees. Payment is due while service is active
                  even if not used. You agree that any returned payment may be
                  subject to a returned payment fee. Prices are subject to
                  change upon notice. TaaP reserves the right to deny service
                  to anyone at its sole discretion and to cancel service upon
                  reasonable notice and/or reasonable attempts to notify.
                </li>
                <li>
                  <h3 className="mt-3">SECURITY DEPOSIT.</h3>
                  TaaP reserves the right to require a security deposit
                  payable in advance of initiating any service and retain such
                  deposit for a duration determined solely by TaaP.
                </li>
                <li>
                  <h3 className="mt-3">DISPUTES.</h3>
                  All billing disputes must be presented in writing to TaaP
                  within thirty (30) days of invoice date. Direct inquiries to
                  the Customer Financial Services Department, TaaP Solutions,
                  LLC, 8405 SW Nimbus Ave, Beaverton, Oregon 97008, or via FAX
                  at&nbsp;
                  <a
                    href="tel:(503)%20672-5108"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    503.672.5108
                  </a>
                  , or by e-mail to&nbsp;
                  <a href="mailto:support@empowerdl.com">
                    support@empowerdl.com
                  </a>
                  .
                </li>
                <li>
                  <h3 className="mt-3">TERMINATION POLICY.</h3>&nbsp;(As
                  Applicable.) TaaP may with or without notice, terminate
                  your access to this Service in the event You violate these
                  Terms &amp; Conditions or for any reason or for convenience.
                  TaaP may deny or cancel service immediately at its sole
                  discretion if complaints are received. If You have a
                  month-to-month contract, You may terminate your Service by
                  going to support@empowerdl.com to initiate the termination.
                  The termination will be effective on your next bill cycle date
                  prior to which time the Service will remain available to You.
                  For annual or multi-year agreements, the terms of your signed
                  contract shall govern. Upon termination, immediately
                  discontinue your use of the Service and destroy all materials
                  obtained from the Service. Payment obligations, as agreed to
                  by You in writing, in advance of termination, may survive
                  termination. In the event You terminate a contract early,
                  TaaP reserves the right to charge back multiple month term
                  contract sign-up discounts received or charge an early
                  termination fee as set forth in your signed contract.
                </li>
                <li>
                  <h3 className="mt-3">ADDITIONAL TERMS.</h3>
                  Certain features, programs, products or services may contain
                  separate terms and conditions, which are in addition to these
                  TaaP Terms &amp; Conditions. In the event of conflicting
                  provisions, the additional terms and conditions will govern.
                </li>
                <li>
                  <h3 className="mt-3">MISCELLANEOUS PROVISIONS</h3>. You accept
                  that TaaP has the right to change the content or technical
                  specifications of any aspect of the Service at any time, at
                  its sole discretion.
                </li>
              </ol>
              <p>
                These Terms &amp; Conditions and any updates hereto represent
                the entire agreement between You and TaaP with respect to the
                subject matter hereof, supersede any and all prior and
                contemporaneous written and oral representations,
                understandings, and agreements between us, and will be governed
                by and construed in accordance with the laws of the State of
                Delaware. The waiver or failure of TaaP to exercise in any
                respect any right provided hereunder shall not be deemed a
                waiver of such right in the future or a waiver of any other
                rights established under these Terms &amp; Conditions.In the
                event TaaP retains legal counsel to enforce this agreement it
                shall be entitled to receive attorney’s fees, including fees on
                appeal, whether or not suit or action is commenced. If any
                provision of the Terms &amp; Conditions is found by a court of
                competent jurisdiction to be invalid, the parties nevertheless
                agree that the court should endeavor to give effect to the
                parties’ intentions as reflected in the provision, and the other
                provisions of the Terms &amp; Conditions remain in full force
                and effect.
              </p>
              <p>
                <h3 className="mt-3">CONTACT US.</h3>
                If you wish to report a concern regarding these Terms &amp;
                Conditions, have any questions or need assistance, please
                contact TaaP via email at&nbsp;
                <a href="mailto:support@empowerdl.com">support@empowerdl.com</a>
                , or by telephone at 800-547-5417.
              </p>
              <p>
                <h3 className="mt-3">PRODUCT SPECIFIC TERMS</h3>
              </p>
              <p>
                <h3 className="mt-3">TaaP ONTIME MOBILE APPLICATION</h3>
                In addition to the terms above, You agree that by downloading
                the TaaP OnTime application (“TaaP OnTime”), TaaP will
                license to You use of TaaP OnTime for purposes of i)
                accepting tracking on a shipment, ii) viewing shipment addresses
                and appointment times and notes about the shipment, iii) marking
                shipments at risk, and iv) exchanging messages with the load
                provider. You expressly consent to and grant TaaP the right
                to collect and disclose the location of your mobile device and
                other related information (“Location Information”) for use in
                connection with the TaaP OnTime Service. You authorize
                TaaP to share Location Information with the load provider
                requesting the tracking that you accepted. In addition, TaaP
                reserves the right to utilize Location Information gathered
                through TaaP OnTime, as part of an aggregated data set, for
                any reasonable commercial use; however, this data does not
                include any of your personal identifying information. Upon
                termination, TaaP retains all rights to previously captured
                data.
              </p>
              <p>
                <h3 className="mt-3">RATEVIEW</h3>
                <h3 className="mt-3">
                  Confidentiality of TaaP Rate Information.
                </h3>
                &nbsp;You will not use TaaP rate information or other
                proprietary product information to develop a competitive lane
                rate product or provide TaaP rate or product/service
                information to any company considered by TaaP to be a
                competitor. You shall not attempt to mine or replicate the rate
                database in order to compete with TaaP. You may use bulk
                download capability for internal purposes. Customer may not
                replicate or resell data or files structures received from
                TaaP.&nbsp;Confidentiality requirements shall survive any
                termination of your agreement(s).
              </p>
              <p>
                <h3 className="mt-3">CONNEXION</h3>
                You must use a TaaP certified transportation management
                system in order to use TaaP Connexion. You agree to only use
                TaaP Connexion within the designed functionality of the
                certified transportation management system and not to reverse
                engineer TaaP Connexion, access TaaP Connexion outside of
                the certified transportation management system, or make any
                other use of or access to TaaP Connexion. It is solely your
                responsibility at all times to backup your data to be prepared
                to conduct your account without access to TaaP Connexion.
                TaaP DOES NOT REPRESENT OR WARRANT, AND SPECIFICALLY
                DISCLAIMS, THAT TaaP CONNEXION WILL BE AVAILABLE WITHOUT
                INTERRUPTION. However, TaaP shall use commercially reasonable
                efforts to make TaaP Connexion available to You during
                standard business hours.
              </p>
              <p>
                <h3 className="mt-3">TaaP ONBOARD</h3>
                TaaP OnBoard is a TaaP product; co-branded by the named
                Broker and TaaP. TaaP will not sell information provided
                by carrier in the TaaP OnBoard process and will not disclose
                any personal information, i.e. tax ID numbers, to any third
                party; however TaaP reserves the right to use information
                provided to (a) send You marketing communications that we
                believe may be of interest to You in accordance with your
                preferences; (b) maintain, improve, and/or enhance the TaaP
                Services; and (c) protect You. Non-personal information provided
                by the carrier using TaaP OnBoard, such as equipment,
                preferred lanes and insurance agent information may be shared
                within the TaaP network. TaaP OnBoard carriers are
                authorized to use such Service at no charge.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(TermsConditionsPage));
