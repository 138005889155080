/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import ActivateAccount from "./modules/Auth/pages/ActivateAccount";
import faq from "./modules/Auth/pages/Faq/faq";
import PrivacyPolicy from "./modules/Auth/pages/PrivacyPolicy/PrivacyPolicy";
import Registration from "./modules/Auth/pages/Registration";
import TermsConditions from "./modules/Auth/pages/Terms/Terms-Conditions";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import withTracker from "./withTracker";

export function Routes() {
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );
    if (
      window.location.href.includes("/auth/login?token=") &&
      !window.location.href.includes("&reLogin=true")
    ) {
      var token = window.location.search?.replace("?token=", "");
      if (!!token) {
        window.localStorage.clear();
        setTimeout(() => {
          window.location.href = "/auth/login?token=" + token + "&reLogin=true";
        }, 1500);
      }
    }
    // useEffect(() => console.log("sss", isAuthorized), [isAuthorized]);
    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}
            <Route path="/register/membership" component={withTracker(Registration)} />
            <Route path="/useractivationemail" component={ActivateAccount} />

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>   
            <Route path="/terms" component={withTracker(TermsConditions)}/>   
            <Route path="/faq" component={withTracker(faq)}/>
            <Route path="/privacy-policy" component={withTracker(PrivacyPolicy)}/>
            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
