import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

async function apiCall(props) {
  if (props?.isFormData) {
    return await fetch(`${window.$apiUrl}${props?.link}`, {
      method: props?.method,
      withCredentials: true,
      redirect: "follow",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
      },
      body: props?.body,
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    return await fetch(`${window.$apiUrl}${props?.link}`, {
      method: props?.method,
      withCredentials: true,
      redirect: "follow",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props?.body),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
function validateField(value) {
  if (value === undefined || value === null || value === "") return false;
  return true;
}
export { apiCall, validateField };
