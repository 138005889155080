import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Filter from "./Filter";
import BodyTable from "./table";
import { apiCall } from "./redux";
import { Helmet } from "react-helmet";
import { CardHeader } from "../../../../../_metronic/_partials/controls";
import { useTranslation } from "react-i18next";

const initValues = {
  CustomerName: "",
  ShipperZipCode: "",
  ShipperCity: "",
  ShipperState: "",
  ConsigneeZipCode: "",
  ConsigneeCity: "",
  ConsigneeState: "",
  ModeId: "",
  ShipperReq: "",
  ConsigneeReq: "",
  ShipmentReq: "",
};
function MyLoadsIndex() {
  const {t} = useTranslation();
  const [filter, setFilter] = useState({
    destination: "",
    status : "",
    isQuickPay : "",
    onlyAllowed : false,
    pickup : "",
    Id : "",
    equipmentType : "",
    pageNumber: 1,
    pageSize: 10,
    sortField: "Id",
    sortOrder: "desc",
  });
  const [paramsGet, setParamsGet] = useState(
    `?status=${filter?.status}&destination=${filter.destination}&onlyAllowed=${filter.onlyAllowed}&isQuickPay=${filter.isQuickPay}&pickup=${filter.pickup}&onlyAllowed=${filter.onlyAllowed}&isQuickPay=${filter.isQuickPay}&code=${filter.Id}&equipmentType[0]=${filter.equipmentType}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}&sortField=${filter.sortField}&sortOrder=${filter.sortOrder}`
  );
  useEffect(() => {
    fetchData(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [paginationOptions, setpaginationOptions] = useState({
    custom: true,
    totalSize: 0,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
    ],
    sizePerPage: 10,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [modalPost, setModalPost] = useState(false);
  const [dataModal, setDataModal] = useState(initValues);
  // functions *********************
  async function fetchData(params) {
    try {
      const result = await apiCall({
        method: "Get",
        link: `/api/MyLoads${params === null ? paramsGet : params}`,
      });
      if (result[0] === 200) {
        setData(result[1] ?? []);
        let _paginationOptions = JSON.parse(JSON.stringify(paginationOptions));
        _paginationOptions.totalSize =
          result[1]?.Pagination?.TotalItemCount || 0;
        _paginationOptions.page = result[1]?.Pagination?.PageNumber || 0;
        _paginationOptions.sizePerPage = result[1]?.Pagination?.PageSize || 10;
        setpaginationOptions(_paginationOptions);
      } else {
        setData([]);
      }
    } catch (err) {}
  }
  function fetchFilter(column, value, value2, paginationprop) {
    try {
      let dataFilter = { ...filter };
      if (column === "startDate") {
        dataFilter.startDate = value;
        dataFilter.endDate = value2;
      } else if (column === "reload") {
        //fetch params
        const paramGet1 = `?status=${dataFilter?.status}&destination=${dataFilter.destination}&onlyAllowed=${dataFilter.onlyAllowed}&isQuickPay=${dataFilter.isQuickPay}&pickup=${dataFilter.pickup}&code=${dataFilter.Id}&equipmentType[0]=${dataFilter.equipmentType}&pageNumber=${paginationprop.page}&pageSize=${paginationprop.sizePerPage}&sortField=${paginationprop.sortField}&sortOrder=${paginationprop.sortOrder}`;
        setParamsGet(paramGet1);
        setFilter(dataFilter);
        //call api
        fetchData(paramGet1);
        return false;
      } else {
        dataFilter[column] = value;
      }
      const paramGet1 = `?status=${dataFilter?.status}&destination=${dataFilter.destination}&onlyAllowed=${dataFilter.onlyAllowed}&isQuickPay=${dataFilter.isQuickPay}&pickup=${dataFilter.pickup}&code=${dataFilter.Id}&equipmentType[0]=${dataFilter.equipmentType}&pageSize=${paginationprop.sizePerPage}&sortField=${
        paginationprop.sortField
      }&sortOrder=${paginationprop.sortOrder}`;


      setParamsGet(paramGet1);
      setFilter(dataFilter);

      //call api
      fetchData(paramGet1);
    } catch (err) {}
  }
  // *******************************
  return (
    <>
    <Helmet>
      <title>TaaP | {t("My_Loads")}</title>
      <meta name="description" content="This is my meta description" />
    </Helmet>
    <Row>
      <Col xl="12" lg="12" md="12">
        <Row className="form-group">
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <h3 className="card-label">{t("My_Loads")}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Card.Body className="shipmentQuotes">
                <Row className="form-group">
                  <Col>
                    <Filter
                      fetchFilter={fetchFilter}
                      setDataModal={setDataModal}
                      dataFilter={filter}
                      setFilter={filter}
                      dataModal={dataModal}
                      data={data}
                      initValues={initValues}
                      modalPost={modalPost}
                      setModalPost={setModalPost}
                      paginationOptions={paginationOptions}
                      setpaginationOptions={setpaginationOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BodyTable
                      data={data ?? []}
                      dataFilter={filter}
                      setDataFilter={setFilter}
                      setDataModal={setDataModal}
                      setData={setData}
                      dataModal={dataModal}
                      modalPost={modalPost}
                      setModalPost={setModalPost}
                      fetchFilter={fetchFilter}
                      paginationOptions={paginationOptions}
                      setpaginationOptions={setpaginationOptions}
                      statusId={filter.statusId}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
  );
}

export default MyLoadsIndex;
