import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import LoadsIndex from "./modules/Auth/pages/Loads";
import MyLoadsIndex from "./modules/Auth/pages/MyLoads";
import { TrackLoadDetail } from "./modules/Auth/pages/MyLoads/Detail";
import { LoadDetail } from "./modules/Auth/pages/MyLoads/Detail/detailLoad";
import { ProductDetail } from "./modules/ECommerce/pages/products/product-detail/ProductDetail";
import { ProductEdit } from "./modules/ECommerce/pages/products/product-edit/ProductEdit";
import { DashboardPage } from "./pages/DashboardPage";
import { Trackload } from "./pages/Truckload";
import withTracker from "./withTracker";



const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const Search = lazy(() =>
  import("./modules/Search/Search")
);
const Chat = lazy(() =>
  import("./modules/Chat/Chat")
);


export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {!!window.$isProfile ? <Redirect exact from="/" to="/user-profile?billing=true" /> : <Redirect exact from="/" to="/dashboard" />}

        <ContentRoute path="/dashboard" component={withTracker(DashboardPage)} />
        <Route path="/book" component={withTracker(LoadsIndex)} />
        <Route path="/myLoads" component={withTracker(MyLoadsIndex)} />
        <Route path="/track-load/:id" component={withTracker(TrackLoadDetail)} />
        <Route path="/load-detail/:id" component={withTracker(LoadDetail)} />
        <Route path="/shipper" component={withTracker(ECommercePage)} />
        <Route path="/user-profile" component={withTracker(UserProfilepage)} />
        <Route path="/search" component={withTracker(Search)} />
        <Route path="/chat" component={withTracker(Chat)} />
        <Route path="/trackload/:id/details" component={withTracker(Trackload)} />
        <Route path="/taap/loads/new" component={withTracker(ProductEdit)} />
        <Route path="/taap/loads/:id/edit" component={withTracker(ProductEdit)} />
        <Route path="/taap/loads/:id/detail" component={withTracker(ProductDetail)} />
        {/* <Redirect to="error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}
