 /* eslint-disable react-hooks/exhaustive-deps */
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import Swal from "sweetalert2";
import CreatableSelect from "react-select/creatable";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { GoogleMap } from "../../_metronic/_partials/dashboards/GoogleMapComp";

import { Helmet } from "react-helmet";
import {
  ConvertToDateCurrentTimeZone,
  translateAuto,
  validateEmail,
} from "../../_metronic/_helpers/AtHelper";
import { useTranslation } from "react-i18next";
import { apiCall } from "../modules/Auth/pages/Loads/redux";
import { Link } from "react-router-dom";
const adapter = new LocalStorage("db");
const db = low(adapter);
const MINUTE_MS = 300000;

export const Trackload = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const {t} = useTranslation();
  const [ShowModalShare, setShowModalShare] = useState(false);
  const [ListOfEmailsToSahareLoads, setListOfEmailsToSahareLoads] = useState(
    []
  );
  const [LoadInformations, setLoadInformations] = useState(null);
  const [DetailTrakingGeometryInfo, setDetailTrakingGeometryInfo] = useState(
    []
  );

  function getLoadInfo(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/Tracking/${params}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setLoadInformations(data);
          setDetailTrakingGeometryInfo(data?.Polyline || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    if (id) {
      getLoadInfo(id);
    }
  }, [id]);

  useEffect(() => {
    const interval = setInterval(() => {
      getLoadInfo(id);
    }, MINUTE_MS);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  // const suhbeader = useSubheader();
  // suhbeader.setTitle(t("Track_Load"));
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  useEffect(() => {
    return () => {};
  }, [user]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("Shipper")} | {t("Track_a_load")}
        </title>
      </Helmet>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="3" className="pt-2">
                  <h2>Track load #{LoadInformations?.Code}</h2>
                </Col>
                <Col lg="9" style={{ textAlignLast: "right" }}>
                  <Link to="/book" className="btn btn-secondary mr-2">
                    <i className="flaticon2-left-arrow-1 pr-2"></i>Back
                  </Link>
                  {LoadInformations?.data?.BiddingEnabled &&
                    (LoadInformations?.data?.BidDetails !== null ? (
                      <button
                        className="btn btnPrimaryTaap mr-2"
                        onClick={(e) => {
                          // setModalBiding(true);
                          e.preventDefault();
                        }}
                      >
                        Update bid
                      </button>
                    ) : (
                      <button
                        className="btn btnPrimaryTaap mr-2"
                        onClick={(e) => {
                          // setModalBiding(true);
                          e.preventDefault();
                        }}
                      >
                        Add bid
                      </button>
                    ))}

                  <button
                    className="btn btnPrimaryTaap mr-2"
                    onClick={async (e) => {
                      try {
                        if (LoadInformations?.data?.IsQuickPay) {
                          Swal.fire({
                            title: t("this_load_is_available_for_quick_pay_do_you_want_to_opt_for_it"),
                            showDenyButton: true,
                            confirmButtonText: "With quick pay",
                            denyButtonText: "Without quick pay",
                            showCloseButton: true,
                            confirmButtonColor: "#13215f",
                            customClass: {
                              actions: "my-actions",
                              cancelButton: "order-1 right-gap",
                              confirmButton: "order-2",
                              denyButton: "order-3",
                            },
                          }).then(async (result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                              const result = await apiCall({
                                method: "POST",
                                link: `/api/shipments/accept/${LoadInformations?.data?.Id}`,
                                body: { QuickPayRequested: true },
                              });
                              if (
                                (result?.length > 0 && result[0] === 200) ||
                                result === undefined
                              ) {
                                Swal.fire({
                                  position: "center",
                                  icon: "success",
                                  title: `${t("Your_load")} (#${
                                    LoadInformations?.data?.Code
                                  }) ${t("with_Quick_Pay_has_been_accepted_successfully")}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                                //reload
                                history.push(
                                  "/track-load/" + LoadInformations?.data?.Id
                                );
                              } else {
                                if (
                                  result[1].ModelState !== null &&
                                  result[1].ModelState !== undefined &&
                                  result[1].ModelState !== ""
                                ) {
                                  let modelState = result[1].ModelState;
                                  if (modelState)
                                    Object.keys(modelState).forEach(function(
                                      k
                                    ) {
                                      modelState[k].forEach((element) => {
                                        Swal.fire(t("Error"), translateAuto(element), "error");
                                      });
                                    });
                                } else if (
                                  result[1].Message !== null &&
                                  result[1].Message !== undefined &&
                                  result[1].Message !== ""
                                ) {
                                  Swal.fire(
                                    t("Error"),
                                    translateAuto(result[1].Message),
                                    "error"
                                  );
                                } else if (
                                  result[1].error_description !== null &&
                                  result[1].error_description !== undefined &&
                                  result[1].error_description !== ""
                                ) {
                                  Swal.fire(
                                    t("Error"),
                                    translateAuto(result[1].error_description),
                                    "error"
                                  );
                                } else {
                                  Swal.fire(
                                    t("Error"),
                                    t("Bad_Request"),
                                    "error"
                                  );
                                }
                              }
                            } else if (result.isDenied) {
                              const result = await apiCall({
                                method: "POST",
                                link: `/api/shipments/accept/${LoadInformations?.data?.Id}`,
                                body: { QuickPayRequested: false },
                              });
                              if (
                                (result?.length > 0 && result[0] === 200) ||
                                result === undefined
                              ) {
                                Swal.fire({
                                  position: "center",
                                  icon: "success",
                                  title: `${t("Your_load")} (#${
                                    LoadInformations?.data?.Code
                                  }) ${t("has_been_accepted_succesfully")}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                                //reload
                                history.push(
                                  "/track-load/" + LoadInformations?.data?.Id
                                );
                              } else {
                                if (
                                  result[1].ModelState !== null &&
                                  result[1].ModelState !== undefined &&
                                  result[1].ModelState !== ""
                                ) {
                                  let modelState = result[1].ModelState;
                                  if (modelState)
                                    Object.keys(modelState).forEach(function(
                                      k
                                    ) {
                                      modelState[k].forEach((element) => {
                                        Swal.fire(t("Error"), translateAuto(element), "error");
                                      });
                                    });
                                } else if (
                                  result[1].Message !== null &&
                                  result[1].Message !== undefined &&
                                  result[1].Message !== ""
                                ) {
                                  Swal.fire(
                                    t("Error"),
                                    translateAuto(result[1].Message),
                                    "error"
                                  );
                                } else if (
                                  result[1].error_description !== null &&
                                  result[1].error_description !== undefined &&
                                  result[1].error_description !== ""
                                ) {
                                  Swal.fire(
                                    t("Error"),
                                    translateAuto(result[1].error_description),
                                    "error"
                                  );
                                } else {
                                  Swal.fire(
                                    t("Error"),
                                    t("Bad_Request"),
                                    "error"
                                  );
                                }
                              }
                            }
                          });
                        } else {
                          Swal.fire({
                            title: t("Accept_Load"),
                            text: `${t(
                              "Are_you_sure_you_want_to_accept_this_Load"
                            )} '${LoadInformations?.data?.Code}' ?`,
                            icon: "warning",
                            showCancelButton: true,
                            cancelButtonText: t("Cancel"),
                            confirmButtonColor: "#1BC5BD ",
                            cancelButtonColor: "##80808F",
                            confirmButtonText: t("Accept"),
                          }).then(async (res) => {
                            if (res.isConfirmed) {
                              const result = await apiCall({
                                method: "POST",
                                link: `/api/shipments/accept/${LoadInformations?.data?.Id}`,
                                body: { QuickPayRequested: false },
                              });
                              if (
                                (result?.length > 0 && result[0] === 200) ||
                                result === undefined
                              ) {
                                Swal.fire({
                                  position: "center",
                                  icon: "success",
                                  title: `${t("Your_load")} (#${
                                    LoadInformations?.data?.Code
                                  }) ${t("has_been_accepted_succesfully")}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                                //reload
                                history.push(
                                  "/track-load/" + LoadInformations?.data?.Id
                                );
                              } else {
                                if (
                                  result[1].ModelState !== null &&
                                  result[1].ModelState !== undefined &&
                                  result[1].ModelState !== ""
                                ) {
                                  let modelState = result[1].ModelState;
                                  if (modelState)
                                    Object.keys(modelState).forEach(function(
                                      k
                                    ) {
                                      modelState[k].forEach((element) => {
                                        Swal.fire(t("Error"), translateAuto(element), "error");
                                      });
                                    });
                                } else if (
                                  result[1].Message !== null &&
                                  result[1].Message !== undefined &&
                                  result[1].Message !== ""
                                ) {
                                  Swal.fire(
                                    t("Error"),
                                    translateAuto(result[1].Message),
                                    "error"
                                  );
                                } else if (
                                  result[1].error_description !== null &&
                                  result[1].error_description !== undefined &&
                                  result[1].error_description !== ""
                                ) {
                                  Swal.fire(
                                    t("Error"),
                                    translateAuto(result[1].error_description),
                                    "error"
                                  );
                                } else {
                                  Swal.fire(
                                    t("Error"),
                                    t("Bad_Request"),
                                    "error"
                                  );
                                }
                              }
                            }
                          });
                        }
                      } catch (err) {}
                    }}
                  >
                    {t("Accept_Load")}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
          <Card>
            <CardHeader title={t("Trip")}>
              <CardHeaderToolbar>
                <div className="d-flex flex-column flex-grow-1">
                  <h3 className="card-title align-items-start flex-wrap mt-5 mb-0">
                    <span className="font-weight-bold text-muted font-size-sm">
                      {t("Load_Number")}
                    </span>
                  </h3>
                  <h3 className="card-title align-items-start flex-wrap mt-0">
                    <span className="font-weight-bolder text-primary font-size-lg">
                      {LoadInformations?.Shipment?.Code}
                    </span>
                  </h3>
                </div>
                {LoadInformations?.Shipment?.Status !== "Delivered" &&
                LoadInformations?.Shipment?.Status !== undefined &&
                LoadInformations?.Shipment?.Status !== "Closed" ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">
                        {t("Share")}
                      </Tooltip>
                    }
                  >
                    <a
                      href={() => false()}
                      className="btn btn-icon btn-light btn-hover-success btn-sm mt-2 ml-2"
                      onClick={() => {}}
                    >
                      <span
                        className="svg-icon svg-icon-md"
                        onClick={() => {
                          setListOfEmailsToSahareLoads([]);
                          setShowModalShare(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          className="bi xl bi-share"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"></path>
                        </svg>
                      </span>
                    </a>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="12">
                  {/* <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <button className="btn btnPrimaryTaap mr-2">
                            Accept Load
                          </button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card> */}
                </Col>
              </Row>
              <div className="timeline-item align-items-center mb-1">
                <div className="timeline-line w-20px mt-9 mb-n14" />
                <div
                  className="timeline-icon pt-1"
                  style={{ marginLeft: "0.7px" }}
                >
                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Loads/Receiver.svg")}
                    ></SVG>
                  </span>
                  <span className="fs-8 font-weight-boldest text-primary text-uppercase">
                    {t("Sender")}
                  </span>
                </div>
                <div className="timeline-content mb-4">
                  <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0">
                    {LoadInformations?.Shipment?.ShippingCompany !==
                      undefined && LoadInformations?.Shipment?.ShippingCompany}
                  </p>
                  <span className="font-weight-bold text-gray-400">
                    {(LoadInformations?.Shipment?.ShippingAddress1 !==
                      undefined &&
                      LoadInformations?.Shipment?.ShippingAddress1 + ", ") +
                      (LoadInformations?.Shipment?.ShippingCity !== undefined &&
                        LoadInformations?.Shipment?.ShippingCity + ", ") +
                      (LoadInformations?.Shipment?.ShippingState !==
                        undefined &&
                        LoadInformations?.Shipment?.ShippingState + " ") +
                      (LoadInformations?.Shipment?.ShippingZipCode !==
                        undefined &&
                        LoadInformations?.Shipment?.ShippingZipCode)}
                  </span>
                </div>
              </div>
              {LoadInformations?.Stops?.map((item, index) => {
                return (
                  <div className="timeline-item align-items-center" key={index}>
                    <div className="timeline-line w-20px" />
                    <div
                      className="timeline-icon pt-1"
                      style={{ marginLeft: "0.5px" }}
                    >
                      <span className="svg-icon svg-icon-2 svg-icon-warning">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Loads/Receiver.svg"
                          )}
                        ></SVG>
                      </span>
                      <span className="text-warning">
                        <span className="fs-3 font-weight-boldest text-warning text-uppercase">
                          {item.Type}
                        </span>{" "}
                        {t("Stop")}
                      </span>
                    </div>
                    <div className="timeline-content m-0">
                      <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0">
                        {item?.Name}
                      </p>

                      <span className="font-weight-bold text-gray-400">
                        {(item?.Address1 !== null && item?.Address1 + ", ") +
                          (item?.City !== null && item?.City + ", ") +
                          (item?.State !== null && item?.State + " ") +
                          item?.Zipcode}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="timeline-item align-items-center mt-4">
                <div className="timeline-line w-20px" />
                <div
                  className="timeline-icon pt-1"
                  style={{ marginLeft: "0.5px" }}
                >
                  <span className="svg-icon svg-icon-2 svg-icon-info mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="fs-8 font-weight-boldest text-info text-uppercase">
                    {t("Receiver")}
                  </span>
                </div>
                <div className="timeline-content m-0">
                  <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0">
                    {LoadInformations?.Shipment?.ReceivingCompany}
                  </p>

                  <span className="font-weight-bold text-gray-400">
                    {(LoadInformations?.Shipment?.ReceivingAddress1 !== null &&
                      LoadInformations?.Shipment?.ReceivingAddress1 + ", ") +
                      (LoadInformations?.Shipment?.ReceivingCity !== null &&
                        LoadInformations?.Shipment?.ReceivingCity + ", ") +
                      (LoadInformations?.Shipment?.ReceivingState !== null &&
                        LoadInformations?.Shipment?.ReceivingState + " ") +
                      LoadInformations?.Shipment?.ReceivingZipCode}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="Hisory-status">
            <CardHeader title={t("Status_History")}></CardHeader>
            <CardBody>
              {LoadInformations?.StatusesHistoy.length > 0 ? (
                <div className="timeline timeline-6 mt-3">
                  {LoadInformations?.StatusesHistoy?.map((item, index) => {
                    return (
                      <div
                        className="timeline-item align-items-start"
                        key={index}
                      >
                        <div className="timeline-label font-weight-mormal text-muted font-size-lg">
                          {item?.Created &&
                            ConvertToDateCurrentTimeZone(item?.Created)}
                        </div>

                        <div className="timeline-badge">
                          <i className="fa fa-genderless text-warning icon-xl" />
                        </div>

                        <div className="font-weight-bolder font-size-lg timeline-content text-dark-75 pl-3">
                          {item?.Name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <h4>{t("There_is_no_data_available_at_the_moment")}.</h4>
              )}
            </CardBody>
          </Card>
        </div>
        <div className="flex-lg-row-fluid ml-lg-7 ml-xl-10">
          <Row>
            <Col xl="8" lg="8" md="6" xs="12">
              <div className="maps-section mb-5">
                <GoogleMap
                  setDetailTrakingGeometryInfo={setDetailTrakingGeometryInfo}
                  DetailTrakingGeometryInfo={DetailTrakingGeometryInfo}
                  LoadInformations={LoadInformations}
                />
              </div>
              <Card>
                <CardHeader title={t("Load_Info")}>
                  <CardHeaderToolbar></CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-wrap">
                      <div className="d-flex flex-column">
                      {LoadInformations?.Shipment?.Mode === "Drayage" ? (
                          <>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                              LFD
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.Shipment?.LastFreeDate !== null
                                ? moment(
                                    LoadInformations?.Shipment?.LastFreeDate
                                  ).format("MM/DD/YYYY")
                                : "- - - -"}
                            </span>
                          </>
                        ) : (
                          <>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                              {t("Req_Ship_Date")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.Shipment?.ReqShipDate !== null
                                ? moment(
                                    LoadInformations?.Shipment?.ReqShipDate
                                  ).format("MM/DD/YYYY")
                                : "- - - -"}
                            </span>
                          </>
                        )}
                        <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                          {t("PU_Appointment")}
                        </p>
                        <span className="text-dark-75 font-weight-bold">
                          {LoadInformations?.Shipment?.PuAppointmentDate !==
                          null
                            ? ConvertToDateCurrentTimeZone(
                                LoadInformations?.Shipment?.PuAppointmentDate
                              )
                            : "- - - -"}
                        </span>
                        <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                          {t("Departure_Date")}
                        </p>
                        <span className="text-dark-75 font-weight-bold">
                          {LoadInformations?.Shipment?.ShippingDepartureDate !==
                          null
                            ? ConvertToDateCurrentTimeZone(
                                LoadInformations?.Shipment
                                  ?.ShippingDepartureDate
                              )
                            : "- - - -"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center col-xl-8 col-lg-7 col-md-6 col-4">
                      <span className="svg-icon svg-icon-2 svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Loads/Receiver.svg"
                          )}
                        ></SVG>
                      </span>
                      <div className="d-flex flex-column flex-grow-1">
                        <span className="separator separator-dashed separator-border-2 separator-secondary" />
                      </div>
                      <span className="svg-icon svg-icon-2 svg-icon-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                            fill="currentColor"
                          />
                          <path
                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>

                    <div className="d-flex flex-wrap">
                      <div className="d-flex flex-column flex-grow-1">
                      {LoadInformations?.Shipment?.Mode === "Drayage" &&
                        LoadInformations?.Shipment?.IsStayWith === true ? (
                          <>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                              Reported empty
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.Shipment?.ReportedEmpty !==
                              null
                                ? moment(
                                    LoadInformations?.Shipment?.ReportedEmpty
                                  ).format("MM/DD/YYYY")
                                : "- - - -"}
                            </span>
                          </>
                        ) : (
                          <>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                              MABD
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.Shipment?.Mabd !== null
                                ? moment(
                                    LoadInformations?.Shipment?.Mabd
                                  ).format("MM/DD/YYYY")
                                : "- - - -"}
                            </span>
                          </>
                        )}
                        <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                          {t("Del_Appointment")}
                        </p>
                        <span className="text-dark-75 font-weight-bold">
                          {LoadInformations?.Shipment?.DelAppointmentDate !==
                          null
                            ? ConvertToDateCurrentTimeZone(
                                LoadInformations?.Shipment?.DelAppointmentDate
                              )
                            : "- - - -"}
                        </span>
                        <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                          {t("Receiving_Arrival")}
                        </p>
                        <span className="text-dark-75 font-weight-bold">
                          {LoadInformations?.Shipment?.ReceivingArrivalDate !==
                          null
                            ? ConvertToDateCurrentTimeZone(
                                LoadInformations?.Shipment?.ReceivingArrivalDate
                              )
                            : "- - - -"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Col xl="12">
                    <Row className="align-items-center mt-10">
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Equipment_Type")}
                          </p>
                          <span className="text-dark-75 font-weight-bold">
                            {LoadInformations?.Shipment?.EquipmentType}
                          </span>
                        </div>
                      </Fragment>
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Total_Weight")}
                          </p>
                          <span className="text-dark-75 font-weight-bold">
                            {LoadInformations?.Shipment?.TotalWeight !== null
                              ? LoadInformations?.Shipment?.TotalWeight +
                                  " " +
                                  LoadInformations?.Shipment?.WeightType !==
                                null
                                ? LoadInformations?.Shipment?.WeightType
                                : ""
                              : ""}
                          </span>
                        </div>
                      </Fragment>

                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Total_Stops")}
                          </p>
                          <span className="text-dark-75 font-weight-bold">
                            {(LoadInformations?.Stops?.length || 0) + 2}
                          </span>
                        </div>
                      </Fragment>

                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Total_Price")}
                          </p>
                          <span className="text-dark-75 font-weight-bold">
                            $ {LoadInformations?.Shipment?.TotalPrice}
                          </span>
                        </div>
                      </Fragment>
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Distance")} (Mile)
                          </p>
                          <span className="text-dark-75 font-weight-bold">
                            {LoadInformations?.Shipment?.TotalMiles} miles
                          </span>
                        </div>
                      </Fragment>
                    </Row>
                  </Col>
                  <Col xl="12">
                    <Row className="align-items-center mt-10">
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Trucker")}
                          </p>
                          <div className="mb-10">
                            <span className="font-weight-bolder text-dark-75 font-size-md">
                              {LoadInformations?.Trucker?.Name}
                            </span>
                          </div>
                        </div>
                      </Fragment>
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Driver")}
                          </p>
                          <div className="mb-10">
                            <span className="font-weight-bolder text-dark-75 font-size-md">
                              {LoadInformations?.Trucker?.Driver}
                            </span>
                          </div>
                        </div>
                      </Fragment>
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Email")}
                          </p>
                          <div className="mb-10">
                            <span className="font-weight-bolder text-dark-75 font-size-md">
                              {LoadInformations?.Trucker?.ContactEmail}
                            </span>
                          </div>
                        </div>
                      </Fragment>
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Licence")} #
                          </p>
                          <div className="mb-10">
                            <span className="font-weight-bolder text-dark-75 font-size-md">
                              {LoadInformations?.Trucker?.LicenceNumber}
                            </span>
                          </div>
                        </div>
                      </Fragment>
                      <Fragment>
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                            {t("Work_Phone")} #
                          </p>
                          <div className="mb-10">
                            <span className="font-weight-bolder text-dark-75 font-size-md">
                              {LoadInformations?.Trucker?.WorkPhoneNumber}
                            </span>
                          </div>
                        </div>
                      </Fragment>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" lg="4" md="6" xs="12">
              <Card>
                <CardHeader title={t("Itinerary")}></CardHeader>
                <CardBody>
                  <div
                    className="scroll  ps--active-y"
                    style={{ height: "770px", overflow: "scroll" }}
                  >
                    <div className="timeline ml-n1 pl-1">
                      <div className="timeline-item align-items-center">
                        <div className="timeline-line w-20px mt-11 mb-n14" />
                        <div
                          className="timeline-icon pt-1"
                          style={{ marginLeft: "0.7px" }}
                        >
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Loads/Receiver.svg"
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="timeline-content m-0">
                          <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                            {(LoadInformations?.Shipment?.ShippingCity !== null
                              ? LoadInformations?.Shipment?.ShippingCity + ", "
                              : "") +
                              (LoadInformations?.Shipment?.ShippingState !==
                              null
                                ? LoadInformations?.Shipment?.ShippingState
                                : "")}
                            <span
                              className="text-muted"
                              style={{
                                fontWeight: "100",
                                fontSize: "smaller",
                                display: "block",
                              }}
                            >
                              Shipping:{" "}
                              {LoadInformations?.Shipment
                                ?.ShippingDepartureDate !== null
                                ? LoadInformations?.Shipment
                                    ?.ShippingDepartureDate &&
                                  ConvertToDateCurrentTimeZone(
                                    LoadInformations?.Shipment
                                      ?.ShippingDepartureDate
                                  )
                                : "---------- "}
                            </span>
                          </p>
                        </div>
                      </div>
                      {LoadInformations?.Tracking?.map((item, index) => {
                        return (
                          <div
                            className="timeline-item align-items-center"
                            key={index}
                          >
                            <div className="timeline-line w-20px" />
                            <div
                              className="timeline-icon pt-1"
                              style={{ marginLeft: "0.5px" }}
                            >
                              <span className="svg-icon svg-icon-2 svg-icon-success">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Loads/Receiver.svg"
                                  )}
                                ></SVG>
                              </span>
                            </div>
                            <div className="timeline-content m-0">
                              <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                                {item?.City !== null
                                  ? item?.City + ", " + item?.State
                                  : ""}
                                {/* <span className="text-primary">
                                  {item?.City!== null ?((item.LeftOver || 0)+" Miles"):""} 
                                </span> */}
                                <span
                                  className="text-muted"
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "smaller",
                                    display: "block",
                                  }}
                                >
                                  {item.EventName}:{" "}
                                  {item.EventDate &&
                                    ConvertToDateCurrentTimeZone(
                                      item.EventDate
                                    )}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      {LoadInformations?.Stops?.filter(
                        (a) => a.Delivered === false
                      )?.map((item, index) => {
                        return (
                          <div className="timeline-item align-items-center">
                            <div className="timeline-line w-20px" />
                            <div
                              className="timeline-icon pt-1"
                              style={{ marginLeft: "0.5px" }}
                            >
                              <span className="svg-icon svg-icon-2 svg-icon-warning">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Loads/Receiver.svg"
                                  )}
                                ></SVG>
                              </span>
                            </div>
                            <div className="timeline-content m-0">
                              <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                                {item?.City !== null
                                  ? item?.City + ", " + item?.State
                                  : ""}{" "}
                                <span className="text-primary">
                                  {item.Type} Stop
                                </span>
                                <span
                                  className="text-muted"
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "smaller",
                                    display: "block",
                                  }}
                                >
                                  {item.EventName}
                                  {item.EventDate && ":"}
                                  {item.EventDate &&
                                    ConvertToDateCurrentTimeZone(
                                      item.EventDate
                                    )}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      <div className="timeline-item align-items-center">
                        <div className="timeline-line w-20px" />
                        <div
                          className="timeline-icon pt-1"
                          style={{ marginLeft: "0.5px" }}
                        >
                          <span className="svg-icon svg-icon-2 svg-icon-info">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Loads/Receiver.svg"
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="timeline-content m-0">
                          <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                            {(LoadInformations?.Shipment?.ReceivingCity !== null
                              ? LoadInformations?.Shipment?.ReceivingCity + ", "
                              : "") +
                              (LoadInformations?.Shipment?.ReceivingState !==
                              null
                                ? LoadInformations?.Shipment?.ReceivingState +
                                  ", "
                                : "")}
                          </p>
                          <span
                            className="text-muted"
                            style={{
                              fontWeight: "100",
                              fontSize: "smaller",
                              display: "block",
                            }}
                          >
                            Receiving:{" "}
                            {LoadInformations?.Shipment
                              ?.ReceivingArrivalDate !== null
                              ? LoadInformations?.Shipment
                                  ?.ReceivingArrivalDate &&
                                ConvertToDateCurrentTimeZone(
                                  LoadInformations?.Shipment
                                    ?.ReceivingArrivalDate
                                )
                              : " ----------"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Modal */}
      <Modal
        size="lg"
        show={ShowModalShare}
        onHide={() => {
          setShowModalShare(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Live Tracking:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            href={() => false}
            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
          >
            Emails
          </a>
          <CreatableSelect
            isMulti
            className="mt-4"
            isClearable
            name="PickupId"
            id="PickupId"
            label="Company"
            value={ListOfEmailsToSahareLoads.map((item, index) => {
              return { label: item.label, value: item.label };
            })}
            onChange={(newValue) => {
              // console.log("newValue", newValue);
              if (newValue === null) {
                setListOfEmailsToSahareLoads([]);
                return;
              }
              let newArray = [];
              newValue.forEach((element) => {
                const tt = validateEmail(element.label);
                if (tt) {
                  newArray.push(element);
                } else {
                  Swal.fire(t("Error"), t("Please_Fill_Valid_Email"));
                }
              });
              setListOfEmailsToSahareLoads(newArray);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModalShare(false);
            }}
          >
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            disabled={isLoadingAuth}
            onClick={() => {
              if (ListOfEmailsToSahareLoads.length === 0) {
                Swal.fire(t("Error"), t("Please_Fill_an_Email"));
                return;
              }
              setisLoadingAuth(true);
              var row = JSON.stringify({
                ShipmentId: id,
                Emails: ListOfEmailsToSahareLoads.map((a) => a.label),
              });
              return fetch(`${window.$apiUrl}/api/Tracking/Share`, {
                method: "POST",
                body: row,
                redirect: "follow",
                withCredentials: true,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${db.read().getState().Token}`,
                  "X-FP-API-KEY": "iphone",
                },
              })
                .then(async (response) => {
                  const statusCode = await response.status;
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => [statusCode, data]);
                  } else {
                    return response
                      .text()
                      .then((text) => [statusCode, { Message: "" }]);
                  }
                })
                .then((result) => {
                  if (result[0] === 200) {
                    setShowModalShare(false);
                  } else {
                    if (
                      result[1].ModelState !== null &&
                      result[1].ModelState !== undefined &&
                      result[1].ModelState !== ""
                    ) {
                      let modelState = result[1].ModelState;
                      if (modelState)
                        Object.keys(modelState).forEach(function(k) {
                          modelState[k].forEach((element) => {
                            Swal.fire(t("Error"), translateAuto(element), "error");
                          });
                        });
                    } else if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Swal.fire(t("Error"), translateAuto(result[1].Message) );
                    } else if (
                      result[1].error_description !== null &&
                      result[1].error_description !== undefined &&
                      result[1].error_description !== ""
                    ) {
                      Swal.fire(t("Error"), translateAuto(result[1].error_description));
                    } else {
                      Swal.fire(t("Error"), t("Bad_Request"));
                    }
                  }
                  setisLoadingAuth(false);
                })
                .catch((error) => {
                  Swal.fire("error", error);
                  setisLoadingAuth(false);
                });
            }}
          >
            {t("Share")}
            {isLoadingAuth && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
