import { LanguageSelectorDropdown } from ".../../../src/_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { Field, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  creditCardTypeFromNumber,
  FetchApiExample,
  RequiredHtml,
} from "../../../../_metronic/layout/components/custom/index.js";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  currencyFormat,
  translateAuto,
} from "../../../../_metronic/_helpers/AtHelper";
import * as auth from "../_redux/authRedux";
import { ArrowSvg } from "./SvgForm/index.js";

const initValues = {
  AmountName: "",
  Amount: "",
  IsVeteran: false,
  VeteranBranch: "",
  FirstName: "Jhon",
  LastName: "Brown",
  CompanyName: "",
  ScacCode: "",
  McNumber: "",
  DotNumber: "",
  Address1: "",
  Address2: "",
  City: "",
  State: "",
  Zipcode: "",
  Country: "United States",
  PlanId: "",
  CardName: "",
  CardNumber: "",
  CardExpiryMonth: "",
  CardExpiryYear: "",
  CardCvc: "",
  BillingAddress1: "",
  BillingAddress2: "",
  BillingZipcode: "",
  BillingCity: "",
  BillingState: "",
  BillingCountry: "United States",
  usesameaddress: true,
  Coupon: "",
};
function Registration(props) {
  const history = useHistory();
  const [step, setstep] = useState(0);
  const [PlanOptions, setPlanOptions] = useState();
  const [iniAmount, setiniAmount] = useState();
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const [toggleBasic, setToggleBasics] = useState(false);
  const [toggleCompany, setToggleCompany] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dotNumberError, setDotNumberError] = useState(false);
  const [mcNumberError, setMcNumberError] = useState(false);

  const [mcNumber, setMcNumber] = useState("");
  const [dotNumber, setDotNumber] = useState("");
  const [isVeteran, setIsVeteran] = useState(false);
  const [isNotVeteran, setIsNotVeteran] = useState(false);
  const [currentTypeVeteran, setCurrentTypeVeteran] = useState(null);
  const { t } = useTranslation();
  var currentTime = new Date().getFullYear();
  const listMonth = [];
  const listYear = [];
  for (let index = 1; index <= 12; index++) {
    listMonth.push(index);
  }
  for (let index = 0; index <= 20; index++) {
    listYear.push(currentTime + index);
  }
  const listAuthorities = [
    { value: 1, label: "My DOT" },
    { value: 2, label: "My MC" },
    { value: 3, label: "No DOT / MC" },
  ];
  const [checkbox, setCheckBox] = useState(true);
  const [couponChecked, setIsCoupon] = useState(false);
  const [ContriesViewModel, setContriesViewModel] = useState([]);

  const BillingSchema = couponChecked
    ? Yup.object().shape({
        Address1: Yup.string()
          .min(1, t("Address_is_required"))
          .required(t("Address_is_required")),
        CardExpiryMonth: Yup.string()
          .min(1, t("Card_Expiration_Month_is_not_valid"))
          .max(2, t("Card_Expiration_Month_is_not_valid"))
          .required(t("Card_Expiration_Month_is_required")),
        CardExpiryYear: Yup.string()
          .min(4, t("Card_Expiration_Year_is_not_valid"))
          .max(4, t("Card_Expiration_Year_is_not_valid"))
          .required(t("Card_Expiration_Year_is_required")),
        CardNumber: Yup.string()
          .min(13, t("Must_be_at_least_13_numbers"))
          .max(16, t("The_credit_card_number_is_not_valid"))
          .required(t("Card_Number_is_required")),
        CardName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("Card_Name_is_required")),
        CardCvc: Yup.string()
          .typeError(t("Cvv_must_be_a_number"))
          .min(3, t("Must_be_at_least_3_numbers"))
          .label("CVV")
          .required(t("Please_Enter_The_Card_CVV")),
        Email: Yup.string()
          .email()
          .max(40, t("Too_Long"))
          .required(t("Email_is_required")),
        auth_option: Yup.string().required(t("Please_choose_a_plan")),
        FirstName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("First_Name_is_required")),
        LastName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("Last_Name_is_required")),
        CompanyName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("Company_Name_is_required")),
        // DotNumber: Yup.string().required(t("Dot_Number_is_required")),
        // ScacCode: Yup.string().required(t("Scac_Code_is_required")),
        // McNumber: Yup.string().required(t("Mc_Number_is_required")),
        Coupon: Yup.string().required(t("Discount_code_is_required")),
        City: Yup.string()
          .min(1, "City is required")
          .required("* City is required"),
        State: Yup.string()
          .min(1, "State is required")
          .required("* State is required"),
        Zipcode: Yup.string()
          .min(1, "Zip is required")
          .required("* Zip is required"),
        Country: Yup.string().required("* Country is required"),
      })
    : Yup.object().shape({
        CardExpiryMonth: Yup.string()
          .min(1, t("Card_Expiration_Month_is_not_valid"))
          .max(2, t("Card_Expiration_Month_is_not_valid"))
          .required(t("Card_Expiration_Month_is_required")),
        CardExpiryYear: Yup.string()
          .min(4, t("Card_Expiration_Year_is_not_valid"))
          .max(4, t("Card_Expiration_Year_is_not_valid"))
          .required(t("Card_Expiration_Year_is_required")),
        CardNumber: Yup.string()
          .min(13, t("Must_be_at_least_13_numbers"))
          .max(16, t("The_credit_card_number_is_not_valid"))
          .required(t("Card_Number_is_required")),
        CardName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("Card_Name_is_required")),
        CardCvc: Yup.string()
          .typeError(t("Cvv_must_be_a_number"))
          .min(3, t("Must_be_at_least_3_numbers"))
          .label("CVV")
          .required(t("Please_Enter_The_Card_CVV")),
        Email: Yup.string()
          .email()
          .max(40, t("Too_Long"))
          .required(t("Email_is_required")),
        auth_option: Yup.string().required(t("Please_choose_a_plan")),
        Address1: Yup.string()
          .min(1, t("Address_is_required"))
          .required(t("Address_is_required")),
        FirstName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("First_Name_is_required")),
        LastName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("Last_Name_is_required")),
        CompanyName: Yup.string()
          .max(40, t("Too_Long"))
          .required(t("Company_Name_is_required")),
        // DotNumber: Yup.string().required(t("Dot_Number_is_required")),
        // ScacCode: Yup.string().required(t("Scac_Code_is_required")),
        // McNumber: Yup.string().required(t("Mc_Number_is_required")),
        City: Yup.string()
          .min(1, "City is required")
          .required("* City is required"),
        State: Yup.string()
          .min(1, "State is required")
          .required("* State is required"),
        Zipcode: Yup.string()
          .min(1, "Zip is required")
          .required("* Zip is required"),
        Country: Yup.string().required("* Country is required"),
      });
  useEffect(() => {
    GetPlan();
    GetContriesViewModel();
  }, []);
  function GetPlan() {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/Billing/plans`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setPlanOptions(result[1]);
          // console.log("success", "Add successfuly");
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  console.log("error", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            console.log("error", translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            console.log("error", result[1].error_description);
          } else {
            console.log("error", "Bad Request");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function GetContriesViewModel() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/generic/Countries`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setContriesViewModel(data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //fetch url parameter
  const location = useLocation();
  const [dataUser, setDataUser] = useState(initValues);
  const [token, setToken] = useState("");

  useEffect(() => {
    try {
      const token = location?.search
        ?.replace("?token=", "")
        .replace("&isWeb=true", "");
      setToken(token);
      if (token === "") {
        return;
      } else {
        FetchApiExample(
          "get",
          `api/Account/Register/profile`,
          setDataUser,
          null,
          null,
          token
        );
      }
    } catch (err) {}
  }, [location]);

  useEffect(() => {
    if (!!dataUser?.McNumber) {
      setMcNumber(dataUser?.McNumber);
    }
    if (!!dataUser?.DotNumber) {
      setDotNumber(dataUser?.DotNumber);
    }
  }, [dataUser]);
  function SubmitForm(values) {
    try {
      //fetch FullName
      if (isVeteran) {
        values.IsVeteran = true;
        values.VeteranBranch = currentTypeVeteran?.value;
      }
      values.FirstName = dataUser.FirstName;
      values.LastName = dataUser.LastName;

      if (values.usesameaddress) {
        values.BillingAddress2 =
          values.BillingAddress2 === ""
            ? values.Address2
            : values.BillingAddress2;
        values.BillingAddress1 =
          values.BillingAddress1 === ""
            ? values.Address1
            : values.BillingAddress1;
        values.BillingZipcode =
          values.BillingZipcode === "" ? values.ZipCode : values.BillingZipcode;
        values.BillingCity =
          values.BillingCity === "" ? values.City : values.BillingCity;
        values.BillingState =
          values.BillingState === "" ? values.State : values.BillingState;
        values.BillingCountry =
          values.BillingCountry === "" ? values.Country : values.BillingCountry;
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(values),
      };
      setisLoadingAuth(true);
      fetch(`${window.$apiUrl}/api/Account/register/membership`, requestOptions)
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: t("Your_TaaP_subscription_has_been_paid_Congrats"),
              timer: 3000,
            });
            if (!isMobile) {
              history.push(`/user-profile?billing=true`);
            } else {
              setTimeout(() => {
                window.location.href =
                  "https://taapapp.page.link/?link=https://dev-taap-app.empowerdl.com/Open?Open=123&apn=com.taapapp.taapapp&ibi=com.empower.taapapp";
              }, 3000);
            }
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Swal.fire(t("Error"), translateAuto(element), "error");
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Swal.fire(t("Error"), translateAuto(result[1].Message));
            } else if (
              result[1].error_description !== null &&
              result[1].error_description !== undefined &&
              result[1].error_description !== ""
            ) {
              Swal.fire(t("Error"), translateAuto(result[1].error_description));
            } else {
              Swal.fire(t("Error"), t("Bad_Request"));
            }
          }
          setisLoadingAuth(false);
        })
        .catch((error) => {
          Swal.fire(t("Error"), error);
          setisLoadingAuth(false);
        });
    } catch (err) {}
  }

  // function ScrollBottom(value) {
  //   try {
  //     window.scrollTo({ left: 0, top: value, behavior: "smooth" });
  //   } catch (err) {}
  // }

  const [codeRef, setCodeRef] = useState("");
  return (
    <div
      className="login login-3 login-signin-on d-flex flex-row-fluid"
      style={{ display: "block" }}
    >
      <div
        className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
        style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
      >
        <div className="position-fixed top-0 end-0 m-5 Zindex-1">
          <LanguageSelectorDropdown />
        </div>
        <div className="col-xl-9 col-lg-10 col-md-12 col-12  p-7 position-relative overflow-hidden">
          <div
            className={
              "d-flex flex-center " +
              (isVeteran && !!currentTypeVeteran?.value && isNotVeteran
                ? "mb-3"
                : "mb-15")
            }
          >
            <p>
              <SVG src={toAbsoluteUrl("/media/svg/logos/TaapLogo.svg")} />
            </p>
          </div>
          <div className="d-flex flex-center mb-15">
            {isVeteran && !!currentTypeVeteran?.value && isNotVeteran && (
              <Fragment>
                <button class="btn btn-outline-success btn-sm">
                  <b>Veteran:</b> {currentTypeVeteran?.value}
                </button>
              </Fragment>
            )}
          </div>
          {!isNotVeteran ? (
            <Row>
              <Col xl="7" lg="7" md="12" className="mx-auto">
                <div
                  className="card card-custom"
                  style={{ marginBottom: "16rem" }}
                >
                  <div className="card-body p-md-15">
                    <div
                      className={`chooseVt-bod ${isVeteran ? "d-none" : ""}`}
                    >
                      <Row className="form-group">
                        <Col>
                          <h1 className="text-center">Are you a veteran ?</h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "-webkit-center" }}>
                          <button
                            className="btn btnPrimaryTaap font-weight-bolder text-uppercase px-9 py-4 mr-3"
                            onClick={(e) => {
                              setIsVeteran(true);
                              setIsNotVeteran(false);
                              e.preventDefault();
                            }}
                          >
                            Yes
                          </button>
                          <button
                            className="btn btn-default font-weight-bolder text-uppercase px-9 py-4 mr-3"
                            onClick={(e) => {
                              setIsVeteran(false);
                              setIsNotVeteran(true);
                              e.preventDefault();
                            }}
                          >
                            No
                          </button>
                          <button
                            className="btn btn-default font-weight-bolder text-uppercase px-9 py-4"
                            onClick={(e) => {
                              history.push("/user-profile?billing=true");
                              e.preventDefault();
                            }}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className={`selectVt-body ${!isVeteran ? "d-none" : ""}`}
                    >
                      <Row className="form-group">
                        <Col lg="12">
                          <h1 className="text-center">
                            Select branch from drop down menu and enter code
                          </h1>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col>
                          <label>Which branch were you in ?</label>
                          <ReactSelect
                            onChange={(e) => {
                              setCurrentTypeVeteran(e ?? null);
                            }}
                            name="Country"
                            isSearchable={true}
                            placeholder={"Select..."}
                            options={[
                              { value: "Army", label: "Army" },
                              { value: "Navy", label: "Navy" },
                              { value: "Marine", label: "Marine" },
                              { value: "Air Force", label: "Air Force" },
                              { value: "Coast Guard", label: "Coast Guard" },
                              { value: "Army Reserve", label: "Army Reserve" },
                              {
                                value: "Army National Guard",
                                label: "Army National Guard",
                              },
                            ]}
                            value={currentTypeVeteran}
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col>
                          <label>Enter code provided by VIB network</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter referral code"
                            value={codeRef}
                            onChange={(e) => {
                              var value = e.target.value;
                              setCodeRef(value ?? "");
                            }}
                          />
                          {codeRef.trim().toUpperCase() !== "" && (
                            <div>
                              <i
                                style={{ fontSize: "smaller" }}
                                className={`mr-2 text-${
                                  codeRef.trim().toUpperCase() === "VIB2023"
                                    ? "success flaticon2-check-mark"
                                    : "danger flaticon2-cross"
                                }`}
                              ></i>
                              <label
                                className={`mt-3 text-${
                                  codeRef.trim().toUpperCase() === "VIB2023"
                                    ? "success"
                                    : "danger"
                                }`}
                              >
                                {codeRef.trim().toUpperCase() === "VIB2023"
                                  ? "Valid code"
                                  : "Invalid code"}
                              </label>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlignLast: "right" }}>
                          <button
                            className="btn btn-default font-weight-bolder text-uppercase px-9 py-4 mr-3"
                            onClick={() => {
                              setIsVeteran(false);
                            }}
                          >
                            {t("Back")}
                          </button>
                          <button
                            className="btn btnPrimaryTaap font-weight-bolder text-uppercase px-9 py-4 mr-3"
                            disabled={
                              !!currentTypeVeteran?.value === false ||
                              codeRef.trim().toUpperCase() !== "VIB2023"
                            }
                            onClick={(e) => {
                              if (!!currentTypeVeteran?.value) {
                                setIsNotVeteran(true);
                                setIsVeteran(true);
                              }
                              e.preventDefault();
                            }}
                          >
                            {t("Next")}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {isNotVeteran && (
            <Row>
              <Col>
                <div className="card card-custom mb-md-25">
                  <div className="card-body p-0">
                    {/*begin::Wizard*/}
                    <div
                      className="wizard wizard-1"
                      id="kt_wizard"
                      data-wizard-state="first"
                      data-wizard-clickable="false"
                    >
                      {/*begin::Wizard Nav*/}
                      <div className="wizard-nav border-bottom">
                        <div className="wizard-steps p-8 p-lg-10">
                          {/*begin::Wizard Step 1 Nav*/}
                          <div
                            className="wizard-step"
                            data-wizard-type="step"
                            data-wizard-state={`${
                              step >= 0 ? "current" : "pending"
                            }`}
                          >
                            <div className="wizard-label">
                              <h3
                                className={
                                  step === 0
                                    ? "text-primary"
                                    : "text-muted disabledLink"
                                }
                                onClick={() => setstep(0)}
                              >
                                {t("Account_Informations")}
                              </h3>
                            </div>
                            <ArrowSvg />
                          </div>
                          {/*end::Wizard Step 1 Nav*/}
                          {/*begin::Wizard Step 3 Nav*/}
                          <div
                            className="wizard-step"
                            data-wizard-type="step"
                            data-wizard-state={`${
                              step > 1 ? "current" : "pending"
                            }`}
                          >
                            <div className="wizard-label">
                              <h3
                                className={
                                  step === 1
                                    ? "text-primary"
                                    : "text-muted disabledLink"
                                }
                              >
                                {t("Plan_Details")}
                              </h3>
                            </div>
                            <ArrowSvg />
                          </div>
                          {/*end::Wizard Step 3 Nav*/}
                          {/*begin::Wizard Step 4 Nav*/}
                          <div
                            className="wizard-step"
                            data-wizard-type="step"
                            data-wizard-state={`${
                              step > 2 ? "current" : "pending"
                            }`}
                          >
                            <div className="wizard-label">
                              <h3
                                className={
                                  step === 2
                                    ? "text-primary"
                                    : "text-muted disabledLink"
                                }
                              >
                                {t("Billing_Details")}
                              </h3>
                            </div>
                            <ArrowSvg />
                          </div>
                          {/*end::Wizard Step 4 Nav*/}
                          {/*begin::Wizard Step 5 Nav*/}
                          <div
                            className="wizard-step"
                            data-wizard-type="step"
                            data-wizard-state={`${
                              step > 3 ? "current" : "pending"
                            }`}
                          >
                            <div className="wizard-label">
                              <h3
                                className={
                                  step === 3
                                    ? "text-primary"
                                    : "text-muted disabledLink"
                                }
                              >
                                {t("Summary")}
                              </h3>
                            </div>
                          </div>
                          {/*end::Wizard Step 5 Nav*/}
                        </div>
                      </div>
                      {/*end::Wizard Nav*/}
                      {/*begin::Wizard Body*/}
                      <div className="row justify-content-center my-10 px-xl-8 px-lg-8 px-md-8 px-5 px-8 my-lg-15 px-lg-10">
                        <div className="col-xl-12 col-xxl-8">
                          {/*begin::Wizard Form*/}
                          <Formik
                            enableReinitialize={true}
                            initialValues={dataUser}
                            validationSchema={BillingSchema}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              setFieldValue,
                              handleSubmit,
                              setFieldTouched,
                            }) => {
                              return (
                                <form className="form fv-plugins-bootstrap fv-plugins-framework">
                                  {step === 0 && (
                                    <Row className="form-group">
                                      <Col>
                                        <h3 id="titleMain">
                                          {t(
                                            "Set_up_your_account_to_start_booking_loads_today"
                                          )}
                                        </h3>
                                      </Col>
                                    </Row>
                                  )}

                                  {step === 0 && (
                                    <div className="pb-5">
                                      {/* {verField ? (
                       <div className="fv-plugins-message-container pb-3">
                         <div
                           data-field="width"
                           data-validator="digits"
                           className="fv-help-block text-danger"
                         >
                           Please check your required fields *
                         </div>
                       </div>
                     ) : null} */}
                                      <Row>
                                        <Col lg="12">
                                          <div className="d-flex">
                                            <div
                                              className="text-truncate label label-lg labelPrimary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {t("Basic_Informations")}
                                            </div>
                                            <button
                                              className="btn btn-light-primary pdBtnToggle ml-2"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setToggleBasics(
                                                  toggleBasic ? false : true
                                                );
                                              }}
                                            >
                                              <i
                                                className={
                                                  toggleBasic
                                                    ? "flaticon2-up pt-1 pl-1"
                                                    : "flaticon2-down pt-1 pl-1"
                                                }
                                              ></i>
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div
                                        className={`p-3 ${
                                          toggleBasic ? "d-none" : ""
                                        }`}
                                      >
                                        <div className="row">
                                          <Form.Label
                                            column
                                            sm={2}
                                            className="d-flex"
                                          >
                                            <span className="d-lg-block d-xl-block d-md-block d-none">
                                              {t("Full_Name")}
                                            </span>
                                            <span className="d-block d-sm-none">
                                              First name
                                            </span>
                                            <RequiredHtml />
                                          </Form.Label>
                                          <div className="col-xl-5 col-sm-5 col-md-5 col-lg-5 col-12">
                                            {/*begin::Input*/}
                                            <div className="form-group fv-plugins-icon-container has-success">
                                              <Field
                                                disabled={true}
                                                type="text"
                                                className="form-control  form-control-lg"
                                                value={dataUser.FirstName}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "FirstName",
                                                    e.target.value
                                                  );
                                                  let data = { ...dataUser };
                                                  data.FirstName =
                                                    e.target.value;
                                                  setDataUser(data);
                                                }}
                                                name="FirstName"
                                              />

                                              {errors.FirstName &&
                                              touched.FirstName ? (
                                                <div className="fv-plugins-message-container">
                                                  <div
                                                    data-field="width"
                                                    data-validator="digits"
                                                    class="fv-help-block text-danger"
                                                  >
                                                    {errors.FirstName}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                            {/*end::Input*/}
                                          </div>
                                          <Form.Label
                                            column
                                            sm={4}
                                            className="d-block d-sm-none"
                                          >
                                            Last name
                                            <RequiredHtml />
                                          </Form.Label>
                                          <div className="col-xl-5 col-sm-5 col-md-5 col-lg-5 col-12">
                                            {/*begin::Input*/}
                                            <div className="form-group fv-plugins-icon-container has-success">
                                              <Field
                                                disabled={true}
                                                type="text"
                                                className="form-control  form-control-lg"
                                                value={dataUser.LastName}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "LastName",
                                                    e.target.value
                                                  );
                                                  let data = { ...dataUser };
                                                  data.LastName =
                                                    e.target.value;
                                                  setDataUser(data);
                                                }}
                                                name="LastName"
                                              />

                                              {errors.LastName &&
                                              touched.LastName ? (
                                                <div className="fv-plugins-message-container">
                                                  <div
                                                    data-field="width"
                                                    data-validator="digits"
                                                    class="fv-help-block text-danger"
                                                  >
                                                    {errors.LastName}
                                                  </div>
                                                </div>
                                              ) : null}

                                              <div className="fv-plugins-message-container" />
                                            </div>

                                            {/*end::Input*/}
                                          </div>
                                        </div>

                                        {/*begin::Input*/}
                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalEmail"
                                        >
                                          <Form.Label column sm={2}>
                                            {t("Email")}
                                          </Form.Label>
                                          <Col sm={10}>
                                            <Field
                                              disabled={true}
                                              className="form-control form-control-lg "
                                              value={dataUser.Email}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "Email",
                                                  e.target.value
                                                );
                                              }}
                                              type="email"
                                              name="Email"
                                            />
                                            {errors.Email && touched.Email ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.Email}
                                                </div>
                                              </div>
                                            ) : null}
                                          </Col>
                                        </Form.Group>

                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalPassword"
                                        >
                                          <Form.Label column sm={2}>
                                            {t("Phone")}
                                          </Form.Label>
                                          <Col sm={10}>
                                            <Field
                                              disabled={true}
                                              className="form-control form-control-lg "
                                              type="text"
                                              value={dataUser.PhoneNumber}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "PhoneNumber",
                                                  e.target.value
                                                );
                                              }}
                                              name="PhoneNumber"
                                            />
                                          </Col>
                                        </Form.Group>
                                      </div>

                                      <Row>
                                        <Col lg="12">
                                          <div className="d-flex">
                                            <div
                                              className="headerScroll text-truncate label label-lg labelPrimary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {t("Company_Information")}
                                            </div>
                                            <button
                                              className="btn btn-light-primary pdBtnToggle ml-2"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setToggleCompany(
                                                  toggleCompany ? false : true
                                                );
                                              }}
                                            >
                                              <i
                                                className={
                                                  toggleCompany
                                                    ? "flaticon2-up pt-1 pl-1"
                                                    : "flaticon2-down pt-1 pl-1"
                                                }
                                              ></i>
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div
                                        className={`p-3 ${
                                          toggleCompany ? "d-none" : ""
                                        }`}
                                      >
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <Form.Group
                                            as={Row}
                                            controlId="formHorizontalEmail"
                                          >
                                            <Form.Label column sm={3}>
                                              {t("Company_Name")}
                                              <RequiredHtml />
                                            </Form.Label>
                                            <Col sm={9}>
                                              <Field
                                                className="form-control "
                                                type="text"
                                                value={values.CompanyName}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "CompanyName",
                                                    e.target.value
                                                  );
                                                }}
                                                name="CompanyName"
                                              />

                                              {errors.CompanyName &&
                                              touched.CompanyName ? (
                                                <div className="fv-plugins-message-container">
                                                  <div
                                                    data-field="width"
                                                    data-validator="digits"
                                                    class="fv-help-block text-danger"
                                                  >
                                                    {errors.CompanyName}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </Col>
                                          </Form.Group>
                                          <Form.Group
                                            as={Row}
                                            controlId="formHorizontalEmail"
                                          >
                                            <Form.Label column sm={3}>
                                              {t("Authority_Type")}
                                            </Form.Label>
                                            <Col sm={9}>
                                              <ReactSelect
                                                onChange={(e) => {
                                                  if (e.value === 1) {
                                                    setFieldValue(
                                                      "DotNumber",
                                                      ""
                                                    );
                                                  }
                                                  if (e.value === 2) {
                                                    setFieldValue(
                                                      "McNumber",
                                                      ""
                                                    );
                                                  }
                                                  if (e.value === 3) {
                                                    setFieldValue(
                                                      "McNumber",
                                                      ""
                                                    );
                                                    setFieldValue(
                                                      "DotNumber",
                                                      ""
                                                    );
                                                    setFieldValue(
                                                      "ScacCode",
                                                      ""
                                                    );
                                                  }
                                                  setFieldValue(
                                                    "AuthorityType",
                                                    e?.value
                                                  );
                                                }}
                                                value={
                                                  listAuthorities?.find(
                                                    (x) =>
                                                      x.value ===
                                                      values.AuthorityType
                                                  ) ?? null
                                                }
                                                name="AuthorityType"
                                                isSearchable={true}
                                                options={listAuthorities.map(
                                                  (a) => {
                                                    return {
                                                      label: a.label,
                                                      value: a.value,
                                                    };
                                                  }
                                                )}
                                              />
                                            </Col>
                                          </Form.Group>
                                          {values.AuthorityType === 1 ? (
                                            <Fragment>
                                              <Card
                                                style={{
                                                  borderStyle: "dotted",
                                                }}
                                              >
                                                <Card.Body className="p-4">
                                                  <Form.Group
                                                    as={Row}
                                                    controlId="formHorizontalEmail"
                                                  >
                                                    <Form.Label column sm={3}>
                                                      {t("Dot_Number")}
                                                      <RequiredHtml />
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                      <Field
                                                        className="form-control "
                                                        type="text"
                                                        value={dotNumber}
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.value
                                                              .length > 0
                                                          ) {
                                                            setDotNumberError(
                                                              false
                                                            );
                                                          } else {
                                                            setDotNumberError(
                                                              true
                                                            );
                                                          }
                                                          setFieldValue(
                                                            "DotNumber",
                                                            e.target.value
                                                          );
                                                          setDotNumber(
                                                            e.target.value
                                                          );
                                                        }}
                                                        name="DotNumber"
                                                      />
                                                      {dotNumberError ? (
                                                        <div className="fv-plugins-message-container">
                                                          <div
                                                            data-field="width"
                                                            data-validator="digits"
                                                            class="fv-help-block text-danger"
                                                          >
                                                            {t(
                                                              "Dot_Number_is_required"
                                                            )}
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </Col>
                                                  </Form.Group>
                                                  <div className="row">
                                                    <Form.Label column sm={3}>
                                                      {t("Scac_Code")}
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                      <Field
                                                        className="form-control"
                                                        type="text"
                                                        value={values.ScacCode}
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            "ScacCode",
                                                            e.target.value
                                                          );
                                                        }}
                                                        name="ScacCode"
                                                      />
                                                    </Col>
                                                  </div>
                                                </Card.Body>
                                              </Card>
                                            </Fragment>
                                          ) : values.AuthorityType === 2 ? (
                                            <Card
                                              style={{ borderStyle: "dotted" }}
                                            >
                                              <Card.Body className="p-4">
                                                <Fragment>
                                                  <Form.Group
                                                    as={Row}
                                                    controlId="formHorizontalEmail"
                                                  >
                                                    <Form.Label column sm={3}>
                                                      {t("Mc_Number")}
                                                      <RequiredHtml />
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                      <Field
                                                        className="form-control"
                                                        type="text"
                                                        value={mcNumber}
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.value
                                                              .length > 0
                                                          ) {
                                                            setMcNumberError(
                                                              false
                                                            );
                                                          } else {
                                                            setMcNumberError(
                                                              true
                                                            );
                                                          }
                                                          setFieldValue(
                                                            "McNumber",
                                                            e.target.value
                                                          );
                                                          setMcNumber(
                                                            e.target.value
                                                          );
                                                        }}
                                                        name="McNumber"
                                                      />

                                                      {mcNumberError ? (
                                                        <div className="fv-plugins-message-container">
                                                          <div
                                                            data-field="width"
                                                            data-validator="digits"
                                                            class="fv-help-block text-danger"
                                                          >
                                                            {t(
                                                              "Mc_Number_is_required"
                                                            )}
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </Col>
                                                  </Form.Group>
                                                  <div className="row">
                                                    <Form.Label column sm={3}>
                                                      {t("Scac_Code")}
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                      <Field
                                                        className="form-control"
                                                        type="text"
                                                        value={values.ScacCode}
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            "ScacCode",
                                                            e.target.value
                                                          );
                                                        }}
                                                        name="ScacCode"
                                                      />
                                                    </Col>
                                                  </div>
                                                </Fragment>
                                              </Card.Body>
                                            </Card>
                                          ) : (
                                            ""
                                          )}
                                          <div className="fv-plugins-message-container" />
                                        </div>
                                        {/*end::Input*/}
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container row">
                                          <Form.Label column sm={3}>
                                            {t("Address")} 1
                                            <RequiredHtml />
                                          </Form.Label>
                                          <Col sm={9}>
                                            <Field
                                              className="form-control"
                                              value={values.Address1}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "Address1",
                                                  e.target.value
                                                );
                                              }}
                                              type="text"
                                              name="Address1"
                                            />
                                            {errors.Address1 &&
                                            touched.Address1 ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.Address1}
                                                </div>
                                              </div>
                                            ) : null}
                                          </Col>
                                          <div className="fv-plugins-message-container" />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                          <Form.Group
                                            as={Row}
                                            controlId="formHorizontalEmail"
                                          >
                                            <Form.Label column sm={3}>
                                              {t("Address")} 2
                                            </Form.Label>
                                            <Col sm={9}>
                                              <input
                                                className="form-control"
                                                value={values.Address2}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "Address2",
                                                    e.target.value
                                                  );
                                                }}
                                                type="text"
                                                name="Address2"
                                              />
                                            </Col>
                                          </Form.Group>
                                          <div className="fv-plugins-message-container" />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container row">
                                          <Form.Label
                                            column
                                            sm={3}
                                            className="d-flex"
                                          >
                                            {t("Country")}
                                            <span className="d-lg-block d-xl-block d-md-block d-none">
                                              /{t("State")}
                                            </span>
                                            <RequiredHtml />
                                          </Form.Label>
                                          <Col
                                            xl="5"
                                            lg="5"
                                            md="5"
                                            className="mb-xl-0 mb-lg-0 mb-md-3 mb-6 country"
                                          >
                                            <ReactSelect
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "Country",
                                                  e?.value
                                                );
                                              }}
                                              name="Country"
                                              isSearchable={true}
                                              isClearable
                                              placeholder={"Select Country"}
                                              options={ContriesViewModel?.map(
                                                (a) => {
                                                  return {
                                                    label: a.Name,
                                                    value: a.Name,
                                                  };
                                                }
                                              )}
                                              value={ContriesViewModel.filter(
                                                (a) =>
                                                  a.Name === values?.Country
                                              ).map((a) => {
                                                return {
                                                  label: a.Name,
                                                  value: a.Name,
                                                };
                                              })}
                                            />
                                            {errors.Country &&
                                            touched.Country ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.Country}
                                                </div>
                                              </div>
                                            ) : null}
                                          </Col>
                                          <Form.Label
                                            column
                                            sm={4}
                                            className="d-block d-sm-none"
                                          >
                                            {t("State")}
                                            <RequiredHtml />
                                          </Form.Label>
                                          <Col xl="4" lg="4" md="4">
                                            <ReactSelect
                                              isSearchable={true}
                                              isClearable
                                              placeholder={t("Select_State")}
                                              options={ContriesViewModel?.find(
                                                (a) =>
                                                  a.Name === values?.Country
                                              )?.States?.map((a) => {
                                                return {
                                                  label: a.Name,
                                                  value: a.Name,
                                                };
                                              })}
                                              value={ContriesViewModel?.find(
                                                (a) =>
                                                  a.Name === values?.Country
                                              )
                                                ?.States?.filter(
                                                  (a) =>
                                                    a.Name === values?.State
                                                )
                                                ?.map((a) => {
                                                  return {
                                                    label: a.Name,
                                                    value: a.Name,
                                                  };
                                                })}
                                              onChange={(e) => {
                                                // console.log("e", e);
                                                setFieldValue(
                                                  "State",
                                                  e?.value
                                                );
                                              }}
                                            />
                                            {errors.State && touched.State ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.State}
                                                </div>
                                              </div>
                                            ) : null}
                                          </Col>
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                          <Form.Group
                                            as={Row}
                                            controlId="formHorizontalEmail"
                                          >
                                            <Form.Label
                                              column
                                              sm={3}
                                              className="d-flex"
                                            >
                                              {t("City")}
                                              <span className="d-lg-block d-xl-block d-md-block d-none">
                                                /{t("Zip")}
                                              </span>
                                              <RequiredHtml />
                                            </Form.Label>
                                            <div className="col-xl-5 col-sm-5 col-md-5 col-lg-5 col-12">
                                              {/*begin::Input*/}
                                              <div className="form-group fv-plugins-icon-container has-success">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="City"
                                                  value={values.City}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      "City",
                                                      e.target.value
                                                    );
                                                  }}
                                                  name="City"
                                                />
                                                {errors.City && touched.City ? (
                                                  <div className="fv-plugins-message-container">
                                                    <div
                                                      data-field="width"
                                                      data-validator="digits"
                                                      class="fv-help-block text-danger"
                                                    >
                                                      {errors.City}
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                              {/*end::Input*/}
                                            </div>

                                            <Form.Label
                                              column
                                              sm={3}
                                              className="d-block d-sm-none"
                                            >
                                              {t("Zip")}
                                              <RequiredHtml />
                                            </Form.Label>
                                            <div className="col-xl-4 col-sm-5 col-md-4 col-lg-4 col-12">
                                              {/*begin::Input*/}
                                              <div className="form-group fv-plugins-icon-container has-success">
                                                <input
                                                  type="text"
                                                  placeholder="zip code"
                                                  className="form-control"
                                                  value={values.Zipcode}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      "Zipcode",
                                                      e.target.value
                                                    );
                                                  }}
                                                  name="Zipcode"
                                                />
                                                {errors.Zipcode &&
                                                touched.Zipcode ? (
                                                  <div className="fv-plugins-message-container">
                                                    <div
                                                      data-field="width"
                                                      data-validator="digits"
                                                      class="fv-help-block text-danger"
                                                    >
                                                      {errors.Zipcode}
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                              {/*end::Input*/}
                                            </div>
                                          </Form.Group>
                                          <div className="fv-plugins-message-container" />
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                    </div>
                                  )}

                                  {step === 1 && (
                                    <Fragment>
                                      <h4 className="mb-10 font-weight-bolder text-dark">
                                        {t("Choose_Plan")}
                                      </h4>

                                      {errors.auth_option &&
                                      touched.auth_option ? (
                                        <div className="fv-plugins-message-container">
                                          <div
                                            data-field="width"
                                            data-validator="digits"
                                            class="fv-help-block text-danger"
                                          >
                                            {errors.auth_option}
                                          </div>
                                        </div>
                                      ) : null}

                                      <Fragment>
                                        {!isVeteran ? (
                                          <Row>
                                            {PlanOptions?.Plans?.map(
                                              (item, index) => {
                                                return (
                                                  <Col
                                                    xl="6"
                                                    lg="6"
                                                    md="6"
                                                    key={index}
                                                  >
                                                    <Field
                                                      type="radio"
                                                      className="btn-check"
                                                      checked={
                                                        values.PlanId ===
                                                        item.Id
                                                      }
                                                      value={item.Amount}
                                                      onClick={(e) => {
                                                        setCurrentSubscription(
                                                          item ?? null
                                                        );
                                                        setFieldValue(
                                                          "PlanId",
                                                          item.Id
                                                        );
                                                        setFieldValue(
                                                          "Amount",
                                                          item.Amount
                                                        );
                                                        setFieldValue(
                                                          "AmountName",
                                                          item.Name
                                                        );
                                                        setiniAmount(
                                                          item.Amount
                                                        );
                                                      }}
                                                      id={`kt_modal_two_factor_authentication_option_${index}`}
                                                      name="auth_option"
                                                    />
                                                    <label
                                                      className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5"
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                      htmlFor={`kt_modal_two_factor_authentication_option_${index}`}
                                                    >
                                                      <span className="d-block font-weightbold text-left">
                                                      <span className="text-dark font-weight-bolder font-size-h2">
                                                          ${item.Amount}
                                                        </span>{" / "}
                                                        <span className="text-muted font-weight-bolder font-size-h3">
                                                          {item.Name} fee
                                                        </span>
                                                       
                                                        <span className="text-muted font-weightbold font-size-h6">
                                                          {item.Reccuring}
                                                        </span>
                                                      </span>
                                                    </label>
                                                  </Col>
                                                );
                                              }
                                            )}
                                            {errors.PlanId && touched.PlanId ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.PlanId}
                                                </div>
                                              </div>
                                            ) : null}
                                          </Row>
                                        ) : (
                                          <Row
                                            style={{ placeContent: "center" }}
                                          >
                                            <Col xl="6" lg="6" md="6" key={999}>
                                              <Field
                                                type="radio"
                                                className="btn-check"
                                                checked={true}
                                                value={"9.99"}
                                                id={`kt_modal_two_factor_authentication_option_${99}`}
                                                name="auth_optionCc"
                                              />
                                              <label
                                                className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                                htmlFor={`kt_modal_two_factor_authentication_option_${99}`}
                                              >
                                                <span className="d-block font-weightbold text-left">
                                                  <span className="text-dark font-weight-bolder font-size-h3">
                                                    $9.99
                                                  </span>{" / "}
                                                  <span className="text-muted font-weightbold font-size-h6 text-center">
                                                    {"Veteran plan"}
                                                  </span>
                                                  <span className="text-muted font-weightbold font-size-h6"></span>
                                                </span>
                                              </label>
                                            </Col>
                                          </Row>
                                        )}
                                        {!isVeteran && (
                                          <Fragment>
                                            <div className="form-group fv-plugins-icon-container row">
                                              <Col lg="6" xl="6" md="6" sm="6">
                                                <Form.Label className="text-dark font-size-sm checkbox">
                                                  <input
                                                    type="checkbox"
                                                    name="CouponCheck"
                                                    onChange={(e) => {
                                                      setIsCoupon(
                                                        e.target.checked
                                                      );
                                                    }}
                                                    className="mr-2"
                                                    checked={couponChecked}
                                                  />
                                                  <span className="mr-2 mb-auto"></span>
                                                  {couponChecked
                                                    ? t(
                                                        "Yes_I_have_a_Discount_code"
                                                      )
                                                    : t(
                                                        "Do_you_have_a_Discount_code"
                                                      )}
                                                </Form.Label>
                                              </Col>
                                              <Col
                                                lg="6"
                                                xl="6"
                                                md="6"
                                                sm="6"
                                                style={{ textAlign: "right" }}
                                              >
                                                <p className="font-weight-bolder">
                                                  {values.Amount != null
                                                    ? `$${currencyFormat(
                                                        values.Amount
                                                      )}`
                                                    : ``}
                                                </p>
                                              </Col>
                                            </div>
                                            <div className="form-group fv-plugins-icon-container">
                                              <Row sm="7">
                                                {couponChecked && (
                                                  <Fragment>
                                                    <Col lg="6">
                                                      <div className="form-group">
                                                        <div className="input-group">
                                                          <input
                                                            className="form-control"
                                                            placeholder={t(
                                                              "Enter_a_valid_Discount_code"
                                                            )}
                                                            value={
                                                              values.Coupon
                                                            }
                                                            onKeyPress={(e) => {
                                                              if (
                                                                e.key ===
                                                                "Enter"
                                                              ) {
                                                                e.preventDefault();
                                                              }
                                                            }}
                                                            onChange={(e) => {
                                                              console.log(
                                                                e.target.value.trim()
                                                              );
                                                              try {
                                                                setFieldValue(
                                                                  "Coupon",
                                                                  e.target.value.trim()
                                                                );
                                                              } catch (error) {}
                                                            }}
                                                            type="text"
                                                            name="Coupon"
                                                          />
                                                          <div className="input-group-append">
                                                            <button
                                                              className="btn btnPrimaryTaap"
                                                              type="button"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                var requestOptionsC = {
                                                                  method: "GET",
                                                                  redirect:
                                                                    "follow",
                                                                };

                                                                fetch(
                                                                  `${window.$apiUrl}/api/Billing/ValidateCoupon?planId=${values.PlanId}&coupon=${values.Coupon}`,
                                                                  requestOptionsC
                                                                )
                                                                  .then(
                                                                    async (
                                                                      response
                                                                    ) => {
                                                                      const statusCode = await response.status;
                                                                      const contentType = response.headers.get(
                                                                        "content-type"
                                                                      );
                                                                      if (
                                                                        contentType &&
                                                                        contentType.indexOf(
                                                                          "application/json"
                                                                        ) !== -1
                                                                      ) {
                                                                        return response
                                                                          .json()
                                                                          .then(
                                                                            (
                                                                              data
                                                                            ) => [
                                                                              statusCode,
                                                                              data,
                                                                            ]
                                                                          );
                                                                      } else {
                                                                        return response
                                                                          .text()
                                                                          .then(
                                                                            (
                                                                              text
                                                                            ) => [
                                                                              statusCode,
                                                                              {
                                                                                Message:
                                                                                  "",
                                                                              },
                                                                            ]
                                                                          );
                                                                      }
                                                                    }
                                                                  )
                                                                  .then(
                                                                    (
                                                                      result
                                                                    ) => {
                                                                      if (
                                                                        result[0] ===
                                                                        200
                                                                      ) {
                                                                        setFieldValue(
                                                                          "Amount",
                                                                          result[1]
                                                                        );
                                                                      } else {
                                                                        setFieldValue(
                                                                          "Amount",
                                                                          iniAmount
                                                                        );
                                                                        if (
                                                                          result[1]
                                                                            .ModelState !==
                                                                            null &&
                                                                          result[1]
                                                                            .ModelState !==
                                                                            undefined &&
                                                                          result[1]
                                                                            .ModelState !==
                                                                            ""
                                                                        ) {
                                                                          let modelState =
                                                                            result[1]
                                                                              .ModelState;
                                                                          if (
                                                                            modelState
                                                                          )
                                                                            Object.keys(
                                                                              modelState
                                                                            ).forEach(
                                                                              function(
                                                                                k
                                                                              ) {
                                                                                modelState[
                                                                                  k
                                                                                ].forEach(
                                                                                  (
                                                                                    element
                                                                                  ) => {
                                                                                    Swal.fire(
                                                                                      t(
                                                                                        "Error"
                                                                                      ),
                                                                                      element
                                                                                    );
                                                                                  }
                                                                                );
                                                                              }
                                                                            );
                                                                        } else if (
                                                                          result[1]
                                                                            .Message !==
                                                                            null &&
                                                                          result[1]
                                                                            .Message !==
                                                                            undefined &&
                                                                          result[1]
                                                                            .Message !==
                                                                            ""
                                                                        ) {
                                                                          Swal.fire(
                                                                            t(
                                                                              "Error"
                                                                            ),
                                                                            translateAuto(
                                                                              result[1]
                                                                                .Message
                                                                            )
                                                                          );
                                                                        } else if (
                                                                          result[1]
                                                                            .error_description !==
                                                                            null &&
                                                                          result[1]
                                                                            .error_description !==
                                                                            undefined &&
                                                                          result[1]
                                                                            .error_description !==
                                                                            ""
                                                                        ) {
                                                                          Swal.fire(
                                                                            t(
                                                                              "Error"
                                                                            ),
                                                                            translateAuto(
                                                                              result[1]
                                                                                .error_description
                                                                            )
                                                                          );
                                                                        } else {
                                                                          Swal.fire(
                                                                            t(
                                                                              "Error"
                                                                            ),
                                                                            t(
                                                                              "Bad_Request"
                                                                            )
                                                                          );
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) => {
                                                                      Swal.fire(
                                                                        t(
                                                                          "Error"
                                                                        ),
                                                                        error
                                                                      );
                                                                    }
                                                                  );
                                                              }}
                                                            >
                                                              {t("Apply")}
                                                            </button>
                                                          </div>
                                                          {errors.Coupon ? (
                                                            <div className="fv-plugins-message-container invalid-feedback">
                                                              * {errors.Coupon}
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Fragment>
                                                )}
                                              </Row>
                                              <div className="fv-plugins-message-container" />
                                            </div>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    </Fragment>
                                  )}
                                  {step === 2 && (
                                    <div className="pb-5">
                                      <h4
                                        className="mb-5 font-weight-bold text-dark"
                                        id="titleCredit"
                                      >
                                        {t("Set_up_your_credit_or_debit_card")}
                                      </h4>
                                      {/* {verField ? (
                       <div className="fv-plugins-message-container pb-3">
                         <div
                           data-field="width"
                           data-validator="digits"
                           class="fv-help-block text-danger"
                         >
                           Please check your required fields
                         </div>
                       </div>
                     ) : null} */}
                                      <Col
                                        sm="10"
                                        className="pl-0 mb-5 checkbox-list"
                                      >
                                        <Form.Label className="text-dark font-size-sm checkbox">
                                          <input
                                            type="checkbox"
                                            name="Checkboxes1"
                                            onChange={(e) => {
                                              setFieldValue(
                                                "usesameaddress",
                                                e.target.checked
                                              );
                                              const isChecked =
                                                e.target.checked;
                                              if (isChecked) {
                                                setFieldValue(
                                                  "BillingAddress2",
                                                  values.Address2
                                                );
                                                setFieldValue(
                                                  "BillingAddress1",
                                                  values.Address1
                                                );
                                                setFieldValue(
                                                  "BillingZipcode",
                                                  values.Zipcode
                                                );
                                                setFieldValue(
                                                  "BillingCity",
                                                  values.City
                                                );
                                                setFieldValue(
                                                  "BillingState",
                                                  values.State
                                                );
                                                setFieldValue(
                                                  "BillingCountry",
                                                  values.Country
                                                );
                                              } else {
                                                setFieldValue(
                                                  "BillingAddress2",
                                                  ""
                                                );
                                                setFieldValue(
                                                  "BillingAddress1",
                                                  ""
                                                );
                                                setFieldValue(
                                                  "BillingZipcode",
                                                  ""
                                                );
                                                setFieldValue(
                                                  "BillingCity",
                                                  ""
                                                );
                                                setFieldValue(
                                                  "BillingState",
                                                  ""
                                                );
                                                setFieldValue(
                                                  "BillingCountry",
                                                  ""
                                                );
                                              }
                                              setCheckBox(e.target.checked);
                                            }}
                                            className="mr-2"
                                            checked={checkbox}
                                          />
                                          <span></span>
                                          {t("Use_same_address")}
                                        </Form.Label>
                                      </Col>
                                      <Fragment>
                                        <div className="form-group fv-plugins-icon-container">
                                          <Form.Group
                                            as={Row}
                                            controlId="formHorizontalEmail"
                                          >
                                            <Form.Label column sm={3}>
                                              {t("Address")} 1
                                              <RequiredHtml />
                                            </Form.Label>
                                            <Col sm={9}>
                                              <input
                                                className="form-control form-control-lg "
                                                value={values.BillingAddress1}
                                                placeholder={
                                                  t("Address") + " 1"
                                                }
                                                disabled={checkbox}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "BillingAddress1",
                                                    e.target.value
                                                  );
                                                }}
                                                type="text"
                                                name="BillingAddress1"
                                              />
                                            </Col>
                                          </Form.Group>
                                          <div className="fv-plugins-message-container" />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                          <Form.Group
                                            as={Row}
                                            controlId="formHorizontalEmail"
                                          >
                                            <Form.Label column sm={3}>
                                              {t("Address")} 2
                                            </Form.Label>
                                            <Col sm={9}>
                                              <input
                                                className="form-control form-control-lg "
                                                value={values.BillingAddress2}
                                                disabled={checkbox}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "BillingAddress2",
                                                    e.target.value
                                                  );
                                                }}
                                                type="text"
                                                name="BillingAddress2"
                                                placeholder={
                                                  t("Address") + " 2"
                                                }
                                              />
                                            </Col>
                                          </Form.Group>
                                          <div className="fv-plugins-message-container" />
                                        </div>
                                        <div className="row">
                                          <Form.Label
                                            column
                                            sm={3}
                                            className="d-flex"
                                          >
                                            {t("City")}
                                            <span className="d-lg-block d-xl-block d-md-block d-none">
                                              /{t("State")}
                                            </span>
                                          </Form.Label>
                                          <div className="col-xl-4 col-md-4 col-lg-4 col-12">
                                            {/*begin::Input*/}
                                            <div className="form-group fv-plugins-icon-container has-success">
                                              <input
                                                type="text"
                                                className="form-control  form-control-lg"
                                                value={values.BillingCity}
                                                disabled={checkbox}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "BillingCity",
                                                    e.target.value
                                                  );
                                                }}
                                                name="BillingCity"
                                                placeholder={t("Billing_City")}
                                              />
                                              <div className="fv-plugins-message-container" />
                                            </div>
                                            {/*end::Input*/}
                                          </div>
                                          <Form.Label
                                            column
                                            sm={4}
                                            className="d-block d-sm-none"
                                          >
                                            {t("State")}
                                          </Form.Label>
                                          <Col xl="5" lg="5" md="5">
                                            <ReactSelect
                                              isSearchable={true}
                                              isClearable
                                              placeholder={t("Select_State")}
                                              isDisabled={checkbox}
                                              options={ContriesViewModel?.find(
                                                (a) =>
                                                  a.Name ===
                                                  values?.BillingCountry
                                              )?.States?.map((a) => {
                                                return {
                                                  label: a.Name,
                                                  value: a.Name,
                                                };
                                              })}
                                              value={ContriesViewModel?.find(
                                                (a) =>
                                                  a.Name ===
                                                  values?.BillingCountry
                                              )
                                                ?.States?.filter(
                                                  (a) =>
                                                    a.Name ===
                                                    values?.BillingState
                                                )
                                                ?.map((a) => {
                                                  return {
                                                    label: a.Name,
                                                    value: a.Name,
                                                  };
                                                })}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "BillingState",
                                                  e?.value
                                                );
                                              }}
                                            />
                                          </Col>
                                          <Form.Label
                                            column
                                            sm={3}
                                            className="d-flex"
                                          >
                                            {t("Zip")}
                                            <span className="d-lg-block d-xl-block d-md-block d-none">
                                              /{t("Country")}
                                            </span>
                                          </Form.Label>
                                          <div className="col-xl-4 col-md-4 col-lg-4 col-12">
                                            {/*begin::Input*/}
                                            <div className="form-group fv-plugins-icon-container has-success">
                                              <input
                                                type="text"
                                                className="form-control  form-control-lg"
                                                value={values.BillingZipcode}
                                                disabled={checkbox}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "BillingZipcode",
                                                    e.target.value
                                                  );
                                                }}
                                                name="BillingZipcode"
                                                placeholder={t(
                                                  "Billing_Zip_Code"
                                                )}
                                              />
                                              <div className="fv-plugins-message-container" />
                                            </div>
                                            {/*end::Input*/}
                                          </div>
                                          <Form.Label
                                            column
                                            sm={3}
                                            className="d-block d-sm-none"
                                          >
                                            {t("Country")}
                                          </Form.Label>
                                          <Col
                                            xl="5"
                                            lg="5"
                                            md="5"
                                            className="mb-xl-0 mb-lg-0 mb-md-3 mb-6"
                                          >
                                            <ReactSelect
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "BillingCountry",
                                                  e?.value
                                                );
                                              }}
                                              name="BillingCountry"
                                              isSearchable={true}
                                              isDisabled={checkbox}
                                              isClearable
                                              placeholder={"Select Country"}
                                              options={ContriesViewModel?.map(
                                                (a) => {
                                                  return {
                                                    label: a.Name,
                                                    value: a.Name,
                                                  };
                                                }
                                              )}
                                              value={ContriesViewModel.filter(
                                                (a) =>
                                                  a.Name ===
                                                  values?.BillingCountry
                                              ).map((a) => {
                                                return {
                                                  label: a.Name,
                                                  value: a.Name,
                                                };
                                              })}
                                            />
                                          </Col>
                                        </div>
                                        {/*end::Input*/}
                                      </Fragment>

                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>
                                          {t("Name_On_Card")}{" "}
                                          <span class="text-danger">*</span>
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip
                                                style={{
                                                  pointerEvents: "none",
                                                }}
                                                id="products-delete-tooltip"
                                              >
                                                {t(
                                                  "Specify_a_card_holders_name"
                                                )}
                                              </Tooltip>
                                            }
                                          >
                                            <i class="fa fa-exclamation-circle ml-2 fs-7"></i>
                                          </OverlayTrigger>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control  form-control-lg"
                                          value={values.CardName}
                                          placeholder={t("Name_On_Card")}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "CardName",
                                              e.target.value
                                            );
                                          }}
                                          name="CardName"
                                        />

                                        {errors.CardName && touched.CardName ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block text-danger"
                                            >
                                              {errors.CardName}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      {/*end::Input*/}
                                      {/*begin::Input*/}
                                      <div className="form-group">
                                        <label>
                                          {t("Card_Number")}
                                          <span class="text-danger">*</span>
                                        </label>
                                        <div className="position-relative">
                                          <input
                                            type="number"
                                            className="form-control  form-control-lg"
                                            value={values.CardNumber}
                                            maxLength="16"
                                            placeholder={t("Card_Number")}
                                            onChange={(e) => {
                                              const length =
                                                e?.target?.value?.length;
                                              if (length > 16) return false;
                                              setFieldValue(
                                                "CardNumber",
                                                e.target.value
                                              );
                                            }}
                                            name="CardNumber"
                                          />
                                          {values.CardNumber !== "" && (
                                            <div class="position-absolute translate-middle-c top-50 end-0 mr-5">
                                              <img
                                                src={toAbsoluteUrl(
                                                  creditCardTypeFromNumber(
                                                    values.CardNumber
                                                  ) === "Visa"
                                                    ? "/media/svg/card-logos/visa.svg"
                                                    : creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "MasterCard"
                                                    ? "/media/svg/card-logos/mastercard.svg"
                                                    : creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "Amex"
                                                    ? "/media/svg/card-logos/american-express.svg"
                                                    : creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "Discover"
                                                    ? "/media/svg/card-logos/discover.png"
                                                    : creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "UnionPay"
                                                    ? "/media/svg/card-logos/union-pay.png"
                                                    : creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "JCB"
                                                    ? "/media/svg/card-logos/jcb.png"
                                                    : creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "Diners"
                                                    ? "/media/svg/card-logos/diners-club.png"
                                                    : creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "Union Pay"
                                                    ? "/media/svg/card-logos/unionpay.png"
                                                    : ""
                                                )}
                                                alt=""
                                                class="h-25px"
                                              />
                                            </div>
                                          )}
                                        </div>
                                        {errors.CardNumber &&
                                        touched.CardNumber ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block text-danger"
                                            >
                                              {errors.CardNumber}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      {/*end::Input*/}
                                      <div className="row">
                                        <div className="col-xl-4 cl-lg-4 col-md-4 col-12">
                                          {/*begin::Input*/}
                                          <div className="form-group fv-plugins-icon-container">
                                            <label>
                                              {t("Expiration_Date")}{" "}
                                              <span class="text-danger">*</span>
                                            </label>
                                            <ReactSelect
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "CardExpiryMonth",
                                                  e?.value
                                                );
                                              }}
                                              name="CardExpiryMonth"
                                              isSearchable={true}
                                              placeholder={t("Select_Month")}
                                              value={listMonth
                                                ?.filter(
                                                  (x) =>
                                                    x === values.CardExpiryMonth
                                                )
                                                .map((a) => {
                                                  return {
                                                    label: a,
                                                    value: a,
                                                  };
                                                })}
                                              options={listMonth.map((a) => {
                                                return {
                                                  label: a,
                                                  value: a,
                                                };
                                              })}
                                            />
                                            {errors.CardExpiryMonth &&
                                            touched.CardExpiryMonth ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.CardExpiryMonth}
                                                </div>
                                              </div>
                                            ) : null}
                                            <div className="fv-plugins-message-container" />
                                          </div>
                                          {/*end::Input*/}
                                        </div>

                                        <div className="col-xl-4 cl-lg-4 col-md-4 col-12">
                                          {/*begin::Input*/}
                                          <div className="form-group fv-plugins-icon-container mt-xl-8 mt-lg-8 mt-md-8 mt-0">
                                            <ReactSelect
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "CardExpiryYear",
                                                  e?.value
                                                );
                                              }}
                                              name="CardExpiryYear"
                                              isSearchable={true}
                                              placeholder={t("Select_Year")}
                                              value={listYear
                                                ?.filter(
                                                  (x) =>
                                                    x === values.CardExpiryYear
                                                )
                                                .map((a) => {
                                                  return {
                                                    label: a,
                                                    value: a,
                                                  };
                                                })}
                                              options={listYear.map((a) => {
                                                return {
                                                  label: a,
                                                  value: a,
                                                };
                                              })}
                                            />
                                            {errors.CardExpiryYear &&
                                            touched.CardExpiryYear ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.CardExpiryYear}
                                                </div>
                                              </div>
                                            ) : null}
                                            <div className="fv-plugins-message-container" />
                                          </div>
                                          {/*end::Input*/}
                                        </div>

                                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                          {/*begin::Input*/}
                                          <div className="form-group fv-plugins-icon-container">
                                            <label>
                                              {t("CVV")}{" "}
                                              <span class="text-danger">*</span>
                                              <OverlayTrigger
                                                overlay={
                                                  <Tooltip
                                                    style={{
                                                      pointerEvents: "none",
                                                    }}
                                                    id="products-delete-tooltip"
                                                  >
                                                    {t("Enter_a_card_CVV_code")}
                                                  </Tooltip>
                                                }
                                              >
                                                <i class="fa fa-exclamation-circle ml-2 fs-7"></i>
                                              </OverlayTrigger>
                                            </label>
                                            <div className="position-relative">
                                              <Field
                                                type="number"
                                                className="form-control form-control-md"
                                                value={values.CardCvc}
                                                placeholder="CVV"
                                                max="4"
                                                onChange={(e) => {
                                                  const length =
                                                    e?.target?.value?.length;
                                                  if (length > 4) return false;
                                                  setFieldValue(
                                                    "CardCvc",
                                                    e.target.value
                                                  );
                                                }}
                                                name="CardCvc"
                                              />
                                              <div className="position-absolute translate-middle-c top-50 end-0 mr-3">
                                                <span className="svg-icon svg-icon-2hx">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M22 7H2V11H22V7Z"
                                                      fill="currentColor"
                                                    />
                                                    <path
                                                      opacity="0.3"
                                                      d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                                                      fill="currentColor"
                                                    />
                                                  </svg>
                                                </span>
                                              </div>
                                            </div>
                                            {errors.CardCvc &&
                                            touched.CardCvc ? (
                                              <div className="fv-plugins-message-container">
                                                <div
                                                  data-field="width"
                                                  data-validator="digits"
                                                  class="fv-help-block text-danger"
                                                >
                                                  {errors.CardCvc}
                                                </div>
                                              </div>
                                            ) : null}
                                            <div className="fv-plugins-message-container" />
                                          </div>
                                          {/*end::Input*/}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {step === 3 && (
                                    <div className="pb-5">
                                      <h4 className="mb-10 font-weight-bold text-dark">
                                        {t("Review_your_Details_and_Submit")}
                                      </h4>
                                      <div
                                        className="label label-lg labelPrimary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                                        style={{ fontSize: "15px" }}
                                      >
                                        {t("Account_Settings")}
                                      </div>
                                      <div className="text-dark-50 line-height-lg p-3">
                                        <div className="mb-2">
                                          <span className="font-weight-bolder pr-2">
                                            {t("Full_Name")}:
                                          </span>{" "}
                                          {dataUser.FirstName}{" "}
                                          {dataUser.LastName}
                                        </div>
                                        <div className="mb-2">
                                          <span className="font-weight-bolder pr-2">
                                            {t("Email")}:
                                          </span>{" "}
                                          {dataUser.Email}
                                        </div>
                                        <div>
                                          <span className="font-weight-bolder pr-2">
                                            {t("Phone")}:
                                          </span>{" "}
                                          {dataUser.PhoneNumber}
                                        </div>
                                      </div>
                                      <div
                                        className="label label-xl labelPrimary label-inline border-0 w-100 font-weight-bold text-left p-5 p-xl-5 p-lg-5 py-10 px-2 my-5 justify-content-start"
                                        style={{ fontSize: "15px" }}
                                      >
                                        {t("Company_Information")}{" "}
                                        {values.usesameaddress &&
                                          "(Billing Address)"}
                                      </div>
                                      <div className="">
                                        <div className="text-dark-50 line-height-lg">
                                          <div className="mb-2">
                                            <span className="font-weight-bolder pr-2">
                                              {t("Company_Name")}:
                                            </span>{" "}
                                            {values.CompanyName}
                                          </div>
                                          {values.AuthorityType === 1 ? (
                                            <Fragment>
                                              <div className="mb-2">
                                                <span className="font-weight-bolder pr-2">
                                                  {t("Dot_Number")}:
                                                </span>{" "}
                                                {values.DotNumber}
                                              </div>
                                              <div className="mb-2">
                                                <span className="font-weight-bolder pr-2">
                                                  {t("Scac_Code")}:
                                                </span>{" "}
                                                {values.ScacCode}
                                              </div>
                                            </Fragment>
                                          ) : values.AuthorityType === 2 ? (
                                            <Fragment>
                                              <div className="mb-2">
                                                <span className="font-weight-bolder pr-2">
                                                  {t("MC Number")}:
                                                </span>{" "}
                                                {values.McNumber}
                                              </div>
                                              <div className="mb-2">
                                                <span className="font-weight-bolder pr-2">
                                                  {t("Scac_Code")}:
                                                </span>{" "}
                                                {values.ScacCode}
                                              </div>
                                            </Fragment>
                                          ) : (
                                            ""
                                          )}
                                          <div className="mb-2">
                                            <span className="font-weight-bolder pr-2">
                                              {t("Address")} 1:
                                            </span>{" "}
                                            {values.Address1}
                                          </div>
                                          <div className="mb-2">
                                            <span className="font-weight-bolder pr-2">
                                              {t("Address")} 2:
                                            </span>{" "}
                                            {values.Address2}
                                          </div>
                                          <div className="mb-2">
                                            <span className="font-weight-bolder pr-2">
                                              {t("City")}:
                                            </span>{" "}
                                            {values.City}
                                          </div>
                                          <div className="mb-2">
                                            <span className="font-weight-bolder pr-2">
                                              {t("State")}:
                                            </span>{" "}
                                            {values.State}
                                          </div>
                                          <div className="mb-2">
                                            <span className="font-weight-bolder pr-2">
                                              {t("Zip_Code")}:
                                            </span>{" "}
                                            {values.Zipcode}
                                          </div>
                                          <div className="mb-2">
                                            <span className="font-weight-bolder pr-2">
                                              {t("Country")}:
                                            </span>{" "}
                                            {values.Country}
                                          </div>
                                        </div>
                                        <div
                                          className="label label-lg labelPrimary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                                          style={{ fontSize: "15px" }}
                                        >
                                          {t("Billing_Details")}
                                        </div>
                                        <div className="">
                                          <div className="text-dark-50 line-height-lg">
                                            <div>
                                              <span className="font-weight-bolder pr-2">
                                                {t("Amount")}:
                                              </span>{" "}
                                              {isVeteran ? (
                                                "Veteran plan / $9.99"
                                              ) : (
                                                <span>
                                                  {!!currentSubscription
                                                    ? `${currentSubscription.Name} / $${currentSubscription.Amount}`
                                                    : ""}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="text-dark-50 line-height-lg pt-3">
                                            <div>
                                              <span className="font-weight-bolder pr-2">
                                                {t("Name_On_Card")}:
                                              </span>{" "}
                                              {values.CardName}
                                            </div>
                                            <div className="d-flex pt-3 pb-2">
                                              <span className="font-weight-bolder pr-2">
                                                {t("Card_Number")}:
                                              </span>{" "}
                                              <div className="d-flex">
                                                <span className="pr-3 pt-1">
                                                  {values.CardNumber?.replace(
                                                    /.(?=.{4})/g,
                                                    "*"
                                                  )}
                                                </span>
                                                <div>
                                                  <img
                                                    src={toAbsoluteUrl(
                                                      creditCardTypeFromNumber(
                                                        values.CardNumber
                                                      ) === "Visa"
                                                        ? "/media/svg/card-logos/visa.svg"
                                                        : creditCardTypeFromNumber(
                                                            values.CardNumber
                                                          ) === "MasterCard"
                                                        ? "/media/svg/card-logos/mastercard.svg"
                                                        : creditCardTypeFromNumber(
                                                            values.CardNumber
                                                          ) === "Amex"
                                                        ? "/media/svg/card-logos/american-express.svg"
                                                        : creditCardTypeFromNumber(
                                                            values.CardNumber
                                                          ) === "Discover"
                                                        ? "/media/svg/card-logos/discover.png"
                                                        : creditCardTypeFromNumber(
                                                            values.CardNumber
                                                          ) === "UnionPay"
                                                        ? "/media/svg/card-logos/union-pay.png"
                                                        : creditCardTypeFromNumber(
                                                            values.CardNumber
                                                          ) === "JCB"
                                                        ? "/media/svg/card-logos/jcb.png"
                                                        : creditCardTypeFromNumber(
                                                            values.CardNumber
                                                          ) === "Diners"
                                                        ? "/media/svg/card-logos/diners-club.png"
                                                        : creditCardTypeFromNumber(
                                                            values.CardNumber
                                                          ) === "Union Pay"
                                                        ? "/media/svg/card-logos/unionpay.png"
                                                        : ""
                                                    )}
                                                    alt=""
                                                    class="h-25px"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {!values.usesameaddress && (
                                          <Fragment>
                                            <div
                                              className="label label-lg labelPrimary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {t("Billing_Address")}
                                            </div>
                                            <div className="p-3">
                                              <div className="text-dark-50 line-height-lg">
                                                <div className="mb-2">
                                                  <span className="font-weight-bolder pr-2">
                                                    {t("Address")} 1:
                                                  </span>{" "}
                                                  {values.BillingAddress1}
                                                </div>
                                                <div className="mb-2">
                                                  <span className="font-weight-bolder pr-2">
                                                    {t("Address")} 2:
                                                  </span>{" "}
                                                  {values.BillingAddress2}
                                                </div>
                                                <div className="mb-2">
                                                  <span className="font-weight-bolder pr-2">
                                                    {t("Country")}:
                                                  </span>{" "}
                                                  {values.BillingCountry}
                                                </div>
                                                <div className="mb-2">
                                                  <span className="font-weight-bolder pr-2">
                                                    {t("City")}:
                                                  </span>{" "}
                                                  {values.BillingCity}
                                                </div>
                                                <div className="mb-2">
                                                  <span className="font-weight-bolder pr-2">
                                                    {t("State")}:
                                                  </span>{" "}
                                                  {values.BillingState}
                                                </div>
                                                <div className="mb-2">
                                                  <span className="font-weight-bolder pr-2">
                                                    {t("Zip")}:
                                                  </span>{" "}
                                                  {values.BillingZipcode}
                                                </div>
                                              </div>
                                            </div>
                                          </Fragment>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                    {step !== 0 && (
                                      <div className="mr-2">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setstep(step - 1);
                                          }}
                                          className="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4"
                                        >
                                          {t("Back")}
                                        </button>
                                      </div>
                                    )}
                                    <div className="ml-auto">
                                      {step === 3 && (
                                        <button
                                          onClick={(e) => {
                                            SubmitForm(values);
                                            e.preventDefault();
                                            return false;
                                          }}
                                          disabled={isLoadingAuth}
                                          className="btn btnPrimaryTaap font-weight-bolder text-uppercase px-9 py-4 btnSubmit"
                                        >
                                          {t("Submit")}
                                          {isLoadingAuth && (
                                            <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </button>
                                      )}
                                      {step !== 3 && (
                                        <Fragment>
                                          {step === 0 && (
                                            <button
                                              className="btn btn-default font-weight-bolder text-uppercase px-9 py-4 mr-3"
                                              onClick={(e) => {
                                                setIsNotVeteran(false);
                                                e.preventDefault();
                                              }}
                                            >
                                              {t("Back")}
                                            </button>
                                          )}

                                          <button
                                            type="button"
                                            onClick={() => {
                                              if (step === 0) {
                                                if (isVeteran) {
                                                  setFieldValue("PlanId", 0);
                                                  setFieldValue(
                                                    "auth_option",
                                                    ""
                                                  );
                                                }
                                                //usesame address
                                                if (
                                                  values.usesameaddress ===
                                                    undefined ||
                                                  values.usesameaddress === true
                                                ) {
                                                  setFieldValue(
                                                    "BillingAddress2",
                                                    values.Address2
                                                  );
                                                  setFieldValue(
                                                    "BillingAddress1",
                                                    values.Address1
                                                  );
                                                  setFieldValue(
                                                    "BillingZipcode",
                                                    values.Zipcode
                                                  );
                                                  setFieldValue(
                                                    "BillingCity",
                                                    values.City
                                                  );
                                                  setFieldValue(
                                                    "BillingState",
                                                    values.State
                                                  );
                                                  setFieldValue(
                                                    "BillingCountry",
                                                    values.Country
                                                  );
                                                }

                                                if (
                                                  !!values.CompanyName === false
                                                ) {
                                                  setFieldTouched(
                                                    "CompanyName",
                                                    true
                                                  );

                                                  // return;
                                                }
                                                if (
                                                  !!values.Address1 === false
                                                ) {
                                                  setFieldTouched(
                                                    "Address1",
                                                    true
                                                  );
                                                }
                                                if (!!values.City === false) {
                                                  setFieldTouched("City", true);
                                                }
                                                if (!!values.State === false) {
                                                  setFieldTouched(
                                                    "State",
                                                    true
                                                  );
                                                }
                                                if (
                                                  !!values.Zipcode === false
                                                ) {
                                                  setFieldTouched(
                                                    "Zipcode",
                                                    true
                                                  );
                                                }
                                                if (
                                                  !!values.Country === false
                                                ) {
                                                  setFieldTouched(
                                                    "Country",
                                                    true
                                                  );
                                                }
                                                if (
                                                  values.AuthorityType === 1
                                                ) {
                                                  if (!!dotNumber === false) {
                                                    setDotNumberError(true);
                                                    return;
                                                  }
                                                }
                                                if (
                                                  values.AuthorityType === 2
                                                ) {
                                                  if (!!mcNumber === false) {
                                                    setMcNumberError(true);
                                                    return;
                                                  }
                                                }
                                                if (
                                                  !!values.CompanyName ===
                                                    false ||
                                                  !!values.Address1 === false ||
                                                  !!values.City === false ||
                                                  !!values.State === false ||
                                                  !!values.Zipcode === false ||
                                                  !!values.Country === false
                                                ) {
                                                  return;
                                                }
                                              }

                                              if (step === 1) {
                                                if (
                                                  (values.PlanId === "" ||
                                                    values.PlanId ===
                                                      undefined ||
                                                    values.PlanId === null) &&
                                                  !isVeteran
                                                ) {
                                                  setFieldTouched(
                                                    "auth_option",
                                                    true
                                                  );
                                                }
                                                if (
                                                  couponChecked === true &&
                                                  !!values.Coupon === false
                                                ) {
                                                  setFieldTouched(
                                                    "Coupon",
                                                    true
                                                  );
                                                }
                                                if (
                                                  values.PlanId === "" ||
                                                  values.PlanId === undefined ||
                                                  values.PlanId === null
                                                ) {
                                                  return;
                                                }
                                                if (
                                                  couponChecked === true &&
                                                  !!values.Coupon === false
                                                ) {
                                                  return;
                                                }
                                              }

                                              if (step === 2) {
                                                setFieldTouched(
                                                  "CardCvc",
                                                  true
                                                );
                                                setFieldTouched(
                                                  "CardName",
                                                  true
                                                );
                                                setFieldTouched(
                                                  "CardNumber",
                                                  true
                                                );
                                                setFieldTouched(
                                                  "CardExpiryMonth",
                                                  true
                                                );
                                                setFieldTouched(
                                                  "CardExpiryYear",
                                                  true
                                                );

                                                if (
                                                  !!values.CardCvc === false ||
                                                  !!values.CardName === false ||
                                                  !!values.CardNumber ===
                                                    false ||
                                                  !!values.CardExpiryMonth ===
                                                    false ||
                                                  !!values.CardExpiryYear ===
                                                    false
                                                ) {
                                                  return;
                                                }
                                                if (
                                                  values.CardNumber.length < 13
                                                ) {
                                                  return;
                                                }
                                                if (values.CardCvc.length < 3) {
                                                  return;
                                                }
                                              }
                                              setstep(step + 1);
                                            }}
                                            className="btn btnPrimaryTaap font-weight-bolder text-uppercase px-9 py-4 btnNext"
                                          >
                                            {t("Next")}
                                          </button>
                                        </Fragment>
                                      )}
                                    </div>
                                  </div>
                                  {/*end::Wizard Actions*/}
                                  <div />
                                  <div />
                                  <div />
                                  <div />
                                </form>
                              );
                            }}
                          </Formik>
                          {/*end::Wizard Form*/}
                        </div>
                      </div>
                      {/*end::Wizard Body*/}
                    </div>
                    {/*end::Wizard*/}
                  </div>
                  {/*end::Wizard*/}
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
