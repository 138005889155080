/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { ProductDetailForm } from "./ProductDetailForm";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../../../_metronic/_helpers/AtHelper";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function ProductDetail({
  history,
  match: {
    params: { id },
  },
}) {
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const [ActivitiesList, setActivitiesList] = useState([]);
  const { t } = useTranslation();
  function getActivitiesData(LoadId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/api/Shipments/Activities/${LoadId}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setActivitiesList(result[1]?.Activities || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    if (id) {
      getActivitiesData(id);
    }
  }, [id]);
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);

  const [initProduct, setinitProduct] = useState({
    ShipmentDate: null,
    ModeId: null,
    EquipmentTypeId: null,
    BillToId: null,
    TotalPrice: null,
    SealNumber: "",
    Equipment: "",
    PickupId: null,
    PickupNumber: "",
    ShippingSpecialReq: "",
    ReqShipDate: null,
    PuAppointmentDate: null,
    PuAppointmentDateTo: null,
    DestinationId: null,
    DestinationNumber: "",
    Mabd: null,
    DelAppointmentDate: null,
    DelAppointmentDateTo: null,
    ReceivingSpecialReq: "",
    IsDraft: true,
    SignatureRequired: true,
    BiddingEnabled:false
  });
  function getShipmentData(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(
      `${window.$apiurl}/api/Shipments/${params}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setinitProduct({ id: id, ...result[1].Shipment });
        } else if (result[0] === 404) {
          history.push("/error/error-v1");
        } else if (result[0] === 402) {
          Swal.fire(t("Error"), translateAuto(result[1].Message));
          history.push("/user-profile#billing");
        } else if (result[0] === 401) {
          history.push("/logout");
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  console.log("error", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            console.log("error", result[1].Message);
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            console.log("error", result[1].error_description);
          } else {
            console.log("error", t("Bad_request"));
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    if (id) {
      getShipmentData(id);
    }
  }, [id, dispatch]);
  function postShipment(params) {
    if (
      params.ModeId === null ||
      params.ModeId === "" ||
      params.ModeId === undefined
    ) {
      Swal.fire(t("Error"), t("Please_select_a_mode"));
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(params);

    setisLoadingAuth(true);

    return fetch(
      `${window.$apiurl}/api/Shipments`,
      {
        method: "post",
        headers: myHeaders,
        body: raw,
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          history.push(`/taap/loads/${result[1].Id}/edit`);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire(t("Error"), translateAuto(element));
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_Request"));
          }
        }
        setisLoadingAuth(false);
      })
      .catch((error) => {
        Swal.fire(t("Error"), error);
        setisLoadingAuth(false);
      });
  }
  function putShipment(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(params);

    setisLoadingAuth(true);

    return fetch(
      `${window.$apiurl}/api/Shipments/${params.Id}`,
      {
        method: "Put",
        headers: myHeaders,
        body: raw,
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          Swal.fire(
            t("Great"),
            t("Shipment_has_been_Updated_successfully"),
            "success"
          );
          getShipmentData(id);
          getActivitiesData(id);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Swal.fire(t("Error"), translateAuto(element), "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].Message));
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire(t("Error"), translateAuto(result[1].error_description));
          } else {
            Swal.fire(t("Error"), t("Bad_Request"));
          }
        }
        setisLoadingAuth(false);
      })
      .catch((error) => {
        Swal.fire(t("Error"), error);
        setisLoadingAuth(false);
      });
  }
  const saveProduct = (values) => {
    if (values.Id !== undefined) {
      putShipment(values);
    } else {
      postShipment(values);
    }

    // if (!id) {
    //   dispatch(actions.createProduct(values)).then(() => backToProductsList());
    // } else {
    //   dispatch(actions.updateProduct(values)).then(() => backToProductsList());
    // }
  };

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    history.push(`/taap/loads`);
  };

  return (
    <>
      <ProductDetailForm
        actionsLoading={isLoadingAuth}
        product={initProduct}
        btnRef={btnRef}
        saveProduct={saveProduct}
        backToProductsList={backToProductsList}
        saveProductClick={saveProductClick}
        isLoadingAuth={isLoadingAuth}
        setisLoadingAuth={setisLoadingAuth}
        getShipmentData={getShipmentData}
        ActivitiesList={ActivitiesList}
        setActivitiesList={setActivitiesList}
      />
    </>
  );
}
