/* eslint-disable react-hooks/exhaustive-deps */
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { GoogleMap } from "../../../../../../_metronic/_partials/dashboards/GoogleMapComp";

import { Helmet } from "react-helmet";
import { RequiredHtml } from "../../../../../../_metronic/layout/components/custom";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  ConvertToDateCurrentTimeZone,
  translateAuto,
  validateEmail,
} from "../../../../../../_metronic/_helpers/AtHelper";
import DynamicReport from "../../../../../pages/DynamicReport";
import AttachementComp from "../../../../ECommerce/pages/products/LoadComponent/Attachement";
import { apiCall } from "../redux";
import { BillForm } from "../Report";
import { ModalIndex } from "../table/modal";
const adapter = new LocalStorage("db");
const db = low(adapter);

export const TrackLoadDetail = ({
  match: {
    params: { id },
  },
}) => {
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const [ShowModalShare, setShowModalShare] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ListOfEmailsToSahareLoads, setListOfEmailsToSahareLoads] = useState(
    []
  );
  const [LoadInformations, setLoadInformations] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [DetailTrakingGeometryInfo, setDetailTrakingGeometryInfo] = useState(
    []
  );
  const { t } = useTranslation();

  const currentEmail = db.get("User").value()?.Email;

  function getLoadInfo(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/Tracking/${params}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          // console.log('data',data);
          setLoadInformations(data);
          setDetailTrakingGeometryInfo(data?.Polyline || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const [detail, setDetail] = useState(null);
  useEffect(() => {
    if (id) {
      getLoadInfo(id);
      getFetchDataEffect(`/api/myloads/details/${id}`, setDetail);
    }
  }, [id]);
  async function getFetchDataEffect(link, setState) {
    const result1 = await apiCall({
      method: "Get",
      link: link,
    });
    if (result1?.length > 0 && result1[0] === 200) setState(result1[1]);
  }
  // const suhbeader = useSubheader();
  // suhbeader.setTitle(t("Track_Load"));
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  useEffect(() => {
    return () => {};
  }, [user]);

  const [drivers, setDrivers] = useState([]);
  const [modalAssign, setModalAssign] = useState(false);
  const [loaderAssign, setLoaderAssign] = useState(false);
  const [driverValue, setDriverValue] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);

  async function AssignLoad(data, id) {
    try {
      let dataPost = { ...data };
      setLoaderAssign(true);
      const result = await apiCall({
        method: "PUT",
        link: `/api/myLoads/assign/${id}`,
        body: dataPost,
      });
      if ((result?.length > 0 && result[0] === 200) || result === undefined) {
        setModalAssign(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${t("Your_load")}# ${currentRow?.Code} ${t("has_been")} ${
            currentRow?.IsDriverAssigned ? t("reassigned") : t("assigned")
          } ${t("successfully_to_the_driver")} ('${driverValue?.label}')`,
          showConfirmButton: false,
          timer: 1500,
        });
        //reload
        getLoadInfo(id);
      } else {
        if (
          result[1].ModelState !== null &&
          result[1].ModelState !== undefined &&
          result[1].ModelState !== ""
        ) {
          let modelState = result[1].ModelState;
          if (modelState)
            Object.keys(modelState).forEach(function(k) {
              modelState[k].forEach((element) => {
                Swal.fire(t("Error"), translateAuto(element), "error");
              });
            });
        } else if (
          result[1].Message !== null &&
          result[1].Message !== undefined &&
          result[1].Message !== ""
        ) {
          Swal.fire(t("Error"), translateAuto(result[1].Message), "error");
        } else if (
          result[1].error_description !== null &&
          result[1].error_description !== undefined &&
          result[1].error_description !== ""
        ) {
          Swal.fire(
            t("Error"),
            translateAuto(result[1].error_description),
            "error"
          );
        } else {
          Swal.fire(t("Error"), t("Bad_Request"), "error");
        }
      }
      setLoaderAssign(false);
    } catch (err) {}
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("Taap")} | {t("Track_Load")}
        </title>
      </Helmet>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="3" className="pt-2">
                  <h2>{t("Track_Load")}</h2>
                </Col>
                <Col lg="9" style={{ textAlignLast: "right" }}>
                  <Link to="/myLoads" className="btn btn-secondary mr-2">
                    <i className="flaticon2-left-arrow-1 pr-2"></i>
                    {t("Back")}
                  </Link>
                  {detail?.Status === "Booked" && (
                    <Fragment>
                      {LoadInformations?.Shipment?.IsDriverAssigned ===
                      false ? (
                        <button
                          className="btn btnPrimaryTaap mr-2"
                          onClick={async (e) => {
                            try {
                              const result = await apiCall({
                                method: "GET",
                                link: `/api/truckers/getDrivers`,
                              });
                              if (result.length > 0 && result[0] === 200) {
                                setDrivers(result[1]);
                                setCurrentRow(LoadInformations?.Shipment);
                                setModalAssign(true);
                              }
                            } catch (err) {}
                          }}
                        >
                          <i className="flaticon2-user text-white pr-2"></i>
                          {t("Assign_Load")}
                        </button>
                      ) : (
                        LoadInformations?.Shipment?.DriversCount > 1 && (
                          <button
                            className="btn btnPrimaryTaap mr-2"
                            onClick={async (e) => {
                              try {
                                const result = await apiCall({
                                  method: "GET",
                                  link: `/api/truckers/getDrivers`,
                                });
                                if (result.length > 0 && result[0] === 200) {
                                  setDrivers(result[1]);
                                  setCurrentRow(LoadInformations?.Shipment);
                                  setModalAssign(true);
                                }
                              } catch (err) {}
                            }}
                          >
                            <i className="flaticon2-user text-white pr-2"></i>
                            {t("Reassign_driver")}
                          </button>
                        )
                      )}
                      {LoadInformations?.Shipment?.IsDriverAssigned &&
                        LoadInformations?.Shipment?.DriversCount > 1 && (
                          <button
                            className="btn btnPrimaryTaap mr-2"
                            onClick={async (e) => {
                              try {
                                Swal.fire({
                                  title: t("Unassign_load"),
                                  text: `${t(
                                    "Are_you_sure_you_want_to_unassign_this_driver"
                                  )} '${
                                    LoadInformations?.Shipment?.DriverFisrtName
                                  } ${
                                    LoadInformations?.Shipment?.DriverLastName
                                  }' ${t("from_the_load")} #${
                                    LoadInformations?.Shipment?.Code
                                  } ?`,
                                  icon: "warning",
                                  showCancelButton: true,
                                  cancelButtonText: t("Cancel"),
                                  confirmButtonColor: "#F60606 ",
                                  cancelButtonColor: "##80808F",
                                  confirmButtonText: t("Unassign"),
                                }).then(async (res) => {
                                  if (res.isConfirmed) {
                                    const result = await apiCall({
                                      method: "PUT",
                                      link: `/api/myLoads/unassign/${LoadInformations?.Shipment?.Id}`,
                                    });
                                    if (
                                      (result?.length > 0 &&
                                        result[0] === 200) ||
                                      result === undefined
                                    ) {
                                      Swal.fire({
                                        position: "center",
                                        icon: "success",
                                        title: `${t("Your_load")} (#${
                                          LoadInformations?.Shipment?.Code
                                        }) ${t(
                                          "has_been_unassigned_succesfully"
                                        )}`,
                                        showConfirmButton: false,
                                        timer: 1500,
                                      });
                                      //reload
                                      getLoadInfo(id);
                                    } else {
                                      if (
                                        result[1].ModelState !== null &&
                                        result[1].ModelState !== undefined &&
                                        result[1].ModelState !== ""
                                      ) {
                                        let modelState = result[1].ModelState;
                                        if (modelState)
                                          Object.keys(modelState).forEach(
                                            function(k) {
                                              modelState[k].forEach(
                                                (element) => {
                                                  Swal.fire(
                                                    t("Error"),
                                                    translateAuto(element),
                                                    "error"
                                                  );
                                                }
                                              );
                                            }
                                          );
                                      } else if (
                                        result[1].Message !== null &&
                                        result[1].Message !== undefined &&
                                        result[1].Message !== ""
                                      ) {
                                        Swal.fire(
                                          t("Error"),
                                          translateAuto(result[1].Message),
                                          "error"
                                        );
                                      } else if (
                                        result[1].error_description !== null &&
                                        result[1].error_description !==
                                          undefined &&
                                        result[1].error_description !== ""
                                      ) {
                                        Swal.fire(
                                          t("Error"),
                                          translateAuto(
                                            result[1].error_description
                                          ),
                                          "error"
                                        );
                                      } else {
                                        Swal.fire(
                                          t("Error"),
                                          t("Bad_Request"),
                                          "error"
                                        );
                                      }
                                    }
                                  }
                                });
                              } catch (err) {}
                            }}
                          >
                            <i className="flaticon2-cross text-white pr-2"></i>
                            {t("Unassign_driver")}
                          </button>
                        )}
                    </Fragment>
                  )}

                  <button
                    className="btn btnPrimaryTaap mr-2"
                    onClick={(e) => {
                      setShowModal(true);
                      e.preventDefault();
                    }}
                  >
                    <i className="flaticon2-printer text-white pr-2"></i>
                    {t("Print_bill")}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="row">
        <div className="flex-column flex-lg-row-auto col-xl-3 col-lg-3 col-md-4 mb-10 mb-lg-0">
          <Card>
            <CardHeader title={t("Trip")}>
              <CardHeaderToolbar>
                <div className="d-flex flex-column flex-grow-1">
                  <h3 className="card-title align-items-start flex-wrap mt-5 mb-0">
                    <span className="font-weight-bold text-muted font-size-sm">
                      {t("Load_Number")}
                    </span>
                  </h3>
                  <h3 className="card-title align-items-start flex-wrap mt-0">
                    <span className="font-weight-bolder text-primary2 font-size-lg">
                      {LoadInformations?.Shipment?.Code}
                    </span>
                  </h3>
                </div>
                {LoadInformations?.Shipment?.Status !== "Delivered" &&
                LoadInformations?.Shipment?.Status !== undefined &&
                LoadInformations?.Shipment?.Status !== "Closed" ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">
                        {t("Share")}
                      </Tooltip>
                    }
                  >
                    <a
                      href={() => false()}
                      className="btn btn-icon btn-light btn-hover-success btn-sm mt-2 ml-2"
                      onClick={() => {}}
                    >
                      <span
                        className="svg-icon svg-icon-md"
                        onClick={() => {
                          setListOfEmailsToSahareLoads([]);
                          setShowModalShare(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          className="bi xl bi-share"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"></path>
                        </svg>
                      </span>
                    </a>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <div className="timeline-item align-items-center mb-1">
                <div className="timeline-line w-20px mt-9 mb-n14" />
                <div
                  className="timeline-icon pt-1"
                  style={{ marginLeft: "0.7px" }}
                >
                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/icons/Receiver.svg")}></SVG>
                  </span>
                  <span className="fs-8 font-weight-boldest text-primary2 text-uppercase pl-2">
                    {detail?.Mode === "Drayage" &&
                    detail?.Stops?.length > 0 &&
                    detail?.Stops[0]?.Type === "Consignee"
                      ? "Pickup location"
                      : t("PickUp")}
                  </span>
                </div>
                <div className="timeline-content mb-4">
                  <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0">
                    {LoadInformations?.Shipment?.ShippingCompany !==
                      undefined && LoadInformations?.Shipment?.ShippingCompany}
                  </p>
                  {/* <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0">
                    {`PU# : ${}`}
                  </p> */}
                  <span className="font-weight-bold text-gray-400">
                    {(LoadInformations?.Shipment?.ShippingAddress1 !==
                      undefined &&
                      LoadInformations?.Shipment?.ShippingAddress1 + ", ") +
                      (LoadInformations?.Shipment?.ShippingCity !== undefined &&
                        LoadInformations?.Shipment?.ShippingCity + ", ") +
                      (LoadInformations?.Shipment?.ShippingState !==
                        undefined &&
                        LoadInformations?.Shipment?.ShippingState + " ") +
                      (LoadInformations?.Shipment?.ShippingZipCode !==
                        undefined &&
                        LoadInformations?.Shipment?.ShippingZipCode) +
                      (LoadInformations?.Shipment?.ShippingCountry !==
                        undefined &&
                        ", " + LoadInformations?.Shipment?.ShippingCountry)}
                  </span>
                </div>
              </div>
              {LoadInformations?.Stops?.map((item, index) => {
                return (
                  <div className="timeline-item align-items-center" key={index}>
                    <div className="timeline-line w-20px" />
                    <div
                      className="timeline-icon pt-1"
                      style={{ marginLeft: "0.5px" }}
                    >
                      <span className="svg-icon svg-icon-2 svg-icon-warning">
                        <SVG
                          src={toAbsoluteUrl("/media/icons/Receiver.svg")}
                        ></SVG>
                      </span>
                      <span className="text-warning">
                        {!!detail === false ||
                        (detail?.Mode !== "Drayage" &&
                          detail?.Stops?.length > 0 &&
                          detail?.Stops[0]?.Type === "Consignee") ? (
                          <Fragment>
                            <span className="fs-3 font-weight-boldest text-warning text-uppercase">
                              {item.Type}{" "}
                            </span>
                            {t("Stop")}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <span className="fs-3 font-weight-boldest text-warning text-uppercase">
                              {"Delivery "}
                            </span>
                            {t("Stop")}
                          </Fragment>
                        )}
                      </span>
                    </div>
                    <div className="timeline-content m-0">
                      <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0">
                        {item?.Name}
                      </p>

                      <span className="font-weight-bold text-gray-400">
                        {(item?.Address1 !== null && item?.Address1 + ", ") +
                          (item?.City !== null && item?.City + ", ") +
                          (item?.State !== null && item?.State + " ") +
                          (item?.Zipcode !== null && item?.Zipcode + " ") +
                          (item?.Country !== null && ", " + item?.Country)}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="timeline-item align-items-center mt-4">
                <div className="timeline-line w-20px" />
                <div
                  className="timeline-icon pt-1"
                  style={{ marginLeft: "0.5px" }}
                >
                  <span className="svg-icon svg-icon-2 svg-icon-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="fs-8 font-weight-boldest text-info text-uppercase pl-2">
                    {detail?.Mode === "Drayage" &&
                    detail?.Stops?.length > 0 &&
                    detail?.Stops[0]?.Type === "Consignee"
                      ? "Empty location"
                      : t("Destination")}
                  </span>
                </div>
                <div className="timeline-content m-0">
                  <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0">
                    {LoadInformations?.Shipment?.ReceivingCompany}
                  </p>

                  <span className="font-weight-bold text-gray-400">
                    {(LoadInformations?.Shipment?.ReceivingAddress1 !== null &&
                      LoadInformations?.Shipment?.ReceivingAddress1 + ", ") +
                      (LoadInformations?.Shipment?.ReceivingCity !== null &&
                        LoadInformations?.Shipment?.ReceivingCity + ", ") +
                      (LoadInformations?.Shipment?.ReceivingState !== null &&
                        LoadInformations?.Shipment?.ReceivingState + " ") +
                      LoadInformations?.Shipment?.ReceivingZipCode +
                      (LoadInformations?.Shipment?.ReceivingCountry !==
                        undefined &&
                        ", " + LoadInformations?.Shipment?.ReceivingCountry)}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="Hisory-status">
            <CardHeader title={t("Status_History")}></CardHeader>
            <CardBody>
              {LoadInformations?.StatusesHistoy.length > 0 ? (
                <div className="timeline timeline-6 mt-3">
                  {LoadInformations?.StatusesHistoy?.map((item, index) => {
                    return (
                      <div
                        className="timeline-item align-items-start"
                        key={index}
                      >
                        <div className="timeline-label font-weight-mormal text-muted font-size-lg">
                          {item?.Created &&
                            ConvertToDateCurrentTimeZone(item?.Created)}
                        </div>

                        <div className="timeline-badge">
                          <i className="fa fa-genderless text-warning icon-xl" />
                        </div>

                        <div className="font-weight-bolder font-size-lg timeline-content text-dark-75 pl-3">
                          {item?.Name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <h4>{t("There_is_no_data_available_at_the_moment")}</h4>
              )}
            </CardBody>
          </Card>
        </div>
        <div className="flex-lg-row-fluid col-xl-9 col-lg-9 col-md-8">
          <Row>
            <Col xl="8" lg="8" md="8" xs="12">
              <div className="maps-section mb-5">
                <GoogleMap
                  setDetailTrakingGeometryInfo={setDetailTrakingGeometryInfo}
                  DetailTrakingGeometryInfo={DetailTrakingGeometryInfo}
                  LoadInformations={LoadInformations}
                />
              </div>
              <Card>
                <CardHeader title={t("Load_Info")}></CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-2x">
                        <li class="nav-item">
                          <a
                            class={
                              "nav-link " +
                              (currentTab === 0 ? "activeLink" : "")
                            }
                            data-toggle="tab"
                            href="#kt_tab_pane_2"
                            onClick={() => setCurrentTab(0)}
                          >
                            <span class="nav-text">Details</span>
                          </a>
                        </li>{" "}
                        {detail?.Mode === "Drayage" && (
                          <Fragment>
                            <li class="nav-item">
                              <a
                                class={
                                  "nav-link " +
                                  (currentTab === 1 ? "activeLink" : "")
                                }
                                data-toggle="tab"
                                href="#kt_tab_pane_2"
                                onClick={() => setCurrentTab(1)}
                              >
                                <span class="nav-text">Pickup</span>
                              </a>
                            </li>{" "}
                            <li class="nav-item">
                              <a
                                class={
                                  "nav-link " +
                                  (currentTab === 2 ? "activeLink" : "")
                                }
                                data-toggle="tab"
                                href="#kt_tab_pane_2"
                                onClick={() => setCurrentTab(2)}
                              >
                                <span class="nav-text">Delivery</span>
                              </a>
                            </li>{" "}
                            {detail?.Mode === "Drayage" &&
                            detail?.IsStayWith === true ? (
                              <li class="nav-item">
                                <a
                                  class={
                                    "nav-link " +
                                    (currentTab === 3 ? "activeLink" : "")
                                  }
                                  data-toggle="tab"
                                  href="#kt_tab_pane_2"
                                  onClick={() => setCurrentTab(3)}
                                >
                                  <span class="nav-text">Empty</span>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        )}
                      </ul>
                    </Col>
                  </Row>
                  <div className={currentTab === 0 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column">
                            {detail?.Mode === "Drayage" ? (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  LFD
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.LastFreeDate !== null
                                    ? moment(detail?.LastFreeDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "- - - -"}
                                </span>
                              </>
                            ) : (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Req_Ship_Date")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.ReqShipDate !== null
                                    ? moment(detail?.ReqShipDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "- - - -"}
                                </span>
                              </>
                            )}
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("PU_Appointment")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {detail?.PuAppointmentDate !== null
                                ? ConvertToDateCurrentTimeZone(
                                    detail?.PuAppointmentDate
                                  )
                                : "- - - -"}
                            </span>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("Departure_Date")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {detail?.ShippingDepartureDate !== null
                                ? ConvertToDateCurrentTimeZone(
                                    detail?.ShippingDepartureDate
                                  )
                                : "- - - -"}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center col-xl-5 col-lg-5 col-md-6 col-4">
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/icons/Receiver.svg")}
                            ></SVG>
                          </span>
                          <div className="d-flex flex-column flex-grow-1">
                            {detail?.Alert !== null &&
                            detail?.Alert !== undefined &&
                            detail?.Alert !== "" ? (
                              <span className="alert-loads">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{ pointerEvents: "none" }}
                                      id="products-delete-tooltip"
                                    >
                                      {detail?.Alert}
                                    </Tooltip>
                                  }
                                >
                                  <i class="fa fa-exclamation-triangle text-danger icon-xl"></i>
                                </OverlayTrigger>
                              </span>
                            ) : (
                              ""
                            )}
                            <span className="separator separator-dashed separator-border-2 separator-secondary" />
                          </div>
                          <span className="svg-icon svg-icon-2 svg-icon-info">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            {detail?.Mode === "Drayage" &&
                            detail?.IsStayWith === true ? (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  Reported empty
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.ReportedEmpty !== null
                                    ? moment(detail?.ReportedEmpty).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "- - - -"}
                                </span>
                              </>
                            ) : (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  MABD
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.MustArriveByDate !== null
                                    ? moment(detail?.MustArriveByDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "- - - -"}
                                </span>
                              </>
                            )}
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("Del_Appointment")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {detail?.DelAppointmentDate !== null
                                ? ConvertToDateCurrentTimeZone(
                                    detail?.DelAppointmentDate
                                  )
                                : "- - - -"}
                            </span>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("Receiving_Arrival")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {detail?.ReceivingArrivalDate !== null
                                ? ConvertToDateCurrentTimeZone(
                                    detail?.ReceivingArrivalDate
                                  )
                                : "- - - -"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <Col xl="12">
                        <Row className="align-items-center mt-10">
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Equipment_Type")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                {detail?.EquipmentType}
                              </span>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Total_Weight")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                {detail?.Weight !== null &&
                                detail?.Weight !== undefined
                                  ? detail?.Weight +
                                    " " +
                                    (detail?.WeightType !== null &&
                                    detail?.WeightType !== undefined
                                      ? detail?.WeightType
                                      : "")
                                  : ""}
                              </span>
                            </div>
                          </Fragment>

                          <Fragment>
                            {detail?.Mode !== "Drayage" && (
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Total_Stops")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.StopsCount || 0}
                                </span>
                              </div>
                            )}
                          </Fragment>

                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Total_Price")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                $ {detail?.Price}
                              </span>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Distance_Mile")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                {detail?.Miles} miles
                              </span>
                            </div>
                          </Fragment>
                        </Row>
                      </Col>
                      <Col xl="12">
                        <Row className="align-items-center mt-10">
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Trucker")}
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {LoadInformations?.Trucker?.Name}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Driver")}
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {LoadInformations?.Trucker?.Driver}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Email")}
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {LoadInformations?.Shipment?.DriverEmail}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Licence")} #
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {LoadInformations?.Trucker?.LicenceNumber}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            {/* <div className="d-flex flex-column flex-grow-1">
      <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
        {t("Work_Phone")} #
      </p>
      <div className="mb-10">
        <span className="font-weight-bolder text-dark-75 font-size-md">
          {LoadInformations?.Trucker?.WorkPhoneNumber}
        </span>
      </div>
    </div> */}
                          </Fragment>
                        </Row>
                        <Row>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Seal_Number")} #
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {detail?.SealNumber}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            {detail?.Mode !== "Drayage" && (
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Truck_Number")} #
                                </p>
                                <div className="mb-10">
                                  <span className="font-weight-bolder text-dark-75 font-size-md">
                                    {detail?.TruckNumber}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Fragment>
                          <Fragment>
                            {detail?.Mode !== "Drayage" && (
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Trailer_Number")} #
                                </p>
                                <div className="mb-10">
                                  <span className="font-weight-bolder text-dark-75 font-size-md">
                                    {detail?.TrailerNumber}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        </Row>
                      </Col>
                    </div>
                  </div>
                  <div className={currentTab === 1 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Container")} #
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.ContainerNumber ?? "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Release")} #
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.ReleaseNumber ?? "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Last_free_date")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.LastFreeDate !== null
                                    ? ConvertToDateCurrentTimeZone(
                                        detail?.LastFreeDate
                                      )
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Vessel_eta")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.VesselEta !== null
                                    ? ConvertToDateCurrentTimeZone(
                                        detail?.VesselEta
                                      )
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Available_eta")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.AvailableEta !== null
                                    ? ConvertToDateCurrentTimeZone(
                                        detail?.AvailableEta
                                      )
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Company_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.PickupAddress?.Name !== null
                                    ? detail?.PickupAddress?.Name
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Full_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.PickupAddress?.ContactFirstName !==
                                  null
                                    ? detail?.PickupAddress?.ContactFirstName
                                    : "- - - -"}
                                </span>{" "}
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.PickupAddress?.ContactLastName !==
                                  null
                                    ? detail?.PickupAddress?.ContactLastName
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 1
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.PickupAddress?.ContactPhoneNumber !==
                                  null
                                    ? detail?.PickupAddress?.ContactPhoneNumber
                                    : "- - - -"}
                                </span>{" "}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 2
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.PickupAddress
                                    ?.ContactPhoneNumber2 !== null
                                    ? detail?.PickupAddress?.ContactPhoneNumber2
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Note")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.ShippingNote !== null
                                    ? detail?.ShippingNote
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col className="col-8">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Email")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.PickupAddress !== null
                                    ? detail?.PickupAddress?.ContactEmail
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={currentTab === 2 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("DEL")}
                                </p>
                                {detail?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.Stops[0]?.DestinationNumber !==
                                    null
                                      ? detail?.Stops[0]?.DestinationNumber
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.DestinationNumber !== null
                                      ? detail?.DestinationNumber
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Company_Name")}
                                </p>
                                {detail?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.Stops[0]?.Name !== null
                                      ? detail?.Stops[0]?.Name
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.DestinationAddress !== null
                                      ? detail?.DestinationAddress?.Name
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Full_Name")}
                                </p>
                                {detail?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.Stops[0] !== null
                                      ? `${detail?.Stops[0]?.ContactFirstName} ${detail?.Stops[0]?.ContactLastName}`
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.DestinationAddress !== null
                                      ? `${detail?.DestinationAddress?.ContactFirstName} ${detail?.DestinationAddress?.ContactLastName}`
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Email")}
                                </p>
                                {detail?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.Stops[0]?.ContactEmail !== null
                                      ? detail?.Stops[0]?.ContactEmail
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.DestinationAddress !== null
                                      ? detail?.DestinationAddress?.ContactEmail
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 1
                                </p>
                                {detail?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.Stops[0]?.ContactPhoneNumber !==
                                    null
                                      ? detail?.Stops[0]?.ContactPhoneNumber
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.DestinationAddress !== null
                                      ? detail?.DestinationAddress
                                          ?.ContactPhoneNumber
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 2
                                </p>
                                {detail?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.Stops[0] !== null
                                      ? detail?.Stops[0]?.ContactPhoneNumber2
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.DestinationAddress !== null
                                      ? detail?.DestinationAddress
                                          ?.ContactPhoneNumber2
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Note")}
                                </p>
                                {detail?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.Stops[0] !== null
                                      ? detail?.Stops[0]?.Note
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {detail?.ReceivingNote !== null
                                      ? detail?.ReceivingNote
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={currentTab === 3 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Company_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.DestinationAddress !== null
                                    ? detail?.DestinationAddress?.Name
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Full_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.DestinationAddress !== null
                                    ? `${detail?.DestinationAddress?.ContactFirstName} ${detail?.DestinationAddress?.ContactLastName}`
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("DEL")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.DestinationNumber !== null
                                    ? detail?.DestinationNumber
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Email")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.DestinationAddress !== null
                                    ? detail?.DestinationAddress?.ContactEmail
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 1
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.DestinationAddress !== null
                                    ? detail?.DestinationAddress
                                        ?.ContactPhoneNumber
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 2
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.DestinationAddress !== null
                                    ? detail?.DestinationAddress
                                        ?.ContactPhoneNumber2
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Note")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {detail?.ReceivingNote !== null
                                    ? detail?.ReceivingNote
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" lg="4" md="4" xs="12">
              <Card>
                <CardHeader title={t("Itinerary")}></CardHeader>
                <CardBody>
                  <div
                    className="scroll  ps--active-y"
                    style={{ height: "770px", overflow: "scroll" }}
                  >
                    <div className="timeline ml-n1 pl-1">
                      <div className="timeline-item align-items-center">
                        <div className="timeline-line w-20px mt-11 mb-n14" />
                        <div
                          className="timeline-icon pt-1"
                          style={{ marginLeft: "0.7px" }}
                        >
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/icons/Receiver.svg")}
                            ></SVG>
                          </span>
                        </div>
                        <div className="timeline-content m-0">
                          <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                            {(LoadInformations?.Shipment?.ShippingCity !== null
                              ? LoadInformations?.Shipment?.ShippingCity + ", "
                              : "") +
                              (LoadInformations?.Shipment?.ShippingState !==
                              null
                                ? LoadInformations?.Shipment?.ShippingState
                                : "")}
                            <span
                              className="text-muted"
                              style={{
                                fontWeight: "100",
                                fontSize: "smaller",
                                display: "block",
                              }}
                            >
                              {t("Shipping")}:{" "}
                              {LoadInformations?.Shipment
                                ?.ShippingDepartureDate !== null
                                ? LoadInformations?.Shipment
                                    ?.ShippingDepartureDate &&
                                  ConvertToDateCurrentTimeZone(
                                    LoadInformations?.Shipment
                                      ?.ShippingDepartureDate
                                  )
                                : "---------- "}
                            </span>
                          </p>
                        </div>
                      </div>
                      {LoadInformations?.Tracking?.map((item, index) => {
                        return (
                          <div
                            className="timeline-item align-items-center"
                            key={index}
                          >
                            <div className="timeline-line w-20px" />
                            <div
                              className="timeline-icon pt-1"
                              style={{ marginLeft: "0.5px" }}
                            >
                              <span className="svg-icon svg-icon-2 svg-icon-success">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/icons/Receiver.svg"
                                  )}
                                ></SVG>
                              </span>
                            </div>
                            <div className="timeline-content m-0">
                              <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                                {(item?.City !== null
                                  ? item?.City + ", "
                                  : "") +
                                  (item?.State !== null ? item?.State : "")}
                                <span
                                  className="text-muted"
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "smaller",
                                    display: "block",
                                  }}
                                >
                                  {item.EventName}:{" "}
                                  {item.EventDate &&
                                    ConvertToDateCurrentTimeZone(
                                      item.EventDate
                                    )}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      {LoadInformations?.Stops?.filter(
                        (a) => a.Delivered === false
                      )?.map((item, index) => {
                        return (
                          <div className="timeline-item align-items-center">
                            <div className="timeline-line w-20px" />
                            <div
                              className="timeline-icon pt-1"
                              style={{ marginLeft: "0.5px" }}
                            >
                              <span className="svg-icon svg-icon-2 svg-icon-warning">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/icons/Receiver.svg"
                                  )}
                                ></SVG>
                              </span>
                            </div>
                            <div className="timeline-content m-0">
                              <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                                {(item?.City !== null
                                  ? item?.City + ", "
                                  : "") +
                                  (item?.State !== null
                                    ? item?.State
                                    : "")}{" "}
                                <span className="text-primary">
                                  {item.Type} {t("Stop")}
                                </span>
                                <span
                                  className="text-muted"
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "smaller",
                                    display: "block",
                                  }}
                                >
                                  {item.EventName}
                                  {item.EventDate && ":"}
                                  {item.EventDate &&
                                    ConvertToDateCurrentTimeZone(
                                      item.EventDate
                                    )}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      <div className="timeline-item align-items-center">
                        <div className="timeline-line w-20px" />
                        <div
                          className="timeline-icon pt-1"
                          style={{ marginLeft: "0.5px" }}
                        >
                          <span className="svg-icon svg-icon-2 svg-icon-info">
                            <SVG
                              src={toAbsoluteUrl("/media/icons/Receiver.svg")}
                            ></SVG>
                          </span>
                        </div>
                        <div className="timeline-content m-0">
                          <p className="font-size-md text-gray-800 font-weight-bolder d-block mb-0 mt-6">
                            {(LoadInformations?.Shipment?.ReceivingCity !== null
                              ? LoadInformations?.Shipment?.ReceivingCity + ", "
                              : "") +
                              (LoadInformations?.Shipment?.ReceivingState !==
                              null
                                ? LoadInformations?.Shipment?.ReceivingState +
                                  ", "
                                : "")}
                          </p>
                          <span
                            className="text-muted"
                            style={{
                              fontWeight: "100",
                              fontSize: "smaller",
                              display: "block",
                            }}
                          >
                            {t("Receiving")}:{" "}
                            {LoadInformations?.Shipment
                              ?.ReceivingArrivalDate !== null
                              ? LoadInformations?.Shipment
                                  ?.ReceivingArrivalDate &&
                                ConvertToDateCurrentTimeZone(
                                  LoadInformations?.Shipment
                                    ?.ReceivingArrivalDate
                                )
                              : " ----------"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="8">
              <AttachementComp
                id={id}
                IsDetailsMode={true}
                isTruckerDetails={true}
                LoadInformations={LoadInformations}
              />
            </Col>
          </Row>
        </div>
      </div>
      {/* Modal */}
      <Modal
        size="lg"
        show={ShowModalShare}
        onHide={() => {
          setShowModalShare(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Share_Live_Tracking")}:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            href={() => false}
            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
          >
            {t("Email")}
          </a>
          <CreatableSelect
            isMulti
            className="mt-4"
            isClearable
            name="PickupId"
            id="PickupId"
            label="Company"
            value={ListOfEmailsToSahareLoads.map((item, index) => {
              return { label: item.label, value: item.label };
            })}
            onChange={(newValue) => {
              console.log("newValue", newValue);
              if (newValue === null) {
                setListOfEmailsToSahareLoads([]);
                return;
              }
              let newArray = [];
              newValue.forEach((element) => {
                const tt = validateEmail(element.label);
                if (tt) {
                  newArray.push(element);
                } else {
                  Swal.fire(t("Error"), t("Please_fill_valid_email"));
                }
              });
              setListOfEmailsToSahareLoads(newArray);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModalShare(false);
            }}
          >
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            disabled={isLoadingAuth}
            onClick={() => {
              if (ListOfEmailsToSahareLoads.length === 0) {
                Swal.fire(t("Error"), t("Please_fill_an_email"));
                return;
              }
              setisLoadingAuth(true);
              var row = JSON.stringify({
                ShipmentId: id,
                Emails: ListOfEmailsToSahareLoads.map((a) => a.label),
              });
              return fetch(`${window.$apiUrl}/api/Tracking/Share`, {
                method: "POST",
                body: row,
                redirect: "follow",
                withCredentials: true,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${db.read().getState().Token}`,
                  "X-FP-API-KEY": "iphone",
                },
              })
                .then(async (response) => {
                  const statusCode = await response.status;
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => [statusCode, data]);
                  } else {
                    return response
                      .text()
                      .then((text) => [statusCode, { Message: "" }]);
                  }
                })
                .then((result) => {
                  if (result[0] === 200) {
                    setShowModalShare(false);
                  } else {
                    if (
                      result[1].ModelState !== null &&
                      result[1].ModelState !== undefined &&
                      result[1].ModelState !== ""
                    ) {
                      let modelState = result[1].ModelState;
                      if (modelState)
                        Object.keys(modelState).forEach(function(k) {
                          modelState[k].forEach((element) => {
                            Swal.fire(t("Error"), translateAuto(element));
                          });
                        });
                    } else if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Swal.fire(t("Error"), translateAuto(result[1].Message));
                    } else if (
                      result[1].error_description !== null &&
                      result[1].error_description !== undefined &&
                      result[1].error_description !== ""
                    ) {
                      Swal.fire(
                        t("Error"),
                        translateAuto(result[1].error_description)
                      );
                    } else {
                      Swal.fire(t("Error"), t("Bad_Request"));
                    }
                  }
                  setisLoadingAuth(false);
                })
                .catch((error) => {
                  Swal.fire(t("Error"), error);
                  setisLoadingAuth(false);
                });
            }}
          >
            {t("Share")}
            {isLoadingAuth && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Assign Load */}
      <ModalIndex
        show={modalAssign}
        onHide={() => setModalAssign(false)}
        size="lg"
        title={`${
          currentRow?.IsDriverAssigned ? t("reassigned") : t("assigned")
        } ${t("driver_for_the_load")}# '${currentRow?.Code}'`}
        content={
          <Fragment>
            <Row>
              <Col>
                <Row className="form-group">
                  <Col>
                    <label>{t("Drivers")}</label>
                    <RequiredHtml />
                    <Select
                      options={
                        drivers?.length > 0
                          ? drivers.map((x) => {
                              return {
                                value: x?.Id,
                                label: `${x?.FirstName} ${x?.LastName} ${
                                  x?.Email === currentEmail
                                    ? " - " + t("Me")
                                    : ""
                                }`,
                              };
                            })
                          : []
                      }
                      name={"DriverValue"}
                      value={driverValue}
                      onChange={(e) => {
                        setDriverValue(e ?? null);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        }
        footerContent={
          <Fragment>
            {" "}
            <button
              onClick={() => setModalAssign(false)}
              className="mr-3 btn btn-light btn-elevate"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              disabled={
                loaderAssign ||
                driverValue?.value === "" ||
                driverValue === null
              }
              onClick={async () => {
                let data = {
                  DriverId: driverValue?.value,
                };
                await AssignLoad(data, currentRow?.Id);
              }}
              className="btn btnPrimaryTaap"
            >
              {t("Assign_Load")}
              {loaderAssign && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Fragment>
        }
      />

      <DynamicReport
        setShowModal={setShowModal}
        showModal={showModal}
        id={LoadInformations?.Shipment?.Code}
        title={`${t("Bill_report_for_the_load")}#: ${
          LoadInformations?.Shipment?.Code
        }`}
        reportContent={<BillForm row={LoadInformations?.Shipment} />}
      />
    </>
  );
};
