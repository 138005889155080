import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import MarkerClusterer from "@google/markerclusterer";
import { toAbsoluteUrl } from "../../_helpers";
import { ConvertToDateCurrentTimeZone } from "../../_helpers/AtHelper";
const exampleMapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ABE8C7"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#CFE2EB"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#CFE2EB"
      }
    ]
  }
];
export default class GoogleMapContainer extends Component {

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
    script.async = true;
    document.body.appendChild(script);
  }
  setGoogleMapRef(map, maps) {
    //const history = useHistory();

    let _setSelectedLoadId = this.props.setSelectedLoadId;
    let _isDashboard = this.props.isDashboard;
    let _history = this.props.history;
    let GetLoadInfo = this.props.getLoadInfo;

    const infoWindow = new maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
    this.googleMapRef = map;
    this.googleRef = maps;

    let locations = this.props.LoadsPositionList.map((a) => {
      return {
        lat: parseFloat(a.Latitude),
        lng: parseFloat(a.Longitude),
        City: a.City,
        State: a.State,
        Id: a.Id,
        Code: a.Code,
        EventName: a.EventName,
        EventDate: a.EventDate,
      };
    });
    let markers =
      locations &&
      locations.map((location) => {
        var tt = new this.googleRef.Marker({
          position: location, //public\media\icons
          icon: toAbsoluteUrl("/media/icons/maptruck.png"),
          map: map,
        });
        tt.addListener("click", () => {
          infoWindow.setContent(
            `<div class="polyButton" data-id="${location.Id}" style=padding-top:0.8rem>
        <div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Code:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${location.Code}</div>
        </div>
        <div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Location:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${location.City !== null
              ? location.City + ", " + location.State
              : ""
            }</div>
          
        </div>
        <div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
            <div style=margin-right:0.5rem;>Event:</div>
            <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${location.EventName} - ${ConvertToDateCurrentTimeZone(location.EventDate)}</div>
          </div>
          </div>`
          );
          infoWindow.open(map, tt);
        });
        maps.event.addListener(infoWindow, "domready", function () {
          let eee = document.getElementsByClassName("polyButton");
          for (
            var x = 0, sampleDivsLength = eee.length;
            x < sampleDivsLength;
            x++
          ) {
            //Add an event listener for each DOM element
            eee[x].addEventListener("click", function () {
              if (_isDashboard) {
                _history.push(`/trackload/${this.dataset.id}/details`);
              } else {
                _setSelectedLoadId(parseInt(this.dataset.id));
                GetLoadInfo(this.dataset.id);
              }
            });
          }
        });
        return tt;
      });
    var clusterStyles = [
      {
        textColor: 'white',
        url: toAbsoluteUrl("/media/icons/directory.png"),
        height: 50,
        width: 50
      },
      {
        textColor: 'white',
        url: toAbsoluteUrl("/media/icons/D2.png"),
        height: 54,
        width: 54
      },
      {
        textColor: 'white',
        url: toAbsoluteUrl("/media/icons/directory.png"),
        height: 50,
        width: 50
      }
    ];
    new MarkerClusterer(map, markers, {
      styles: clusterStyles,
      minimumClusterSize: 2,
      
    });
  }
  render() {
    // console.log("this.props.LoadsPositionList",this.props.LoadsPositionList)
    return (
      <>
        {this.props.LoadsPositionList.length > 0 ? (
          <GoogleMapReact
            key={1}
            bootstrapURLKeys={{
              key: `AIzaSyA1A9EPSRfIpJkN2v8CweA_W2_BUGCIdU8`,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) =>
              this.setGoogleMapRef(map, maps)
            }
            defaultCenter={{ lat: 37.923984, lng: -97.539071 }}
            defaultZoom={4}
            options={{ streetViewControl: true, styles: exampleMapStyles }}
          />
        ) : (
          <GoogleMapReact
            key={2}
            bootstrapURLKeys={{
              key: `AIzaSyA1A9EPSRfIpJkN2v8CweA_W2_BUGCIdU8`,
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={{ lat: 37.923984, lng: -97.539071 }}
            defaultZoom={4}
            options={{ streetViewControl: true, styles: exampleMapStyles }}
          />
        )}
      </>
    );
  }
}