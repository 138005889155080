import React from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

function ModalIndex(props) {
  return (
    <Modal
      size={props?.size}
      id="LoadReceivedModal"
      show={props?.show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          {[props?.title]}
        </Modal.Title>
        <IconButton
          aria-label="Close"
          onClick={(e) => {
            props.onHide();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body className=" overlay-block">{props?.content}</Modal.Body>
      <Modal.Footer>{props?.footerContent}</Modal.Footer>
    </Modal>
  );
}

export { ModalIndex };
