import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { CheckboxLargeWBFob } from "../../_metronic/layout/components/CheckboxLargeWBFob";
import {
  momentDateOnly,
  momentTime,
  translateAuto
} from "../../_metronic/_helpers/AtHelper";
const adapter = new LocalStorage("db");
const db = low(adapter);

function IsValidAddress(str, isFirstItem) {
  if (str === null || str === undefined || str === "") {
    return "";
  } else {
    return isFirstItem === true ? str : ", " + str;
  }
}

class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
    };
    this.state = { data: obj, stops: [] };
  }

  async componentDidMount() {
    await fetch(window.$apiUrl + "/api/Dashboard/BOL/" + this.props.id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode !== 200) {
          Notification("error", translateAuto("No_data_found"));
        }
        const json = await response.json();
        this.setState({ data: json });
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const formData = this.state.data;

    return (
      <>
        <div className="container">
          <Fragment>
            {/* <div className="col-md-12" key={0}>
              <div className="d-flex justify-content-between flex-column flex-md-row">
                <div className="d-flex flex-column align-items-md-start px-0">
                  <a href={() => false} className="mb-5 max-w-100px">
                    <span className="svg-icon svg-icon-full">
                      <SVG
                        className="logo-default max-h-25px"
                        alt="Logo"
                        src={toAbsoluteUrl("/media/svg/logos/TaapLogo-White.svg")}
                      />
                      <SVG
                        className="logo-sticky max-h-40px"
                        alt="Logo"
                        src={toAbsoluteUrl("/media/svg/logos/LogoEM.svg")}
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
            <div className="card py-5 px-0 bl-form border-0">
              <div className="card-body p-0">
                {/* First Data */}
                <div className="row justify-content-center pt-8 px-1 pt-md-0 px-md-0 pb-md-10">
                  <div className="col-md-12">
                    <div className="row pb-0">
                      <div className="col-md-12 px-0">
                        <table className="w-100">
                          <tr>
                            <td className="border-0 font-weight-boldest w-25">
                              {" "}
                              Date: {moment().format("MM-DD-YYYY")}
                            </td>
                            <td className="border-0 text-center display-4 font-weight-boldest">
                              BILL OF LADING
                            </td>
                            <td className="border-0 text-right font-weight-boldest w-25">
                              Page
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="border-0 font-weight-boldest pb-3"
                              style={{ width: "28%" }}
                            >
                              {" "}
                              {formData?.PuAppointmentDate && (
                                <>
                                  PickupAppointment Date:{" "}
                                  {momentDateOnly(formData?.PuAppointmentDate)}{" "}
                                  {`${momentTime(formData?.PuAppointmentDate)} -
                                  ${
                                    new Date(
                                      formData?.PuAppointmentDate
                                    ).getDate() ===
                                    new Date(
                                      formData?.PuAppointmentToDate
                                    ).getDate()
                                      ? momentTime(
                                          formData?.PuAppointmentToDate
                                        )
                                      : momentDateOnly(
                                          formData?.PuAppointmentToDate
                                        ) +
                                        " " +
                                        momentTime(
                                          formData?.PuAppointmentToDate
                                        )
                                  }`}
                                </>
                              )}
                            </td>
                            <td className="border-0 text-center display-4 font-weight-boldest"></td>
                            <td className="border-0 text-right font-weight-boldest w-25"></td>
                          </tr>
                          <tr>
                            <td
                              className="border-0 font-weight-boldest pb-2"
                              style={{ width: "28%" }}
                            >
                              {" "}
                              {formData?.DelAppointmentDate && (
                                <>
                                  Appointment Date :{" "}
                                  {momentDateOnly(formData?.DelAppointmentDate)}{" "}
                                  {`${momentTime(
                                    formData?.DelAppointmentDate
                                  )} -
                                  ${
                                    new Date(
                                      formData?.DelAppointmentDate
                                    ).getDate() ===
                                    new Date(
                                      formData?.DelAppointmentToDate
                                    ).getDate()
                                      ? momentTime(
                                          formData?.DelAppointmentToDate
                                        )
                                      : momentDateOnly(
                                          formData?.DelAppointmentToDate
                                        ) +
                                        " " +
                                        momentTime(
                                          formData?.DelAppointmentToDate
                                        )
                                  }`}
                                </>
                              )}
                            </td>
                            <td className="border-0 text-center display-4 font-weight-boldest"></td>
                            <td className="border-0 text-right font-weight-boldest w-25"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead className="d-flex justify-content-center">
                            <tr>
                              <th colspan="10" className="text-center">
                                SHIP FROM
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="w-50 font-weight-boldest">
                                Name:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipFrom?.Company}
                                </span>
                              </td>
                              <td
                                rowspan="5"
                                className="font-weight-boldest align-top"
                              >
                                Bill of Lading Number:
                                <span className="font-weight-normal">
                                  {formData?.Code}
                                </span>{" "}
                                <br></br>
                                <span>Customer Ref # :</span>
                                <span className="font-weight-normal">
                                  {formData?.CustomerRefNumber}
                                </span>{" "}
                                <br></br> <br></br>
                                <p className="text-muted text-center font-weight-boldest"></p>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                Address:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipFrom?.Address1}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                City/State/Zip:{" "}
                                <span className="font-weight-normal">
                                  {IsValidAddress(
                                    formData?.ShipFrom?.City?.replace(/ /g, ""),
                                    true
                                  ) +
                                    IsValidAddress(
                                      formData?.ShipFrom?.State,
                                      false
                                    ) +
                                    IsValidAddress(
                                      formData?.ShipFrom?.ZipCode,
                                      false
                                    )}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                Phone:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipFrom?.ContactPhone}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                SID#:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipFrom?.Number}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="mb-4">
                                <CheckboxLargeWBFob
                                  style={{ float: "right" }}
                                  label=""
                                  text="FOB"
                                  name=""
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead className="d-flex justify-content-center">
                            <tr>
                              <th colspan="10" className="text-center">
                                SHIP TO{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest w-50">
                                Name:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipTo?.Company}
                                </span>
                              </td>
                              <td className="font-weight-boldest">
                                CARRIER NAME:{" "}
                                <span className="font-weight-normal">
                                  {formData?.Trucker?.Name}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                Address:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipTo?.Address1}
                                </span>
                              </td>
                              <td className="font-weight-boldest">
                                Trailer number:{" "}
                                <span className="font-weight-normal">
                                  {formData?.Trucker?.TrailerNumber}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                City/State/Zip:{" "}
                                <span className="font-weight-normal">
                                  {IsValidAddress(
                                    formData?.ShipTo?.City?.replace(/ /g, ""),
                                    true
                                  ) +
                                    IsValidAddress(
                                      formData?.ShipTo?.State,
                                      false
                                    ) +
                                    IsValidAddress(
                                      formData?.ShipTo?.ZipCode,
                                      false
                                    )}
                                </span>
                              </td>
                              <td className="font-weight-boldest">
                                Seal number(s):{" "}
                                <span className="font-weight-normal">
                                  {formData?.Trucker?.SealNumber}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                Phone:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipTo?.ContactPhone}
                                </span>
                              </td>
                              <td className="font-weight-boldest">
                                SCAC:{" "}
                                <span className="font-weight-normal">
                                  {formData?.Trucker?.Scac}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                Location#:{" "}
                              </td>
                              <td className="font-weight-boldest">
                                Pro number:{" "}
                                <span className="font-weight-normal">
                                  {formData?.Trucker?.ProNumber}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                CID#:{" "}
                                <span className="font-weight-normal">
                                  {formData?.ShipTo?.Number}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="mb-4">
                                <CheckboxLargeWBFob
                                  style={{ float: "right" }}
                                  label=""
                                  text="FOB"
                                  name=""
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead className="d-flex justify-content-center">
                            <tr>
                              <th colspan="10" className="text-center">
                                THIRD PARTY FREIGHT CHARGES BILL TO:{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest">
                                Name:{" "}
                                <span className="font-weight-normal">
                                  {formData?.BillTo?.Company}
                                </span>
                              </td>
                              <td rowspan="3" className="font-weight-boldest">
                                Freight Charge Terms:<br></br>
                                <small className="italic">
                                  (freight charges are prepaid unless marked
                                  collect)
                                </small>
                                <br></br>
                                <div className="d-flex">
                                  <CheckboxLargeWBFob
                                    Id="PrepaidCheck"
                                    label=""
                                    text="Prepaid"
                                    name=""
                                    checked={
                                      formData?.FreightChargesterms ===
                                      "Prepaid"
                                        ? true
                                        : false
                                    }
                                  />
                                  <CheckboxLargeWBFob
                                    Id="CollectCheck"
                                    label=""
                                    text="Collect"
                                    name=""
                                    checked={
                                      formData?.FreightChargesterms ===
                                      "Collect"
                                        ? true
                                        : false
                                    }
                                  />
                                  <CheckboxLargeWBFob
                                    Id="PartyCheck"
                                    label=""
                                    text="3rd Party"
                                    name=""
                                    checked={
                                      formData?.FreightChargesterms ===
                                      "Third Party Billing"
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                Address:{" "}
                                <span className="font-weight-normal">
                                  {formData?.BillTo?.Address1}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                City/State/Zip:{" "}
                                <span className="font-weight-normal">
                                  {IsValidAddress(
                                    formData?.BillTo?.City?.replace(/ /g, ""),
                                    true
                                  ) +
                                    IsValidAddress(
                                      formData?.BillTo?.State,
                                      false
                                    ) +
                                    IsValidAddress(
                                      formData?.BillTo?.ZipCode,
                                      false
                                    )}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest">
                                Phone:{" "}
                                <span className="font-weight-normal">
                                  {formData?.BillTo?.ContactPhone}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest w-50">
                                SPECIAL INSTRUCTIONS:{" "}
                                <p className="form-control border-0 h-auto"></p>
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    fontWeight: "bold",
                                    padding: "1.2rem",
                                  }}
                                ></span>
                              </td>
                              <td className="align-top">
                                <CheckboxLargeWBFob
                                  label=""
                                  text="Master Bill of Lading: with attached underlying Bills of Lading"
                                  name=""
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100 footer-bol">
                          <thead className="justify-content-center">
                            <tr>
                              <th colspan="14" className="text-center">
                                CARRIER INFORMATION
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                rowspan="2"
                                className="font-weight-boldest text-center space-bol"
                              >
                                ITEM NO
                              </td>

                              <td
                                colspan="2"
                                className="font-weight-boldest text-center"
                              >
                                PACKAGE{" "}
                              </td>
                              <td
                                rowspan="2"
                                colspan="1"
                                className="font-weight-boldest text-center"
                              >
                                WEIGHT({formData?.WeightType})
                              </td>
                              <td
                                rowspan="2"
                                className="font-weight-boldest text-center"
                              >
                                H.M.(X)
                              </td>
                              <td
                                rowspan="2"
                                colspan="3"
                                className="font-weight-boldest text-center"
                              >
                                COMMODITY DESCRIPTION<br></br>
                                <small>
                                  Commodities requiring special or additional
                                  care or attention in handling or stowing{" "}
                                </small>
                                <br></br>
                                <small>
                                  must be so marked and packaged as to ensure
                                  safe transportation with ordinary care.{" "}
                                </small>
                                <br></br>
                                <small className="font-weight-boldest">
                                  See Section 2(e) of NMFC Item 360
                                </small>
                              </td>
                              <td
                                colspan="2"
                                className="font-weight-boldest text-center"
                              >
                                LTL ONLY{" "}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest text-center">
                                QTY
                              </td>
                              <td className="font-weight-boldest text-center">
                                Type
                              </td>
                              <td className="font-weight-boldest text-center">
                                NMFC #{" "}
                              </td>
                              <td className="font-weight-boldest text-center">
                                CLASS
                              </td>
                            </tr>
                            {formData?.Items?.length > 0 &&
                              formData?.Items.map((item, index) => (
                                <tr>
                                  <td>{item.Id}</td>
                                  <td>{item.Pieces}</td>
                                  <td>{item.PackageType}</td>
                                  <td colspan="1">{item.Weight}</td>
                                  {/* HZ CHECK */}
                                  <td className="">
                                    <CheckboxLargeWBFob
                                      checked={
                                        item.HazMat !== null &&
                                        item.HazMat !== false
                                          ? true
                                          : false
                                      }
                                      label=""
                                      text=""
                                      name=""
                                      className=""
                                    />
                                  </td>
                                  <td colspan="3">
                                    {item.Commodity === ""
                                      ? ""
                                      : item.Commodity}
                                  </td>
                                  <td>{item.NMFC}</td>
                                  <td>{item.Class}</td>
                                </tr>
                              ))}
                            <tr>
                              <td className="font-weight-boldest text-center">
                                GRAND TOTAL
                              </td>
                              <td>{formData?.TotalPieces}</td>
                              <td className="font-weight-boldest text-center">
                                GRAND TOTAL
                              </td>
                              <td colspan="1">
                                {formData?.TotalWeight === undefined
                                  ? ""
                                  : formData?.TotalWeight}
                              </td>

                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div className="col-12 break-all">
                      <h4 className="mt-5 mb-5">
                        There are {formData?.Items?.length} items on this BOL -
                        See packing list below
                      </h4>
                    </div> */}
                    {/* Notes */}

                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td colspan="5" className="align-top">
                                Where the rate is dependent on value, shippers
                                are required to state specifically in writing
                                the agreed or declared value of the property as
                                follows: Noting a value is not a request for
                                Additional Cargo Liability under OD Rules 100,
                                Item 574. COD Amount: $ ______________________
                                “The agreed or declared value of the property is
                                specifically stated by the shipper to be not
                                exceeding<br></br>
                                _________________ per ___________________.”
                              </td>
                              <td colspan="4" className="font-weight-boldest">
                                COD Amount: $ {formData?.CodAmount}
                                <br></br>
                                <label className="font-weight-boldest mr-2">
                                  {" "}
                                  Fee Terms:
                                </label>
                                <div className="d-flex">
                                  <CheckboxLargeWBFob
                                    id="prepaidFeeCheck"
                                    label=""
                                    text="Prepaid"
                                    name=""
                                    checked={false}
                                  />
                                  <CheckboxLargeWBFob
                                    id="collectFeeCheck"
                                    label=""
                                    text="Collect"
                                    name=""
                                    checked={false}
                                  />
                                  <CheckboxLargeWBFob
                                    Id="acceptableCheck"
                                    label=""
                                    text="Personal/company check NOT acceptable: "
                                    name=""
                                    checked={false}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td
                                colspan="9"
                                className="font-weight-boldest text-danger"
                              >
                                {formData?.IsHeavy === true
                                  ? "NOTE - This Load is Heavy"
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td
                                colspan="4"
                                className="font-smaller align-top"
                              >
                                RECEIVED, subject to the rates, classifications
                                and rules that have been established by the
                                Carrier and are available on request to the
                                Shipper (Shipper defined in 49 U.S.C.A. §
                                13102(13)(c)), and to all applicable state and
                                federal regulations. Shipper 1) warrants it has
                                read all applicable contract(s) or Carrier’s
                                applicable tariff(s) and the limitation of
                                liability provisions set forth therein; and 2)
                                has actual knowledge of and accepts the
                                applicable contract or tariff terms, including
                                the limits on carrier liability. Carriers’
                                tariff(s), including OD Rules 100, take
                                precedence in the event of any terms or
                                conditions conflicts.
                              </td>
                              <td colspan="5" className="">
                                The carrier shall not make delivery of this
                                shipment without payment of freight and all
                                other lawful charges.<br></br>
                                <label className="font-weight-boldest mr-2">
                                  {" "}
                                  ______________________ <br></br>
                                  Shipper Signature
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest align-top">
                                SHIPPER SIGNATURE / DATE
                                <p>
                                  <small>
                                    This is to certify that the above named
                                    materials are properly classified,
                                    described, packaged, marked and labeled, and
                                    are in proper condition for transportation
                                    according to the applicable regulations of
                                    the U.S. DOT
                                  </small>
                                </p>
                              </td>
                              <td
                                className="align-top border-right-0"
                                colspan="2"
                              >
                                <label className="font-weight-boldest">
                                  Trailer Loaded
                                </label>
                                <CheckboxLargeWBFob
                                  label=""
                                  text="By Shipper"
                                  name=""
                                />
                                <CheckboxLargeWBFob
                                  label=""
                                  text="By Driver"
                                  name=""
                                />
                              </td>
                              <td className="align-top border-left-0 w-25">
                                <label className="font-weight-boldest">
                                  Freight Counted:
                                </label>
                                <CheckboxLargeWBFob
                                  label=""
                                  text="By Shipper"
                                  name=""
                                />
                                <CheckboxLargeWBFob
                                  label=""
                                  text="By Driver/pallets said to contain"
                                  name=""
                                />
                                <CheckboxLargeWBFob
                                  label=""
                                  text="By Driver/Pieces"
                                  name=""
                                />
                              </td>
                              <td className="font-weight-boldest align-top">
                                CARRIER SIGNATURE / PICKUP DATE <br></br>
                                <p>
                                  <small>
                                    Carrier acknowledges receipt of packages and
                                    required placards. Carrier certifies
                                    emergency response information was made
                                    available and/or carrier has the U.S. DOT
                                    emergency response guidebook or equivalent
                                    documentation in the vehicle.
                                  </small>
                                </p>
                                <br></br>
                                <p>
                                  <small className="font-weight-boldest">
                                    <em>
                                      Property described above is received in
                                      good order, except as noted
                                    </em>
                                  </small>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest align-top marginPrint">
                                CUSTOMER SIGNATURE / DATE
                                <p>
                                  <small>{""}</small>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
          <div className="separator separator-dashed separator-border-2"></div>
          {formData?.Stops?.map((items, index) => {
            return (
              <>
                <Fragment>
                  <div
                    className="card py-5 px-0 bl-form border-0 break-all"
                    key={index + 1}
                  >
                    <div className="card-body p-0">
                      {/* First Data */}
                      <div className="row justify-content-center pt-8 px-1 pt-md-0 px-md-0 pb-md-10">
                        <div className="col-md-12">
                          <div className="row pb-0">
                            <div className="col-md-12 px-0">
                              <table className="w-100">
                              <tr>
                            <td className="border-0 font-weight-boldest w-25">
                            </td>
                            <td className="border-0 text-center display-4 font-weight-boldest">
                              BILL OF LADING
                            </td>
                            <td className="border-0 text-right font-weight-boldest w-25">
                              
                            </td>
                          </tr>
                                <tr>
                                  <td
                                    className="border-0 font-weight-boldest pb-3"
                                    style={{ width: "28%" }}
                                  >
                                    {" "}
                                    {items?.PuAppointmentDate && (
                                      <>
                                        PickupAppointment Date:{" "}
                                        {momentDateOnly(
                                          items?.PuAppointmentDate
                                        )}{" "}
                                        {`${momentTime(
                                          items?.PuAppointmentDate
                                        )} -
                                  ${
                                    new Date(
                                      items?.PuAppointmentDate
                                    ).getDate() ===
                                    new Date(
                                      items?.PuAppointmentToDate
                                    ).getDate()
                                      ? momentTime(items?.PuAppointmentToDate)
                                      : momentDateOnly(
                                          items?.PuAppointmentToDate
                                        ) +
                                        " " +
                                        momentTime(items?.PuAppointmentToDate)
                                  }`}
                                      </>
                                    )}
                                  </td>
                                  <td className="border-0 text-center display-4 font-weight-boldest"></td>
                                  <td className="border-0 text-right font-weight-boldest w-25"></td>
                                </tr>
                                <tr>
                                  <td
                                    className="border-0 font-weight-boldest pb-2"
                                    style={{ width: "28%" }}
                                  >
                                    {" "}
                                    {items?.DelAppointmentDate && (
                                      <>
                                        Appointment Date :{" "}
                                        {momentDateOnly(
                                          items?.DelAppointmentDate
                                        )}{" "}
                                        {`${momentTime(
                                          items?.DelAppointmentDate
                                        )} -
                                  ${
                                    new Date(
                                      items?.DelAppointmentDate
                                    ).getDate() ===
                                    new Date(
                                      items?.DelAppointmentToDate
                                    ).getDate()
                                      ? momentTime(items?.DelAppointmentToDate)
                                      : momentDateOnly(
                                          items?.DelAppointmentToDate
                                        ) +
                                        " " +
                                        momentTime(items?.DelAppointmentToDate)
                                  }`}
                                      </>
                                    )}
                                  </td>
                                  <td className="border-0 text-center display-4 font-weight-boldest"></td>
                                  <td className="border-0 text-right font-weight-boldest w-25"></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <thead className="d-flex justify-content-center">
                                  <tr>
                                    <th colspan="10" className="text-center">
                                      SHIP FROM
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="w-50 font-weight-boldest">
                                      Name:{" "}
                                      <span className="font-weight-normal">
                                        {items?.FromAddress?.Company}
                                      </span>
                                    </td>
                                    <td
                                      rowspan="5"
                                      className="font-weight-boldest align-top"
                                    >
                                      Bill of Lading Number:
                                      <span className="font-weight-normal">
                                        {formData?.Code}
                                      </span>{" "}
                                      <br></br>
                                      <span>Customer Ref # :</span>
                                      <span className="font-weight-normal">
                                        {formData?.CustomerRefNumber}
                                      </span>{" "}
                                      <br></br> <br></br>
                                      <p className="text-muted text-center font-weight-boldest"></p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Address:{" "}
                                      <span className="font-weight-normal">
                                        {items?.FromAddress?.Address1}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      City/State/Zip:{" "}
                                      <span className="font-weight-normal">
                                        {IsValidAddress(
                                          items?.FromAddress?.City?.replace(
                                            / /g,
                                            ""
                                          ),
                                          true
                                        ) +
                                          IsValidAddress(
                                            items?.FromAddress?.State,
                                            false
                                          ) +
                                          IsValidAddress(
                                            items?.FromAddress?.ZipCode,
                                            false
                                          )}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Phone:{" "}
                                      <span className="font-weight-normal">
                                        {items?.FromAddress?.ContactPhone}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      SID#:{" "}
                                      <span className="font-weight-normal">
                                        {items?.FromAddress?.Number}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="mb-4">
                                      <CheckboxLargeWBFob
                                        style={{ float: "right" }}
                                        label=""
                                        text="FOB"
                                        name=""
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <thead className="d-flex justify-content-center">
                                  <tr>
                                    <th colspan="10" className="text-center">
                                      SHIP TO{" "}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="font-weight-boldest w-50">
                                      Name:{" "}
                                      <span className="font-weight-normal">
                                        {items?.ToAddress?.Company}
                                      </span>
                                    </td>
                                    <td className="font-weight-boldest">
                                      CARRIER NAME:{" "}
                                      <span className="font-weight-normal">
                                        {formData?.Trucker?.Name}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Address:{" "}
                                      <span className="font-weight-normal">
                                        {items?.ToAddress?.Address1}
                                      </span>
                                    </td>
                                    <td className="font-weight-boldest">
                                      Trailer number:{" "}
                                      <span className="font-weight-normal">
                                        {formData?.Trucker?.TrailerNumber}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      City/State/Zip:{" "}
                                      <span className="font-weight-normal">
                                        {IsValidAddress(
                                          items?.ToAddress?.City?.replace(
                                            / /g,
                                            ""
                                          ),
                                          true
                                        ) +
                                          IsValidAddress(
                                            items?.ToAddress?.State,
                                            false
                                          ) +
                                          IsValidAddress(
                                            items?.ToAddress?.ZipCode,
                                            false
                                          )}
                                      </span>
                                    </td>
                                    <td className="font-weight-boldest">
                                      Seal number(s):{" "}
                                      <span className="font-weight-normal">
                                        {formData?.Trucker?.SealNumber}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Phone:{" "}
                                      <span className="font-weight-normal">
                                        {items?.ToAddress?.ContactPhone}
                                      </span>
                                    </td>
                                    <td className="font-weight-boldest">
                                      SCAC:{" "}
                                      <span className="font-weight-normal">
                                        {formData?.Trucker?.Scac}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Location#:{" "}
                                    </td>
                                    <td className="font-weight-boldest">
                                      Pro number:{" "}
                                      <span className="font-weight-normal">
                                        {formData?.Trucker?.ProNumber}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      CID#:{" "}
                                      <span className="font-weight-normal">
                                        {items?.ToAddress?.Number}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="mb-4">
                                      <CheckboxLargeWBFob
                                        style={{ float: "right" }}
                                        label=""
                                        text="FOB"
                                        name=""
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <thead className="d-flex justify-content-center">
                                  <tr>
                                    <th colspan="10" className="text-center">
                                      THIRD PARTY FREIGHT CHARGES BILL TO:{" "}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Name:{" "}
                                      <span className="font-weight-normal">
                                        {formData?.BillTo?.Company}
                                      </span>
                                    </td>
                                    <td
                                      rowspan="3"
                                      className="font-weight-boldest"
                                    >
                                      Freight Charge Terms:<br></br>
                                      <small className="italic">
                                        (freight charges are prepaid unless
                                        marked collect)
                                      </small>
                                      <br></br>
                                      <div className="d-flex">
                                        <CheckboxLargeWBFob
                                          Id="PrepaidCheck"
                                          label=""
                                          text="Prepaid"
                                          name=""
                                          checked={
                                            formData?.FreightChargesterms ===
                                            "Prepaid"
                                              ? true
                                              : false
                                          }
                                        />
                                        <CheckboxLargeWBFob
                                          Id="CollectCheck"
                                          label=""
                                          text="Collect"
                                          name=""
                                          checked={
                                            formData?.FreightChargesterms ===
                                            "Collect"
                                              ? true
                                              : false
                                          }
                                        />
                                        <CheckboxLargeWBFob
                                          Id="PartyCheck"
                                          label=""
                                          text="3rd Party"
                                          name=""
                                          checked={
                                            formData?.FreightChargesterms ===
                                            "Third Party Billing"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Address:{" "}
                                      <span className="font-weight-normal">
                                        {formData?.BillTo?.Address1}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      City/State/Zip:{" "}
                                      <span className="font-weight-normal">
                                        {IsValidAddress(
                                          formData?.BillTo?.City?.replace(
                                            / /g,
                                            ""
                                          ),
                                          true
                                        ) +
                                          IsValidAddress(
                                            formData?.BillTo?.State,
                                            false
                                          ) +
                                          IsValidAddress(
                                            formData?.BillTo?.ZipCode,
                                            false
                                          )}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest">
                                      Phone:{" "}
                                      <span className="font-weight-normal">
                                        {formData?.BillTo?.ContactPhone}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest w-50">
                                      SPECIAL INSTRUCTIONS:{" "}
                                      <p className="form-control border-0 h-auto"></p>
                                      <span
                                        style={{
                                          textDecoration: "underline",
                                          fontWeight: "bold",
                                          padding: "1.2rem",
                                        }}
                                      ></span>
                                    </td>
                                    <td className="align-top">
                                      <CheckboxLargeWBFob
                                        label=""
                                        text="Master Bill of Lading: with attached
                                                                underlying Bills of Lading"
                                        name=""
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100 footer-bol">
                                <thead className="justify-content-center">
                                  <tr>
                                    <th colspan="14" className="text-center">
                                      CARRIER INFORMATION
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      rowspan="2"
                                      className="font-weight-boldest text-center space-bol"
                                    >
                                      ITEM NO
                                    </td>

                                    <td
                                      colspan="2"
                                      className="font-weight-boldest text-center"
                                    >
                                      PACKAGE{" "}
                                    </td>
                                    <td
                                      rowspan="2"
                                      colspan="1"
                                      className="font-weight-boldest text-center"
                                    >
                                      WEIGHT
                                    </td>
                                    <td
                                      rowspan="2"
                                      className="font-weight-boldest text-center"
                                    >
                                      H.M.(X)
                                    </td>
                                    <td
                                      rowspan="2"
                                      colspan="3"
                                      className="font-weight-boldest text-center"
                                    >
                                      COMMODITY DESCRIPTION<br></br>
                                      <small>
                                        Commodities requiring special or
                                        additional care or attention in handling
                                        or stowing{" "}
                                      </small>
                                      <br></br>
                                      <small>
                                        must be so marked and packaged as to
                                        ensure safe transportation with ordinary
                                        care.{" "}
                                      </small>
                                      <br></br>
                                      <small className="font-weight-boldest">
                                        See Section 2(e) of NMFC Item 360
                                      </small>
                                    </td>
                                    <td
                                      colspan="2"
                                      className="font-weight-boldest text-center"
                                    >
                                      LTL ONLY{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest text-center">
                                      QTY
                                    </td>
                                    <td className="font-weight-boldest text-center">
                                      Type
                                    </td>
                                    <td className="font-weight-boldest text-center">
                                      NMFC #{" "}
                                    </td>
                                    <td className="font-weight-boldest text-center">
                                      CLASS
                                    </td>
                                  </tr>
                                  {items?.Items?.length > 0 &&
                                    items?.Items.map((item, indx) => (
                                      <tr key={indx}>
                                        <td>{item.Id}</td>
                                        <td>{item.Pieces}</td>
                                        <td>{item.PackageType}</td>
                                        <td colspan="1">{item.Weight}</td>
                                        {/* HZ CHECK */}
                                        <td className="">
                                          <CheckboxLargeWBFob
                                            checked={
                                              item.HazMat !== null &&
                                              item.HazMat !== false
                                                ? true
                                                : false
                                            }
                                            label=""
                                            text=""
                                            name=""
                                            className=""
                                          />
                                        </td>
                                        <td colspan="3">
                                          {item.Commodity === ""
                                            ? ""
                                            : item.Commodity}
                                        </td>
                                        <td>{item.NMFC}</td>
                                        <td>{item.Class}</td>
                                      </tr>
                                    ))}
                                  <tr>
                                    <td className="font-weight-boldest text-center">
                                      GRAND TOTAL
                                    </td>
                                    <td>{items?.TotalPieces}</td>
                                    <td className="font-weight-boldest text-center">
                                      GRAND TOTAL
                                    </td>
                                    <td colspan="1">
                                      {items?.TotalWeight === undefined
                                        ? ""
                                        : items?.TotalWeight}
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* <div className="col-12 break-all">
                            <h4 className="mt-5 mb-5">
                              There are {items?.Items?.length} items on this BOL
                              - See packing list below
                            </h4>
                          </div> */}

                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td colspan="5" className="align-top">
                                      Where the rate is dependent on value,
                                      shippers are required to state
                                      specifically in writing the agreed or
                                      declared value of the property as follows:
                                      Noting a value is not a request for
                                      Additional Cargo Liability under OD Rules
                                      100, Item 574. COD Amount: $
                                      ______________________ “The agreed or
                                      declared value of the property is
                                      specifically stated by the shipper to be
                                      not exceeding<br></br>
                                      _________________ per
                                      ___________________.”
                                    </td>
                                    <td
                                      colspan="4"
                                      className="font-weight-boldest"
                                    >
                                      COD Amount: $ {formData?.CodAmount}
                                      <br></br>
                                      <label className="font-weight-boldest mr-2">
                                        {" "}
                                        Fee Terms:
                                      </label>
                                      <div className="d-flex">
                                        <CheckboxLargeWBFob
                                          id="prepaidFeeCheck"
                                          label=""
                                          text="Prepaid"
                                          name=""
                                          checked={false}
                                        />
                                        <CheckboxLargeWBFob
                                          id="collectFeeCheck"
                                          label=""
                                          text="Collect"
                                          name=""
                                          checked={false}
                                        />
                                        <CheckboxLargeWBFob
                                          Id="acceptableCheck"
                                          label=""
                                          text="Personal/company check NOT acceptable: "
                                          name=""
                                          checked={false}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td
                                      colspan="9"
                                      className="font-weight-boldest text-danger"
                                    >
                                      {formData?.IsHeavy === true
                                        ? "NOTE - This Load is Heavy"
                                        : ""}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td
                                      colspan="4"
                                      className="font-smaller align-top"
                                    >
                                      RECEIVED, subject to the rates,
                                      classifications and rules that have been
                                      established by the Carrier and are
                                      available on request to the Shipper
                                      (Shipper defined in 49 U.S.C.A. §
                                      13102(13)(c)), and to all applicable state
                                      and federal regulations. Shipper 1)
                                      warrants it has read all applicable
                                      contract(s) or Carrier’s applicable
                                      tariff(s) and the limitation of liability
                                      provisions set forth therein; and 2) has
                                      actual knowledge of and accepts the
                                      applicable contract or tariff terms,
                                      including the limits on carrier liability.
                                      Carriers’ tariff(s), including OD Rules
                                      100, take precedence in the event of any
                                      terms or conditions conflicts.
                                    </td>
                                    <td colspan="5" className="">
                                      The carrier shall not make delivery of
                                      this shipment without payment of freight
                                      and all other lawful charges.<br></br>
                                      <label className="font-weight-boldest mr-2">
                                        {" "}
                                        ______________________ <br></br>
                                        Shipper Signature
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td className="font-weight-boldest align-top">
                                      SHIPPER SIGNATURE / DATE
                                      <p>
                                        <small>
                                          This is to certify that the above
                                          named materials are properly
                                          classified, described, packaged,
                                          marked and labeled, and are in proper
                                          condition for transportation according
                                          to the applicable regulations of the
                                          U.S. DOT
                                        </small>
                                      </p>
                                    </td>
                                    <td
                                      className="align-top border-right-0"
                                      colspan="2"
                                    >
                                      <label className="font-weight-boldest">
                                        Trailer Loaded
                                      </label>
                                      <CheckboxLargeWBFob
                                        label=""
                                        text="By Shipper"
                                        name=""
                                      />
                                      <CheckboxLargeWBFob
                                        label=""
                                        text="By Driver"
                                        name=""
                                      />
                                    </td>
                                    <td className="align-top border-left-0 w-25">
                                      <label className="font-weight-boldest">
                                        Freight Counted:
                                      </label>
                                      <CheckboxLargeWBFob
                                        label=""
                                        text="By Shipper"
                                        name=""
                                      />
                                      <CheckboxLargeWBFob
                                        label=""
                                        text="By Driver/pallets said to contain"
                                        name=""
                                      />
                                      <CheckboxLargeWBFob
                                        label=""
                                        text="By Driver/Pieces"
                                        name=""
                                      />
                                    </td>
                                    <td className="font-weight-boldest align-top">
                                      CARRIER SIGNATURE / PICKUP DATE <br></br>
                                      <p>
                                        <small>
                                          Carrier acknowledges receipt of
                                          packages and required placards.
                                          Carrier certifies emergency response
                                          information was made available and/or
                                          carrier has the U.S. DOT emergency
                                          response guidebook or equivalent
                                          documentation in the vehicle.
                                        </small>
                                      </p>
                                      <br></br>
                                      <p>
                                        <small className="font-weight-boldest">
                                          <em>
                                            Property described above is received
                                            in good order, except as noted
                                          </em>
                                        </small>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pb-0">
                            <div className="col-md-12 px-0 ">
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td className="font-weight-boldest align-top marginPrint">
                                      CUSTOMER SIGNATURE / DATE
                                      <p>
                                        <small>{""}</small>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
                <div className="separator separator-dashed separator-border-2"></div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}
export default function BolForm({ id }) {
  const paramId = window.location.pathname.split("/")[4];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="overflow-hidden">
        <div className="container">
          <div className="col-md-12">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder mr-3"
                onClick={handlePrint}
              >
                Print BLForm
              </button>
              {/* <button type="button" className="btn btn-light-primary font-weight-bolder" onClick={handlePrint}>Download</button> */}
            </div>
          </div>
        </div>
        <ComponentToPrint
          ref={componentRef}
          id={id === undefined ? paramId : id}
        />
    </div>
  );
}
