import MockUtils from "./mock.utilsAT";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;

export default function mockProducts(mock) {
  function GetShipmentsGridData(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    let equipmentType = params.filter.equipmentTypeId;
    if (equipmentType.length > 0) {
      var listequipmentType = equipmentType.map((a) => a.value);
      equipmentType = "&equipmentType=" + listequipmentType.join("&equipmentType=");
    } else {
      equipmentType = "";
    }
    let Status = params.filter.statusId;
    if (Status != null) {
      const Id = Status.value;
      Status = "&status=" + Id;
    } else {
      Status = "";
    }
    let Search = params.filter.searchText;
    if (Search != null) {
      // const searchText = Search;
      Search = "&search=" + Search;
    } else {
      Search = "";
    }
    // console.log("Search", Search);
    return fetch(
      //api/MyLoads?equipmentType[0]={equipmentType[0]}&equipmentType[1]={equipmentType[1]}&pickup={pickup}&destination={destination}&status={status}&code={code}&pageNumber={pageNumber}&pageSize={pageSize}&sortField={sortField}&sortOrder={sortOrder}
      `${window.$apiUrl}/api/MyLoads?${equipmentType}${Status}${Search}&pageNumber=${params.pageNumber}&pageSize=${params.pageSize}
         &sortField=${params.sortField}&sortOrder=${params.sortOrder}`,
         requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        return result;
      });
  }
  function deleteShipmet(id) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "delete",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/Shipments/${id}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  mock.onPost("api/products").reply(({ data }) => {
    const { product } = JSON.parse(data);
    const {
      model = "",
      manufacture = "",
      modelYear = 2000,
      mileage = 0,
      description = "",
      color = "Black",
      price = 1000,
      condition = 0,
      status = 0,
      VINCode = "",
    } = product;

    const id = generateProductId();
    const newProduct = {
      id,
      model,
      manufacture,
      modelYear,
      mileage,
      description,
      color,
      price,
      condition,
      status,
      VINCode,
    };
    carTableMock.push(newProduct);
    return [200, { product: newProduct }];
  });

  mock.onPost("api/products/find").reply(async (config) => {
    //alert();
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    let _Filter = { ...queryParams };
    let result = await GetShipmentsGridData(_Filter);
    if (result[0] === 200) {
      let data = result[1];
      _Filter.TotalItems = data.Pagination.TotalItemCount;
      carTableMock = data.Result.map((element) => {
        return { id: element.Id, ...element };
      });
      const filteredProducts = mockUtils.baseFilter(carTableMock, _Filter);
      return [200, filteredProducts];
    } else {
      const filteredProducts = mockUtils.baseFilter(carTableMock, _Filter);

      return [400, filteredProducts];
    }
  });

  mock.onPost("api/products/deleteProducts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/products/updateStatusForProducts").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/products\/\d+/).reply((config) => {
    const id = config.url.match(/api\/products\/(\d+)/)[1];
    const product = carTableMock.find((el) => el.id === +id);
    if (!product) {
      return [400];
    }

    return [200, product];
  });

  mock.onPut(/api\/products\/\d+/).reply((config) => {
    const id = config.url.match(/api\/products\/(\d+)/)[1];
    const { product } = JSON.parse(config.data);
    const index = carTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...product };
    return [200];
  });

  mock.onDelete(/api\/products\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/products\/(\d+)/)[1];
    const index = carTableMock.findIndex((el) => el.id === +id);
    let result = await deleteShipmet(id);
    carTableMock.splice(index, 1);
    if (result[0] !== 200) {
      return [400];
    }

    return [200];
  });
}

function generateProductId() {
  const ids = carTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
