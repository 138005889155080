/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { TableLoadWidget } from "../widgets";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import GoogleMapContainer from "./GoogleMapComp copy";
import { useTranslation } from "react-i18next";
import { useHtmlClassService } from "../../layout";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { KTUtil } from "../../_assets/js/components/util";
import { translateAuto } from "../../_helpers/AtHelper";
const adapter = new LocalStorage("db");
const db = low(adapter);
const Colors = [
  "#8950FC",
  "#32C5D2",
  "#ff7c43",
  "#6993ff",
  "#FFA800",
  "#009688",
  "#991f17",
];
export function Demo2Dashboard() {
  const history = useHistory();
  const { t } = useTranslation();
  const [Dash, setDash] = useState([]);
  const [SelectedLoadId, setSelectedLoadId] = useState(null);
  const [statusSelected, setstatusSelected] = useState("");
  const [loadingData, setloadingData] = useState(false);
  const [LoadsPositionList, setLoadsPositionList] = useState([]);

  function getLoadsPosition() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/Tracking`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setLoadsPositionList(data?.Shipments || []);
        } else if (result[0] === 402) {
          Swal.fire("error", translateAuto(result[1].Message));
          history.push("/user-profile#billing");
        } else if (result[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const [entities, setentities] = useState([]);
  const [SortOption, setSortOption] = useState({
    sortField: "Id",
    sortOrder: "desc",
  });
  function getDachboardLoads(statusId, sortOptions) {
    var _status =
      statusId !== undefined && statusId !== null && statusId !== ""
        ? statusId
        : "";
    setloadingData(true);
    setentities([]);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiUrl}/api/MyLoads?status=${_status}&destination=&onlyAllowed=&isQuickPay=&pickup=&code=&equipmentType[0]=&pageNumber=1&pageSize=10&sortField=${sortOptions?.sortField}&sortOrder=${sortOptions?.sortOrder}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setentities(result[1].Result || []);
          setSortOption(sortOptions);
        }
        setloadingData(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingData(false);
      });
  }
  useEffect(() => {
    getLoadsPosition();
    getDachboardLoads(statusSelected?.StatusId, SortOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getchartDate();
  }, []);
  function getchartDate() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${window.$apiUrl}/api/Dashboard`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setDash(result[1].Shipment || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);
  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, "height"));
    const options = getChartOptions(layoutProps, height);

    const chart = new ApexCharts(element, options);
    chart.render();
    // function reset() {
    //   return options.series;
    // }
    return function cleanUp() {
      chart.updateSeries(options.series);
      chart.destroy();
    };
  }, [Dash]);
  function getChartOptions(layoutProps, height) {
    const options = {
      series: Dash.map((a) => a.Count),
      chart: {
        height: 600,
        type: "donut",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            if (config.selectedDataPoints[0].length > 0) {
              let id = Dash.find(
                (a) => a.Name === config.w.config.labels[config.dataPointIndex]
              );
              setstatusSelected(id);
              getDachboardLoads(id.StatusId, SortOption);
            } else {
              setstatusSelected("");
              getDachboardLoads("", SortOption);
              chartContext.updateOptions(
                {
                  chart: {
                    selection: {
                      xaxis: {
                        min: undefined,
                        max: undefined,
                      },
                      yaxis: {
                        min: undefined,
                        max: undefined,
                      },
                    },
                  },
                },
                true
              );
            }
          },
        },
      },
      colors: Colors,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                showAlways: true,
                label: "Total",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        position: "bottom",
        offsetY: 0,
        fontSize: "15px",
        fontWeight: 600,
        horizontalAlign: "left",
        itemMargin: {
          horizontal: 10,
          vertical: 20,
        },
        formatter: function(seriesName, opts) {
          return `${seriesName} (${
            opts.w.globals.seriesTotals[opts.seriesIndex]
          })`;
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 1180,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 1181,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 600,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: Dash.map((a) => a.Name),
    };
    return options;
  }
  return (
    <>
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-6">
          {Dash.length > 0 && (
            <div className={`card card-custom gutter-b card-stretch `}>
              {/* Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder ">
                  {t("Statistics")}
                </h3>
              </div>
              {/* Body */}
              <div className="card-body d-flex flex-column justify-content-center pl-xl-0 pl-lg-0">
                <div
                  id="kt_mixed_widget_14_chart"
                  className="h-xl-600px h-lg-500px"
                  style={{ cursor: "pointer" }}
                ></div>
              </div>
            </div>
          )}
        </div>
        <div className="col-xl-8 col-lg-8 col-md-6">
          <TableLoadWidget
            className="card-stretch gutter-b"
            Dash={Dash}
            entities={entities}
            loadingData={loadingData}
            setstatusSelected={setstatusSelected}
            statusSelected={statusSelected}
            getDachboardLoads={getDachboardLoads}
            setSortOption={setSortOption}
            SortOption={SortOption}
            getchartDate={getchartDate}
          />
        </div>
        <div className="col-xl-12">
          <div className={`card card-custom card-stretch gutter-b`}>
            {/* Header */}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title font-weight-bolder ">
                {t("Tracking")}
              </h3>
            </div>
            {/* Body */}
            <div
              className="card-body d-flex flex-column"
              style={{ height: "600px" }}
            >
              <GoogleMapContainer
                LoadsPositionList={LoadsPositionList}
                setSelectedLoadId={setSelectedLoadId}
                SelectedLoadId={SelectedLoadId}
                isDashboard={true}
                history={history}
              />
            </div>
          </div>
        </div>
      </div>

      {/* end::Dashboard */}
    </>
  );
}
