/* eslint-disable react-hooks/exhaustive-deps */
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ConvertToDateCurrentTimeZone } from "../../../../../../_metronic/_helpers/AtHelper";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../../../_metronic/_partials/controls";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function AttachementComp({ id,  isTruckerDetails , LoadInformations}) {
  const history = useHistory();
  const [AttachmentsList, setAttachmentsList] = useState([]);
  const [path, setPath] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [attachmentsInfo, setAttachmentsInfo] = useState([]);
  const { t } = useTranslation();
  const [attachmentsGroupedByStopId, setAttachmentsGroupedByStopId] = useState([]);


  function groupBy(array, property) {
    var grouped = array.reduce(function (acc, obj) {
      var key = obj[property] ? obj[property] : "";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      console.log("acc", acc);
      return acc;
    }, {});
    setAttachmentsGroupedByStopId(grouped);
  }

  function GetAttachments() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/attachments/shipment/${id}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setAttachmentsList(data);
          groupBy(data, "StopId");
        } else if (result[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }



  function GetAttachmentPath(id) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiUrl}/api/Attachments/${id}`, requestOptions)


      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setAttachmentsInfo(data);
          setPath(data.Attachment.FilePath);
          if (
            data.Attachment.FileName.includes(".jpg") ||
            data.Attachment.FileName.includes(".png") ||
            data.Attachment.FileName.includes(".jpeg")

          ) {
            handleShow();
          } else {
            window.open(data.Attachment.FilePath, "_blank");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }


  useEffect(() => {
    GetAttachments();
  }, [id]);
  return (
    <>
      <Card>
        <CardHeader title={t("Attachments")}>
          <CardHeaderToolbar>
          
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            {
              Object.keys(attachmentsGroupedByStopId).map((key, index) => {
                return (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h4>
                          {
                            key === "" ? "" : t("Stop").toUpperCase() +" : " + LoadInformations?.Stops?.find(x => x.Id == key)?.Name?.toUpperCase()
                          }
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      {
                        attachmentsGroupedByStopId[key].map((items, index) => {
                          let extention = items.FileName?.split(".").pop();
                          return (
                            <div key={index} className={isTruckerDetails ? "col-xl-6 col-lg-6 col-md-6 col-sm-6" : "col-xl-3 col-lg-6 col-md-6 col-sm-6"}>
                              {/*begin::Card*/}
                              <div className="card card-custom gutter-b card-stretch shadow-sm  bg-gray-100">
                                <div className="card-body">
                                  <div className="d-flex flex-column align-items-center">
                                    {/*begin: Icon*/}
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        GetAttachmentPath(items.Id);
                                      }}
                                      alt="logo"
                                      className="max-h-65px"
                                      src={`/media/svg/files/${
                                        String(extention).includes("pdf")
                                          ? "pdf"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("xml")
                                          ? "xml"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("tif")
                                          ? "tif"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("sql")
                                          ? "sql"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("doc")
                                          ? "doc"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("css")
                                          ? "css"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("pdf")
                                          ? "pdf"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("ai")
                                          ? "ai"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("xls")
                                          ? "xls"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("png")
                                          ? "png"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("jpg")
                                          ? "jpg"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("jpeg")
                                          ? "jpg"
                                          : String(extention)
                                              .toLocaleLowerCase()
                                              .includes("csv")
                                          ? "csv"
                                          : "text"
                                      }.svg`}
                                    />
                                    {/*  eslint-disable */}
                                    <a
                                      onClick={(e) => {
                                        GetAttachmentPath(items.Id);
                                      }}
                                      rel="noopener noreferrer"
                                      className="text-dark-75 font-weight-bold mt-3 font-size-lg w-100 text-center"
                                    >
                                      {items.FileName}
                                    </a>
                                    <span className="navi-text text-muted">
                                      {items?.Type}
                                    </span>
                                    <span className="navi-text text-muted">
                                      {ConvertToDateCurrentTimeZone(items.Created)}
                                    </span>
                                    {/*end: Tite*/}
                                  </div>
                                </div>
                              </div>
                              {/*end:: Card*/}
                            </div>
                        );
                        })
                      }
                    </div>
                  </div>
                )
              }).reverse()
            }
          </div>
        </CardBody>
      </Card>

      {/* ########################Open attachments ####################### */}
      <Modal size="lg" show={show} onHide={handleClose} animation={false}>
        <Modal.Body className="pb-0">
          <Row className="mb-6">
            <Col>
              <div className="d-flex flex-column font-weight-bold">
              {/*eslint-disable */  }
                <a className="text-muted text-hover-primary mb-1 font-size-lg">
                  {t("Uploaded_at")}
                </a>
                <span className="text-dark font-weight-bold">{ConvertToDateCurrentTimeZone(attachmentsInfo?.Attachment?.Created)}</span>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column font-weight-bold">
                <a className="text-muted text-hover-primary mb-1 font-size-lg">
                {t("Type")}
                </a>
                <span className="text-dark">{attachmentsInfo?.Attachment?.Type}</span>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column font-weight-bold">
                <a className="text-muted text-hover-primary mb-1 font-size-lg">
                {t("File_Name")}
                </a>
                <span className="text-dark"> {attachmentsInfo?.Attachment?.FileName?.length > 25 ? attachmentsInfo?.Attachment?.FileName?.substring(0, 25) + "..." : attachmentsInfo?.Attachment?.FileName}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <img style={{ maxWidth: "100%", height: "auto" }} src={path} alt={attachmentsInfo?.Attachment?.FilePath || ""} />
          </Row>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
