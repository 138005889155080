import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { default as Select } from "react-select";
import { apiCall } from "./redux";

var typingTimer;

function Filter(props) {
  const {t} = useTranslation();
  //Date Filter
  const [equipments, setEquipments] = useState([]);
  const [value, setValue] = useState({ value: "", label: "All" });
  const [quickPay, setQuickPay] = useState({ value: null, label: "All" });
  async function getEquipments() {
    try {
      const result = await apiCall({
        method: "Get",
        link: `/api/generic/EquipmentTypes`,
      });
      if (result[0] === 200) {
        setEquipments(result[1] ?? []);
      } else {
        setEquipments([]);
      }
    } catch (err) {}
  }
  useEffect(() => {
    getEquipments();
  }, []);
  return (
    <Fragment>
      <form id="FilterShipmentManagement" className="form form-label-right">
        <div className="form-group row">
          <div className={"col-xl-3 col-lg-3 col-md-3"}>
              <p className="mb-2 mr-4">ID</p>
            <input
              type="search"
              className="form-control"
              name="searchText"
              id="searchTextTable"
              placeholder="ID"
              onChange={(e) => {
                const value = e.target.value;
                clearTimeout(typingTimer);
                typingTimer = setTimeout(function() {
                  let pagination = { ...props.paginationOptions };
                  pagination.page = 1;
                  props.fetchFilter("Id", value, "", pagination);
                }, 1000);
              }}
            />
          </div>
          <div className={"col-xl-3 col-lg-3 col-md-3"}>
            <p className="mb-2">{t("Origin")}</p>
            <input
              type="search"
              className="form-control"
              name="searchText"
              id="searchTextTable"
              placeholder={t("Origin")}
              onChange={(e) => {
                const value = e.target.value;
                clearTimeout(typingTimer);
                typingTimer = setTimeout(function() {
                  let pagination = { ...props.paginationOptions };
                  pagination.page = 1;
                  props.fetchFilter("pickup", value, "", pagination);
                }, 1000);
              }}
            />
          </div>
          <div className={"col-xl-3 col-lg-3 col-md-3"}>
            <p className="mb-2">{t("Destination")}</p>
            <input
              type="search"
              className="form-control"
              name="searchText"
              id="searchTextTable"
              placeholder={t("Destination")}
              onChange={(e) => {
                const value = e.target.value;
                clearTimeout(typingTimer);
                typingTimer = setTimeout(function() {
                  let pagination = { ...props.paginationOptions };
                  pagination.page = 1;
                  props.fetchFilter("destination", value, "", pagination);
                }, 1000);
              }}
            />
          </div>
          <Col lg="3" className="mt-md-12">
            <div className="d-flex">
              <div className="checkbox-list d-flex pl-0">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    id="AllowLoad"
                    onChange={(e) => {
                      let pagination = { ...props.paginationOptions };
                      pagination.page = 1;
                      props.fetchFilter(
                        "onlyAllowed",
                        e.target.checked,
                        "",
                        pagination
                      );
                    }}
                    name="AllowLoad"
                    className="mr-2"
                  />
                  <span className="mb-auto"></span>
                  <div className="d-flex flex-column flex-grow-1">
                    <label className="form-check-label" htmlFor="AllowLoad">
                      {t("Only_allowed_loads")}
                    </label>
                  </div>
                </label>
              </div>
            </div>
          </Col>
        </div>
        <Row>
          <div className={"col-xl-3 col-lg-3 col-md-3"}>
            <label>{t("Equipment")}</label>
            <Select
              options={
                equipments.length > 0
                  ? equipments.map((s) => {
                      return {
                        value: s.Id,
                        label: s.Name,
                      };
                    })
                  : []
              }
              value={value}
              name={"Equipment"}
              placeholder="All"
              isClearable
              onChange={(e) => {
                let pagination = { ...props.paginationOptions };
                pagination.page = 1;
                props.fetchFilter(
                  "equipmentType",
                  e?.value ?? "",
                  "",
                  pagination
                );
                setValue(e ?? { value: "", label: "All" });
              }}
            />
          </div>
          <Col lg="3">
            <label>{t("Only_Quick_Pay_loads")}</label>
            <Select
              options={[
                { value: null, label: "All" },
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              value={quickPay}
              name={"isQuickPay"}
              placeholder="All"
              isClearable
              onChange={(e) => {
                let pagination = { ...props.paginationOptions };
                pagination.page = 1;
                props.fetchFilter(
                  "isQuickPay",
                  e?.value ?? null,
                  "",
                  pagination
                );
                setQuickPay(e ?? { value: "", label: "All" });
              }}
            />
          </Col>
        </Row>
      </form>
    </Fragment>
  );
}

export default Filter;
