import {
  LOGIN_URL,
  ME_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
} from "../_redux/authCrud";
import userTableMock from "./userTableMock";

import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import Swal from "sweetalert2";
import { translateAuto } from "../../../../_metronic/_helpers/AtHelper";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function mockAuth(mock) {
  
  mock.onPost(LOGIN_URL).reply(({ data }) => {
    const { email, password } = JSON.parse(data);

    if (email && password) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("username", email);
      urlencoded.append("password", password);
      urlencoded.append("grant_type", "password");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      return fetch(
        `${window.$apiUrl}/api/token`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            let CallBackObjct = result[1];
            if(CallBackObjct.role === "Driver"){
              Swal.fire("error", translateAuto("You_dont_have_permission_to_access_into_TaaP_Web"), "error");
              return [400, { ...null, password: undefined }];
            }
            const userDefault = {
              FullName: CallBackObjct.userName,
              Email: CallBackObjct.userName,
            };
            db.set("Token", CallBackObjct.access_token).write();
            db.set("FirstName", CallBackObjct?.firstName).write();
          db.set("LastName", CallBackObjct?.lastName).write();
            db.set("User", userDefault).write();
            const user = {
              id: 1,
              username: CallBackObjct.UserName,
              password: undefined,
              email: CallBackObjct.userName,
              accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
              refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
            };

            db.set("user", user).write();
            return [200, { ...user, password: undefined }];
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Swal.fire("error", translateAuto(element), "error");
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Swal.fire("error", translateAuto(result[1].Message), "error");
            } else if (
              result[1].error_description !== null &&
              result[1].error_description !== undefined &&
              result[1].error_description !== ""
            ) {
              Swal.fire(translateAuto("Error"), translateAuto(result[1].error_description), "error");
            } else {
              Swal.fire(translateAuto("Error"), translateAuto("Bad_Request"), "error");
            }
            let usr = {
              id: 1,
              username: email,
              email: email,
              accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
              refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
            };
            return [400, { ...usr, password: undefined }];
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  });

  mock.onPost(REGISTER_URL).reply(({ data }) => {
    const { email, fullname, username, password } = JSON.parse(data);

    if (email && fullname && username && password) {
      const user = {
        id: generateUserId(),
        email,
        fullname,
        username,
        password,
        roles: [2], // Manager
        accessToken: "access-token-" + Math.random(),
        refreshToken: "access-token-" + Math.random(),
        pic: process.env.PUBLIC_URL + "/media/users/default.jpg",
      };

      userTableMock.push(user);

      return [200, { ...user, password: undefined }];
    }

    return [400];
  });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = userTableMock.find(
        (x) => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (accessToken) {
      const user = userTableMock.find((x) => x.accessToken === accessToken);

      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }

    return [401];
  });

  function generateUserId() {
    const ids = userTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
