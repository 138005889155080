import React, { createContext } from "react";
import { useMemo } from "react";
 
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nextLng";
const initialState = {
  selectedLang:  "en",
};

const ln = localStorage.getItem(I18N_CONFIG_KEY);
if (ln) {
  if (ln === 'fr-FR') {
    localStorage.setItem(I18N_CONFIG_KEY, "fr");
    window.location.reload();
  }
  if (ln === 'es-ES') {
    localStorage.setItem(I18N_CONFIG_KEY, "es");
    window.location.reload();
  }
  let languages = ["en-US", "fr", "es", 'fr-FR', 'es-ES'];
  if (!languages.includes(ln)) 
      localStorage.setItem(I18N_CONFIG_KEY, "en");
}


function getConfig() {
  const ls = localStorage.getItem("i18nextLng");
  if (ls) {

    try {
      return ls;
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang) {
  localStorage.setItem(I18N_CONFIG_KEY, lang);
  //window.location.reload();
}

const I18nContext = createContext();

export function useLang() {
  return localStorage.getItem(I18N_CONFIG_KEY);
}

export function withI18n(Component) {
  class WithI18n extends React.Component {
    static displayName = `WithI18n(${Component.displayName || Component.name})`;

    static contextType = I18nContext;

    render() {
      return <Component {...this.props} menu={this.context} />;
    }
  }

  return WithI18n;
}

export const I18nConsumer = I18nContext.Consumer;

export function MetronicI18nProvider({ children }) {
  const lang = useMemo(getConfig, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
}
